import { ApiUrl } from '../../public/app/Config';

export default {
  getFormMetadata: (fillProviderId, formName, onSuccess, onError) => {
    $.jjax({
      url: `${ApiUrl}fulfillmentapi/provider/${fillProviderId}/form/${formName}`,
      type: 'GET',
      success(response) {
        onSuccess({
          providerName: 'Fill Provider',
          ...response.form_metadata
        });
      },
      error: onError
    });
  }
};
