import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: 'e334bcda-6b8b-41d9-aee6-c65a875ec1ff',
    defaultMessage: 'Save Changes'
  }),
  defineMessage({
    id: '79716def-7e42-4968-9b51-6f0072f50f43',
    defaultMessage: 'Cancel'
  })
];

export default translations;
