import { ApiUrl } from '../app/Config';

define(['Data', 'models/RollbackModel'], function(Data, RollbackModel) {
  var copy = function(first, second) {
    var atts = _.keys(this.attributes);
    for (var x in atts) {
      if (atts[x].indexOf(first) === 0) {
        if (this.get(atts[x]) !== this.get(atts[x].replace(first, second)))
          return false;
      }
    }
    return true;
  };

  return RollbackModel.extend({
    urlRoot: ApiUrl + 'Warehouses',
    idAttribute: 'WarehouseID',
    defaults: {
      CopyOrigin: function() {
        return copy.call(this, 'Return', 'Origin');
      },
      CopyReturn: function() {
        return copy.call(this, 'Origin', 'Return');
      },
      '3rdPartyInventory': function() {
        var storeId = this.get('InventoryStoreID');
        if (!storeId) {
          return false;
        }
        var store = Data.Stores.get(storeId);
        if (store && store.get('Marketplace').InventoryLevels) {
          return true;
        }
        return false;
      }
    }
  });
});
