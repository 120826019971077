import ApiHttpService from 'client/services/api/api-http-service';

/**
 * @typedef PusherApp
 * @property {string} Key
 * @property {string} Cluster
 */

/**
 * @typedef PusherConfig
 * @property {PusherApp[]} PusherApps
 * @property {boolean} UseBatchAuth
 * @property {boolean} EnableWorkstationChannelBypass
 */

/**
 * @returns {Promise<PusherConfig>}
 */
export const getPusherConfig = () => ApiHttpService.get('pusher/config');

/**
 * @typedef AuthenticatePusherRequest
 * @property {string} type
 * @property {string} socket_id
 * @property {string} channel_name
 * @property {string} pusherAppKey
 * @property {'primary'|'failover'} pusherInstanceName
 */

/**
 * @param {AuthenticatePusherRequest} request
 * @returns {Promise<object>}
 */
export const authenticatePusher = request =>
  ApiHttpService.post('pusher/auth', request);
