import { ApiUrl } from '../../public/app/Config';
import GetProviderDisplayName from 'client/components/data-driven-logo/get-provider-display-name';

export default class ProviderFormService {
  static getFormMetadata(
    providerId,
    sellerProviderId,
    formName,
    onSuccess,
    onFail
  ) {
    $.jjax({
      url:
        `${ApiUrl}carrierapi/provider/${providerId}/form/${formName}` +
        (sellerProviderId ? `?sellerProviderId=${sellerProviderId}` : ''),
      type: 'GET',
      success(response) {
        onSuccess({
          providerName: GetProviderDisplayName(providerId),
          ...response.form_metadata
        });
      },
      error: onFail
    });
  }
}
