import localeService from 'client/i18n/locale-service';
import getTranslatedStringObject from 'client/i18n/get-translated-string-object';
import loadOptions from 'public/core/utilities/select/load-options';

define(['underscore', 'backbone', 'Data', 'pubsub', 'core/Hint'], function(
  _,
  Backbone,
  Data,
  pubsub,
  Hint
) {
  return Backbone.View.extend(
    {
      initialize: function() {
        // providing function calls for before and after render
        _.bindAll(this, 'beforeRender', 'render', 'afterRender');
        var oldRender = this.render;
        this.render = function() {
          this.beforeRender.apply(this, arguments);
          this.calculateTranslatedStrings();
          var result = oldRender.apply(this, arguments);
          this.afterRender.apply(this, arguments);
          return result;
        };
        this._super('initialize', arguments);
        if (this.sub && !_.isEmpty(this.sub)) {
          for (var key in this.sub) {
            var method = this.sub[key];
            if (!_.isFunction(method)) this.sub[key] = this[this.sub[key]];
          }

          this.listenTo(pubsub, this.sub, this);
        }

        this.listenTo(
          pubsub,
          'app:window_resize',
          function() {
            this.showHints(0);
          },
          this
        );

        this._hints = [];
        if (this.hints && !_.isEmpty(this.hints)) {
          for (var key in this.hints) {
            var hintCfg = $.extend(this.hints[key], { key: key, view: this });
            var hint = new Hint(hintCfg);
            this._hints.push(hint);
          }
        }
        this._localeServiceSubscription = localeService.subscribe(() => {
          if (!this.translations) {
            return;
          }
          this.render();
        });
      },
      beforeRender: function() {},
      afterRender: function() {
        // load the options for any selects that specify the option set on their data-options attr
        var model = this.model;
        if (
          model !== null &&
          model !== undefined &&
          model.toJSON !== undefined &&
          typeof model.toJSON === 'function'
        ) {
          model = model.toJSON();
        }
        loadOptions(this.findEls('select[data-options]'), Data, model);
        var me = this;
        me.showHints();
      },
      findEls: function(selector) {
        return this.$el.find(selector);
      },
      show: function() {
        this.$el.show();
        if (this.onShow) this.onShow();
      },
      showHints: function(delay) {
        if (this._hints.length) {
          _.each(
            this._hints,
            function(hint) {
              var target = undefined;
              if (!hint.target) {
                target = this.$el;
              } else {
                target = this.$el.find(hint.target);
              }
              hint.show(target, delay);
            },
            this
          );
        }
      },
      hide: function() {
        this.$el.hide();
        if (this.onHide) {
          this.onHide();
        }
      },
      remove: function() {
        if (this.onRemove) {
          this.onRemove();
        }
        // clean up any subviews
        _.each(this.subViews, function(subView) {
          if (
            typeof subView !== 'undefined' &&
            typeof subView.remove === 'function'
          ) {
            if (typeof subView.remove === 'function') {
              _.defer(function() {
                subView.remove();
              });
            }
          }
        });

        _.each(this._hints, function(hint) {
          hint.remove();
        });

        if (this._localeServiceSubscription) {
          this._localeServiceSubscription();
        }
        this._super('remove', arguments);
      },
      pub: function() {
        return pubsub.trigger.apply(pubsub, arguments);
      },
      subscribe: function(name, func, ctx, throttle) {
        if (throttle) {
          var throttled = _.throttle(func, throttle);
          this.listenTo(pubsub, name, throttled, ctx);
        } else {
          this.listenTo(pubsub, name, func, ctx);
        }
      },
      unsubscribe: function(name, func) {
        this.stopListening(pubsub, name, func);
      },
      calculateTranslatedStrings: function() {
        if (!this.translations) {
          return;
        }
        const translationValues = this.translationValues || {};
        this.translatedStrings = getTranslatedStringObject(
          this.translations,
          translationValues
        );
      }
    },
    {
      //merge event hashes of the base and derived views
      extend: function(properties, classProperties) {
        properties.events = _({}).extend(
          properties.events || {},
          this.prototype.events
        );

        properties.sub = _({}).extend(properties.sub || {}, this.prototype.sub);

        return Backbone.View.extend.call(this, properties, classProperties);
      }
    }
  );
});
