import ReactDOM from 'react-dom';

define(['Modal'], function(Modal) {
  return Modal.extend({
    modalType: 'ReactGenericModal',
    initialize: function(options) {
      this.reactComponent = options.reactComponent;
      this.reactComponentProps = options.reactComponentProps;
      this.preventClose = options.preventClose || false;
      this.modalClass = options.modalClass || '';

      options.height = options.height || 'auto';
      options.width = options.width || 'auto';
      options.title = options.title || '';
      options.bgCls = options.bgCls || '';

      // expose modal functionality to underlying react
      if (!this.reactComponentProps) {
        this.reactComponentProps = {};
      }

      this.reactComponentProps.modal = {
        close: function() {
          this.close();
        }.bind(this),
        setTitle: function(title, shouldAnimate) {
          this.setTitle(title, shouldAnimate);
        }.bind(this),
        getTitle: function() {
          return this.getTitle();
        }.bind(this),
        sizeToContent: function(max) {
          this.sizeToContent(max);
        }.bind(this),
        setHeight: function(height) {
          this.setHeight(height);
        }.bind(this),
        setWidth: function(width) {
          this.setWidth(width);
        }.bind(this)
      };

      this._super('initialize', [options]);
    },
    render: function() {
      this._super('render', arguments);
      this.$el.addClass(this.modalClass);
      if (this.reactComponent) {
        var modalBody = this.$el.find('.modal-body');
        modalBody.html('<div class="react-generic-modal"></div>');

        var reactElement = this.$el.find('.react-generic-modal')[0];
        this.reactComponent.render(reactElement, this.reactComponentProps);
      }
    },
    onClose: function() {
      if (this.reactComponent) {
        var reactElement = this.$el.find('.react-generic-modal')[0];
        ReactDOM.unmountComponentAtNode(reactElement);
      }
      this._super('onClose', arguments);
    },
    shouldBeAbleToClose: function() {
      return !this.preventClose;
    }
  });
});
