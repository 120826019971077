import React from 'react';
import ReactDOM from 'react-dom';
import style from 'client/components/integration-selector/integration-selector.less';
import AppDataService from 'client/services/app-data-service';
import UserService from 'client/services/user-service';
import DataCacheService from 'client/services/data-cache-service';
import SubscribableService from 'client/services/subscribable-service';
import Enums from 'public/core/Enums';
import shippingServiceService from './shipping-service-service';
import { registryProviderOptions } from 'client/services/other-shipping-options/helpers/provider-option-map';
import * as OtherShippingOptionConstants from 'client/services/other-shipping-options/constants';
import { providerHasFeature } from 'client/services/other-shipping-options/helpers/provider-option-map';
import DataDrivenLogo from 'client/components/data-driven-logo';

class ProviderService extends SubscribableService {
  constructor() {
    super();

    for (const key in Enums.Provider) {
      this[key] = Enums.Provider[key];
    }

    this.allProviders = [];
    this.gettingAllProviders = false;
  }

  getProviderDoesAutofunding(providerId) {
    if (providerId !== this.Express1 && providerId !== this.Stamps_com) {
      return false;
    }
    return (
      UserService.hasPurchasingRole() &&
      this.getPropertyFromId(providerId, 'MaintainBalance')
    );
  }

  getProviderHasAutofundingTurnedOn(providerId) {
    return (
      this.getProviderDoesAutofunding(providerId) &&
      this.getPrimaryAccountPropertyFromId(
        providerId,
        'AutoPurchaseThreshold'
      ) &&
      this.getPrimaryAccountPropertyFromId(providerId, 'AutoPurchaseAmount') &&
      this.getPrimaryAccountPropertyFromId(providerId, 'AutoPurchaseCutoff')
    );
  }

  getProviderFromId(providerId) {
    if (!providerId) {
      return;
    }
    if (typeof providerId === 'string') {
      providerId = parseFloat(providerId);
    }
    const providers = AppDataService.getProviders() || [];
    const appDataProvider = providers.find(
      provider => provider.ProviderID === providerId
    );
    if (appDataProvider) {
      return appDataProvider;
    }
    return this.getAllProviders().find(
      provider => provider.ProviderID === providerId
    );
  }

  getPropertyFromId(providerId, propertyName) {
    const provider = this.getProviderFromId(providerId);
    if (provider === undefined) {
      return;
    }
    return provider[propertyName];
  }

  getPrimaryProviderAccountFromId(providerId) {
    if (!providerId) {
      return;
    }
    if (typeof providerId === 'string') {
      providerId = parseFloat(providerId);
    }
    return AppDataService.getProviderAccounts().find(
      providerAccount =>
        providerAccount.ProviderID === providerId &&
        providerAccount.IsPrimaryAccount
    );
  }

  getPrimaryAccountPropertyFromId(providerId, propertyName) {
    const providerAccount = this.getPrimaryProviderAccountFromId(providerId);
    if (providerAccount === undefined) {
      return;
    }
    return providerAccount[propertyName];
  }

  hasStampsWhiteLabelAccount() {
    const whitelabelProvider = this.getProviderFromId(this.StampsWhiteLabel);
    return !!whitelabelProvider;
  }

  getOptionCodes(providerId) {
    if (typeof providerId === 'string') {
      providerId = parseFloat(providerId);
    }
    const provOtherShippingOptions = registryProviderOptions(providerId);
    return provOtherShippingOptions;
  }

  supportsCarrierInsurance(providerId) {
    return providerHasFeature(
      providerId,
      OtherShippingOptionConstants.CARRIER_INSURANCE
    );
  }

  providerSupportsReturnLabel(providerId) {
    if (!providerId) {
      return false;
    }

    // this ultimately relies on data from /data/lookup which makes it data-driven from the DB
    const returnsServices = shippingServiceService.getServices(null, true);
    return Object.values(returnsServices).some(
      service => service.ProviderId === providerId
    );
  }

  getAllProviders() {
    if (this.allProviders.length !== 0) {
      return this.allProviders;
    }
    if (this.gettingAllProviders) {
      return [];
    }
    this.gettingAllProviders = true;
    DataCacheService.getAllProviders().then(
      allProviders => {
        this.allProviders = allProviders;
        this.onChange();
        this.gettingAllProviders = false;
      },
      () => (this.gettingAllProviders = false)
    );
    return [];
  }

  renderProviderLogo(providerId, el) {
    if (!el) {
      return;
    }
    ReactDOM.render(
      <DataDrivenLogo
        providerId={providerId}
        className={style['integration-logo']}
      />,
      el
    );
  }
}

export default new ProviderService();
