import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '0d6ef2bd-e792-40b8-b6af-bc04c9031f82',
    defaultMessage: 'Sheesh, do it already!'
  }),
  defineMessage({
    id: '566dd431-e7b0-48f5-8f45-5ef3ae52939a',
    defaultMessage: 'Cancel'
  })
];

export default translations;
