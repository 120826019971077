import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '3f82e662-f100-4755-afe9-de86d52326c3',
    defaultMessage: 'Authorize'
  }),
  defineMessage({
    id: 'adfdc715-ad91-42cc-81cd-72e517911b28',
    defaultMessage: 'Cancel'
  }),
  defineMessage({
    id: 'c0eec811-dc3b-4622-afbd-40e35edc53e1',
    defaultMessage: 'Back'
  }),
  defineMessage({
    id: 'cca5e830-feed-47df-b18c-0997fea723cf',
    defaultMessage: 'Next'
  })
];

export default translations;
