import AmplifyService from 'amplify';

const SAVED_PRINTER_AMPLIFY_STRING = 'ss_Setting_Printer_DocType';

export default class PrinterService {
  static getLocalStoragePrinters() {
    return AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING) || [];
  }

  static findMatchingStoredPrinter(docType) {
    const printerStore = this.getLocalStoragePrinters();
    if (printerStore === undefined) {
      return;
    }
    const printerStoreResult = printerStore.find(
      printer => printer.doctype === docType
    );
    if (!printerStoreResult) {
      return;
    }
    return printerStoreResult.printer;
  }

  static getAnyDefaultPrinter(docType) {
    return this.findMatchingStoredPrinter(docType);
  }

  static saveDefaultPrinter(printer) {
    const existingSavedPrinters =
      AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING) || [];
    const newSavedPrinters = [
      ...existingSavedPrinters.filter(p => p.doctype !== printer.doctype),
      {
        doctype: printer.doctype,
        printer
      }
    ];
    AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING, newSavedPrinters);
  }

  static clearDefaultPrinter(doctype) {
    const existingSavedPrinters =
      AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING) || [];
    const newSavedPrinters = existingSavedPrinters.filter(
      p => p.doctype !== doctype
    );
    AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING, newSavedPrinters);
  }
}
