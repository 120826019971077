import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '2f82b933-4daa-4184-97c7-0c3812d359d5',
    defaultMessage: 'Accept ShipStation Terms And Conditions'
  }),
  defineMessage({
    id: '3f82b933-4daa-4184-97c7-0c3812d359d6',
    defaultMessage:
      'Please read the terms and conditions below. Scroll to the bottom of the agreement to accept and continue.'
  })
];

export default translations;
