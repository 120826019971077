export default async () => {
  // TOBY: Polyfill Math.sign for Safari and IE, from MDN
  Math.sign =
    Math.sign ||
    function(x) {
      x = +x; // convert to a number
      if (x === 0 || isNaN(x)) {
        return x;
      }
      return x > 0 ? 1 : -1;
    };

  // es6 polyfills
  // TODO - only import these on browsers that need them

  // doing these as require because they have side effects.
  require('core-js/fn/array/find');
  require('core-js/fn/array/find-index');
  require('core-js/fn/array/from');
  require('core-js/fn/number/is-nan');
  require('core-js/fn/number/parse-float');
  require('core-js/fn/number/parse-int');
  require('core-js/fn/set');
  require('core-js/fn/object/values');
  require('core-js/fn/object/is');
  require('core-js/es6/promise');
  require('core-js/es6/object');
  require('core-js/es6/string');
  require('core-js/es7/array');

  const regeneratorRuntime = require('@babel/runtime/regenerator');
  window.regeneratorRuntime = regeneratorRuntime;
};
