import _ from 'underscore';

import ShipstationConnectService from 'client/services/shipstation-connect-service';

import ShipstationConnectUtils from '../shipstation-connect-utils';

const Data = require('Data');
const PrintersCollection = require('collections/Printers');

const BroadcastPrintersPubSubHandler = {
  event: 'app:message:client-connect:broadcast:printers',
  handler: msg => {
    if (!msg) {
      return;
    }
    const { data } = msg;
    //ignore messages that are not for me
    if (data.userId && data.userId != App.user.userId) {
      return;
    }

    const workstation = Data.Workstations.get(data.workstationId);
    if (!workstation) {
      return;
    }

    ShipstationConnectUtils.makeWorkstationOnline(workstation);

    //Printers collection in the form:
    //{i, n, o, s}
    //where
    //i=ID
    //n=name
    //s = shared
    //o = online
    //d = disabled

    //Filter the list of non-shared printers to only the list that are private to me
    let printers = _.filter(data.printers, function(p) {
      if (p.s) {
        return true;
      }
      return workstation.get('UserID') == App.user.userId;
    });

    printers = _.map(
      printers,
      ShipstationConnectService.mapConnectPrinterToModel
    );

    workstation.set('Printers', new PrintersCollection(printers));
  }
};

export default BroadcastPrintersPubSubHandler;
