import { ApiUrl } from '../app/Config';

define(['backbone', 'models/DocTemplate'], function(Backbone, DocTemplate) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'DocTemplates',
    model: DocTemplate,
    comparator: function(first, second) {
      var firstDefault = first.isDefault();
      var firstName = (first.get('Name') || '').toLowerCase();
      var secondDefault = second.isDefault();
      var secondName = (second.get('Name') || '').toLowerCase();

      if (
        (firstDefault && secondDefault) ||
        (!firstDefault && !secondDefault)
      ) {
        return firstName === secondName ? 0 : firstName > secondName ? 1 : -1;
      } else {
        return firstDefault ? -1 : 1;
      }
    },

    forContext: function(context) {
      var x = this.filter(function(d) {
        return d.attributes.Contexts.indexOf(context) > -1;
      });
      return x;
    }
  });
});
