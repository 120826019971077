import { ApiUrl } from '../app/Config';

define(['models/RollbackModel'], function(RollbackModel) {
  return RollbackModel.extend({
    idAttribute: 'DocTemplateID',
    urlRoot: ApiUrl + 'DocTemplates',

    isDefault: function() {
      return this.get('Shared');
    },

    getDocTypeName: function() {
      var docTypeId = this.get('DocTypeID');
      switch (docTypeId) {
        case 1:
          return 'Orders';
        case 2:
          return 'OrderItems';
        case 3:
          return 'Customers';
        case 4:
          return 'Products';
        case 5:
          return 'Shipments';
        case 6:
          return 'OrderItemsLite';
      }
      return '';
    }
  });
});
