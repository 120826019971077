import $ from 'jquery';
import 'mask';
import jsCookie from 'js-cookie';
import Backbone from 'backbone';
import Auth0Session from 'client/services/auth0-session';
import DropInRestrictions from 'client/services/dropin-restrictions-service';
import AppError from 'public/core/AppError';
import CookieService from 'client/services/cookie-service';
import notify from 'public/core/utilities/notify';
import UserService from 'client/services/user-service';
import notifyContext from 'public/core/utilities/notify-context';

const SsJwtAuthTokenKey = 'SsJwtAuthToken';

const getStickAndErrorMessage = (xhr, downtimeMode) => {
  const defaultErrorMessage =
    'Sorry but an unexpected error occurred. <br /><br />';
  if (downtimeMode) {
    const parts = xhr.responseText.split(':');
    return {
      errorMessage: parts.length === 2 ? parts[1] : defaultErrorMessage,
      stick: false
    };
  }
  if (UserService.isSysAdmin()) {
    return {
      errorMessage: `<a href='javascript:;' onclick='$(this).next().toggle()'>Show Admin Details</a><div class='error-details' style='display:none'>${xhr.responseText}</div>`,
      stick: true
    };
  }
  return {
    errorMessage: defaultErrorMessage,
    stick: false
  };
};

const xhrErrorNotify = (title, msgObj, stickTime) => {
  const delay = stickTime ? null : 30000;
  // if there's a modal visible, make that our context
  const currentModal = $('.modal-content:visible');
  const opts = {
    text_escape: false,
    html: true
  };

  if (currentModal.length) {
    notifyContext(
      currentModal,
      msgObj.title,
      msgObj.message,
      delay,
      'error',
      opts
    );
    return;
  }
  notify(msgObj.title, msgObj.message, delay, 'error', opts);
};

const needsAuth = (xhr, settings) => {
  return (
    (xhr.status === 401 || xhr.status === 409) &&
    (!settings || settings.url.indexOf('GetToken') === -1)
  );
};

const isUnauthorized = xhr => {
  return xhr.status === 403 && !xhr.authHandled;
};

const isErrorState = xhr => {
  return xhr.status === 500 && xhr.unmask !== false;
};

const handleNeedsAuthError = () => {
  var terminateRequest = true;
  Auth0Session.logout();

  return terminateRequest;
};

const handleUnauthorizedDropInActionError = xhr => {
  var terminateRequest = true;

  if (DropInRestrictions.isDropInActive()) {
    if (xhr.unauthorizedDropInActionErrorHandled) return terminateRequest;
    const msgObj = DropInRestrictions.getDropinActionRestrictedMessage(xhr);
    xhr.unauthorizedDropInActionErrorHandled = true;
    xhrErrorNotify('Unauthorized', msgObj);
  } else {
    const pubsub = require('pubsub');
    Backbone.history.navigate('/unauthorized', true);
    pubsub.trigger('globalinit:403:unauthorized');
  }

  return terminateRequest;
};

const handleError = xhr => {
  var terminateRequest = true;

  $('.masked').unmask();
  if (xhr.errorHandled === true) {
    return terminateRequest;
  }
  const downtimeMode =
    xhr.responseText && xhr.responseText.indexOf('DOWNTIME:') === 0;
  const UnhandledErrorMessage = downtimeMode
    ? 'Downtime'
    : 'An unexpected error occurred.';
  AppError(
    xhr,
    'An unexpected error occurred and was caught in the GlobalInit. Error: ' +
      xhr.responseText
  );
  const globErrors = $('.global-uncaught-error').length;
  if (globErrors) {
    return terminateRequest;
  }

  const { stick, errorMessage } = getStickAndErrorMessage(xhr, downtimeMode);
  xhrErrorNotify(UnhandledErrorMessage, errorMessage, stick);

  return terminateRequest;
};

const ajaxCompleteHandler = (event, xhr, settings) => {
  if (needsAuth(xhr, settings)) {
    if (handleNeedsAuthError(xhr)) return;
  }
  if (isUnauthorized(xhr)) {
    if (handleUnauthorizedDropInActionError(xhr)) return;
  }

  const token = xhr.getResponseHeader('Renew-Token');
  if (token) {
    CookieService.setSecureCookie('auth', token);
  }

  if (isErrorState(xhr)) {
    if (handleError(xhr)) return;
  }
};

const appendAuthTokenToBody = settings => {
  if (Auth0Session.isAuthenticatedFast() && typeof settings.data === 'string') {
    try {
      const jsonObj = JSON.parse(settings.data);
      if (!jsonObj[SsJwtAuthTokenKey]) {
        jsonObj[SsJwtAuthTokenKey] = Auth0Session.getTokenFast();
        settings.data = JSON.stringify(jsonObj);
      }
    } catch (err) {
      // do nothing, body not json
    }
  }
};

const jqueryAjaxSetup = async () => {
  //todo move this sort of stuff into HTTP service.
  $.ajaxSetup({
    beforeSend: function(xhr, settings) {
      const auth = jsCookie.get('auth');
      const imp = jsCookie.get('x-imp');
      if (auth && auth != 'null' && auth != 'undefined') {
        xhr.setRequestHeader('Authorization-Token', auth);
      }
      if (imp) {
        xhr.setRequestHeader('SellerContext', imp);
      }

      if (Auth0Session.isAuthenticatedFast()) {
        xhr.setRequestHeader(
          'Authorization',
          `Bearer ${Auth0Session.getTokenFast()}`
        );
        if (settings.crossDomain) {
          xhr.withCredentials = true;
          appendAuthTokenToBody(settings);
        }
      }
    },
    complete: function(xhr) {
      if (isUnauthorized(xhr)) {
        handleUnauthorizedDropInActionError(xhr);
      }
    },
    headers: {
      'git-version': window.gitVersion
    }
  });

  window.$.jjax = args => {
    args.data = JSON.stringify(args.data);
    args.contentType = 'application/json; charset=utf-8';
    args.dataType = 'json';
    return $.ajax(args);
  };

  //todo move this sort of stuff into HTTP service.
  $(document).ajaxComplete(ajaxCompleteHandler);
  $(document).ajaxError(ajaxCompleteHandler);

  // Enables proper array serialization for arrays
  window.$.ajaxSettings.traditional = true;
  $.ajaxSettings.traditional = true;
};

export default jqueryAjaxSetup;
