import { v4 } from 'uuid';
import ApiHttpService from 'client/services/api/api-http-service';

let cache = [];
let subscribers = [];

const getCache = () => cache;
const setCache = newCache => (cache = newCache);
const unsubscribe = id =>
  (subscribers = subscribers.filter(subscriber => subscriber.id !== id));
const subscribe = subscriber => {
  const id = v4();
  subscribers.push({ id, subscriber });
  return () => unsubscribe(id);
};

const initCache = () => {
  ApiHttpService.get('integrationsPlatform/displayInfo/fulfillmentproviders', {
    useV3: true
  }).then(data => {
    if (!Array.isArray(data)) {
      return;
    }
    cache = data;
    if (subscribers.length > 0) {
      subscribers.forEach(subscriber => subscriber.subscriber());
      subscribers = [];
    }
  });
};

const FulfillmentProviderDisplayInformationCache = {
  getCache,
  setCache,
  initCache,
  subscribe,
  unsubscribe
};
export default FulfillmentProviderDisplayInformationCache;
