import { ApiUrl } from '../app/Config';

define(['backbone', 'models/User'], function(Backbone, UserModel) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Users',
    model: UserModel,
    getName: function(userId) {
      if (!userId) return '';
      var m = this.get(userId);
      if (!m) return '???';
      return m.get('Value');
    },
    parse: function(response) {
      return response.users || [];
    }
  });
});
