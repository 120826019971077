import AppDataService from 'client/services/app-data-service';

const devExpressSetup = async () => {
  if (AppDataService.isRunningLabelApi()) {
    return;
  }
  await import('dxcharts');
  const theme = {
    name: 'ss',
    chart: {
      size: {
        height: 300
      },
      loadingIndicator: {
        backgroundColor: 'rgba(225,238,247,0.75)',
        font: {
          weight: 200,
          size: 16
        },
        text: 'Loading'
      },
      commonAxisSettings: {
        grid: {
          color: '#718795',
          opacity: 0.2,
          width: 2
        },
        constantLineStyle: {
          color: '#c0dae8'
        },
        title: {
          font: {
            family: 'Roboto',
            color: '#718795',
            size: '20px',
            weight: 400
          }
        },
        label: {
          font: {
            family: 'Roboto',
            color: '#718795'
          }
        }
      },
      argumentAxis: {
        color: '#444',
        width: 6,
        grid: {
          visible: false
        }
      }
    }
  };
  if (typeof DevExpress !== 'undefined') {
    DevExpress.viz.core.registerTheme(theme, 'ss');
  }
};

export default devExpressSetup;
