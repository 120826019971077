define(['core/BaseModel'], function(BaseModel) {
  return BaseModel.extend({
    idAttribute: 'MarketplaceID',
    defaults: {
      MarketplaceID: '', // id of the marketplace
      Name: '', // display name
      CanConfirmMultipleShipments: true,
      CanConfirmShipments: true,
      CanLeaveFeedback: true,
      CanRefresh: true,
      CustomMappings: false,
      CustomStatuses: false,
      IsLive: false,
      IsOffline: false,
      Monitored: false,
      ScoreFactorForAutoRefresh: undefined,
      SendEmails: true,
      SortOrder: 100,
      // these 2 fields are currently client-side only
      CustomerFieldsTemplateName: undefined, // name of the template (if any) for marketplace specific form fields
      ImageUrl: function() {
        return '/images/marketplace/' + this.get('Name') + '.png';
      }
    }
  });
});
