import semver from 'semver';
import AppDataService from 'client/services/app-data-service';

const settingIsTurnedOn = settingName => {
  return AppDataService.getSetting(settingName) === 'true';
};

class SellerSettingService {
  hasEnableScanToPrint() {
    return settingIsTurnedOn('EnableScanToPrint');
  }

  quickShipIsTurnedOn() {
    return settingIsTurnedOn('QuickShip');
  }

  autoRateIsTurnedOn() {
    return settingIsTurnedOn('AutoRate');
  }

  nlpServiceTurnedOn() {
    return settingIsTurnedOn('EnableNlpInput');
  }

  nlpAddressServiceTurnedOn() {
    return settingIsTurnedOn('EnableNlpAddressInput');
  }

  earlyAccessTurnedOn() {
    return settingIsTurnedOn('EarlyAccessEnabled');
  }

  enableMagentoToken() {
    return settingIsTurnedOn('EnableMagentoToken');
  }

  enableQuickbooksOAuth2() {
    return settingIsTurnedOn('EnableQuickbooksOAuth2');
  }

  enableSquareSpaceV2() {
    return settingIsTurnedOn('EnableSquareSpaceV2');
  }

  shouldAutoCreateUpsWhenConnectingStamps() {
    return settingIsTurnedOn('UpsWallet.AutoCreateUpsWhenConnectingStamps');
  }

  enableUpsAccountCreationFromUpsModal() {
    return settingIsTurnedOn('UpsWallet.EnableAccountCreationFromUpsModal');
  }

  enableEnrollmentModalForUpsWallet() {
    return settingIsTurnedOn('UpsWallet.ShowEnrollmentModal');
  }

  canBringOwnStampsAccountForWallet() {
    return settingIsTurnedOn('CanBringOwnStampsAccountForWallet');
  }

  isV3Native() {
    return settingIsTurnedOn('IsNativeV3Seller');
  }

  enableWishV2() {
    return settingIsTurnedOn('EnableWishV2');
  }

  enableRakutenV2() {
    return settingIsTurnedOn('EnableRakutenV2');
  }

  isEnableEbayV2() {
    return settingIsTurnedOn('EnableEbayV2');
  }

  // this is a seller setting, but it's being returned in AuthInfo in a more reliable way.
  isV3OptOutAllowed() {
    const v3Version = '3.0.0';
    const minimumSemVersion = AppDataService.getMinimumSemVersion();

    return semver.valid(minimumSemVersion)
      ? semver.lt(minimumSemVersion, v3Version)
      : true;
  }
}

export default new SellerSettingService();
