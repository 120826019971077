import { defineMessage } from 'react-intl';

import LocaleService from 'client/i18n/locale-service';
import 'core/LogosPlease';
import translations from './translations';
import template from './StampsTermsAndConditions.html';

const jsTranslations = {
  title: defineMessage({
    id: 'views.Settings.Providers.Settings.StampsTermsAndConditions.title',
    defaultMessage: 'Terms & Conditions'
  })
};

define(['core/services/api/AccountService', 'Modal'], function(
  AccountService,
  Modal
) {
  return Modal.extend({
    template: _.template(template),
    initialize(options) {
      this.translations = translations;
      this.options = options;
      this.options.title = LocaleService.getTranslatedString(
        jsTranslations.title
      );
      this.options.height = '668px';
      this.options.width = '820px';
      this.sellerProviderId = options.sellerProviderId;
      this.currentTerms =
        App.user.termsAndConditionsResponses[this.sellerProviderId];
      this.sellerId = App.user.seller;
      if (this.currentTerms) {
        this.version = this.currentTerms.version;
      }
      this.shipStationTerms = this.version > 1;
      this.svgProviderId = this.version > 1 ? 26 : 2;
      this._super('initialize', [options]);
    },
    render() {
      this._super('render', arguments);
      this.$el.find('.modal-body').html(
        this.template({
          translatedStrings: this.translatedStrings
        })
      );
      this.$el.find('.error-message').hide();
      this.$el.logosPlease();
    },
    updateTerms() {
      var updateTerms = {
        SellerId: this.sellerId,
        Version: this.version,
        HasAccepted: true
      };
      AccountService.updateAccountTerms(updateTerms).then(
        responseData => {
          if (responseData.success) {
            this.close();
            return;
          }
        },
        () => {}
      );
    },
    events: {
      'click .stamps-accept-terms': 'updateTerms'
    }
  });
});
