import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '9317b440-c5e7-4935-97be-d2a7cdfdb6f0',
    defaultMessage: 'Search'
  }),
  defineMessage({
    id: '29e9f269-4413-438c-9e31-77332784b611',
    defaultMessage: 'Cancel'
  })
];

export default translations;
