import uuid from 'uuid';
import amplify from 'amplify';
import UAParser from 'ua-parser-js';
import jsCookie from 'js-cookie';

import { ApiUrl, AppID } from '../app/Config';

import 'NotificationHandler';
import UserInterfaceService from 'client/services/user-interface-service';
import AppDataService from '../../client/services/app-data-service';
import Auth0Session from '../../client/services/auth0-session';
import { PusherService } from 'client/services/pusher/pusher-service';
import Register from 'views/Settings/Providers/Register';
import Enums from 'public/core/Enums';
import defaultCookieConfig from 'public/defaultCookieConfig';
import AuthRedirectService from './services/AuthRedirectService';
import V3AccessService from 'client/services/v3-access-service';
import UrlService from 'public/core/services/UrlService';
import CookieService from 'client/services/cookie-service';
import notify from 'public/core/utilities/notify';
import MarketplaceDisplayInformationCache from 'client/components/data-driven-logo/marketplace-display-information-cache';
import ProviderDisplayInformationCache from 'client/components/data-driven-logo/provider-display-information-cache';
import FillProviderDisplayInformationCache from 'client/components/data-driven-logo/fulfillment-provider-display-information-cache';
import ShipStationConnectService from './ShipStationConnect';
import { getEnableInitialConnectRefresh } from 'client/services/launch-darkly-flag-service.js';

/* global $ */
/* global App */
define([
  'backbone',
  'ResourceManager',
  'pubsub',
  'Modal',
  'views/Settings/Providers/Settings/StampsTermsAndConditions',
  'Help',
  'MobilePrompt',
  'NotificationStampsExpress1',
  'pnotify'
], function(
  Backbone,
  ResourceManager,
  pubsub,
  Modal,
  StampsTermsAndConditions,
  Help,
  MobilePrompt,
  StampsExpress1,
  PNotify
) {
  var SERVER_SIDE_LOGOUT = (window.features || 0).SERVER_SIDE_LOGOUT;
  const sessionHandleKey = AppID + 'sessionHandle';

  return {
    historyStarted: false,
    auth0: Auth0Session,
    start: function(response) {
      // get rid of any onboard oauth return urls
      jsCookie.remove('onboard-return', defaultCookieConfig);

      //Show mobile prompt if UA is mobile device
      const osName = new UAParser().getOS().name;
      if (!AppDataService.isRunningLabelApi()) {
        if (osName.toLowerCase().indexOf('android') > -1) {
          //Show a prompt to install Android app
          if (jsCookie.get('no-mobile-prompt') != '1') {
            var prompt = new MobilePrompt({
              os: 'android'
            });
            prompt.show();
          }
        } else if (osName.toLowerCase().indexOf('ios') > -1) {
          //Show a prompt to install IOS app
          if (jsCookie.get('no-mobile-prompt') != '1') {
            var prompt = new MobilePrompt({
              os: 'ios'
            });
            prompt.show();
          }
        }
      }
      // if the user is already auth'd or we have an auth response passed in
      var me = this;

      const subscribePubSub = () => {
        pubsub.on('user:logmeout', function() {
          me.logout(true);
        });

        pubsub.on('app:message:deactivated-user', function(resp) {
          if (resp.data.userId === App.user.userId) me.logout(false);
        });
      };

      this.isAuthenticated().then(authenticationResponse => {
        // console.group('session start');
        // console.debug(`authenticationResponse: isAuthenticated: ${authenticationResponse.authenticated} legacy: ${authenticationResponse.legacy}`);
        var isAuthenticated = authenticationResponse.authenticated;

        if (authenticationResponse.legacy) {
          if (isAuthenticated || typeof response !== 'undefined') {
            //console.debug('calling logged in');
            this.loggedIn(response);
          } else {
            $('.load-cover').hide('fade', {
              duration: 250,
              complete: function() {
                $(this).remove();
              }
            });
            //console.debug('calling start history');
            this.startHistory();
          }
        } else if (isAuthenticated) {
          // console.debug('Session: auth0 says we are authenticated');
          // console.debug('Session: auth0 user is: ' + Auth0Session.getUserFast().name);
          //auth0
          this.specialPostLoginDirectives();
          this.clearCachedData();
          this.startNew();
          this.isLogin = Auth0Session.isLogin();
          pubsub.trigger('app:trackEvent', 'Login');

          // TEMP - Remove when mgmt is fully on Auth0
          this.makeAuth0SessionCompatibleWithMgmt();
        }
        //console.groupEnd();

        subscribePubSub();
      });
    },
    // TEMP - Remove when mgmt is fully on Auth0
    // Fake the auth cookie to let mgmt load
    makeAuth0SessionCompatibleWithMgmt: function() {
      if (jsCookie.get('auth') !== undefined) {
        return;
      }
      var cookieOptions = {
        domain: window.location.cookieDomain(),
        path: '/',
        expires: 30,
        ...defaultCookieConfig
      };
      jsCookie.set('auth', undefined, cookieOptions);
    },
    // END TEMP
    startNew: function() {
      ResourceManager.loadCache(() => {
        this.startUI();
        let sessionHandle = amplify.store(sessionHandleKey);
        if (!sessionHandle) {
          sessionHandle = uuid.v4();
          amplify.store(sessionHandleKey, sessionHandle);
        }
        const messageArgs = {
          username: App.user.userName,
          handle: sessionHandle
        };
        PusherService.queuePush('user:session:auth0login', messageArgs);

        getEnableInitialConnectRefresh(initialConnectRefreshEnabled => {
          if (initialConnectRefreshEnabled) {
            ShipStationConnectService.queueRefresh();
          }
        });
      }, false);
    },
    isAuthenticated: function() {
      return Auth0Session.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          return { authenticated: isAuthenticated, legacy: false };
        } else {
          return { authenticated: this.isLegacyAuthenticated(), legacy: true };
        }
      });
    },
    isLegacyAuthenticated: function() {
      return (
        typeof jsCookie.get('auth') !== 'undefined' &&
        !UrlService.getQueryVariableFromEncodedSegment(
          Backbone.history.location.hash,
          '___a'
        )
      );
    },
    migrateLocalSettings: function() {
      _.each(amplify.store(), function(i, k) {
        if (k.indexOf('order-view-') == 0) {
          var newKey = k
            .replace('order-view-', 'Orders:View:')
            .replace('-', ':');
          var value = amplify.store(k);
          App.user.settings(newKey, value);
          amplify.store(k, null);
        } else if (k == 'order-default-status') {
          App.user.settings('Orders:DefaultStatus', amplify.store(k));
          amplify.store(k, null);
        } else if (k == 'order-default-view') {
          App.user.settings('Orders:DefaultView', amplify.store(k));
          amplify.store(k, null);
        }
      });
    },
    // Actions, normally for integrations that require some automatic action after login
    specialPostLoginDirectives: function() {
      if (
        jsCookie.get('bigcommerce-token') ||
        jsCookie.get('threedcart-token') ||
        jsCookie.get('sap-code') ||
        jsCookie.get('sap-login') ||
        jsCookie.get('wix-token') ||
        jsCookie.get('prestashop-setup')
      ) {
        this.redirectPath = '#/settings/stores';
      }
    },
    // This will either be called by Session in the case that the page was loaded and user already auth'd
    // or by the login view after receiving a successful login response.
    loggedIn: function(authInfo) {
      // set authInfo only if passed, otherwise user was already authed
      if (typeof authInfo !== 'undefined') {
        var cookieOptions = {
          domain: window.location.cookieDomain(),
          path: '/',
          expires: parseFloat(authInfo.tokenTtl) / 60 / 24 || undefined,
          ...defaultCookieConfig
        };

        CookieService.removeSecureCookie('auth');
        if (authInfo.redirect) {
          if (AppDataService.getIsShipEngine()) {
            authInfo.redirectHost = authInfo.redirectHost.replace(
              'shipstation',
              'shipengine'
            );
          } else {
            authInfo.redirectHost = authInfo.redirectHost.replace('app', 'ss');
          }

          //the destination system should add the sshome cookie
          CookieService.removeSecureCookie('SSHome');
          jsCookie.set('auth', 'CrossDomain:' + authInfo.token, cookieOptions);
          window.location.href = window.location.href.replace(
            window.location.hostname,
            authInfo.redirectHost
          );
          return;
        }

        amplify.store(sessionHandleKey, authInfo.sessionHandle);

        jsCookie.set('auth', authInfo.token, cookieOptions);
        jsCookie.set('v3Auth', authInfo.token, cookieOptions);
        this.isLogin = true;
      }

      this.specialPostLoginDirectives();

      if (
        UserInterfaceService.appIsIFramedInV3() &&
        Auth0Session.isAuthenticatedFast()
      ) {
        // load data for this user, call startUI once loaded
        ResourceManager.loadCache($.proxy(this.startUI, this), false);

        jsCookie.remove('login:button', defaultCookieConfig);
      } else {
        // load data for this user, call startUI once loaded
        ResourceManager.loadCache($.proxy(this.startUI, this));

        jsCookie.remove('login:button', defaultCookieConfig);
      }
    },
    startHistory: function() {
      if (!this.historyStarted) {
        Backbone.history.start();
        this.historyStarted = true;
      }
    },
    handleStampsTermsGrowler: function() {
      var modalTermsProvider = 'Stamps';
      var userTermsToAccept = App.user.termsAndConditionsResponses;
      var hasMultipleTermsAndConditions =
        Object.keys(userTermsToAccept).length > 1;
      var acceptBtnContent = '<div class="provider-settings">';

      //Build Accept Terms Links
      Object.keys(userTermsToAccept).forEach(key => {
        var terms = userTermsToAccept[key];
        if (terms) {
          if (terms.version > 1) {
            modalTermsProvider = 'Shipstation';
          }
          acceptBtnContent += terms.modalMsg + '</br></br>';
          var terms = 'View and Accept Terms';
          if (hasMultipleTermsAndConditions && parseInt(key)) {
            var sellerProviderNickname = App.user.getSellerProviderNickname(
              parseInt(key)
            );
            if (sellerProviderNickname) {
              terms += ' (' + sellerProviderNickname + ')';
            }
          }
          acceptBtnContent +=
            '<a data-action="AcknowledgeGrowler" data-id="' +
            key +
            '" class="btn btn-primary">' +
            terms +
            '</a> ';
          if (hasMultipleTermsAndConditions) {
            acceptBtnContent += '</br></br>';
          }
        }
      });
      acceptBtnContent +=
        '<a data-action="RemindLaterGrowler" class="btn btn-default">Dismiss</a></br>';
      acceptBtnContent += '</div>';
      var title = null;
      var message = null;
      var stick = true;
      var opts = {
        text: acceptBtnContent,
        title: 'Updated ' + modalTermsProvider + ' Terms and Conditions',
        type: 'info',
        width: '700px',
        buttons: {
          closer: false,
          closer_hover: false,
          sticker: false
        },
        after_open: function(notification) {
          notification.elem.on(
            'click',
            'a',
            function(e) {
              var action = $(e.currentTarget).data('action');
              if (action === 'AcknowledgeGrowler') {
                var sellerProviderId = $(e.currentTarget).data('id');
                var stampsTCModal = new StampsTermsAndConditions({
                  sellerProviderId: sellerProviderId
                });
                stampsTCModal.show();
                if (!hasMultipleTermsAndConditions) {
                  $('.ui-pnotify').hide();
                }
                return;
              }
              if (action === 'RemindLaterGrowler') {
                $('.ui-pnotify').hide();
              }
            }.bind(this)
          );
        }.bind(this)
      };
      this.stampsTermsAnnouncement = notify(
        title,
        message,
        stick,
        opts.type,
        opts
      );
    },
    urlIsNonRedirectUrl: function() {
      return window.location.href.indexOf('settings/stores/') !== -1;
    },
    startUI: function() {
      if (
        !ResourceManager.getFrontEndConfig().disableRedirectToV3 &&
        UserInterfaceService.shouldSendUserToV3() &&
        !this.urlIsNonRedirectUrl()
      ) {
        UserInterfaceService.sendUserToV3();
      }

      MarketplaceDisplayInformationCache.initCache();
      ProviderDisplayInformationCache.initCache();
      FillProviderDisplayInformationCache.initCache();

      //T&C modal for users with Stamps.com / ShipStation account(s).
      if (!_.isEmpty(App.user.termsAndConditionsResponses)) {
        this.handleStampsTermsGrowler();
      }

      if (App.user.hasFeature(9))
        //This method is temporary and is intended to transition saved grid settings from local storage to the user profile
        this.migrateLocalSettings();

      var dontShowView = false;
      if (App.user.plan == 'sdcdesktopapp') dontShowView = true;

      const isV3User = V3AccessService.isIframeMode();

      const onStartComplete = () => {
        var me = this;

        this.startHistory();

        // nav to pre-login redirect path if it exist
        var redirectPath = this.redirectPath;

        //See if the user has a landing page preference
        if (App.user.settings && !AppDataService.isRunningLabelApi()) {
          if (
            this.isLogin &&
            App.user.settings('DefaultUrl') &&
            (!redirectPath || redirectPath.length <= 2)
          ) {
            var landingUrl = App.user.settings('DefaultUrl');
            if (landingUrl) {
              redirectPath = landingUrl;
            }
          }
        }

        const isThereAnAnnouncement = App.announcement;
        const isStampsUser = App.user.plan == 'sdcdesktopapp';
        const doesAnnouncementExpire =
          isThereAnAnnouncement && App.announcement.Expires !== null;
        const wasAnnouncementPostedAfterUserSignedUp =
          isThereAnAnnouncement &&
          new moment(App.announcement.PostDate) >
            new moment(App.user.userSince);

        if (
          !isV3User &&
          isThereAnAnnouncement &&
          !isStampsUser &&
          (!doesAnnouncementExpire || wasAnnouncementPostedAfterUserSignedUp)
        ) {
          var content = $('<div class="announcement"/>');
          content.append(App.announcement.Content);
          content.append(
            '<div class="row-fluid announcement-links">' +
              '<a href="#" class="dismiss btn btn-primary"><i class="icon-ok"></i> Dismiss</a>&nbsp;&nbsp;' +
              '<a href="#" class="snooze btn btn-default"><i class="icon-time"></i> Show Later...</a>' +
              '</div>'
          );

          var showToRoles = content.find('[data-show-to-role]');
          var hideToRoles = content.find('[data-hide-to-role]');

          showToRoles.each(function(i, e) {
            $(e).hide();
            var roles = $(e).attr('data-show-to-role');
            if (roles) {
              _.each(roles.split(','), function(r) {
                if (App.user.permit(r)) {
                  $(e).show();
                  return;
                }
              });
            }
          });

          hideToRoles.each(function(i, e) {
            var roles = $(e).attr('data-hide-to-role');
            if (roles) {
              _.each(roles.split(','), function(r) {
                if (App.user.permit(r)) {
                  $(e).hide();
                  return;
                }
              });
            }
          });

          var opts = {
            buttons: {
              closer: false,
              closer_hover: false,
              sticker: false
            },
            closer_hover: false,
            hide: false,
            history: false,
            icon: false,
            addclass: 'announcement',
            //stack: stack,
            sticker: false,
            styling: 'bootstrap2',
            text: content.html(),
            title: App.announcement.Title,
            type: 'info',
            width: '500px',
            animation: 'none',
            before_open: function() {
              //Check a cookie to see if the announcement was "slept"
              if (
                jsCookie.get(
                  'snooze-announcement-' + App.announcement.AnnouncementID
                ) == '1'
              ) {
                return false;
              }
              return true;
            },
            after_open: function(notification) {
              //Handle fixed links or track clicks from other links
              notification.elem.on('click', 'a', function(e) {
                var duration = new Date().getTime() - me.startTime;
                duration = duration / 1000;

                var link = $(e.currentTarget).attr('announcelink');
                if (link) {
                  pubsub.trigger('app:trackEvent', 'Announcement Link', {
                    Annoucement: App.announcement.Title,
                    DurationSeconds: duration,
                    AnnouncementLink: link
                  });
                }

                if ($(e.currentTarget).is('[kb-id]')) {
                  e.preventDefault();
                  Help.showArticle(
                    $(e.currentTarget).attr('kb-id'),
                    'Announcement'
                  );
                } else if ($(e.currentTarget).is('.dismiss')) {
                  e.preventDefault();
                  if (me.announcement.close) {
                    me.announcement.close(); //if a modal
                  } else {
                    me.announcement.remove();
                  }

                  pubsub.trigger('app:trackEvent', 'Dismiss Announcement', {
                    Annoucement: App.announcement.Title,
                    DurationSeconds: duration
                  });

                  $.jjax({
                    url:
                      ApiUrl +
                      'profile/DismissAnnouncement/' +
                      App.announcement.AnnouncementID,
                    type: 'POST'
                  });
                } else if ($(e.currentTarget).is('.snooze')) {
                  e.preventDefault();
                  var expire = new Date(new Date().getTime() + 60000 * 60 * 24); //snooze until tomorrow
                  jsCookie.set(
                    'snooze-announcement-' + App.announcement.AnnouncementID,
                    '1',
                    {
                      expires: expire,
                      ...defaultCookieConfig
                    }
                  );

                  if (me.announcement.close) {
                    me.announcement.close(); //if a modal
                  } else {
                    me.announcement.remove();
                  }

                  pubsub.trigger('app:trackEvent', 'Dismiss Announcement', {
                    Annoucement: App.announcement.Title,
                    DurationSeconds: duration
                  });
                } else if ($(e.currentTarget).is('.continue-ups-setup')) {
                  e.preventDefault();
                  $.jjax({
                    url:
                      ApiUrl +
                      'profile/DismissAnnouncement/' +
                      App.announcement.AnnouncementID,
                    type: 'POST'
                  });

                  Register.start(
                    Enums.Provider.UPS, //providerId
                    function() {
                      window.location.href = '#/settings/providers';
                      window.location.reload();
                    }, // complete
                    null, // isyahoo
                    function() {
                      window.location.href = '#settings/providers';
                    }, // cancelled
                    null, // returntoonboard
                    null, //stampsSasConnected
                    null, // sellerProvId
                    null, //isWallet
                    'Agreement' //startingStepName
                  );
                  if (me.announcement.close) {
                    me.announcement.close(); //if a modal
                  } else {
                    me.announcement.remove();
                  }
                } else if ($(e.currentTarget).is('.begin-stamps-ups-setup')) {
                  e.preventDefault();
                  $.jjax({
                    url:
                      ApiUrl +
                      'profile/DismissAnnouncement/' +
                      App.announcement.AnnouncementID,
                    type: 'POST'
                  });

                  Register.start(
                    Enums.Provider.Stamps_com, //providerId
                    function() {
                      window.location.href = '#/settings/providers';
                      window.location.reload();
                    }, // complete
                    null, // isyahoo
                    function() {
                      window.location.href = '#settings/providers';
                    } // cancelled
                  );
                  if (me.announcement.close) {
                    me.announcement.close(); //if a modal
                  } else {
                    me.announcement.remove();
                  }
                } else {
                }
              });
            }
          };

          if (App.announcement.Sticky) {
            if (opts.before_open()) {
              var links = content.find('.announcement-links').detach();
              var w = new Modal({
                height: '650px',
                width: '832px',
                title: App.announcement.Title,
                footer: links.html()
              });

              w.render();

              w.$('.modal-body')
                .html(content)
                .removeClass('scrollable');
              w.$('.modal-header .close').remove();
              w.show();
              opts.after_open({
                elem: w.$el
              });

              me.announcement = w;
            }
          } else {
            me.announcement = new PNotify(opts);
          }
          me.startTime = new Date().getTime();
        } else {
          if (jsCookie.get('initial:login') == 'true') {
            if (!AppDataService.isRunningLabelApi()) {
              var n = new StampsExpress1({
                closeFromTrigger: true
              });
              if (
                n.shouldPrompt(function(should) {
                  if (should) {
                    n.show();
                  }
                })
              );
            }
            jsCookie.remove('initial:login', defaultCookieConfig);
          }
        }

        //When a user trys to navigate to the login route right after login then redirect to the default route
        //this can happen when you click on forgot password and click back to the login page
        if (
          redirectPath === '' ||
          Backbone.history.location.hash
            .toLowerCase()
            .indexOf('public/login') != -1
        ) {
          redirectPath = '#/';
        }
        if (redirectPath) {
          this.redirectPath = null;
          Backbone.history.navigate(redirectPath, {
            trigger: true
          });
        }

        setTimeout(function() {
          $('body').unmask();
          $('.load-cover').hide('fade', {
            duration: 250,
            complete: function() {
              $(this).remove();
            }
          });
        }, 250);
      };

      if (isV3User) {
        onStartComplete();
        return;
      }

      App.vent.trigger('Controller.StartModule', {
        moduleName: 'topnav',
        el: '#topnav',
        options: {
          navItems: amplify.store('AllowedModules'),
          hideSiblings: false,
          User: amplify.store('User'),
          dontShowView: dontShowView
        },
        startComplete: onStartComplete()
      });
    },
    clearCachedData: function() {
      //console.debug('clearing cache');
      var keys = amplify.store();
      _.each(keys, function(o, k) {
        if (k.match(/^ss_cache/gi) || k.match(/^UserInfo/gi))
          amplify.store(k, null);
      });
    },
    isLockedIntoAuth0Flow() {
      return Auth0Session.isLockedIntoAuth0Flow();
    },
    /**
     *
     * @param redirectToBlog
     * @param triggerEvents raise logout event. undefined defaults to TRUE
     */
    logout: function(redirectToBlog) {
      var me = this;

      if (Auth0Session.isAuthenticatedFast()) {
        amplify.store(sessionHandleKey, null);
        CookieService.removeSecureCookie('auth');
        CookieService.removeSecureCookie('x-imp');
        me.clearCachedData();
        amplify.store('AuthInfo', null);

        AuthRedirectService.redirectOnLogout();
        return;
      }

      var removeCookiesAndRedirect = function() {
        CookieService.removeSecureCookie('auth');
        CookieService.removeSecureCookie('x-imp');
        me.clearCachedData();

        // remove user's auth info
        amplify.store('AuthInfo', null);
        // save current url for redirect after login
        me.redirectPath = Backbone.history.location.hash.toLowerCase();

        if (
          redirectToBlog &&
          !App.user.permit(2048) &&
          AppDataService.getIsShipStation()
        ) {
          var sub = 'ss';
          try {
            sub = window.location.host.split('.')[0];
          } catch (e) {}
          window.location.href =
            '//www.shipstation.com/blog/?utm_source=' +
            sub +
            '&utm_medium=logout&utm_campaign=reroute&ss_src=logout';
        } else {
          // refresh page which will nav to login
          console.log('Session logout...');
          AuthRedirectService.redirectOnLogout();
        }
      };

      if (SERVER_SIDE_LOGOUT) {
        $.post(ApiUrl + 'Auth/LogOut').always(removeCookiesAndRedirect);
      } else {
        removeCookiesAndRedirect();
      }
    }
  };
});
