import { ApiUrl } from '../app/Config';

define(['backbone', 'models/ProductCategory'], function(
  Backbone,
  ProductCategory
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'ProductCategory',
    model: ProductCategory,
    comparator: 'Name',
    parse: function(resp) {
      return resp.categories;
    }
  });
});
