import HttpService from '../http-service';
import Auth0Session from '../auth0-session';

const defaultOptions = {
  useV3: false
};

// lol no error handling with .catch?
export default class ApiHttpService {
  static post(url, postData, apiOptions = defaultOptions) {
    const options = this.getOptions(url, apiOptions);
    options.body = postData;
    return HttpService.post(options).then(({ data }) => data);
  }

  static postForm(url, postData, apiOptions = defaultOptions) {
    const options = this.getOptions(url, apiOptions);
    options.body = postData;
    return HttpService.postForm(options).then(({ data }) => data);
  }

  static get(url, apiOptions = defaultOptions) {
    const options = this.getOptions(url, apiOptions);
    return HttpService.get(options).then(({ data }) => data);
  }

  static delete(url, apiOptions = defaultOptions) {
    const options = this.getOptions(url, apiOptions);
    return HttpService.delete(options).then(({ data }) => data);
  }

  static put(url, postData, apiOptions = defaultOptions) {
    const options = this.getOptions(url, apiOptions);
    options.body = postData;
    return HttpService.put(options).then(({ data }) => data);
  }

  static prefixUrl(url, useV3) {
    if (useV3) {
      return `/apiV3/${url}`;
    }
    return `/api/${url}`;
  }

  static getOptions(url, apiOptions) {
    const options = {};
    options.url = this.prefixUrl(url, apiOptions.useV3);

    if (Auth0Session.isAuthenticatedFast()) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers[
        'Authorization'
      ] = `Bearer ${Auth0Session.getTokenFast()}`;
    }

    return options;
  }
}
