import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '09a2efd5-8632-497d-8c58-cd929619a5b5',
    defaultMessage: 'Please wait...'
  }),
  defineMessage({
    id: '1aa1af7f-d5ed-46b9-b3e9-e01dcfe5a1e2',
    defaultMessage: 'Confirm'
  }),
  defineMessage({
    id: '8303871a-b74e-4868-9734-006c8e18e70b',
    defaultMessage: 'Cancel'
  })
];

export default translations;
