export default new (class ShipengineSandboxCookieService {
  setCookie(sandboxKeyData) {
    let formatKeyData = keyData => {
      let result = {
        keys: []
      };

      if (!keyData) {
        return result;
      }

      keyData.forEach(key => {
        let k = {
          key: key.EncryptedApiKey
        };
        if (key.Description) {
          k['description'] = key.Description;
        }

        result.keys.push(k);
      });

      return result;
    };

    var rawCookieValue = formatKeyData(sandboxKeyData);
    var cookieValueString = JSON.stringify(rawCookieValue);
    var encodedCookieValue = encodeURIComponent(cookieValueString);

    var expiration = new Date();
    expiration.setFullYear(expiration.getFullYear() + 1);

    var cookieString = `personalization=${encodedCookieValue}; Secure; Domain=.shipengine.com; SameSite=Strict; Max-Age=31536000; Expires=${expiration.toGMTString()}; Path=/`;

    document.cookie = cookieString;
  }
})();
