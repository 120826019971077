// this file puts it's output on window.filterXSS which *is* used.
import 'libs/xss/xss';
import getReplacements from '../views/Settings/EmailTemplates/Replacements';
define(['underscore'], function(_) {
  var xssWhiteList = {
    a: ['style', 'class', 'id', 'target', 'href', 'title'],
    abbr: ['style', 'class', 'id', 'title'],
    address: ['style', 'class', 'id'],
    area: ['style', 'class', 'id', 'shape', 'coords', 'href', 'alt'],
    article: ['style', 'class', 'id'],
    aside: ['style', 'class', 'id'],
    audio: [
      'style',
      'class',
      'id',
      'autoplay',
      'controls',
      'loop',
      'preload',
      'src'
    ],
    b: ['style', 'class', 'id'],
    bdi: ['style', 'class', 'id', 'dir'],
    bdo: ['style', 'class', 'id', 'dir'],
    big: ['style', 'class', 'id'],
    blockquote: ['style', 'class', 'id', 'cite'],
    br: ['style', 'class', 'id'],
    caption: ['style', 'class', 'id'],
    center: ['style', 'class', 'id'],
    cite: ['style', 'class', 'id'],
    code: ['style', 'class', 'id'],
    col: ['style', 'class', 'id', 'align', 'valign', 'span', 'width'],
    colgroup: ['style', 'class', 'id', 'align', 'valign', 'span', 'width'],
    dd: ['style', 'class', 'id'],
    del: ['style', 'class', 'id', 'datetime'],
    details: ['style', 'class', 'id', 'open'],
    div: ['style', 'class', 'id', 'align'],
    dl: ['style', 'class', 'id'],
    dt: ['style', 'class', 'id'],
    em: ['style', 'class', 'id'],
    font: ['style', 'class', 'id', 'color', 'size', 'face'],
    footer: ['style', 'class', 'id'],
    h1: ['style', 'class', 'id'],
    h2: ['style', 'class', 'id'],
    h3: ['style', 'class', 'id'],
    h4: ['style', 'class', 'id'],
    h5: ['style', 'class', 'id'],
    h6: ['style', 'class', 'id'],
    header: ['style', 'class', 'id'],
    hr: ['style', 'class', 'id'],
    i: ['style', 'class', 'id'],
    img: ['style', 'class', 'id', 'src', 'alt', 'title', 'width', 'height'],
    ins: ['style', 'class', 'id', 'datetime'],
    li: ['style', 'class', 'id'],
    mark: ['style', 'class', 'id'],
    nav: ['style', 'class', 'id'],
    ol: ['style', 'class', 'id'],
    p: ['style', 'class', 'id'],
    pre: ['style', 'class', 'id'],
    s: ['style', 'class', 'id'],
    section: ['style', 'class', 'id'],
    small: ['style', 'class', 'id'],
    span: ['style', 'class', 'id'],
    sub: ['style', 'class', 'id'],
    sup: ['style', 'class', 'id'],
    strong: ['style', 'class', 'id'],
    table: ['style', 'class', 'id', 'width', 'border', 'align', 'valign'],
    tbody: ['style', 'class', 'id', 'align', 'valign'],
    td: [
      'style',
      'class',
      'id',
      'width',
      'rowspan',
      'colspan',
      'align',
      'valign'
    ],
    tfoot: ['style', 'class', 'id', 'align', 'valign'],
    th: [
      'style',
      'class',
      'id',
      'width',
      'rowspan',
      'colspan',
      'align',
      'valign'
    ],
    thead: ['style', 'class', 'id', 'align', 'valign'],
    tr: ['style', 'class', 'id', 'rowspan', 'align', 'valign'],
    tt: ['style', 'class', 'id'],
    u: ['style', 'class', 'id'],
    ul: ['style', 'class', 'id'],
    video: [
      'style',
      'class',
      'id',
      'autoplay',
      'controls',
      'loop',
      'preload',
      'src',
      'height',
      'width'
    ],
    strike: ['style', 'class', 'id']
  };

  function getReplacementFields() {
    const replacements = getReplacements();
    const replacementItems = _.pluck(replacements.content, 'items');
    const replacementItemsFlattened = _.flatten(replacementItems);
    function pluckValueOrText(item) {
      if (item.value) {
        return item.value;
      }
      if (item.text) {
        return item.text;
      }
    }
    return _.map(replacementItemsFlattened, pluckValueOrText);
  }

  var replacementFields = getReplacementFields();
  function isReplacementField(str) {
    var len = replacementFields.length;
    for (var i = 0; i < len; i++) {
      var field = replacementFields[i];
      if (field && field.indexOf(str) !== -1) {
        return true;
      }
    }
    return false;
  }

  function ssOnTagAttr(tag, name, value) {
    if (tag === 'a' && name === 'href' && isReplacementField(value)) {
      return name + '="' + value + '"';
    }
  }

  function doFilterXss() {
    var filterXssOriginal = window.filterXSS;
    function ssFilterXSS(html, options) {
      var opts = options || {};
      opts.whiteList = xssWhiteList;
      opts.onTagAttr = ssOnTagAttr;
      return filterXssOriginal(html, opts);
    }
    window.filterXSS = ssFilterXSS;
  }
  return doFilterXss;
});
