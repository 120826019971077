import jsCookie from 'js-cookie';

import AppDataService from './app-data-service';

export default class RecaptchaService {
  constructor(elementId, confirmationCallback) {
    this.elementId = elementId;
    this.confirmationCallback = confirmationCallback;
    this.resetCount = 0;
  }

  render() {
    // poll until page fully loads the element
    const rcTimeout = window.setTimeout(() => {
      if (!$(this.elementId)) {
        return;
      }
      window.clearTimeout(rcTimeout);

      //prevent double render
      if (this.reCaptchaRenderCalled) {
        return;
      }

      this.reCaptchaRenderCalled = true;

      window.grecaptcha.render(this.elementId, {
        sitekey: AppDataService.getRecaptchaSiteKey(),
        callback: this.confirmationCallback
      });
    }, 500);
  }

  hasPassedChallenge(username) {
    if (this.shouldSuppress(username)) {
      return true;
    }
    if (window.grecaptcha.getResponse()) {
      return true;
    }
    return false;
  }

  getResponse() {
    return window.grecaptcha.getResponse();
  }

  challenge() {
    window.grecaptcha.execute();
  }

  reset() {
    window.grecaptcha.reset();
    this.resetCount++;
  }

  shouldSuppress(username) {
    const cookie = jsCookie.get('uct');

    if (!cookie) {
      return false;
    }

    const tokens = cookie.split('&');
    if (tokens.length === 0) {
      return false;
    }
    const hasUsername =
      tokens.filter(tok => tok.startsWith(username)).length > 0;
    if (hasUsername && this.resetCount < 5) {
      return true;
    }
    return false;
  }
}
