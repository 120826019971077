import _ from 'underscore';

import { getEnabledTermsAndConditions } from 'client/services/launch-darkly-flag-service';
import ModalManagerService from 'client/services/modal-manager-service';
import UserService from 'client/services/user-service';
import ApiHttpService from 'client/services/api/api-http-service';

import { buildCanadaTermsAndConditionsModalConfig } from '../components/canada-terms-conditions/index';

const newTermsLabel = 'canada_mar_2021';

export default class CanadaTermsAndConditionsService {
  static async showTermsIfRequired(sellerId, userId) {
    this.SellerId = sellerId;
    this.UserId = userId;
    const flag = await getEnabledTermsAndConditions();
    const hasAcceptedTerms = await this.getHasAcceptedTerms();
    const { mustAcceptByDateUTC, enabled } = flag;
    const isAdmin = UserService.hasAccessToAdministration();
    const now = new Date();
    const acceptBy = new Date(mustAcceptByDateUTC);
    const pastDeadline = now > acceptBy;

    if (!enabled || hasAcceptedTerms || (!isAdmin && !pastDeadline)) {
      return;
    }

    ModalManagerService.launchModal(
      buildCanadaTermsAndConditionsModalConfig({
        isAdmin,
        pastDeadline,
        acceptCanadaTermsAndConditions: this.acceptCanadaTermsAndConditions.bind(
          this
        )
      })
    );
  }

  static getHasAcceptedTerms() {
    return ApiHttpService.post('terms/GetCountryTermsAccepted', {
      SellerId: this.SellerId,
      UserId: this.UserId,
      newTermsLabel
    }).then(res => _.isNull(res) || res); // if the response is null, the user has signed up after the terms changed and accepted them on signup.
  }

  static acceptCanadaTermsAndConditions() {
    return ApiHttpService.post('terms/AcceptCountrySpecificTerms', {
      SellerId: this.SellerId,
      UserId: this.UserId,
      newTermsLabel
    });
  }
}
