import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PaletteService from '../../services/palette-service';
import ButtonThemeService from '../../services/button-theme-service';
import LoadingIndicator from '../loading-indicator';
import ButtonSizes from '../../prop-types/button-sizes';
import Noop from '../../default-props/noop';
import generateDataTestidAttribute from '../../services/testid-service';

import './buttons.less';

const buttonSizeToClassMap = {
  normal: '',
  large: 'large-button',
  small: 'small-button'
};

const getClassNameFromTheme = theme => {
  const appPalette = PaletteService.getPalette();
  return appPalette.buttonClasses[theme] || `button-${theme}`;
};

const getLoadingTheme = theme => {
  if (theme === '') {
    return 'grey';
  }
  return 'white';
};

const renderLoadingIndicator = (isLoading, theme) => {
  if (!isLoading) {
    return;
  }
  return <LoadingIndicator theme={getLoadingTheme(theme)} />;
};

const Button = class extends React.PureComponent {
  static propTypes = {
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    href: PropTypes.string,
    theme: PropTypes.oneOf(ButtonThemeService.getButtonThemes()),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    otherProperties: PropTypes.object,
    id: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    tabIndex: PropTypes.number,
    buttonSize: ButtonSizes,
    style: PropTypes.object,
    testId: PropTypes.string
  };

  static defaultProps = {
    onBlur: Noop,
    onClick: Noop,
    onFocus: Noop,
    onKeyDown: Noop,
    onMouseEnter: Noop,
    onMouseLeave: Noop,
    href: undefined,
    theme: '',
    children: null,
    disabled: false,
    className: '',
    isLoading: false,
    otherProperties: {},
    id: '',
    target: '_blank',
    rel: 'noreferrer noopener',
    tabIndex: 0,
    buttonSize: 'normal',
    style: {},
    testId: undefined
  };

  onClick = e => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    const {
      onBlur,
      onClick,
      onFocus,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      href,
      theme,
      disabled,
      children,
      className,
      isLoading,
      otherProperties,
      id,
      target,
      rel,
      tabIndex,
      buttonSize,
      style,
      testId
    } = this.props;

    const buttonClassnames = classNames(
      getClassNameFromTheme(theme),
      className,
      {
        'is-loading': isLoading,
        [buttonSizeToClassMap[buttonSize]]:
          theme !== 'link' && theme !== 'unstyled'
      }
    );
    const buttonTitle = typeof children === 'string' ? children : undefined;
    if (href) {
      return (
        <a
          disabled={disabled}
          className={buttonClassnames}
          href={href}
          target={target}
          rel={rel}
          id={id}
          tabIndex={tabIndex}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={buttonTitle}
          {...otherProperties}
          {...generateDataTestidAttribute(testId)}
        >
          {children}
          {renderLoadingIndicator(isLoading, theme)}
        </a>
      );
    }
    return (
      <button
        disabled={disabled}
        className={buttonClassnames}
        onBlur={onBlur}
        onClick={this.onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type="button"
        id={id}
        tabIndex={tabIndex}
        style={style}
        title={buttonTitle}
        {...otherProperties}
        {...generateDataTestidAttribute(testId)}
      >
        {children}
        {renderLoadingIndicator(isLoading, theme)}
      </button>
    );
  }
};

export default Button;
