import LogRocket from 'logrocket';
import NoopAnalyticsService from 'client/services/analytics/noop-analytics-service';
import AppDataService from 'client/services/app-data-service';
import UserService from 'client/services/user-service';
import UserInterfaceService from 'client/services/user-interface-service';

const scrubRequestUrls = [
  'billinginfo',
  'changestampsaccount',
  'stamps/updatepaymentinfo',
  'providers/register',
  'provider/endicia/changeaccount',
  'wallet/updatebillinginformation',
  'wallet/register',
  'provider/dhl/internationalsetup',
  'firstmile/updatesettings',
  'unishippers/updatesettings',
  'marketplace',
  'stores/connect',
  'disableapikey'
];

const scrubResponseUrls = [
  'createshipengineapikey',
  'getsellershipengineapikeys',
  'sandbox/api_keys',
  'sandbox/create_api_key',
  'billinginfo'
];

const checkUrlList = (url, urlsToScrub) => {
  return urlsToScrub.reduce((acc, val) => {
    return acc || url.indexOf(val) !== -1;
  }, false);
};

const logRocketNetworkSanitizer = {
  network: {
    requestSanitizer: request => {
      if (request.headers['Authorization-Token']) {
        request.headers['Authorization-Token'] = null;
      }
      if (request.headers['Authorization']) {
        request.headers['Authorization'] = null;
      }
      if (checkUrlList(request?.url?.toLowerCase(), scrubRequestUrls)) {
        request.body = null;
      }
      return request;
    },
    responseSanitizer: response => {
      if (response.headers['Authorization-Token']) {
        response.headers['Authorization-Token'] = null;
      }
      if (response.headers['Authorization']) {
        response.headers['Authorization'] = null;
      }
      if (checkUrlList(response?.url?.toLowerCase(), scrubResponseUrls)) {
        response.body = null;
      }
      return response;
    }
  }
};
export default class LogRocketService extends NoopAnalyticsService {
  constructor(logRocketKey) {
    super();

    const isShipEngine = AppDataService.getIsShipEngine();
    const isLogRocketEnabledForSeller =
      AppDataService.getSetting('EnableLogRocketForV2') === 'true' ||
      isShipEngine;

    if (!isLogRocketEnabledForSeller) {
      return;
    }

    if (!logRocketKey) {
      console.log('LogRocket init failed.  Key is undefined!');
      return;
    }

    const userData = isShipEngine
      ? UserService.getShipEngineUserData()
      : UserService.getUserData();

    const billingPlansToExclude = [
      'ss_starter',
      'starter',
      'yahoo_starter',
      'free_starter'
    ];
    if (billingPlansToExclude.includes(userData.plan)) {
      return;
    }
    setTimeout(() => {
      LogRocket.init(logRocketKey, {
        ...logRocketNetworkSanitizer,
        release:
          process.env.RELEASE_VERSION && process.env.RELEASE_VERSION.length
            ? `${isShipEngine ? 'SE-Dash' : 'SS-V2'}@${
                process.env.RELEASE_VERSION
              }`
            : undefined
      });
      LogRocket.identify(userData.email, {
        name: userData.name,
        email: userData.email,
        refCode: userData.refcode,
        plan: userData.plan,
        username: userData.userName,
        sellerId: userData.seller,
        user: userData.userId,
        company: userData.pageTitle,
        createdAt: userData.userSince,
        isIFramedInV3: UserInterfaceService.appIsIFramedInV3(),
        isNativeV3User: AppDataService.getSetting('IsNativeV3Seller'),
        isBetaV3User: AppDataService.getSetting('EarlyAccessEnabled')
      });
    }, 20000);
  }
}
