import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '542b8e0c-2dd1-485a-ab61-ab3617df4529',
    defaultMessage: 'Close'
  })
];

export default translations;
