import ShipstationConnectUtils from '../shipstation-connect-utils';

const VersionPubSubHandler = {
  event: 'app:message:client-connect:response:version',
  handler: msg => {
    ShipstationConnectUtils.updateJob(msg, job => {
      job.notification.remove();

      job.def.resolve(msg.data);
    });
  }
};

export default VersionPubSubHandler;
