import React from 'react';
import { render } from 'react-dom';
import { ApiUrl } from '../../../../../app/Config';
import ProviderRegistrationForm from 'client/components/ipaas-forms/shipping/provider-registration-form';
import template from './ProviderRegistrationWizard.html';
import 'public/less/ipaas-data-driven-modal.less';

define(['core/Wizard'], function(Wizard) {
  return Wizard.extend({
    initialize(options) {
      options = options || {};
      options.width = '800px';
      options.height = '720px';
      options.title = `Connect your ${options.formMetadata.providerName} account`;
      this.formMetadata = options.formMetadata;
      this.fillProviderId = options.fillProviderId;
      this._super('initialize', [options]);
    },
    getConfig() {
      return {
        template,
        tplData: {
          fillProviderId: this.fillProviderId
        },
        steps: {
          Connect: {
            el: '.provider-registration-form-wizard',
            title: this.formMetadata.formSchema.jsonSchema.title
          }
        }
      };
    },
    showError(error) {
      this.$el.find('.reg-error').show();
      this.$el.find('.reg-error-message').html(error);
    },
    finish() {
      this.form.submit();
    },
    onFormSubmission(formData) {
      this.mask();
      formData.providerId = this.fillProviderId;
      $.post(
        `${ApiUrl}${
          this.formMetadata.submitUrl
            ? this.formMetadata.submitUrl
            : 'fillproviders/register'
        }`,
        { ...formData },
        'json'
      )
        .done(() => {
          this.close();
          this.trigger('complete');
        })
        .fail(resp => {
          this.showError(resp.responseJSON.Message);
        })
        .always(() => {
          this.unmask();
        });
      this._super('finish', arguments);
    },
    onCancel(e) {
      this.trigger('cancel');
      this._super('onCancel', e);
    },
    render() {
      this._super('render', arguments);
      this.$el.find('.wizard-crumbs').hide();
      this.$el.find('.wizard-steps').css({ top: 0, bottom: 0 });

      const jsonSchema = {
        title: 'Account Information',
        type: 'object',
        ...this.formMetadata.formSchema.jsonSchema
      };
      const uiSchema = this.formMetadata.formSchema.uiSchema;

      this.form = render(
        <ProviderRegistrationForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          providerId={this.fillProviderId}
          onSubmit={({ formData }) => this.onFormSubmission(formData)}
          hideDataDrivenLogo
        />,
        this.$el.find('#fill-provider-registration-form')[0]
      );
    }
  });
});
