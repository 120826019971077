/* jshint browser: true */

import { createAuth0Client } from '@auth0/auth0-spa-js';
import AppDataService from 'client/services/app-data-service';
import UserInterfaceService from 'client/services/user-interface-service';
import jsCookie from 'js-cookie';

const CookieSSHome = 'SSHome';
const CookieAuth0Flag = 'auth0.is.authenticated';
const CookieAuth0PartitionClaimChanged = 'auth0.partition.host.changed';
const CookieAccessToken = '_lat';
const CookieRedirecting = 'ss.redirecting';
const CookieIsAuth0Login = 'auth0.is.login';
const CookieLegacyAuth = 'auth';
const CookieAuthFlow = '_auth_flow';
const CheckAuth0SessionInterval = 3600000; // 1 hr
const CheckAuth0SessionIntervalOnError = 3600000; // 1 hr

const SSClaimPrefix = 'http://shipstation.com/';

class Auth0Session {
  initialize() {
    if (this.auth0) {
      //console.warn('auth0-session: already been initialized (noop)');
      return;
    }
    if (this.clientInitializePromise) {
      //console.warn('auth0-session: initializing');
      return;
    }

    this.nextAccessTokenRefreshTime = 0;
    this.checkedIsAuthenticatedAfterInit = false;
    this.checkedGetInitialAccessTokenAfterInit = false;
    this.instaPromise = new Promise(resolve => {
      resolve();
    });

    const auth0Settings = AppDataService.getAuth0ClientCredentials();
    if (auth0Settings) {
      const opts = {
        domain: auth0Settings.domain,
        clientId: auth0Settings.client_id,
        authorizationParams: {
          audience: auth0Settings.audience
        },
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        useRefreshTokensFallback: true
      };

      if (auth0Settings.connection) {
        opts.connection = auth0Settings.connection;
      }
      this.clientInitializePromise = createAuth0Client(opts)
        .then(auth0 => {
          this.auth0 = auth0;

          if (this._isAuth0Down()) {
            this._restoreUserAndTokenFromLocalStorage().then(restoreSuccess => {
              if (!restoreSuccess) {
                this.logout();
                return;
              }
              this._startKeepAlive();
            });
          }

          delete this.clientInitializePromise;
        })
        .catch(e => {
          console.error(e);
          delete this.clientInitializePromise;
        });
    }
  }

  /**
   * skips the SDK-provided promise based
   * check for sync calls. may not be super reliable
   * this is to prevent refactoring auth into callback hell
   */
  isAuthenticatedFast() {
    if (this.inCodeExchange) {
      return true;
    }
    return !!(this.user && this.token);
  }

  /**
   *
   */
  isAuthenticated() {
    const promiseWrapper = this.clientInitializePromise
      ? this.clientInitializePromise
      : this.instaPromise;

    // noinspection JSValidateTypes
    return promiseWrapper
      .then(() => {
        let isAuthenticatedAccordingToAuth0 = this.auth0.isAuthenticated();
        return isAuthenticatedAccordingToAuth0;
      })
      .then(isAuthenticated => {
        if (typeof isAuthenticated === 'undefined') {
          return false;
        }

        if (this._shouldTrySilentAuth(isAuthenticated)) {
          return this.auth0.getTokenSilently().then(accessToken => {
            this._promoteAuth0CookieDomain();
            return accessToken;
          });
        }
        return false;
      })
      .catch(error => {
        console.log(error);
        return false;
      })
      .then(token => {
        if (!token) {
          return false;
        }
        const shouldUpdateAt = this.token !== token;
        this.token = token;
        if (shouldUpdateAt) {
          this._updateAt();
        }
        return this.auth0.getUser();
      })
      .then(user => {
        if (!user) {
          // when auth0 is down, any token, expired or otherwise, will suffice.
          return !!(this.isAuthenticatedFast() && window.SS_GLOBALS.auth0Down);
        }
        const redirecting = Auth0Session._handlePartitionRedirect(user);

        if (redirecting) {
          return false;
        }

        this.user = user;

        const opts = Auth0Session._createCookieOptions();
        jsCookie.set(CookieAuthFlow, 'auth0', opts);
        this._startKeepAlive();

        return true;
      });
  }

  /**
   * AT for session
   */
  getTokenFast() {
    return this.token;
  }

  isLogin() {
    const isLogin = jsCookie.get(CookieIsAuth0Login) === 'true';
    if (isLogin) {
      const cookieOptions = Auth0Session._createCookieOptions();
      jsCookie.remove(CookieIsAuth0Login, cookieOptions);
    }
    return isLogin;
  }

  // Auth0 API doesn't return a promise but this is an async function
  // and we want a handler for it
  logout() {
    this._stopKeepAlive();
    delete this.token;
    delete this.user;

    const cookieOptions = Auth0Session._createCookieOptions();
    jsCookie.remove(CookieAccessToken, cookieOptions);
    jsCookie.remove(CookieAuth0Flag, cookieOptions);
    jsCookie.remove(CookieAuthFlow, cookieOptions);
    jsCookie.remove(CookieAuth0PartitionClaimChanged, cookieOptions);
    jsCookie.remove(CookieLegacyAuth, cookieOptions);

    const promiseWrapper = this.clientInitializePromise
      ? this.clientInitializePromise
      : this.instaPromise;
    promiseWrapper.then(() => {
      const logoutOpts = {
        logoutParams: {
          returnTo: window.location.origin
        }
      };

      this.auth0.logout(logoutOpts);

      if (this._isAuth0Down()) {
        this._forceLegacyAuth();
      }
    });
  }

  isLockedIntoAuth0Flow() {
    return jsCookie.get(CookieAuthFlow);
  }

  _forceLegacyAuth() {
    App.vent.trigger('Controller.StartModule', {
      moduleName: 'login',
      options: {
        modal: false
      }
    });
  }

  _restoreUserAndTokenFromLocalStorage() {
    if (!this.user) {
      return this.auth0.getUser().then(user => {
        this.user = user;
        this.token = this.token ? this.token : jsCookie.get(CookieAccessToken);

        return this.isAuthenticatedFast();
      });
    }
  }

  _isAuth0Down() {
    return !!window.SS_GLOBALS.auth0Down;
  }

  // we want to be able to accept "cross-origin" tld cookie so that other partitions will honor auth0 SSO state. we
  // remove the subdomain cookie (e.g. ship9.shipstation.com) and replace it with a tld (.shipstation.com). this
  // needs to recur on every exchange
  _promoteAuth0CookieDomain() {
    // bypass jquery so we don't inherit cookie options. just expire it directly
    document.cookie =
      'auth0.is.authenticated=false; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';

    const opts = Auth0Session._createCookieOptions();
    opts.expires = 1;
    jsCookie.set(CookieAuth0Flag, true, opts);
  }

  _shouldTrySilentAuth(clientIsAuthenticatedResult) {
    const isCookieAuth = !!jsCookie.get(CookieAuth0Flag);

    const hasLegacyAuthToken = !!(
      jsCookie.get(CookieLegacyAuth) &&
      jsCookie.get(CookieLegacyAuth) !== 'undefined'
    );

    const isV3iFrame = UserInterfaceService.appIsIFramedInV3();

    /*
    console.table({
      auth0ClientAuth: clientIsAuthenticatedResult,
      auth0CookieAuth: isCookieAuth,
      'v3-iFrame': isV3iFrame,
      'legacy-token': hasLegacyAuthToken
    });
    */

    if (clientIsAuthenticatedResult || isCookieAuth) {
      return true;
    }

    // when in v3 rely on auth0 session to reinit the auth in V2
    if (isV3iFrame && !hasLegacyAuthToken) {
      return true;
    }

    // noinspection RedundantIfStatementJS
    if (Auth0Session.isRedirection()) {
      return true;
    }

    return false;
  }

  _startKeepAlive() {
    if (this.keepAlive) {
      return;
    }

    // getTokenSilently will only hit auth0 for a new AT when the current AT
    // is expired or within 60 sec of expiry => Refresh interval must be < 60 sec.
    this.keepAlive = setInterval(() => {
      if (Date.now() >= this.nextAccessTokenRefreshTime) {
        let nextCheckIn;
        try {
          this.auth0
            .getTokenSilently()
            .then(token => {
              nextCheckIn = !token
                ? CheckAuth0SessionIntervalOnError
                : CheckAuth0SessionInterval;
              this._promoteAuth0CookieDomain();
              const shouldUpdateAt = this.token !== token;
              this.token = token;
              if (shouldUpdateAt) {
                this._updateAt();
              }
            })
            .catch(() => {
              nextCheckIn = CheckAuth0SessionIntervalOnError;
            })
            .finally(() => {
              this.nextAccessTokenRefreshTime = Date.now() + nextCheckIn;
            });
        } catch (ex) {
          nextCheckIn = CheckAuth0SessionIntervalOnError;
          console.error(`error in session keepalive => ${JSON.stringify(ex)}`);
        }
      }
    }, 1000);
  }

  _stopKeepAlive() {
    clearInterval(this.keepAlive);
  }

  _updateAt() {
    const currentAT = jsCookie.get(CookieAccessToken);
    if (currentAT !== this.token) {
      jsCookie.set(
        CookieAccessToken,
        this.token,
        Auth0Session._createCookieOptions()
      );
    }
  }

  /**
   * Extract partition from user/IT and setup for redirect if applicable
   * @param user from IT
   * @returns {boolean} whether or not redirect will happen
   * @private
   */

  static _handlePartitionRedirect(user) {
    const _isLocalDevEnv = () => {
      return window.location.href.match(
        /^http[s]?:\/\/.*local.*.(shipstation|sslocal)\.com/gi
      );
    };
    if (_isLocalDevEnv()) {
      return false;
    }
    const partitionHost = UserInterfaceService.specialHomeParitionDevFilters(
      Auth0Session._getShipStationClaim(user, 'partition_host')
    );
    if (!partitionHost) {
      console.warn('missing partition_host claim!');
      return false;
    }

    const isV3iFrame = UserInterfaceService.appIsIFramedInV3();
    const isImpersonating = jsCookie.get('x-imp');
    const isSysAdmin = Auth0Session._isSysAdmin(user);
    const cookieOptions = Auth0Session._createCookieOptions();
    const currentHost = window.location.hostname;

    if (
      !isV3iFrame &&
      !isSysAdmin &&
      !isImpersonating &&
      partitionHost !== currentHost
    ) {
      const currentSSHome = jsCookie.get(CookieSSHome);
      //the destination system should add the sshome cookie (from Session.js)
      if (currentSSHome) {
        jsCookie.remove(CookieSSHome, cookieOptions);
      }
      jsCookie.set(CookieRedirecting, partitionHost, cookieOptions);
      window.location.href = window.location.href.replace(
        currentHost,
        partitionHost
      );
      return true;
    }

    let shouldWriteSSHomeCookie = !isV3iFrame && !isSysAdmin;
    if (shouldWriteSSHomeCookie) {
      jsCookie.set(CookieSSHome, partitionHost, cookieOptions);
    }
    jsCookie.remove(CookieRedirecting, cookieOptions);

    return false;
  }

  static _createCookieOptions() {
    return {
      path: '/',
      domain: window.location.cookieDomain(),
      secure: window.location.protocol === 'https:',
      samesite: 'none'
    };
  }

  static isRedirection() {
    return jsCookie.get(CookieRedirecting) === window.location.hostname;
  }

  // KCP - Note:  Duplicating this functionality here (also in UserService) is pretty bad, but
  // we need knowledge of a user's role well before data call.  Consider fetching data before
  // app load
  static _isSysAdmin = user => {
    const userRoleMask = Auth0Session._getShipStationClaim(user, 'role_set');
    if (!userRoleMask) {
      return false;
    }
    return (2048 & userRoleMask) == 2048;
  };

  static _getShipStationClaim(token, claim) {
    if (
      claim === 'partition_host' &&
      jsCookie.get(CookieAuth0PartitionClaimChanged)
    ) {
      return jsCookie.get(CookieAuth0PartitionClaimChanged);
    }
    return token[`${SSClaimPrefix}${claim}`];
  }
}

export default new Auth0Session();
