import CountryService from '../../../../client/services/country-service';

import ActionBarTemplate from './actionBarTemplate.html';

const hintKBArticlesLoaders = {
  AU: cb => import('./hintKBArticles/au').then(cb),
  CA: cb => import('./hintKBArticles/ca').then(cb),
  GB: cb => import('./hintKBArticles/gb').then(cb),
  US: cb => import('./hintKBArticles/us').then(cb)
};

const loadHintKBArticles = async () => {
  const homeCountry = CountryService.getHomeCountry();
  const loader = hintKBArticlesLoaders[homeCountry];
  if (!loader) {
    return {
      default: {}
    };
  }
  const articles = await loader();
  return articles;
};

const getHintsConfig = () => {
  return new Promise(async resolve => {
    const countryArticles = (await loadHintKBArticles()).default;

    resolve({
      'Hint:Orders:BottomToggle': {
        sellersAfter: 100000, //New feature for existing sellers
        icon: 'icon-gear',
        title: 'Show workflow panes',
        content:
          'Have a large screen? Try enabling workflow panes to show more useful tools.'
      },
      'Hint:Orders:Drawer': {
        title: 'The Order Sidebar',
        content:
          'Change shipping settings, create labels, add orders to batches, and get rate quotes either individually or in bulk!',
        articles: [
          {
            title: 'What is a Ship From Location?',
            href: countryArticles['What is a Ship From Location?'] || ''
          },
          {
            title: 'How do I create shipping labels for my orders?',
            href:
              countryArticles[
                'How do I create shipping labels for my orders?'
              ] || ''
          }
        ]
      },
      'Hint:Orders:Filters': {
        title: 'Order Filters',
        icon: 'icon-bullseye',
        content:
          'Narrow down the results below by applying quick filters or save frequent or complex filters for reuse in the future.',
        sellersAfter: 100000, //Onboard hint for new sellers
        articles: [
          {
            title: 'How can I save filters to reuse them?',
            href: countryArticles['How can I save filters to reuse them?'] || ''
          },
          {
            title: 'How can I select more than one filter field?',
            href:
              countryArticles['How can I select more than one filter field?'] ||
              ''
          }
        ]
      },
      'Hint:Orders:Actions': {
        title: 'Action Bar',
        icon: 'icon-bullseye',
        content: ActionBarTemplate,
        sellersAfter: 100000, //Onboard hint for new sellers
        articles: [
          {
            title:
              'How can I set default printing methods for different types of documents?',
            href:
              countryArticles[
                'How can I set default printing methods for different types of documents?'
              ] || ''
          },
          {
            title:
              "What does ShipStation's native Inventory Management solution offer?",
            href:
              countryArticles[
                "What does ShipStation's native Inventory Management solution offer?"
              ] || ''
          }
        ]
      },
      'Hint:Orders:Tray': {
        sellersAfter: 100000, //Onboard hint for new sellers
        title: 'Order Statuses & Views',
        icon: 'icon-lightbulb',
        content:
          'Quickly switch between orders in various statuses, various sales channels, or even custom views you build yourself!',
        articles: [
          {
            title: 'How can I save a view for reuse?',
            href: countryArticles['How can I save a view for reuse?'] || ''
          }
        ]
      },
      'Hint:Orders:Batches': {
        sellersAfter: 100000, //Onboard hint for new sellers
        title: 'Open Batches',
        icon: 'icon-star',
        content:
          'Open batches allow you to isolate orders that are ready to ship and process them in a single, efficient transaction.',
        articles: [
          {
            title: 'Why should I create a Label Batch on the Orders tab?',
            href:
              countryArticles[
                'Why should I create a Label Batch on the Orders tab?'
              ] || ''
          }
        ]
      },
      'Hint:Orders:Columns': {
        sellersAfter: 100000, //Onboard hint for new sellers
        title: 'Customize Your View',
        content:
          'Show and hide columns, customize your layout and group orders together. These preferences are saved for your user account.'
      }
    });
  });
};

export default getHintsConfig;
