define(['backbone'], function(Backbone) {
  return Backbone.Collection.extend({
    // SS-4266 - sort package first, then alphabetically, this is also done on the server, but doesn't hurt to do it here too
    comparator: function(m) {
      if (m.get('Name') == 'Package') {
        return 0;
      }
      return m.get('CarrierID') + (m.get('Name') || '').toLowerCase();
    }
  });
});
