import { ApiUrl } from '../app/Config';

define(['backbone', 'models/Workstation'], function(Backbone, Workstation) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Workstations',
    model: Workstation,
    comparator: function(m) {
      var comp = '';
      comp = m.get('Online') ? '0' : '1';
      comp += m.getActivePrinters().length > 0 ? '0' : '1';
      comp += m.get('UserID') == App.user.userId ? '0' : '1';
      comp += (m.get('Description') || '').toUpperCase();
      return comp;
    },
    getWorkstationName: function(workstationId) {
      var workstation = this.get(workstationId);
      var workstationName = workstation
        ? workstation.get('Description')
        : 'A ShipStation Connect workstation';

      return workstationName;
    },
    getScaleName: function(workstationId, scaleId) {
      var workstation = this.get(workstationId);
      if (workstation) {
        var scale = workstation.get('Scales').get(scaleId);
        if (scale) {
          return scale.get('name') || 'A ShipStation Connect scale';
        }
      }

      return 'A ShipStation Connect scale';
    }
  });
});
