import HotkeyLogging from 'client/services/hotkeys/core/hotkey-logging';
import CookieDomainNameResolutionService from 'client/services/cookie-domain-name-resolution-service';
import initRefreshNotifications from 'client/refresh-notification';
import 'Session'; // important! session has side-effects on import

define([], function() {
  initRefreshNotifications();

  window.location.cookieDomain = function() {
    return CookieDomainNameResolutionService.getCookieDomainNameFromHostName(
      window.location.hostname
    );
  };

  setTimeout(() => {
    App.turnOnHotkeyLogging = HotkeyLogging.turnOnHotkeyLogging;
    App.turnOffHotkeyLogging = HotkeyLogging.turnOffHotkeyLogging;
  }, 0);
});
