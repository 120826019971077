import { ApiUrl } from '../app/Config';

define(['WeightAndMeasureModel', 'Data'], function(
  WeightAndMeasureModel,
  Data
) {
  return WeightAndMeasureModel.extend({
    idAttribute: 'PackageTypeID',
    urlRoot: ApiUrl + 'Packages',
    defaults: _.extend(
      {
        HasDimensions: function() {
          return this.get('Length') && this.get('Width') && this.get('Height')
            ? true
            : false;
        }
      },
      WeightAndMeasureModel.prototype.defaults
    ),
    set: function(key, value, options) {
      var attributes = {};
      if (!_.isObject(key)) {
        attributes[key] = value;
      } else {
        attributes = key;
        options = value;
      }

      return this._super('set', [attributes, options]);
    },
    DisplayDimension: function(dim) {
      var uom = (
        Data.Settings['UnitOfMeasure:Dimension'] || 'inch'
      ).toLowerCase();

      if (uom == 'inch') {
        var d = parseFloat(this.get(dim + 'In') || '0');
      } else if (uom == 'centimeter') {
        var d = parseFloat(this.get(dim + 'Cm') || '0');
      }

      var disp = d > 0 ? d : '';
      return disp;
    }
  });
});
