import Amplify from 'amplify';
import * as LDClient from 'launchdarkly-js-client-sdk';
import jsCookie from 'js-cookie';

import { ApiUrl, AppID } from '../app/Config';

import AppDataService from 'client/services/app-data-service';
import { PusherService } from 'client/services/pusher/pusher-service';
import { buildDefaultPusherConfigs } from 'client/services/pusher/default-configs';
import UserService from 'client/services/user-service';
import ShipEngineKeyService from 'client/services/api/shipengine-key-service';
import defaultCookieConfig from 'public/defaultCookieConfig';
import V3AccessService from 'client/services/v3-access-service';
import CookieService from 'client/services/cookie-service';
import CanadaTermsAndConditionsService from 'client/services/canada-terms-conditions-service';
import {
  ACTIVE_SOCKET_KEY_FLAG_NAME,
  getPreferredPusherKeyFlag
} from 'client/services/launch-darkly-flag-service';
import { getPusherConfig } from 'client/services/pusher/pusher-api-client';

define([
  'require',
  'jquery',
  'Data',
  'AdminData',
  'core/PushEcho',
  'pubsub',
  'core/UserFunctions',
  'TrackEvents'
], function(
  require,
  $,
  Data,
  AdminData,
  PushEcho,
  pubsub,
  UserFunctions,
  TrackEvents
) {
  var exports = {
    loadCache: {},
    load: {},
    getData: {}
  };

  AppDataService.setFrontEndConfig(window.SS_GLOBALS.frontEndConfig);

  // mark timestamp/version of templates

  exports.getFrontEndConfig = function() {
    const config = window.SS_GLOBALS.frontEndConfig;
    if (!config) {
      console.warn('missing template cache');
      return {};
    }
    return config;
  };

  // loadCache callback
  var loadCache = function(requireAuthCookie = true) {
    var deferredCall = new $.Deferred();

    var impersonating = jsCookie.get('x-imp');
    if (!impersonating) {
      var since = Amplify.store(AppID + '_cache_timestamp');
    }

    var hasAuthCookie = !!(
      jsCookie.get('auth') && jsCookie.get('auth') !== 'undefined'
    );

    if (!hasAuthCookie && requireAuthCookie) {
      deferredCall.resolve();
    } else {
      // request for data
      var Session = require('Session');
      const dataCallInputs = {
        dfd: deferredCall,
        since,
        Session
      };

      if (exports.getFrontEndConfig().AppMode == 'labelapi') {
        ShipEngineDataCall(dataCallInputs);
        App.productName = 'ShipEngine';
      } else {
        ShipStationDataCall(dataCallInputs);
        App.productName = 'ShipStation';
      }
    }

    return deferredCall.promise();
  };

  // Launch Darkly instantiation:
  const launchDarklyUser = user => {
    const partition = window.location.hostname.split('.')[0];
    const {
      userId,
      email = '',
      name = '',
      plan = '',
      proviso,
      seller,
      homeCountry: country = '',
      userName = ''
    } = user;
    return {
      key: userId,
      email,
      name,
      country,
      custom: {
        userId,
        plan,
        proviso,
        seller,
        userName,
        partition,
        isSystemAdmin: UserService.isSysAdmin()
      }
    };
  };

  const launchDarklyUserBySellerId = user => {
    const partition = window.location.hostname.split('.')[0];
    const {
      userId,
      email = '',
      name = '',
      plan = '',
      proviso,
      seller,
      homeCountry: country = '',
      userName = ''
    } = user;
    return {
      key: seller,
      email,
      name,
      country,
      custom: {
        userId,
        plan,
        proviso,
        seller,
        userName,
        partition,
        isSystemAdmin: UserService.isSysAdmin()
      }
    };
  };

  const initiateLaunchDarkly = async (
    clientSideId = '',
    launchDarklyUser = {}
  ) => {
    window.ldClient = LDClient.initialize(clientSideId, launchDarklyUser);

    await window.ldClient.waitUntilReady();

    window.LDFeatureFlags = window.ldClient.allFlags();

    if (!window.LDFeatureFlags) {
      console.log('Launch Darkly failed to fetch Feature Flags');
    }

    // Can allow for an open stream to watch active for FF changes
    /*
      window.ldClient.on('change', function(settings) {
        console.log('flags changed:', settings);
      });
    */
  };

  const initiateLaunchDarklyBySellerId = async (
    clientSideId = '',
    launchDarklyUser = {}
  ) => {
    window.ldClientBySellerId = LDClient.initialize(
      clientSideId,
      launchDarklyUser
    );

    await window.ldClientBySellerId.waitUntilReady();

    window.LDFeatureFlagsBySellerId = window.ldClientBySellerId.allFlags();

    if (!window.LDFeatureFlagsBySellerId) {
      console.log('Launch Darkly by SellerId failed to fetch Feature Flags');
    }
  };

  // The promise returned will always resolve and never reject. If LD fails to initialize, default flag values will be used instead
  const initializeLaunchDarkly = async (launchDarklyClientSideId, user) => {
    await initiateLaunchDarkly(
      launchDarklyClientSideId,
      launchDarklyUser(user)
    );

    // duplicate LD initialization to support a specific feature flag
    await initiateLaunchDarklyBySellerId(
      launchDarklyClientSideId,
      launchDarklyUserBySellerId(user)
    );

    console.log('ff clients initialized');
  };

  const initializePusherService = async (userId, initProps) => {
    await PusherService.initialize(initProps);

    await PushEcho.start(userId);

    window.ldClient.on(
      `change:${ACTIVE_SOCKET_KEY_FLAG_NAME}`,
      newFlagValue => {
        PusherService.setActiveSocketKey(newFlagValue);
      }
    );
  };

  const initializePusher = async userId => {
    try {
      const [preferredPusherKey, pusherConfig] = await Promise.all([
        // LaunchDarkly provides us with the value we use to initialize Pusher
        getPreferredPusherKeyFlag(),
        // Provides the configs used to initialize Pusher
        getPusherConfig()
      ]);

      const pusherInstanceConfigs = pusherConfig.PusherApps.map(
        ({ Key, Cluster }) => ({ key: Key, cluster: Cluster })
      );

      await initializePusherService(userId, {
        preferredPusherKey,
        pusherInstanceConfigs
      });
    } catch (e) {
      const defaultPusherConfigs = buildDefaultPusherConfigs();

      await initializePusherService(userId, {
        preferredPusherKey: defaultPusherConfigs[0].key,
        pusherInstanceConfigs: defaultPusherConfigs
      });
    }
  };

  const ShipEngineDataCall = ({ dfd, since, Session }) => {
    $.ajax({
      method: 'GET',
      url: ApiUrl + 'shipengine/dashboard_initialize',
      data: {
        since
      },
      success: function(data) {
        if (data && 'success' in data && !data.success) {
          console.error(
            '*** Failed to load lookup data! ***\n\n' + data.message
          );
          return Session.logout();
        }
        /* lookups:
            AuthInfo
            AllMarketplaces
            Announcement
            AutoProvisionedOnlyProviders
            FeatureMask
            Providers --> SE_AllCarriers
            ActivePartnerIntegrations
            SellerSettings
        */
        _.each(data, function(v, k) {
          if (k == 'AuthInfo') {
            Amplify.store(k, v);
            Amplify.store(AppID + '_cache_SE_' + k, v);
          } else if (
            ['AutoProvisionedOnlyProviders', 'FeatureMask'].indexOf(k) >= 0
          ) {
            Amplify.store(AppID + '_cache_' + k, v);
          } else if (k == 'Providers') {
            Amplify.store(AppID + '_cache_SE_AllCarriers', v);
          } else if (k === 'SellerSettings') {
            Amplify.store(AppID + '_cache_Settings', v);
            Amplify.store(AppID + '_cache_SE_' + k, v);
          } else {
            Amplify.store(AppID + '_cache_SE_' + k, v);
          }
        });

        /* Clear specific cached values on refresh */
        // clear carriers/marketplaces on refresh due to OAuth redirects not allowing cache-busting easily, forces GET
        Amplify.store(AppID + '_cache_SE_CurrentMarketplaces', null);
        Amplify.store(AppID + '_cache_SE_CurrentCarriers', null);
        Amplify.store(AppID + '_cache_SE_MetricsSettings', null);

        // Post-Auth
        if (!Amplify.store('AuthInfo')) {
          jsCookie.set('auth', null);
        } else {
          let config = exports.getFrontEndConfig();
          let user = Amplify.store('AuthInfo');

          const { launchDarklyClientSideId } = config;

          initializeLaunchDarkly(launchDarklyClientSideId, user);

          initializePusher(user.userId);

          App.user = $.extend(user, UserFunctions);
          App.user.isLabelApi = config.AppMode == 'labelapi';
          App.user.labelApiEnvironment =
            window.location.href.indexOf('.shipengine') > -1
              ? 'prod'
              : 'sandbox';
          App.country = user.homeCountry;
          switch (App.country) {
            case 'GB':
              App.currencySymbol = '£';
              App.currencyClass = 'icon-gbp';
              break;
            default:
              App.currencySymbol = '$';
              App.currencyClass = 'icon-usd';
              break;
          }

          dfd.resolve();

          //Tracking and Announcements
          if (data && data.announcement) {
            App.announcement = data.announcement;
          }
          const userIsDoNotTrack =
            config.NoTrackList &&
            _.contains(config.NoTrackList, App.user.seller);
          const userIsNotPresent = !App.user;
          const userIsSysAdmin =
            !config.TrackSysAdmin && UserService.isSysAdmin();

          // init analytics here
          if (
            config.EnableSegment &&
            !(userIsDoNotTrack || userIsNotPresent || userIsSysAdmin)
          ) {
            TrackEvents.initialize({
              ssConfig: config
            });
          }

          // Cookies
          // This cookie simply gives us the ability to know that this is an active user (across all our domains for remarketing and other purposes)
          const sixtyDayCookieOptions = {
            expires: 60,
            path: '/',
            domain: window.location.cookieDomain(),
            ...defaultCookieConfig
          };
          const twoYearCookieOptions = {
            expires: 730,
            path: '/',
            domain: window.location.cookieDomain(),
            ...defaultCookieConfig
          };

          if (App.user.permit(2048)) {
            CookieService.removeSecureCookie('SSHome');
          } else if (
            window.SS_GLOBALS.frontEndConfig.SSHome &&
            !V3AccessService.isIframeMode()
          ) {
            //Only setup sshome the first time they sign in
            jsCookie.set(
              'SSVersion',
              window.SS_GLOBALS.frontEndConfig.SSHome,
              twoYearCookieOptions
            );
            jsCookie.set(
              'SSHome',
              window.SS_GLOBALS.frontEndConfig.SSHome,
              twoYearCookieOptions
            );

            //This cookie simply gives us the ability to know that this is an active user (across all our domains for remarketing and other purposes)
            jsCookie.set('.LL', 1, sixtyDayCookieOptions);
          }

          // PubSub and end
          pubsub.trigger('settings:cacheloaded');
          console.log('cache data loaded');

          ShipEngineKeyService.getSandboxKeys();
        }
      },
      error: function() {
        console.log('cache data ERROR');
        //stop lookping with bad token
        if (window.location.hash.indexOf('___a') != -1) {
          jsCookie.set('auth', null);
          window.location.hash = '';
        }
        dfd.resolve();
      },
      context: this
    });
  };

  const ShipStationDataCall = ({ dfd, since, Session, onlineWorkstations }) => {
    $.ajax({
      method: 'GET',
      url: ApiUrl + 'data/lookup',
      data: {
        since: since
      },
      success: function(data) {
        if (data && 'success' in data && !data.success) {
          console.error(
            '*** Failed to load lookup data! ***\n\n' + data.message
          );
          return Session.logout();
        }

        _.each(data.data, function(v, k) {
          if (k == 'AuthInfo') {
            Amplify.store('AuthInfo', v);
          } else {
            Amplify.store(AppID + '_cache_' + k, v);
          }
        });

        if (!Amplify.store('AuthInfo')) {
          jsCookie.set('auth', null);
        } else {
          if (data.data && data.data.announcement) {
            App.announcement = data.data.announcement;
          }
          App.country = Amplify.store('AuthInfo').homeCountry;
          switch (App.country) {
            case 'GB':
              App.currencySymbol = '£';
              App.currencyClass = 'icon-gbp';
              break;
            default:
              App.currencySymbol = '$';
              App.currencyClass = 'icon-usd';
              break;
          }
          var user = Amplify.store('AuthInfo');
          const { launchDarklyClientSideId } = exports.getFrontEndConfig();

          initializeLaunchDarkly(launchDarklyClientSideId, user);

          initializePusher(user.userId);

          // duplicate LD initialization to support a specific feature flag
          initiateLaunchDarklyBySellerId(
            launchDarklyClientSideId,
            launchDarklyUserBySellerId(user)
          );

          if (!V3AccessService.isIframeMode() && App.country === 'CA') {
            CanadaTermsAndConditionsService.showTermsIfRequired(
              user?.seller,
              user?.userId
            );
          }

          App.user = $.extend(user, UserFunctions);
          App.user.isOnFreePostalPlan = App.user.plan === 'free_postal';
          App.user.isOnFreeStarterPlan = App.user.plan === 'free_starter';
          App.user.isLabelApi =
            exports.getFrontEndConfig().AppMode == 'labelapi';
          App.user.labelApiEnvironment =
            window.location.href.indexOf('.shipengine') > -1
              ? 'prod'
              : 'sandbox';

          //when reloading cache it seems ok (in fact neccessary) to re-init Data and it's collections
          Data.init(exports.getData);
          Data.Workstations.add(onlineWorkstations, { merge: true });
        }
        Amplify.store(AppID + '_cache_timestamp', data.timestamp);

        //If user is admin then load admin info
        if (
          Amplify.store('AuthInfo') &&
          Amplify.store('AuthInfo').proviso &&
          Amplify.store('AuthInfo').proviso != 0 &&
          (Amplify.store('AuthInfo').proviso & 2048) != 0
        ) {
          $.get(ApiUrl + 'admin/lookup', {}, function(data) {
            _.each(data.data, function(v, k) {
              Amplify.store(AppID + '_cache_' + k, v);
            });
            AdminData.init(exports.getData);
            dfd.resolve();
          });
        } else {
          dfd.resolve();
        }
        // now that we have the data load it in Data for use in the app, passing a ref to the getData fn

        var config = exports.getFrontEndConfig();
        const twoYearCookieOptions = {
          expires: 730,
          path: '/',
          domain: window.location.cookieDomain(),
          ...defaultCookieConfig
        };
        const sixtyDayCookieOptions = {
          expires: 60,
          path: '/',
          domain: window.location.cookieDomain(),
          ...defaultCookieConfig
        };
        const userIsDoNotTrack =
          config.NoTrackList && _.contains(config.NoTrackList, App.user.seller);
        const userIsNotPresent = !App.user;
        const userIsSysAdmin =
          !config.TrackSysAdmin && UserService.isSysAdmin();

        // init analytics here
        if (
          config.EnableSegment &&
          !(userIsDoNotTrack || userIsNotPresent || userIsSysAdmin)
        ) {
          TrackEvents.initialize({
            ssConfig: config
          });
        }

        // If you are a system admin remove the SSHome Cookie.
        // Otherwise, if you visit V2, you're stuck in V2 unless
        // you opt out.
        if (App.user.permit(2048)) {
          CookieService.removeSecureCookie('SSHome');
        } else if (
          window.SS_GLOBALS.frontEndConfig.SSHome &&
          !V3AccessService.isIframeMode()
        ) {
          //Only setup sshome the first time they sign in
          jsCookie.set(
            'SSVersion',
            window.SS_GLOBALS.frontEndConfig.SSHome,
            twoYearCookieOptions
          );
          jsCookie.set(
            'SSHome',
            window.SS_GLOBALS.frontEndConfig.SSHome,
            twoYearCookieOptions
          );

          //This cookie simply gives us the ability to know that this is an active user (across all our domains for remarketing and other purposes)
          jsCookie.set('.LL', 1, sixtyDayCookieOptions);
        }

        const workstationPermCookieKey = `WS_${App.user.userName.toLowerCase()}`;
        const workstationPermCookieValue = jsCookie.get(
          workstationPermCookieKey
        );

        // Ideally, we check if removing the old, non-domain specific v2 workstation (WS) permission cookie was successful, rather than if any WS cookied existed
        // but the jquery-cookie::removeCookie API is returning true if a cookie with matching key is found, regardless of if it gets actually removed
        if (workstationPermCookieValue) {
          CookieService.removeSecureCookie(workstationPermCookieKey);
          jsCookie.set(workstationPermCookieKey, workstationPermCookieValue, {
            path: '/',
            expires: 365,
            raw: true,
            domain: '.shipstation.com',
            ...defaultCookieConfig
          });
        }

        pubsub.trigger('settings:cacheloaded');
        console.log('cache data loaded');
      },
      error: function(xhr) {
        console.log(`cache data ERROR (${xhr.status})`);
        if (Session) {
          Session.logout();
        }
        //stop lookping with bad token
        if (window.location.hash.indexOf('___a') != -1) {
          jsCookie.set('auth', null);
          window.location.hash = '';
        }
        dfd.resolve();
      },
      context: this
    });
  };

  exports.load = function(callback) {
    $.when(loadCache()).done(function() {
      console.log('resources loaded');
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  exports.loadCache = function(callback, requireAuthCookie) {
    return loadCache(requireAuthCookie).done(function() {
      console.log('cache templates loaded');
      AppDataService.onChange();
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  exports.getData = function(key) {
    var data = Amplify.store(AppID + '_cache_' + key);
    if (typeof data === 'undefined') {
      exports.logError(
        {},
        'Missing data for key: ' + key + ' in ResourceManager.'
      );
    }

    return data;
  };
  exports.logError = function(exception, msg) {
    if (window.console) {
      window.console.warn('exports.logError: ' + msg);
    }
  };
  exports.appendData = function(key, object) {
    // if passed a model the get its data
    if (typeof object.attributes !== 'undefined') {
      object = object.toJSON();
    }
    var data = Amplify.store(AppID + '_cache_' + key);
    if (typeof data === 'undefined') {
      exports.logError(
        {},
        'Missing data for key: ' + key + ' in ResourceManager.'
      );
    }

    // add to the existing collection
    data.push(object);

    // persist
    Amplify.store(AppID + '_cache_' + key, data);

    // update the Data store
    Data.appendToCollection(key, object);

    console.debug('data appended for ' + key);

    return data;
  };

  return exports;
});
