import { ApiUrl } from '../app/Config';
import Enums from 'public/core/Enums';

define(['Data', 'models/RollbackModel'], function(Data, RollbackModel) {
  return RollbackModel.extend({
    urlRoot: ApiUrl + 'Stores',
    idAttribute: 'StoreID',
    defaults: {
      userName: function() {
        return this.get('AccountName');
      },
      about_us: function(val) {
        return this.settings('about_us', val);
      },
      return_policy: function(val) {
        return this.settings('return_policy', val);
      }
    },
    getOdbcStatus: function() {
      if (this.get('Marketplace').MarketplaceID !== Enums.Marketplace.ODBC) {
        return '';
      }
      if (this.get('Param1') == null) {
        return 'Unconnected';
      }

      var workstation = Data.Workstations.findWhere({
        WorkstationID: this.get('Param1')
      });
      if (workstation && workstation.get('Online')) {
        return 'Online';
      }

      return 'Offline';
    },
    settings: function(name, value) {
      var settings = this.get('StoreSettings');
      if (!settings) {
        return null;
      }
      var existing = _.findWhere(settings, {
        Name: name
      });
      if (existing) {
        if (value !== undefined) {
          existing.Value = value;
          return value;
        }
        return existing.Value;
      }
      var newSetting = {
        Name: name,
        Value: value,
        StoreID: this.id
      };
      settings.push(newSetting);
      return value;
    },
    parse: function(resp) {
      if (typeof resp.BrandedTrackingPage != 'undefined') {
        resp.BrandedTrackingPage = resp.BrandedTrackingPage.toString();
      }
      return resp;
    },
    isShipEngineMarketplace: function() {
      if (
        this.get('Marketplace').MarketplaceID === Enums.Marketplace.LabelApi
      ) {
        return true;
      }
      if (
        this.get('Marketplace').MarketplaceID ===
        Enums.Marketplace.ShipEngineApi
      ) {
        return true;
      }
      return false;
    },
    getStoreGuid: function() {
      const myStoreGuid = this.get('StoreGuid');
      if (myStoreGuid) {
        return myStoreGuid;
      }
      const storeId = this.get('StoreID');
      const matchingStore = Data.Stores.toJSON().find(
        store => store.StoreID === storeId
      );
      if (!matchingStore) {
        return storeId;
      }
      return matchingStore.StoreGuid;
    }
  });
});
