define(['core/BaseModel'], function(BaseModel) {
  const PrinterModel = BaseModel.extend({
    resetToDefaults: function() {
      this.set({
        zplChunkSize: 5,
        zplPrintSpeed: 4,
        zplInvert: false,
        zplPrintSpeedRemoval: false
      });
    }
  });

  return PrinterModel;
});
