import AppDataService from 'client/services/app-data-service.js';

export const providerHasFeature = (providerId, optionEnum) => {
  let providerData;
  const allRegistryProvData = AppDataService.getShippingOptions();
  if (Array.isArray(allRegistryProvData) && allRegistryProvData.length) {
    providerData = allRegistryProvData.find(
      prov => prov && prov.ProviderId === providerId
    );
  }

  return (
    providerData &&
    providerData.ShippingOptions &&
    providerData.ShippingOptions.find(
      option => option && option.Type === optionEnum
    )
  );
};

export const registryProviderOptions = providerId => {
  let registryProvShippingOptions;
  const allRegistryProvData = AppDataService.getShippingOptions();
  if (Array.isArray(allRegistryProvData) && allRegistryProvData.length) {
    registryProvShippingOptions = allRegistryProvData.find(
      prov => prov && prov.ProviderId === providerId
    );
  }
  const registryProvOptionCodes = [];
  if (
    registryProvShippingOptions &&
    registryProvShippingOptions.ShippingOptions
  ) {
    for (
      let i = 0;
      i < registryProvShippingOptions.ShippingOptions.length;
      i++
    ) {
      const oso = registryProvShippingOptions.ShippingOptions[i];
      if (oso && oso.Type) {
        registryProvOptionCodes.push(oso.Type);
      }
    }
  }
  return registryProvOptionCodes || [];
};
