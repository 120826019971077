import localeService from 'client/i18n/locale-service';

const getTranslatedStringObject = (translations, values = {}) => {
  if (!translations) {
    return {};
  }
  return translations.reduce((sum, translation) => {
    return {
      ...sum,
      [translation.id]: localeService.getTranslatedString(translation, values)
    };
  }, {});
};

export default getTranslatedStringObject;
