import { ApiUrl } from '../app/Config';

define(['models/RollbackModel'], function(RollbackModel) {
  return RollbackModel.extend({
    idAttribute: 'OrderTagID',
    urlRoot: ApiUrl + 'tags',
    calcColors: function() {
      var color = this.get('Color');
      if (!color) return;
      this.colorSet = {};
      this.colorSet.border = Color(color)
        .lightness(35)
        .hexString();
      this.colorSet.text = Color(color)
        .lightness(25)
        .hexString();
      this.colorSet.bg = Color(color)
        .lightness(95)
        .hexString();
      this.colorSet.orig = color;
    },
    colors: function() {
      if (!this.colorSet) {
        this.calcColors();
      }
      return this.colorSet;
    }
  });
});
