export const PusherMessages = {
  ConnectRequestRefresh: 'connect:request:refresh',
  ConnectRequestRestart: 'connect:request:restart',
  ConnectRequestVersion: 'connect:request:version',
  ConnectRequestPrinterConfig: 'connect:request:printer:config',
  ConnectRequestPrinterJob: 'connect:request:printer:job',
  ConnectRequestScaleConfig: 'connect:request:scale:config',
  ConnectRequestScaleRead: 'connect:request:scale:read',
  ConnectRequestScreenCapture: 'connect:request:screencapture',
  V3StoreAuthRedirect: 'v3-store:auth:redirect'
};
