import { ApiUrl } from '../app/Config';

define(['backbone', 'models/SellerFillProvider'], function(
  Backbone,
  SellerFillProvider
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'FillProviders/SellerFillProviders',
    model: SellerFillProvider,
    getName: function(providerId) {
      if (!providerId) return '';
      var m = this.get(providerId);
      if (!m) return '???';
      return m.get('Name');
    },
    parse: function(resp) {
      return resp.shipping;
    }
  });
});
