import LogService from './log-service';
import NumberFormattingService from './number-formatting-service';
import UnitOfMeasureService from './unit-of-measure-service';

export default class UnitConversionService {
  static MAXIMUM_WEIGHT_DECIMAL_PLACES = 3;
  static OUNCES_PER_POUND = 16;
  static GRAMS_PER_OUNCE = 28.3495;
  static GRAMS_PER_KG = 1000;
  static CM_PER_INCHES = 2.54;

  static UNIT_OF_MEASURE_CONVERSION_MAP = {
    [UnitOfMeasureService.OUNCES.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.GRAMS.id]: UnitConversionService.GRAMS_PER_OUNCE,
        [UnitOfMeasureService.POUNDS.id]:
          1 / UnitConversionService.OUNCES_PER_POUND,
        [UnitOfMeasureService.KILOGRAMS.id]:
          UnitConversionService.GRAMS_PER_OUNCE /
          UnitConversionService.GRAMS_PER_KG
      }
    },
    [UnitOfMeasureService.GRAMS.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.OUNCES.id]:
          1 / UnitConversionService.GRAMS_PER_OUNCE,
        [UnitOfMeasureService.POUNDS.id]:
          1 /
          (UnitConversionService.GRAMS_PER_OUNCE *
            UnitConversionService.OUNCES_PER_POUND),
        [UnitOfMeasureService.KILOGRAMS.id]:
          1 / UnitConversionService.GRAMS_PER_KG
      }
    },
    [UnitOfMeasureService.INCHES.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.CENTIMETERS.id]:
          UnitConversionService.CM_PER_INCHES
      }
    },
    [UnitOfMeasureService.CENTIMETERS.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.INCHES.id]:
          1 / UnitConversionService.CM_PER_INCHES
      }
    },
    [UnitOfMeasureService.POUNDS.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.OUNCES.id]:
          UnitConversionService.OUNCES_PER_POUND,
        [UnitOfMeasureService.GRAMS.id]:
          UnitConversionService.OUNCES_PER_POUND *
          UnitConversionService.GRAMS_PER_OUNCE,
        [UnitOfMeasureService.KILOGRAMS.id]:
          (UnitConversionService.OUNCES_PER_POUND *
            UnitConversionService.GRAMS_PER_OUNCE) /
          UnitConversionService.GRAMS_PER_KG
      }
    },
    [UnitOfMeasureService.KILOGRAMS.id]: {
      conversionMultipliers: {
        [UnitOfMeasureService.OUNCES.id]:
          UnitConversionService.GRAMS_PER_KG /
          UnitConversionService.GRAMS_PER_OUNCE,
        [UnitOfMeasureService.GRAMS.id]: UnitConversionService.GRAMS_PER_KG,
        [UnitOfMeasureService.POUNDS.id]:
          UnitConversionService.GRAMS_PER_KG /
          UnitConversionService.GRAMS_PER_OUNCE /
          UnitConversionService.OUNCES_PER_POUND
      }
    }
  };

  static convertValue(value, currentUnitOfMeasureId, desiredUnitOfMeasureId) {
    const numberValue = NumberFormattingService.coerceOtherTypesToNumber(value);
    if (Number.isNaN(numberValue)) {
      LogService.log(
        `UnitConversionService: convertValue: Invalid value ${value}`
      );
      return Number.NaN;
    }

    if (currentUnitOfMeasureId === desiredUnitOfMeasureId) {
      return numberValue;
    }
    const unitOfMeasureConversion = this.UNIT_OF_MEASURE_CONVERSION_MAP[
      currentUnitOfMeasureId
    ];
    if (unitOfMeasureConversion === undefined) {
      LogService.log(
        `UnitConversionService: convertValue: Invalid unit of measure passed in ${currentUnitOfMeasureId}`
      );
      return Number.NaN;
    }
    const conversionMultiplier =
      unitOfMeasureConversion.conversionMultipliers[desiredUnitOfMeasureId];
    if (conversionMultiplier === undefined) {
      LogService.log(
        `UnitConversionService: convertValue: Cannot convert ${UnitOfMeasureService.getNameById(
          currentUnitOfMeasureId
        )} to ${UnitOfMeasureService.getNameById(desiredUnitOfMeasureId)}`
      );
      return Number.NaN;
    }
    return numberValue * conversionMultiplier;
  }

  static sumValues(listOfValuesAndUnitOfMeasures, desiredUnitOfMeasureId) {
    if (
      !listOfValuesAndUnitOfMeasures ||
      !Array.isArray(listOfValuesAndUnitOfMeasures)
    ) {
      LogService.log(
        `UnitConversionService: sumValues: Invalid list of values ${JSON.stringify(
          listOfValuesAndUnitOfMeasures
        )}`
      );
      return Number.NaN;
    }
    return listOfValuesAndUnitOfMeasures.reduce(
      (sum, valueAndUnitOfMeasure) => {
        if (typeof valueAndUnitOfMeasure !== 'object') {
          return Number.NaN;
        }
        return (
          sum +
          this.convertValue(
            valueAndUnitOfMeasure.value,
            valueAndUnitOfMeasure.unitOfMeasure,
            desiredUnitOfMeasureId
          )
        );
      },
      0
    );
  }

  static getWeightInPoundsAndOunces(value, unitOfMeasure) {
    const weightInPounds = this.convertValue(
      value,
      unitOfMeasure,
      UnitOfMeasureService.POUNDS.id
    );
    if (Number.isNaN(weightInPounds)) {
      return undefined;
    }
    const wholePounds = Math.floor(weightInPounds);
    const leftOverOunces = this.convertValue(
      weightInPounds - wholePounds,
      UnitOfMeasureService.POUNDS.id,
      UnitOfMeasureService.OUNCES.id
    );
    return {
      pounds: wholePounds,
      ounces: leftOverOunces
    };
  }

  static convertPoundsAndOuncesToUnitOfMeasure(poundsAndOunces, unitOfMeasure) {
    if (poundsAndOunces === undefined) {
      return Number.NaN;
    }
    const pounds = poundsAndOunces.pounds || 0;
    const ounces = poundsAndOunces.ounces || 0;
    return this.sumValues(
      [
        {
          value: pounds,
          unitOfMeasure: UnitOfMeasureService.POUNDS.id
        },
        {
          value: NumberFormattingService.roundToPlace(
            ounces,
            this.MAXIMUM_WEIGHT_DECIMAL_PLACES
          ),
          unitOfMeasure: UnitOfMeasureService.OUNCES.id
        }
      ],
      unitOfMeasure
    );
  }
}
