import { ApiUrl } from '../app/Config';

define(['backbone'], function(Backbone) {
  return Backbone.Model.extend({
    urlRoot: ApiUrl + 'PackingSlips',
    idAttribute: 'PackingSlipID',
    isDefault: function() {
      return !!this.get('Shared');
    }
  });
});
