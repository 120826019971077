import AppDataService from './app-data-service';
import ProfileService from './api/profile-service';
import UserAuthService from './user-auth-service';
import AmplifyService from 'amplify';

export default class UserService {
  static getUserData(safe) {
    const cacheData = AmplifyService.store('AuthInfo');
    if (!safe) {
      return cacheData;
    }
    return cacheData || {};
  }

  static getUserId() {
    return this.getUserData(true).userId;
  }

  static getSellerId() {
    const seller = this.getUserData(true).seller;
    return !seller ? '' : seller.toString();
  }

  static getUserSetting(settingName) {
    const userSettings = AppDataService.getUserSettings(true);
    return userSettings[settingName];
  }

  static setUserSetting(settingName, value) {
    const currentUserSettings = AppDataService.getUserSettings();
    currentUserSettings[settingName] = value;
    AppDataService.saveUserSettingsToCache(currentUserSettings);
    ProfileService.saveSetting(settingName, value);
  }

  static getShipEngineUserData() {
    const cacheData = AmplifyService.store('ss_cache_SE_AuthInfo');
    return cacheData || {};
  }

  /*
   Notes:
   8 seems to turn various things on and off in the app
   var permissions = {
   "stores" : App.user.permit(8),
   "filters" : App.user.permit(8),
   "automationrules" : App.user.permit(8),
   "warehouses" : App.user.permit(8),
   "packages" : App.user.permit(8),
   "services" : App.user.permit(8),
   "returns" : App.user.permit(8),
   "international" : App.user.permit(8),
   "emailtemplates" : App.user.permit(8),
   "packingslips" : App.user.permit(8) && App.user.hasFeature(2),
   "integrations" : App.user.permit(8),
   "providers": App.user.permit(8)
   }
   */
  static hasInventoryManagement() {
    return UserAuthService.permit(4096, this.getUserData());
  }

  static isSysAdmin() {
    return UserAuthService.permit(2048, this.getUserData());
  }

  static hasShipping() {
    return UserAuthService.permit(1024, this.getUserData());
  }

  static hasReportingAccess() {
    return UserAuthService.permit(512, this.getUserData());
  }

  static hasPurchasingRole() {
    return UserAuthService.permit(256, this.getUserData());
  }

  static hasProductManagement() {
    return UserAuthService.permit(128, this.getUserData());
  }

  static hasOrderManagement() {
    return UserAuthService.permit(64, this.getUserData());
  }

  static hasCustomerManagement() {
    return UserAuthService.permit(16, this.getUserData());
  }

  static hasAccessToConfiguration() {
    return UserAuthService.permit(8, this.getUserData());
  }

  static hasAccessToAdministration() {
    return UserAuthService.permit(2, this.getUserData());
  }

  static hasAccessToAccountManagement() {
    return UserAuthService.permit(1, this.getUserData());
  }

  /*
   Note I've seen these features but I don't know what they are:
   9
   65536 -- something to do with algolia (now ES)
   1024
   2 -- might be something about packing slips
   131072
   */

  static hasLabelApi() {
    return UserAuthService.hasFeature(524288);
  }

  static hasMultiCarrierAccountsFeature() {
    return UserAuthService.hasFeature(262144);
  }

  static hasInventoryFeature() {
    return UserAuthService.hasFeature(4096);
  }

  static isV2Only() {
    return UserAuthService.hasFeature(1024);
  }

  static hasV2() {
    return UserAuthService.hasFeature(512);
  }

  static hasEnhancedSecurity() {
    return UserAuthService.hasFeature(32);
  }

  static hasLiveChat() {
    return UserAuthService.hasFeature(8);
  }

  static hasCustomPackingSlips() {
    return UserAuthService.hasFeature(2);
  }

  static hasAutoBatching() {
    return UserAuthService.hasFeature(131072);
  }

  static hasAdvancedHotkeysTurnedOn() {
    return this.getUserSetting('AdvancedHotKeys') !== 'false';
  }

  static hasAcknowledgeHotKeysTurnedOn() {
    return this.getUserSetting('AcknowledgeHotKeys') !== 'false';
  }
}
