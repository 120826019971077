import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '430ff666-98b7-4018-9fff-68b9f81f8c4a',
    defaultMessage: 'Ok'
  })
];

export default translations;
