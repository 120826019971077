import { ApiUrl } from '../app/Config';

define(['backbone', 'models/ShippingPreset'], function(
  Backbone,
  ShippingPresetModel
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Presets',
    model: ShippingPresetModel
  });
});
