import MarketplaceDisplayInformationCache from './marketplace-display-information-cache';
import ProviderDisplayInformationCache from './provider-display-information-cache';
import FulfillmentProviderDisplayInformationCache from './fulfillment-provider-display-information-cache';

const getDisplayInformation = ({
  carrierId,
  providerId,
  marketplaceId,
  fillProviderId
}) => {
  if (marketplaceId !== undefined) {
    return MarketplaceDisplayInformationCache.getCache().find(
      m => m.marketplaceId === marketplaceId
    );
  }
  if (fillProviderId !== undefined) {
    return FulfillmentProviderDisplayInformationCache.getCache().find(
      fp => fillProviderId && fp.fillProviderId === fillProviderId
    );
  }
  if (providerId !== undefined) {
    return ProviderDisplayInformationCache.getCache().find(
      d => d.providerId === providerId
    );
  }
  if (carrierId !== undefined) {
    return ProviderDisplayInformationCache.getCache().find(
      d => d.carrierId === carrierId
    );
  }
};

export default getDisplayInformation;
