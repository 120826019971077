/*
// in ResourceManager
window.ldClient.on('ready', function() {
  window.LDFeatureFlags = window.ldClient.allFlags();
*/

/*
RTFM moment -- use await window.ldClient.waitUntilReady() to async wait until flags are returned
*/
// This file is similar to the factory used in the C# SDK. We can request a specific flag by
// fn import with a default set in one location.

export const ACTIVE_SOCKET_KEY_FLAG_NAME = 'active-socket-key';

// TODO: update this name once Pusher is abstracted out of the client
export const getPreferredPusherKeyFlag = async () => {
  const { ldClient } = window;
  await ldClient?.waitUntilReady();

  return ldClient?.variation(
    ACTIVE_SOCKET_KEY_FLAG_NAME,
    window.SS_GLOBALS.frontEndConfig.pusherKey
  );
};

export const getEnableDeliveryOptionsForSellerLocation = () => {
  const { ldClient } = window;
  return (
    ldClient &&
    ldClient.variation('enable-delivery-options-for-seller-location', false)
  );
};

export const getEnableVolusionDeliveryOptionFlag = () => {
  const { ldClient } = window;
  return (
    ldClient && ldClient.variation('enable-volusion-delivery-options', false)
  );
};

export const getEnableManualStoreDeliveryOptionFlag = () => {
  const { ldClient } = window;
  return (
    ldClient &&
    ldClient.variation('enable-manual-store-delivery-options', false)
  );
};

export const getEnableMagentoDeliveryOptionFlag = () => {
  const { ldClient } = window;
  return (
    ldClient && ldClient.variation('enable-magento-delivery-options', false)
  );
};

export const getEnableWixDeliveryOptionFlag = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-wix-delivery-options', false);
};

export const getEnableBrandedTrackingV2Flag = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-branded-tracking-v2', false);
};

export const getEnableBrandedTrackingLegacyLayoutFlag = () => {
  const { ldClient } = window;
  return (
    ldClient &&
    ldClient.variation('enable-branded-tracking-legacy-layout', false)
  );
};

export const getEnable3PLShipRelay = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-3pl-ship-relay', false);
};

export const getEnable3PLEvobox = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-3pl-evobox', false);
};

export const getEnable3PLShippingTree = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-3pl-shipping-tree', false);
};

export const getEnable3PLShipBob = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-3pl-ship-bob', false);
};

export const getEnable3PLAmplifier = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-3pl-amplifier', false);
};

export const getShowNewCarrierPage = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('show-new-carrier-page', false);
};

export const getEnableCreatingWalletedDHLExpressViaStampsAPI = () => {
  const { ldClient } = window;
  return (
    ldClient &&
    ldClient.variation(
      'enable-ui-for-creating-walleted-dhl-express-accounts-via-stamps-api',
      false
    )
  );
};

export const getEnableOneBalance = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-one-balance', false);
};

export const getEnabledEbayV2 = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-ebay-v2', false);
};

export const getEnabledTermsAndConditions = () =>
  window.ldClient?.waitUntilReady().then(() => {
    const flag = window.ldClient?.variation(
      'new-country-terms-and-conditions',
      {
        enabled: false,
        mustAcceptByDateUTC: '2021-04-26T00:00:00.000Z'
      }
    );
    return flag;
  });

export const getEnableInitialConnectRefresh = async cb => {
  await window.ldClient?.waitUntilReady();
  const flag = window.ldClient.variation(
    'enable-initial-connect-refresh',
    true
  );

  cb(flag);
};

export const getV3InsightsSidebarEnabled = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-v3-insights', false);
};

export const getV3DDPEnabled = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-v3-ddp', false);
};

export const getAmazonUseOrderSourceApi = () => {
  const ldClient = window.ldClientBySellerId;
  return ldClient && ldClient.variation('amazon-use-order-source-api', false);
};

export const getWishEnabled = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-new-wish', false);
};

export const getGenericUseOrderSourceApi = marketplaceName => {
  const genericSuffix = '-use-order-source-api';

  const { ldClient } = window;
  return (
    ldClient && ldClient.variation(`${marketplaceName}${genericSuffix}`, false)
  );
};

export const getEnableAsyncClientManager = () => {
  return window?.ldClient?.variation('enable-async-client-manager', false);
};

export const getSuncoAuthEnabled = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-sunco-auth', false);
};

export const getChatAccessToAllUsers = () => {
  const { ldClient } = window;
  return (
    ldClient && ldClient.variation('enable-chat-access-to-all-users', false)
  );
};

export const getEnableSenderEmails = () => {
  return window.ldClient?.variation('enable-sender-emails', false);
};

export const getEnableNewZenDeskSsoLogin = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-new-zd-sso-login', false);
};

export const getEnableAuth0PasswordControl = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-auth0-password-control', false);
};
