define(['core/BaseModel', 'Data'], function(BaseModel, Data) {
  var FIELDS = ['Weight', 'WeightOz', 'Length', 'Width', 'Height'];

  //1.00in == 2.54cm
  var cmToInRatio = 2.54;
  return BaseModel.extend({
    initialize: function() {
      this._super('initialize', arguments);
    },
    defaults: {
      LengthIn: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3) return this.round(this.get('Length')) || '';
            //inches
            else return this.round(this.get('Length') / cmToInRatio) || ''; //convert to inches
          } else {
            return this.round(this.get('Length')) || ''; // inch
          }
        } else {
          var obj = {
            LengthIn: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Length: obj.Length,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            obj.Length + 'x' + this.get('Width') + 'x' + this.get('Height');
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      WidthIn: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3) return this.round(this.get('Width')) || '';
            //inches
            else return this.round(this.get('Width') / cmToInRatio) || ''; //convert to inches
          } else {
            return this.round(this.get('Width')) || ''; // inch
          }
        } else {
          var obj = {
            WidthIn: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Width: obj.Width,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            this.get('Length') + 'x' + obj.Width + 'x' + this.get('Height');
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      HeightIn: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3) return this.round(this.get('Height')) || '';
            //inches
            else return this.round(this.get('Height') / cmToInRatio) || ''; //convert to inches
          } else {
            return this.round(this.get('Height')) || ''; // inch
          }
        } else {
          var obj = {
            HeightIn: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Height: obj.Height,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            this.get('Length') + 'x' + this.get('Width') + 'x' + obj.Height;
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      LengthCm: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3)
              return this.round(this.get('Length') * cmToInRatio) || '';
            //convert to cm
            else return this.round(this.get('Length')) || ''; //cm
          } else {
            return this.round(this.get('Length') * cmToInRatio) || ''; // convert to cm
          }
        } else {
          var obj = {
            LengthCm: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Length: obj.Length,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            obj.Length + 'x' + this.get('Width') + 'x' + this.get('Height');
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      WidthCm: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3)
              return this.round(this.get('Width') * cmToInRatio) || '';
            //convert to cm
            else return this.round(this.get('Width')) || ''; //cm
          } else {
            return this.round(this.get('Width') * cmToInRatio) || ''; // convert to cm
          }
        } else {
          var obj = {
            WidthCm: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Width: obj.Width,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            this.get('Length') + 'x' + obj.Width + 'x' + this.get('Height');
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      HeightCm: function(val, options) {
        var uom = this.get('DimensionUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 3)
              return this.round(this.get('Height') * cmToInRatio) || '';
            //convert to cm
            else return this.round(this.get('Height')) || ''; // cm
          } else {
            return this.round(this.get('Height') * cmToInRatio) || ''; //convert to cm
          }
        } else {
          var obj = {
            HeightCm: val,
            DimensionUnitOfMeasureID: this.get('DimensionUnitOfMeasureID')
          };
          this.setDimensions(obj);
          this.set(
            {
              Height: obj.Height,
              DimensionUnitOfMeasureID: obj.DimensionUnitOfMeasureID
            },
            options
          );

          var dim =
            this.get('Length') + 'x' + this.get('Width') + 'x' + obj.Height;
          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: dim,
                IntParameter: obj.DimensionUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      WeightGr: function(val, options) {
        var uom = this.get('WeightUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 1) return this.round(this.get('Weight') * 28.3495);
            //convert oz to grams
            else return this.round(this.get('Weight')); //grams
          } else {
            return this.round(
              (this.get('TotalOz') ||
                this.get('WeightOz') ||
                this.get('Weight')) * 28.3495
            ); //use the legacy oz field (differs across models that use this)
          }
        } else {
          var obj = {
            WeightGr: val,
            WeightUnitOfMeasureID: this.get('WeightUnitOfMeasureID')
          };
          this.setWeight(obj);
          this.set(
            {
              Weight: obj.Weight,
              WeightUnitOfMeasureID: obj.WeightUnitOfMeasureID
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                IntParameter: obj.Weight,
                Parameter: obj.WeightUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      WeightKg: function(val, options) {
        var uom = this.get('WeightUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 1)
              return this.round((this.get('Weight') * 28.3495) / 1000);
            //convert oz to kg
            else return this.round(this.get('Weight') / 1000); //convert grams to kg
          } else {
            return this.round(
              ((this.get('TotalOz') ||
                this.get('WeightOz') ||
                this.get('Weight')) *
                28.3495) /
                1000
            ); //use the legacy oz field (differs across models that use this)
          }
        } else {
          var obj = {
            WeightKg: val,
            WeightUnitOfMeasureID: this.get('WeightUnitOfMeasureID')
          };
          this.setWeight(obj);
          this.set(
            {
              Weight: obj.Weight,
              WeightUnitOfMeasureID: obj.WeightUnitOfMeasureID
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                IntParameter: obj.Weight,
                Parameter: obj.WeightUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      //WeightPounds is just pounds
      WeightPounds: function(val, options) {
        var uom = this.get('WeightUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 1) return this.round(this.get('Weight') / 16);
            //convert oz to lb
            else return this.round((this.get('Weight') * 0.035274) / 16); //convert gr to lb
          } else {
            return this.round(
              (this.get('TotalOz') ||
                this.get('WeightOz') ||
                this.get('Weight')) / 16
            ); //use the legacy oz field (differs across models that use this)
          }
        } else {
          var obj = {
            WeightPounds: val,
            WeightUnitOfMeasureID: this.get('WeightUnitOfMeasureID')
          };
          this.setWeight(obj);
          this.set(
            {
              Weight: obj.Weight,
              WeightUnitOfMeasureID: obj.WeightUnitOfMeasureID
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                IntParameter: obj.Weight,
                Parameter: obj.WeightUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      //WeightPound pairs with WeightOunce
      WeightPound: function(val, options) {
        var uom = this.get('WeightUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 1) return Math.floor(this.get('Weight') / 16);
            //convert oz to lb
            else return Math.floor((this.get('Weight') * 0.035274) / 16); //convert gr to lb
          } else {
            return Math.floor(
              (this.get('TotalOz') ||
                this.get('WeightOz') ||
                this.get('Weight')) / 16
            ); //use the legacy oz field (differs across models that use this)
          }
        } else {
          var obj = {
            WeightPound: val,
            WeightOunce: this.get('WeightOunce') || 0,
            WeightUnitOfMeasureID: this.get('WeightUnitOfMeasureID')
          };
          this.setWeight(obj);
          this.set(
            {
              Weight: obj.Weight,
              WeightUnitOfMeasureID: obj.WeightUnitOfMeasureID
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                IntParameter: obj.Weight,
                Parameter: obj.WeightUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      WeightOunce: function(val, options) {
        var uom = this.get('WeightUnitOfMeasureID');
        if (!options) {
          if (uom) {
            if (uom == 1)
              return this.round(this.round(this.get('Weight')) % 16);
            //oz
            else
              return this.round(this.round(this.get('Weight') * 0.035274) % 16); //convert gr to oz
          } else {
            return this.round(
              (this.get('TotalOz') ||
                this.get('WeightOz') ||
                this.get('Weight')) % 16
            ); //use the legacy oz field (differs across models that use this)
          }
        } else {
          var obj = {
            WeightPound: this.get('WeightPound'),
            WeightOunce: val,
            WeightUnitOfMeasureID: this.get('WeightUnitOfMeasureID')
          };
          this.setWeight(obj);
          this.set(
            {
              Weight: obj.Weight,
              WeightUnitOfMeasureID: obj.WeightUnitOfMeasureID
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                IntParameter: obj.Weight,
                Parameter: obj.WeightUnitOfMeasureID
              },
              options
            );
          }
        }
      },
      DryIceWeightGr: function(val, options) {
        if (!options) {
          var oz =
            parseFloat(this.get('DryIceWeightOz') || 0) +
            parseFloat(this.get('DryIceWeightLbs') || 0) * 16;
          return this.round(oz * 28.3495); //convert oz to grams
        } else {
          var obj = {
            DryIceWeightGr: val
          };
          this.setWeightDryIce(obj);
          this.set(
            {
              DryIceWeightOz: obj.DryIceWeightOz
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: obj.Parameter
              },
              options
            );
          }
        }
      },
      DryIceWeightKg: function(val, options) {
        if (!options) {
          var oz =
            parseFloat(this.get('DryIceWeightOz') || 0) +
            parseFloat(this.get('DryIceWeightLbs') || 0) * 16;
          return this.round((oz * 28.3495) / 1000); //convert oz to kg
        } else {
          var obj = {
            DryIceWeightKg: val
          };
          this.setWeightDryIce(obj);
          this.set(
            {
              DryIceWeightOz: obj.DryIceWeightOz
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: obj.Parameter
              },
              options
            );
          }
        }
      },
      //DryIceWeightPounds is just pounds
      DryIceWeightPounds: function(val, options) {
        if (!options) {
          var oz =
            parseFloat(this.get('DryIceWeightOz') || 0) +
            parseFloat(this.get('DryIceWeightLbs') || 0) * 16;
          return this.round(oz / 16); //convert oz to lb
        } else {
          var obj = {
            DryIceWeightPounds: val
          };
          this.setWeightDryIce(obj);
          this.set(
            {
              DryIceWeightOz: obj.DryIceWeightOz
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: obj.Parameter
              },
              options
            );
          }
        }
      },
      //DryIceWeightPound pairs with DryIceWeightPound
      DryIceWeightPound: function(val, options) {
        if (!options) {
          var oz = parseFloat(this.get('DryIceWeightOz') || 0);
          return Math.floor(this.round(oz / 16)); //convert oz to lb
        } else {
          var obj = {
            DryIceWeightPound: val,
            DryIceWeightOunce: this.get('DryIceWeightOunce')
          };
          this.setWeightDryIce(obj);
          this.set(
            {
              DryIceWeightOz: obj.DryIceWeightOz
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: obj.Parameter
              },
              options
            );
          }
        }
      },
      DryIceWeightOunce: function(val, options) {
        if (!options) {
          var oz = parseFloat(this.get('DryIceWeightOz') || 0);
          return this.round(oz % 16); //convert oz to lb
        } else {
          var obj = {
            DryIceWeightOunce: val,
            DryIceWeightPound: this.get('DryIceWeightPound')
          };
          this.setWeightDryIce(obj);
          this.set(
            {
              DryIceWeightOz: obj.DryIceWeightOz
            },
            options
          );

          if (this.get('ActionTypeID')) {
            this.set(
              {
                Parameter: obj.Parameter
              },
              options
            );
          }
        }
      },
      DisplayWeight: function() {
        var uom = (
          Data.Settings['UnitOfMeasure:Weight'] || 'ounce'
        ).toLowerCase();

        if (uom == 'ounce') {
          var lb = this.get('WeightPound' || '')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          var oz = this.get('WeightOunce' || '')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

          var display = lb ? lb + ' lbs ' : '';
          display += oz ? oz + ' oz' : '';
          return display == '0 lbs 0 oz' ? '' : display;
        } else if (uom == 'pound') {
          var w = this.get('WeightPounds' || '')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return w == '0' || w == '' ? '' : w + ' lbs';
        } else if (uom == 'gram') {
          var w = this.get('WeightGr' || '')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return w == '0' || w == '' ? '' : w + ' gr';
        } else if (uom == 'kilogram') {
          var w = this.get('WeightKg' || '')
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return w == '0' || w == '' ? '' : w + ' kg';
        }
      },
      DisplayDimensions: function() {
        var uom = (
          Data.Settings['UnitOfMeasure:Dimension'] || 'inch'
        ).toLowerCase();

        if (uom == 'inch') {
          var l = parseFloat(this.get('LengthIn') || '0');
          var w = parseFloat(this.get('WidthIn') || '0');
          var h = parseFloat(this.get('HeightIn') || '0');

          var disp =
            l > 0 || w > 0 || h > 0 ? l + ' x ' + w + ' x ' + h + ' in' : '';

          return disp;
        } else if (uom == 'centimeter') {
          var l = parseFloat(this.get('LengthCm') || '0');
          var w = parseFloat(this.get('WidthCm') || '0');
          var h = parseFloat(this.get('HeightCm') || '0');

          var disp =
            l > 0 || w > 0 || h > 0 ? l + ' x ' + w + ' x ' + h + ' cm' : '';

          return disp;
        }
      },
      DimUom: function() {
        return Data.Settings['UnitOfMeasure:Dimension'].toLowerCase();
      }
    },
    toJSON: function() {
      var obj = this._super('toJSON', arguments);
      return obj;
    },
    setWeight: function(obj) {
      if (obj.WeightGr) {
        obj.Weight = parseFloat(obj.WeightGr || 0);
        obj.WeightUnitOfMeasureID = 2;
      } else if (obj.WeightKg) {
        obj.Weight = parseFloat(obj.WeightKg || 0) * 1000;
        obj.WeightUnitOfMeasureID = 2;
      } else if (obj.WeightPounds) {
        var lbs = parseFloat(obj.WeightPounds || 0);
        obj.Weight = lbs * 16;
        obj.WeightUnitOfMeasureID = 1;
      } else if (obj.WeightPound || obj.WeightOunce) {
        var oz = parseFloat(
          obj.WeightOunce || this.round(obj.Weight % 16) || 0
        );
        var lb = parseInt(obj.WeightPound || Math.floor(obj.Weight / 16) || 0);
        var totalOz = lb * 16 + oz;
        obj.Weight = totalOz;
        obj.WeightUnitOfMeasureID = 1;
      }

      //handle case when input is cleared
      if (obj.WeightGr == '' || obj.WeightKg == '' || obj.WeightPounds == '') {
        obj.Weight = 0;
        obj.WeightUnitOfMeasureID = 2;
        return;
      }

      if (obj.WeightPound == '' || obj.WeightOunce == '') {
        var oz = 0;
        var lb = 0;

        if (obj.WeightPound != '')
          lb = parseInt(obj.WeightPound || obj.WeightLbs || 0);

        if (obj.WeightOunce != '')
          oz = parseFloat(obj.WeightOunce || obj.WeightOz || 0);

        var totalOz = lb * 16 + oz;
        if (totalOz > 0) {
          obj.Weight = totalOz;
          obj.WeightUnitOfMeasureID = 1;
          //for rules
          obj.IntParameter = totalOz;
          obj.Parameter = 1;
        } else {
          obj.Weight = 0;
          obj.WeightUnitOfMeasureID = 1;
        }
      }
    },
    setDimensions: function(obj) {
      if (obj.LengthIn) {
        obj.Length = parseFloat(obj.LengthIn || 0);
        obj.DimensionUnitOfMeasureID = 3;
      } else if (obj.LengthIn == '') {
        obj.Length = null;
        obj.DimensionUnitOfMeasureID = 3;
      }

      if (obj.WidthIn) {
        obj.Width = parseFloat(obj.WidthIn || 0);
        obj.DimensionUnitOfMeasureID = 3;
      } else if (obj.WidthIn == '') {
        obj.Width = null;
        obj.DimensionUnitOfMeasureID = 3;
      }

      if (obj.HeightIn) {
        obj.Height = parseFloat(obj.HeightIn || 0);
        obj.DimensionUnitOfMeasureID = 3;
      } else if (obj.HeightIn == '') {
        obj.Height = null;
        obj.DimensionUnitOfMeasureID = 3;
      }

      if (obj.LengthCm) {
        obj.Length = parseFloat(obj.LengthCm || 0);
        obj.DimensionUnitOfMeasureID = 4;
      } else if (obj.LengthCm == '') {
        obj.Length = null;
        obj.DimensionUnitOfMeasureID = 4;
      }

      if (obj.WidthCm) {
        obj.Width = parseFloat(obj.WidthCm || 0);
        obj.DimensionUnitOfMeasureID = 4;
      } else if (obj.WidthCm == '') {
        obj.Width = null;
        obj.DimensionUnitOfMeasureID = 4;
      }

      if (obj.HeightCm) {
        obj.Height = parseFloat(obj.HeightCm || 0);
        obj.DimensionUnitOfMeasureID = 4;
      } else if (obj.HeightCm == '') {
        obj.Height = null;
        obj.DimensionUnitOfMeasureID = 4;
      }
    },
    set: function(key, value, options) {
      var fields = this.getSetFields(key, value, options);
      if (!fields) {
        return this._super('set', arguments);
      }
      var me = this;
      var setValues = _.omit(fields.values, function(v, k) {
        return (
          _.isFunction(me.defaults[k]) &&
          me.defaults[k].length > 0 &&
          !_.isFunction(v)
        );
      });

      var callValues = _.omit(fields.values, function(v, k) {
        return !_.isFunction(me.defaults[k]);
      });

      _.each(callValues, function(v, k) {
        if (_.isFunction(v)) {
          return;
        }
        me.defaults[k].call(me, v, fields.options || {});
      });

      return this._super('set', [setValues, fields.options]);
    },
    setWeightDryIce: function(obj) {
      if (obj.DryIceWeightGr) {
        obj.DryIceWeightOz = parseFloat(obj.DryIceWeightGr || 0) * 0.035274; //convert gr to oz
      } else if (obj.DryIceWeightKg) {
        obj.DryIceWeightOz =
          parseFloat(obj.DryIceWeightKg || 0) * 0.035274 * 1000; //convert kg to oz
      } else if (obj.DryIceWeightPounds) {
        obj.DryIceWeightOz = parseFloat(obj.DryIceWeightPounds || 0) * 16; //convert lbs to oz
        obj.DryIceWeightOz = parseFloat(obj.DryIceWeightPounds || 0) * 16; //convert lbs to oz
      } else if (obj.DryIceWeightPound || obj.DryIceWeightOunce) {
        var oz =
          parseFloat(
            obj.DryIceWeightPound || Math.floor(obj.DryIceWeightOz / 16) || 0
          ) * 16;
        oz += parseFloat(
          obj.DryIceWeightOunce || this.round(obj.DryIceWeightOz % 16) || 0
        );
        obj.DryIceWeightLbs = 0;
        obj.DryIceWeightOz = oz;
      }
    },
    changedAttributes: function() {
      var me = this;
      var ret = this._super('changedAttributes', arguments);

      var changed = Object.keys(ret).some(k => FIELDS.indexOf(k) > -1);
      if (changed) {
        ret = _.chain(this.defaults)
          .keys()
          .reduce(function(memo, k) {
            var idx =
              k.indexOf('Weight') === 0 ||
              k.indexOf('Length') === 0 ||
              k.indexOf('Width') === 0 ||
              k.indexOf('Height') === 0;

            if (idx && !memo[k]) {
              memo[k] = me.get(k);
            }

            return memo;
          }, ret)
          .value();
      }
      return ret;
    },
    round: function(val, places) {
      if (!places) places = 2;
      //rounds to one decimal place
      return (
        Math.ceil((val * Math.pow(10, places)).toFixed(2)) /
        Math.pow(10, places)
      );
    }
  });
});
