const ResourceManager = require('ResourceManager');
const pubsub = require('pubsub');
const Data = require('Data');

const WorkstationActivatedPubSubHandler = {
  event: 'app:message:connect:workstation:activated',
  handler: msg => {
    ResourceManager.loadCache(() => {
      Data.Workstations.add(
        { WorkstationID: msg.data.workstationId, Online: true },
        { merge: true }
      );

      pubsub.trigger('shipstation-connect:workstations-changed');
    });
  }
};

export default WorkstationActivatedPubSubHandler;
