import 'core/LogosPlease';
import AccountService from 'client/services/api/account-service';
import MarketplaceDisplayInformationCache from 'client/components/data-driven-logo/marketplace-display-information-cache';
import template from './Wizard.html';

define(['backbone', 'Modal', 'backbone.modelbinder'], function(
  Backbone,
  Modal,
  ModelBinder
) {
  return Modal.extend(
    {
      modalType: 'Wizard',
      initialize: function(options) {
        options = options || {};
        options.buttons = options.buttons || 'wizard';
        options.bodyCls = options.bodyCls || 'scrollable';
        this.isStoreWizard =
          options.origin && options.origin === 'StoreWizard' ? true : false;
        this.config = this.getConfig(options);
        this.stepArr = _.values(this.config.steps);
        this.stepCount = this.stepArr.length;
        this._super('initialize', [options]);
        this.model = new Backbone.Model();
        this.modelBinder = new ModelBinder();
        if (MarketplaceDisplayInformationCache.getCache().length === 0) {
          this.unsubscribeToCache = MarketplaceDisplayInformationCache.subscribe(
            () => {
              this.render();
              this.onShow();
            }
          );
        }
      },
      onRemove: function() {
        if (!this.unsubscribeToCache) {
          return;
        }
        this.unsubscribeToCache();
        this.unsubscribeToCache = undefined;
      },
      getConfig: function() {
        return {};
      },
      render: function() {
        this._super('render', arguments);
        this.$el.closest('.modal').addClass('wizard');

        var wizTpl = _.template(template);
        this.$el.find('.modal-body').html(
          wizTpl({
            translatedStrings: this.translatedStrings || {},
            steps: this.stepArr
          })
        );

        var stepTpl = _.template(this.config.template);
        this.$el.find('.wizard-steps').html(
          stepTpl({
            ...this.config.tplData,
            translatedStrings: this.translatedStrings || {}
          })
        );
        if (this.$el.find('form.carrier-setup').length) this.prepopulateForm();
        this.modelBinder.bind(this.model, this.$el);
        if (this.isStoreWizard) {
          this.$el.find('.btn-finish').addClass('track-AddStore');
        }
        this.$el.logosPlease();
      },
      refreshSteps: function() {
        var s = [];
        var i = 0;
        var list = this.$el.find('.wizard-crumbs');
        list.empty();
        _.each(this.config.steps, function(step) {
          if (!step.showOnCondition || step.showOnCondition()) {
            s.push(step);
            var item =
              '<li><span class="wizard-crumb" data-index=' +
              i +
              '>' +
              step.title +
              '</span></li>';
            list.append(item);
            i++;
          }
        });

        this.stepArr = s;
        this.stepCount = this.stepArr.length;
      },
      prepopulateForm: function() {
        var me = this;
        //User has access to account management
        if (App.user.permit(1)) {
          AccountService.getBillingInfo().then('account/billinginfo', function(
            data
          ) {
            if (!data.info) return;

            data = data.info;

            me.model.set('company', data.CompanyName);
            me.model.set('firstname', data.FirstName);
            me.model.set('lastname', data.LastName);

            var name = data.FirstName + ' ' + data.LastName;
            me.model.set('fullname', name);
            me.model.set('name', name);
            me.model.set('email', data.Email);
            me.model.set('email2', data.Email);
            me.model.set('address1', data.Address1);
            me.model.set('address2', data.Address2);
            me.model.set('city', data.City);
            me.model.set('state', data.State);
            me.model.set('postalcode', data.PostalCode);
            me.model.set('zip', data.PostalCode);
            me.model.set('countrycode', data.Country);
            me.model.set('phone', data.Phone);
          });
        }
      },
      onShow: function() {
        this._super('onShow', arguments);
        if (this.config.start) {
          this.showStep(this.config.start);
        } else {
          this.showStep(0);
        }
      },
      getStep: function(mixed) {
        if (_.isNumber(mixed)) {
          return this.stepArr[mixed];
        } else if (_.isString(mixed)) {
          return this.config.steps[mixed];
        } else return mixed;
      },
      stepIndex: function(step) {
        return _.indexOf(this.stepArr, step);
      },
      getStepName: function() {
        var keys = _.keys(this.config.steps);
        return keys[this.index];
      },
      onShowStep: function() {
        this.modelBinder.copyViewValuesToModel();
      },
      isStepValid: function() {
        if (this.step && _.isFunction(this.step.isStepValid))
          return this.step.isStepValid.call(this);

        if (this.$step && this.$step.find('form').length)
          return this.$step.find('form').valid();
        return true;
      },
      onStepShown: function(step) {
        this.$step.find('form').validate(this.step.validation);

        if (_.isFunction(step.onShown)) {
          step.onShown.call(this);
        }

        if (this.getTitle && this.index > -1) {
          this.pub('app:trackEvent', 'Wizard Progress', {
            Wizard: this.getTitle(),
            WizardStepNum: this.index + 1,
            WizardStep: this.getStepName()
          });
        }
      },
      showStep: function(mixed) {
        this.refreshSteps();

        var nextStep = this.getStep(mixed);
        if (this.onShowStep(nextStep) === false) {
          return;
        }

        if (this.step) {
          this.lastStep = this.step;
          this.$el.find(this.step.el).hide();
        }

        this.step = nextStep;
        this.$step = this.$el.find(this.step.el);

        var inx = this.stepIndex(this.step);
        this.index = inx;

        this.$el
          .find('.wizard-crumbs li')
          .removeClass('active')
          .removeClass('done');
        this.$el.find('.wizard-crumbs li:lt(' + inx + ')').addClass('done');
        this.$el
          .find('.wizard-crumbs li')
          .eq(inx)
          .addClass('active');

        this.$el.find(this.step.el).show();
        this.$el.find('.btn-back').toggle(this.lastStep != null);
        this.$el
          .find('.btn-next')
          .toggle(this.stepIndex(this.step) < this.stepCount - 1);
        this.$el
          .find('.btn-finish')
          .toggle(this.stepIndex(this.step) == this.stepCount - 1);
        this.onStepShown(this.step);
      },
      isLastActiveStep: function() {
        const currentStepIndex = this.stepIndex(this.step);
        const stepCount = this.stepCount;
        const isLastActiveStep = currentStepIndex + 1 === stepCount;
        return isLastActiveStep;
      },
      next: function() {
        if (!this.isStepValid()) return false;
        var inx = this.stepIndex(this.step);
        if (inx == this.stepCount - 1) return;
        this.showStep(inx + 1);
      },
      back: function() {
        var inx = this.stepIndex(this.step);
        if (inx == 0) return;
        this.showStep(inx - 1);
        if (inx - 1 == 0) {
          this.$el.find('.btn-back').toggle(false);
        }
        this.$el.find('.btn-next').removeAttr('disabled');
        if (this.growler && this.growler.remove) {
          this.growler.remove();
        }
      },
      finish: function() {
        if (this.getTitle) {
          this.pub('app:trackEvent', 'Wizard Progress', {
            Wizard: this.getTitle(),
            WizardStep: 'COMPLETE'
          });
        }
      },
      onOk: function(e) {
        if (!this.isStepValid()) return false;
        return this.finish(e) || false;
      },
      onCancel: function() {
        this.trigger('cancelled');
        this.close();
      },
      events: {
        'click .btn-next': 'next',
        'click .btn-back': 'back'
      }
    },
    {
      //merge event hashes of the base and derived views
      extend: function(properties, classProperties) {
        properties.events = _({}).extend(
          properties.events || {},
          this.prototype.events
        );
        return Modal.extend.call(this, properties, classProperties);
      }
    }
  );
});
