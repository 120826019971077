import moment from 'moment';
import ModalManagerService from './modal-manager-service';
import UserService from './user-service';
import AppDataService from './app-data-service';
import ProviderWalletService from './provider-wallet-service';
import NumberFormattingService from 'client/services/number-formatting-service';

export default class CostSummaryService {
  static PURCHASE_REQUIRED_BUTTON_TEXT = 'Add Funds & Create Label';
  static NO_PURCHASE_REQUIRED_BUTTON_TEXT = 'Create Label';

  static getShipsuranceProviders(providers) {
    return providers.filter(provider => provider.providerType === 0);
  }

  static shouldShowBalancePurchasing(provider, services) {
    const { canPurchase } = provider;
    const servicesSubtotal = this.getProviderServicesSubtotal(
      provider,
      services
    );
    if (!canPurchase) {
      return false;
    }
    return provider.balance < servicesSubtotal;
  }

  static initPurchasesForProviders(providers, services) {
    const purchases = [];

    providers.forEach(provider => {
      const { providerId } = provider;
      const servicesSubtotal = CostSummaryService.getProviderServicesSubtotal(
        provider,
        services
      );
      const shouldShowPurchasing = CostSummaryService.shouldShowBalancePurchasing(
        provider,
        services
      );
      const isWallet = ProviderWalletService.isWalletProvider(provider);
      if (isWallet) {
        const walletMetaData = ProviderWalletService.getProviderWalletMetaData(
          providerId
        );
        const isPrimaryProvider = ProviderWalletService.isPrimaryProviderForWallet(
          provider,
          walletMetaData
        );
        // only add a purchase for the primary provider if they exist in the provider collection to de-dup the purchases
        const hasPrimaryProvider = providers.some(
          testProvider => testProvider.providerId === walletMetaData.providerId
        );
        if (!isPrimaryProvider && hasPrimaryProvider) {
          return false;
        }
      }
      if (shouldShowPurchasing) {
        purchases.push(
          CostSummaryService.getPurchaseForProvider(provider, servicesSubtotal)
        );
      }
    });
    return purchases;
  }

  static getServicesSubtotal(services) {
    const subtotal = services
      .reduce((sum, service) => sum + (parseFloat(service.cost) || 0), 0)
      .toFixed(2);
    return parseFloat(subtotal);
  }

  static getProviderServicesSubtotal(provider, services) {
    const { providerId } = provider;
    const isWallet = ProviderWalletService.isWalletProvider(provider);
    const walletMetaData = isWallet
      ? ProviderWalletService.getProviderWalletMetaData(providerId)
      : undefined;
    const subtotal = services
      .filter(service => {
        if (isWallet) {
          if (!walletMetaData) {
            return false;
          }
          return walletMetaData.supportedProviders.includes(service.providerId);
        }
        const sellerProviderIdsMatch =
          service.sellerProviderId === provider.sellerProviderId;
        const sellerProviderIdsNotSupplied =
          !service.sellerProviderId && !sellerProviderIdsMatch;
        return (
          service.providerId === provider.providerId &&
          (sellerProviderIdsMatch || sellerProviderIdsNotSupplied)
        );
      })
      .reduce((sum, service) => sum + (parseFloat(service.cost) || 0), 0)
      .toFixed(2);
    return parseFloat(subtotal);
  }

  static getPurchaseForProvider(provider, servicesSubtotal) {
    const {
      providerId,
      sellerProviderId,
      minPurchaseAmount,
      balance,
      autopurchaseEnabled,
      autopurchaseAmount
    } = provider;
    const servicesSubtotalMinusBalance = servicesSubtotal - balance;
    const justWhatsNeeded = ((servicesSubtotalMinusBalance + 0.01) * 100) / 100; // round up to avoid fractional cent balance
    const recommendedPurchase =
      servicesSubtotalMinusBalance > minPurchaseAmount
        ? parseFloat(justWhatsNeeded.toFixed(2))
        : parseFloat(minPurchaseAmount.toFixed(2));
    const purchaseAmount = autopurchaseEnabled
      ? autopurchaseAmount
      : parseFloat(recommendedPurchase.toFixed(2));
    return {
      providerId,
      sellerProviderId,
      provider: providerId,
      sellerprovider: sellerProviderId,
      amount: purchaseAmount,
      minPurchaseAmount: recommendedPurchase,
      invalidReason: `The minimum purchase required is ${NumberFormattingService.formatNumberAsCurrency(
        recommendedPurchase
      )}`,
      isValid: true,
      autopurchaseEnabled,
      autopurchaseAmount
    };
  }

  static hasConsentedToShipsuranceTerms() {
    return UserService.getUserSetting('ConsentedToShipsurance') === 'true';
  }

  static consentedToShipsuranceTermsChange(newValue) {
    UserService.setUserSetting(
      'ConsentedToShipsuranceCreateDate',
      newValue ? moment.utc().toString() : ''
    );
    return UserService.setUserSetting(
      'ConsentedToShipsurance',
      newValue ? 'true' : 'false'
    );
  }

  static showShipsuranceConsentWarning() {
    ModalManagerService.launchSimpleModal({
      title: 'Agree to Continue',
      content: 'You must agree to the Shipsurance Terms and Conditions.',
      height: '195px',
      buttons: 'ok',
      width: '600px'
    });
  }

  static providerWalletsEnabled() {
    return AppDataService.getSetting('EnableProviderWallet') === 'true';
  }

  static validatePurchases(purchases) {
    purchases.forEach(purchase => {
      purchase.isValid = purchase.amount >= purchase.minPurchaseAmount;
    });
  }

  static getMultiWarehouseWarning(warehouseCount) {
    return `This batch contains orders shipping from ${warehouseCount} different locations. Separate batches will be created for each location.`;
  }

  static isProviderAutoProvisioned(providerId) {
    return !!AppDataService.getAutoProvisionedProviders().find(
      provId => provId === providerId
    );
  }

  static findWalletedSellerProviderRequiringSetup(sellerProviderIdList) {
    if (!(sellerProviderIdList || []).length) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvidersFromIds = _.filter(
      allSellerProviders,
      sellerProvider =>
        _.contains(sellerProviderIdList, sellerProvider.SellerProviderID)
    );
    const walletIds = _.pluck(sellerProvidersFromIds, 'WalletID').filter(
      walletId => walletId !== null
    );

    // NOTE: UseHouseCredentials is actually a server-side calculated field
    // It describes if setup is required for any type of wallet, while remaining true to its original purpose for stamps functionality
    // This can cause a lot of confusion if you don't know this
    const walletedSellerProvider = _.find(
      allSellerProviders,
      sellerProvider =>
        _.contains(walletIds, sellerProvider.WalletID) &&
        sellerProvider.UseHouseCredentials
    );
    return walletedSellerProvider;
  }

  static getWalletIdForProvider(providerId) {
    if (!providerId) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvider = _.find(
      allSellerProviders,
      sp => sp.ProviderID === providerId && sp.WalletID
    );

    return (sellerProvider || {}).WalletID;
  }

  static getBaseWalletSellerProvider(sellerProviderId) {
    if (!sellerProviderId) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvider =
      _.findWhere(allSellerProviders, { SellerProviderID: sellerProviderId }) ||
      {};

    if (!sellerProvider.WalletID) {
      return null;
    }

    const allWallets = AppDataService.getWallets();
    const sellerWallet =
      _.findWhere(allWallets, { WalletID: sellerProvider.WalletID }) || {};
    const baseWalletSellerProvider = _.findWhere(allSellerProviders, {
      SellerProviderID: sellerWallet.BaseSellerProviderID
    });

    return baseWalletSellerProvider || null;
  }

  static getWalletBySellerProvider(sellerProviderId) {
    if (!sellerProviderId) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvider =
      _.findWhere(allSellerProviders, { SellerProviderID: sellerProviderId }) ||
      {};

    if (!sellerProvider.WalletID) {
      return null;
    }

    const allWallets = AppDataService.getWallets();
    const sellerWallet = _.findWhere(allWallets, {
      WalletID: sellerProvider.WalletID
    });

    return sellerWallet || null;
  }

  static getSellerProviderByProviderId(providerId) {
    if (!providerId) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvider = _.findWhere(allSellerProviders, {
      ProviderID: providerId,
      IsPrimaryAccount: true
    });

    return sellerProvider || null;
  }

  static getSellerProvider(sellerProviderId) {
    if (!sellerProviderId) {
      return null;
    }

    const allSellerProviders = AppDataService.getProviderAccounts();
    const sellerProvider = _.findWhere(allSellerProviders, {
      SellerProviderID: sellerProviderId
    });

    return sellerProvider || null;
  }

  static getAutoProvProviderInfoFromSellerProviders(sellerProviders) {
    if (!sellerProviders || !sellerProviders.length) {
      throw new Error('Seller Providers cannot be null or empty');
    }

    // Check for Wallet Provider
    const providerIds = sellerProviders.map(provider => provider.ProviderID);
    const allSellerProviders = _.filter(
      AppDataService.getProviderAccounts(),
      sellerProvider => _.contains(providerIds, sellerProvider.ProviderID)
    );
    const allSellerProviderIds = _.pluck(
      allSellerProviders,
      'SellerProviderID'
    );
    const walletSellerProvider = this.findWalletedSellerProviderRequiringSetup(
      allSellerProviderIds
    );
    if (walletSellerProvider) {
      return {
        requiresSetup: true,
        providerId: walletSellerProvider.ProviderID,
        walletedProviderId: walletSellerProvider.ProviderID,
        isWalletProvider: true
      };
    }

    // Check for Stamps Provider
    const autoProvisionedProvider = _.first(
      sellerProviders.some(provider =>
        this.isProviderAutoProvisioned(provider.ProviderID)
      )
    );
    if (autoProvisionedProvider) {
      const providerId = autoProvisionedProvider.ProviderID;
      return {
        requiresSetup: true,
        providerId
      };
    }

    return {
      requiresSetup: false
    };
  }

  static getAutoProvProviderInfo(providerId) {
    const sellerProvider = this.getSellerProviderByProviderId(providerId);
    if (!sellerProvider) {
      return {
        requiresSetup: false
      };
    }

    // Check for Wallet Provider
    if (sellerProvider.WalletID) {
      const wallet = this.getWalletBySellerProvider(
        sellerProvider.SellerProviderID
      );

      if (wallet.BaseSellerProviderID) {
        // Older wallet such as Parcelcast Phase 1, and 2
        const baseWalletSellerProvider = this.getSellerProvider(
          wallet.BaseSellerProviderID
        );
        if (baseWalletSellerProvider) {
          return {
            requiresSetup:
              baseWalletSellerProvider.UseHouseCredentials === true,
            providerId: baseWalletSellerProvider.ProviderID,
            walletedProviderId: providerId,
            isWalletProvider: true,
            SellerProviderId: sellerProvider.SellerProviderID
          };
        }
      } else {
        // Standard wallet such as Hermes
        return {
          requiresSetup: wallet.IsHouseWallet,
          providerId,
          walletedProviderId: providerId,
          isWalletProvider: true,
          SellerProviderId: sellerProvider.SellerProviderID
        };
      }
    }

    // Check for Stamps Provider
    if (this.isProviderAutoProvisioned(providerId)) {
      return {
        requiresSetup: true,
        providerId
      };
    }

    return {
      requiresSetup: false
    };
  }
}
