import _ from 'underscore';

import ShipstationConnectUtils from '../shipstation-connect-utils';
import notify from '../notify';
import notifyConnectJobUpdate from 'public/core/utilities/notify-connect-job-update';

const updateNotification = (job, title, message) => {
  if (!job || !job.notification) return;

  job.notification.remove();
  notify(title, message, true, 'error');
};

const printerJobStatusToHandlerMap = {
  'job:accepted': job =>
    notifyConnectJobUpdate({
      notification: job.notification,
      text: 'Starting print job...'
    }),
  'job:complete': job => {
    notifyConnectJobUpdate({
      notification: job.notification,
      text: 'Sent to printer!'
    });
    setTimeout(function() {
      job.notification.remove();
    }, 2000);
  },
  'job:failed': (job, msg, getMessage) =>
    updateNotification(
      job,
      'Print Job Failed',
      getMessage('failed to start') +
        (msg.data.message ? '<br>' + msg.data.message : '')
    ),
  'download:failed': (job, msg, getMessage) =>
    updateNotification(
      job,
      'Print Job Download Failed',
      getMessage('failed to download') +
        (msg.data.message ? '<br>' + msg.data.message : '')
    ),
  'printer:missing': (job, msg, getMessage) =>
    updateNotification(
      job,
      'Printer Missing',
      getMessage(
        _.str.sprintf(
          'could not be sent to "%s" because that printer was not found.',
          job.printerName
        )
      )
    ),
  'printer:failed': (job, msg, getMessage) =>
    updateNotification(
      job,
      'Print Job Failed',
      getMessage('failed to start') +
        (msg.data.message ? '<br>' + msg.data.message : '')
    ),
  unknown: (job, msg) =>
    console.warn('unexpected printer status: ' + JSON.stringify(msg))
};

const PrinterJobResponsePubSubHandler = {
  event: 'app:message:client-connect:response:printer:job',
  handler: msg => {
    ShipstationConnectUtils.updateJob(msg, job => {
      const getMessage = content => {
        return _.str.sprintf(
          '"%s" %s on "%s"',
          job.get('docName'),
          content,
          job.get('workstationName')
        );
      };

      let status = 'unknown';
      if (msg && msg.data && msg.data.status) {
        status = msg.data.status;
      }
      printerJobStatusToHandlerMap[status](job, msg, getMessage);
    });
  }
};

export default PrinterJobResponsePubSubHandler;
