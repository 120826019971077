import getDisplayInformation from 'client/components/data-driven-logo/get-display-information';
import { defineMessage } from 'react-intl';
import LocaleService from 'client/i18n/locale-service';

const unknownProvider = defineMessage({
  id: 'getProviderDisplayName.unknownProvider',
  defaultMessage: 'Unknown Provider'
});

const GetProviderDisplayName = providerId => {
  providerId = Number.parseInt(providerId, 10);
  const displayInformation = getDisplayInformation({ providerId });
  return (
    displayInformation?.name ||
    LocaleService.getTranslatedString(unknownProvider)
  );
};

export default GetProviderDisplayName;
