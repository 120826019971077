import jsCookie from 'js-cookie';
import defaultCookieConfig from 'public/defaultCookieConfig';

const CookieService = {
  setSecureCookie: (key, value) => {
    jsCookie.set(key, value, {
      domain: window.location.cookieDomain(),
      path: '/',
      ...defaultCookieConfig
    });
  },
  removeSecureCookie: key => {
    jsCookie.remove(key);
    jsCookie.remove(key, {
      domain: window.location.cookieDomain(),
      path: '/',
      ...defaultCookieConfig
    });
  }
};

export default CookieService;
