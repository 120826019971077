import ShipStationConnectService from 'client/services/shipstation-connect-service';

const pubsub = require('pubsub');
const Data = require('Data');

const WorkstationDeactivatedPubSubHandler = {
  event: 'app:message:connect:workstation:deactivated',
  handler: msg => {
    const workstation = Data.Workstations.get(msg.data.workstationId);
    ShipStationConnectService.clearOrphanedScales(msg.data.workstationId);
    ShipStationConnectService.clearOrphanedPrinters(msg.data.workstationId);
    if (workstation) {
      Data.Workstations.remove(workstation);
    }
    pubsub.trigger('shipstation-connect:workstations-changed');
  }
};

export default WorkstationDeactivatedPubSubHandler;
