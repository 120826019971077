import $ from 'jquery';
import notify from 'public/core/utilities/notify';
import notifyModal from 'public/core/utilities/notify-modal';

const appNotify = (title, message, stick, type, options) => {
  const modal = $('.modal');
  if (modal.length) {
    return notifyModal(modal, title, message, stick, type, options);
  }

  return notify(title, message, stick, type, options);
};

export default appNotify;
