const browser = () => {
  const { appVersion, userAgent } = window.navigator;

  // SF: We need only a rough idea of browser for now.
  let browser = 'Unknown Browser';
  if (appVersion.indexOf('Trident') !== -1) {
    browser = 'IE11';
  }
  if (appVersion.indexOf('MSIE') !== -1) {
    browser = 'IE';
  } // Just in case we need to determine IE10 vs 11.
  if (userAgent.indexOf('Firefox') !== -1) {
    browser = 'FF';
  }
  if (userAgent.indexOf('Safari') !== -1) {
    browser = 'SF';
  }
  if (appVersion.indexOf('Chrome') !== -1) {
    browser = 'CH';
  } // SF: Chrome lists both Safari and Chrome. Chrome will need to overwrite Safari.
  if (userAgent.indexOf('iPad') !== -1) {
    browser = 'SFI';
  }
  return browser;
};

export default browser;
