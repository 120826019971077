import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Icon } from '@shipstation/svgs';

import ErrorMessage from 'ui-components/components/error-message';
import Button from 'ui-components/components/button';
import Checkbox from 'ui-components/components/checkbox';

import { useAsyncSafeState } from 'client/hooks/use-async-safe-state';

import style from './style.less';

const CanadaTermsAndConditions = ({
  isAdmin,
  pastDeadline,
  modal: { close },
  acceptCanadaTermsAndConditions
}) => {
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [hasAcceptError, setAcceptError] = useAsyncSafeState(false);

  const acceptTerms = useCallback(async () => {
    try {
      await acceptCanadaTermsAndConditions();
      close();
    } catch (e) {
      setAcceptError(true);
    }
  }, [close, setAcceptError]);

  const dismissError = useCallback(() => setAcceptError(false), [
    setAcceptError
  ]);

  const bodyText = isAdmin
    ? "We've updated our Terms of Service and Privacy Policy. We encourage you to read these documents carefully."
    : "We've updated our Terms of Service and Privacy Policy. Contact your administrator to accept new terms and re-enable your ShipStation access.";

  return (
    <div className={style['new-terms-modal-body']}>
      <h4 className={style['new-terms-modal-header']}>
        New ShipStation Terms of Service
      </h4>
      {hasAcceptError && (
        <ErrorMessage
          className={style['new-terms-modal-body-warning']}
          hideIconLabel
          onClose={dismissError}
          state="error"
        >
          Error accepting terms. Please try again later.
        </ErrorMessage>
      )}
      {!isAdmin && (
        <ErrorMessage
          className={style['new-terms-modal-body-warning']}
          hideIconLabel
          state="error"
        >
          Access to ShipStation is blocked.
        </ErrorMessage>
      )}
      <p className={style['new-terms-modal-body-text']}>{bodyText}</p>
      {isAdmin && (
        <>
          <Checkbox
            checked={checkedTerms}
            className={style['new-terms-modal-check']}
            label={'I have read and agree to the following terms of services:'}
            onChange={() => setCheckedTerms(val => !val)}
          />
          <a
            className={style['settings-link']}
            href="https://www.shipstation.com/ca/terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            ShipStation Terms of Service
            <Icon name="external-link-alt" type="fa" />
          </a>
          <a
            className={style['settings-link']}
            href="https://www.shipstation.com/ca/privacy-policy/"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
            <Icon name="external-link-alt" type="fa" />
          </a>
          <div className={style.buttons}>
            {!pastDeadline && <Button onClick={close}>Close</Button>}
            <Button
              disabled={!checkedTerms}
              onClick={acceptTerms}
              theme="primary"
            >
              Accept Terms
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

CanadaTermsAndConditions.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  pastDeadline: PropTypes.bool.isRequired,
  modal: PropTypes.shape({
    close: PropTypes.func.isRequired
  }).isRequired
};

CanadaTermsAndConditions.render = (el, props) => {
  if (!el) {
    return;
  }
  ReactDOM.render(<CanadaTermsAndConditions {...props} />, el);
};

export const buildCanadaTermsAndConditionsModalConfig = ({
  isAdmin,
  pastDeadline,
  acceptCanadaTermsAndConditions
}) => ({
  backboneModal: true,
  reactComponent: CanadaTermsAndConditions,
  reactComponentProps: {
    pastDeadline,
    isAdmin,
    acceptCanadaTermsAndConditions
  },
  preventClose: pastDeadline,
  title: 'Action Required',
  width: '420px',
  height: '350px',
  containerCls: style['terms-and-conditions-modal']
});

export default CanadaTermsAndConditions;
