import notify from 'public/core/utilities/notify';

const notifyContext = ($context, title, message, stick, type, options) => {
  options = {
    ...options,
    stack: {
      context: $context,
      dir1: 'down',
      dir2: 'left',
      spacing1: 8,
      spacing2: 8
    }
  };

  return notify(title, message, stick, type, options);
};

export default notifyContext;
