const KEY_PREFIX = '__amplify__';
const topicToSubscribersMap = {};

const getKeyWithoutPrefix = key => key.replace(KEY_PREFIX, '');
const addPrefixToKey = key => `${KEY_PREFIX}${key}`;

let inMemoryData = {};

const shouldUseInMemoryData = key => {
  return key.indexOf('_cache_') !== -1;
};

const getValueFromLocalStorage = key => {
  const isInMemory = shouldUseInMemoryData(key);
  const localStorageValue = isInMemory
    ? inMemoryData[key]
    : window.localStorage.getItem(key);
  if (!localStorageValue) {
    return undefined;
  }
  const jsonValue = JSON.parse(localStorageValue);
  if (!jsonValue) {
    return undefined;
  }
  return jsonValue.data;
};

export default {
  // there are two arguments
  store: (...args) => {
    // if no parameters are set, return everything in local storage
    if (args.length === 0) {
      const allKeys = [
        ...Object.keys(inMemoryData),
        ...Object.keys(window.localStorage)
      ];
      return allKeys
        .filter(key => key.startsWith(KEY_PREFIX))
        .reduce(
          (sum, key) => ({
            ...sum,
            [getKeyWithoutPrefix(key)]: getValueFromLocalStorage(key)
          }),
          {}
        );
    }
    // if there is just one argument, it's the key, return the value
    if (args.length === 1) {
      const key = args[0];
      return getValueFromLocalStorage(addPrefixToKey(key));
    }

    // otherwise, assume the first two arguments are key and value
    const key = args[0];
    const value = args[1];
    const isInMemory = shouldUseInMemoryData(key);

    const prefixedKey = addPrefixToKey(key);
    const stringifiedValue = JSON.stringify({
      data: value,
      expires: null
    });

    if (isInMemory) {
      inMemoryData[prefixedKey] = stringifiedValue;
      return;
    }
    localStorage.setItem(prefixedKey, stringifiedValue);
  },
  publish: (topic, ...callbackArgs) => {
    const subscribers = topicToSubscribersMap[topic];
    if (!subscribers) {
      return true;
    }
    let returnValue = false;
    for (let x = 0; x < subscribers.length; x += 1) {
      const subscription = subscribers[0];
      returnValue = subscription.callback.bind(subscription.context)(
        ...callbackArgs
      );
      if (!returnValue) {
        break;
      }
    }
    return returnValue !== false;
  },
  subscribe: (topic, context, callback) => {
    if (!topicToSubscribersMap[topic]) {
      topicToSubscribersMap[topic] = [];
    }
    topicToSubscribersMap[topic].push({ context, callback });
  },
  unsubscribe: (topic, callback) => {
    if (!topicToSubscribersMap[topic]) {
      return;
    }
    topicToSubscribersMap[topic] = topicToSubscribersMap[topic].filter(
      subscription => subscription.callback !== callback
    );
  },
  clear: () => {
    inMemoryData = {};
    Object.keys(window.localStorage).forEach(function(key) {
      if (/^(__amplify__ss)/.test(key)) {
        window.localStorage.removeItem(key);
      }
    });
  }
};
