import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: 'c1a3acad-e8f3-4b66-8e2a-41b7243e14b9',
    defaultMessage: 'Remove Account'
  }),
  defineMessage({
    id: 'ae3ea62d-c5db-4077-9579-54868efa0748',
    defaultMessage: 'Please wait...'
  }),
  defineMessage({
    id: 'cddd7ee8-f03b-4b1d-9391-645596c1d843',
    defaultMessage: 'Confirm'
  }),
  defineMessage({
    id: 'f93e9fc9-8c41-40f2-8d1e-571bbfdcc1d2',
    defaultMessage: 'Cancel'
  })
];

export default translations;
