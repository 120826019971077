import { ApiUrl } from '../app/Config';

define(['backbone', 'models/ProductProfile'], function(
  Backbone,
  ProductProfile
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'ProductProfiles',
    model: ProductProfile,
    parse: function(resp) {
      if (resp.profiles) {
        return resp.profiles;
      }

      return resp;
    }
  });
});
