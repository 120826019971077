function UrlService() {}

UrlService.prototype.getQueryVariableFromString = function(
  querystring,
  variable
) {
  var queryparams = querystring.split('&');
  for (var i = 0; i < queryparams.length; i++) {
    var pair = queryparams[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
};

UrlService.prototype.getQueryVariableFromSegment = function(segment, variable) {
  var queryIndex = segment.indexOf('?');
  if (queryIndex > -1) {
    var query = segment.substring(queryIndex + 1);
    return this.getQueryVariableFromString(query, variable);
  } else {
    return null;
  }
};
UrlService.prototype.getQueryVariableFromEncodedString = function(
  querystring,
  variable
) {
  var queryparams = querystring.split('&');
  for (var i = 0; i < queryparams.length; i++) {
    var pair = queryparams[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

UrlService.prototype.getQueryVariableFromEncodedSegment = function(
  segment,
  variable
) {
  var queryIndex = segment.indexOf('?');
  if (queryIndex > -1) {
    var query = segment.substring(queryIndex + 1);
    return this.getQueryVariableFromEncodedString(query, variable);
  } else {
    return null;
  }
};

export default new UrlService();
