import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import AppDataService from 'client/services/app-data-service';

const SENTRY_IGNORE_LIST = [
  'Extension context invalidated.',
  'No reCAPTCHA clients exist.',
  'window.grecaptcha.render is not a function',
  "window.grecaptcha.getResponse is not a function. (In 'window.grecaptcha.getResponse()', 'window.grecaptcha.getResponse' is undefined)",
  'network error (Error)'
];

if (window && window.SS_GLOBALS) {
  const isLabelApi = window.SS_GLOBALS.appMode === 'labelapi';
  if (!window.SS_GLOBALS.sentryDsn) {
    console.log('Sentry init failed.  DSN is undefined!');
  } else {
    console.log('Sentry init succeessful');

    Sentry.init({
      dsn: window.SS_GLOBALS.sentryDsn,
      environment: window.SS_GLOBALS.serverEnvironment
        ? `${window.SS_GLOBALS.serverEnvironment}`
        : undefined,
      release:
        process.env.RELEASE_VERSION && process.env.RELEASE_VERSION.length
          ? `${isLabelApi ? 'shipengine-dashboard' : 'shipstation-v2'}@${
              process.env.RELEASE_VERSION
            }`
          : undefined,
      blacklistUrls: [/^chrome:\/\//i, /^chrome-extension:\//i],
      beforeSend(event) {
        // this was copied from https://github.com/getsentry/sentry-javascript/issues/2210
        // the intent is to filter out extension errors
        // comes from a dev who works at sentry

        // (try catch is only so you dont have to assert any null values in that long chain)
        try {
          if (
            SENTRY_IGNORE_LIST.includes(
              event.extra.__serialized__.detail.reason.message
            )
          )
            return null;
        } catch (ignore) {
          return event;
        }
        return event;
      }
    });
  }
} else {
  console.log('Could not initialize Sentry');
}

class SentryService {
  identifyUser(id, sellerId, userInfo) {
    const isShipEngine = AppDataService.getIsShipEngine();
    const isLogRocketEnabledForSeller =
      AppDataService.getSetting('EnableLogRocketForV2') === 'true' ||
      isShipEngine;

    if (isLogRocketEnabledForSeller) {
      try {
        LogRocket.getSessionURL(sessionURL => {
          Sentry.configureScope(scope => {
            scope.setUser({
              id,
              sellerId,
              ...userInfo
            });
            scope.setTag('app-seller-id', sellerId);
            scope.setExtra('sessionURL', sessionURL);
          });
        });
      } catch (err) {
        console.error(`Error accessing LogRocket: ${err}`);
      }
    } else {
      Sentry.configureScope(scope => {
        scope.setUser({
          id,
          sellerId,
          ...userInfo
        });
        scope.setTag('app-seller-id', sellerId);
      });
    }
  }

  trackPage(pageName) {
    try {
      Sentry.addBreadcrumb({
        category: 'page',
        message: pageName,
        level: Sentry.Severity.Info
      });
    } catch (err) {
      console.error(`Error accessing Sentry: ${err}`);
    }
  }

  trackEvent(message, data) {
    try {
      Sentry.addBreadcrumb({
        category: 'action',
        message,
        data,
        level: Sentry.Severity.Info
      });
    } catch (err) {
      console.error(`Error accessing Sentry: ${err}`);
    }
  }
}

export default SentryService;
