import LogService from './log-service';
import CountryService from './country-service';
import StringHelperService from './string-helper-service';

function numberToString(number, decimalPlaces) {
  return `${number.toFixed(decimalPlaces)}`;
}

function addCommasToNumberString(numberString) {
  // todo handle chinese formatting based on powers of 10,000 (see https://www.wikiwand.com/en/Decimal_mark for more information)
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default class NumberFormattingService {
  static roundToPlace(number, placesToRound, removeTrailingZeroes = false) {
    if (placesToRound === undefined) {
      LogService.log(
        'NumberFormattingService: Cannot round to undefined place'
      );
      return undefined;
    }
    if (placesToRound < 1) {
      LogService.log(
        'NumberFormattingService: Cannot round to a place less than 1'
      );
      return undefined;
    }
    placesToRound = Math.floor(placesToRound);
    const multipleOfTen = 10 ** placesToRound;
    let fixedNumber = Number.parseFloat(
      (Math.round(number * multipleOfTen) / multipleOfTen).toFixed(
        placesToRound
      )
    );
    if (removeTrailingZeroes) {
      fixedNumber = fixedNumber.toString();
    }
    return fixedNumber;
  }

  static addCommasToNumber(number) {
    if (typeof number !== 'number') {
      return '';
    }
    const numberString = `${number}`;
    const stringParts = numberString.split('.');
    return (
      addCommasToNumberString(stringParts[0]) +
      (stringParts.length > 1 ? `.${stringParts[1]}` : '')
    );
  }

  static formatNumberAsCurrency(
    number,
    currencySymbol = CountryService.getCurrencySymbol(),
    decimalDelimiter = '.',
    digitGroupDelimiter = ',',
    nanDefaultText
  ) {
    if (currencySymbol === undefined) {
      currencySymbol = CountryService.getCurrencySymbol();
    }
    // todo handle currencies that don't use the decimal format like Japanese Yen
    number = this.coerceOtherTypesToNumber(number);
    if (Number.isNaN(number)) {
      if (nanDefaultText) {
        return currencySymbol + nanDefaultText;
      }

      number = 0;
    }
    const numberString = numberToString(number, 2);
    const numberStringWithCommas = addCommasToNumberString(numberString);
    // This intermediate step is needed in the case of switching . with , and vice versa.
    const decimalDelimiterString = 'DECIMAL_DELIMITER';
    const digitGroupDelimiterString = 'DIGIT_GROUP_DELIMITER';
    let formattedNumber = StringHelperService.replaceAll(
      numberStringWithCommas,
      '.',
      decimalDelimiterString
    );
    formattedNumber = StringHelperService.replaceAll(
      formattedNumber,
      ',',
      digitGroupDelimiterString
    );
    formattedNumber = StringHelperService.replaceAll(
      formattedNumber,
      decimalDelimiterString,
      decimalDelimiter
    );
    formattedNumber = StringHelperService.replaceAll(
      formattedNumber,
      digitGroupDelimiterString,
      digitGroupDelimiter
    );

    return currencySymbol + formattedNumber;
  }

  static parseStringToNumber(str) {
    return parseFloat(str.replace(/[^\d.]/g, ''));
  }

  static coerceOtherTypesToNumber(possibleNumber) {
    switch (typeof possibleNumber) {
      case 'string':
        return this.parseStringToNumber(possibleNumber);
      case 'number':
        return possibleNumber;
      default:
        return Number.NaN;
    }
  }

  static isNumberInteger(value) {
    if (typeof Number.isInteger !== 'undefined') {
      return Number.isInteger(value);
    }
    return (
      typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  }
}
