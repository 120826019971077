import AppError from 'public/core/AppError';
import setOptions from './set-options';

const loadOptions = (els, Data) => {
  els.each(function(i, select) {
    const optionsSetName = $(select).data('options');
    if (typeof Data[optionsSetName].models !== 'undefined') {
      setOptions(select, Data[optionsSetName].models);
      return;
    }
    AppError(
      `No such option set for key: ${optionsSetName} in Utilities.select.loadOptions()`
    );
  });
};

export default loadOptions;
