import jsCookie from 'js-cookie';

import { ApiUrl } from '../../../app/Config';
import AnalyticsService from '../../../../client/services/analytics-service.js';
import RecaptchaService from '../../../../client/services/recaptcha-service';
import AppDataService from '../../../../client/services/app-data-service';
import defaultCookieConfig from 'public/defaultCookieConfig';

import template from './Login.html';
const LOGIN_SYSTEM_ERROR_THRESHOLD = 3;

define(['Session', 'pubsub', 'BaseView'], function(Session, pubsub, BaseView) {
  const reCaptchaElementId = 'grecaptcha';
  let recaptchaService;
  let newLoginPromise;

  return BaseView.extend({
    template: _.template(template),
    events: {
      'keypress #username': 'userNameKeypress',
      'keypress #password': 'passwordKeypress',
      'keypress #rememberme': 'passwordKeypress',
      'click #btn-login': 'clickLoginButtonV2',
      'click #btn-login-v3': 'loginToV3',
      'click #btn-login-auth0': 'loginAuth0'
    },
    initialize() {
      this.hideTopbar = true;
      this.loginSystemErrorCount = 0;
      const init = () => {
        document.addEventListener('keydown', function(e) {
          if (e.ctrlKey && e.altKey && e.key === 'l') {
            console.debug('login shortcut!');
            window.location.href = window.location.origin.concat('/login');
            e.stopPropagation();
          }
        });
      };

      const useNewLogin = !!jsCookie.get('NewLoginPage');
      if (!useNewLogin) {
        init();
        return;
      }

      var allowNewLogin =
        window.location.search.toLowerCase().indexOf('nonewlogin') <= 0;
      if (allowNewLogin) {
        newLoginPromise = Session.isAuthenticated().then(
          isAuthenticatedResponse => {
            if (isAuthenticatedResponse.authenticated) {
              init();
              return false;
            }
            window.location.href = window.location.origin.concat('/login');
            return true;
          }
        );
      }
    },
    passwordKeypress(event) {
      if (event.keyCode == 13) {
        this.clickLoginButtonV2();
      }
    },
    render() {
      const renderInternal = () => {
        const isLabelApi = AppDataService.isRunningLabelApi();
        if (isLabelApi) {
          document.title = 'ShipEngine';
        }
        let v3Access = jsCookie.get('v3Access');
        $(this.el).html(
          this.template({
            isLabelApi,
            shouldShowBranch:
              window.SS_GLOBALS.environment !== 'prod' ||
              window.SS_GLOBALS.internalTestServer,
            gitBranch: AppDataService.frontEndConfig.GitBranch,
            v3Access
          })
        );
        this.focusUserName();

        if (window.SS_GLOBALS.useRecaptcha) {
          window.onCaptcha = function() {
            this.login();
          }.bind(this);

          if (!recaptchaService) {
            recaptchaService = new RecaptchaService(
              reCaptchaElementId,
              window.onCaptcha
            );
          }
          recaptchaService.render();
        }
      };
      if (newLoginPromise) {
        newLoginPromise.then(newLoginUsed => {
          if (!newLoginUsed) {
            renderInternal();
          }
        });
      } else {
        renderInternal();
      }
    },
    userNameKeypress(event) {
      if (event.keyCode == 13) {
        $('#password').focus();
      }
    },
    focusUserName() {
      setTimeout(function() {
        $('#username').focus();
      }, 100);
    },
    login() {
      if (this.preLoginValidate()) {
        this.$el.find('#btn-login').button('loading');
        this.$el.find('#login-form').hide();
        this.$el.find('.logging-in').show('slide', {
          direction: 'down'
        });

        // preventing duplicate login submits
        if (this.inProgress) {
          return false;
        }
        this.inProgress = true;

        // send tracking
        if (jsCookie.get('v3Access')) {
          if (jsCookie.get('login:button') === undefined) {
            AnalyticsService.trackEvent('Go to V2 Login Btn Clicked');
          }
          if (jsCookie.get('login:button') === 'v3') {
            AnalyticsService.trackEvent('V3 Login Btn Clicked');
          }
        }
        // blank widget id defaults to first

        $('.login-error')
          .html('')
          .hide();
        $.jjax({
          type: 'POST',
          url: ApiUrl + 'auth/GetToken',
          data: {
            username: $('#username').val(),
            password: $('#password').val(),
            rememberme: $('#rememberme').is(':checked'),
            type: AppDataService.frontEndConfig.AppMode,
            workstationAuthorizationToken: jsCookie.get(
              'WS_' +
                $('#username')
                  .val()
                  .toLowerCase()
            )
          },
          headers: window.SS_GLOBALS.useRecaptcha
            ? { reCAPTCHAresponseToken: recaptchaService.getResponse() }
            : {},
          success: this.loginSuccess,
          error: this.loginError,
          context: this
        });
      }
    },
    loginAuth0() {
      Session.auth0.login();
    },
    clickLoginButtonV2() {
      if (!this.preLoginValidate()) {
        return;
      }

      if (window.SS_GLOBALS.useRecaptcha) {
        this.startCaptcha();
      } else {
        this.login();
      }
    },
    startCaptcha() {
      const $username = $(this.$el.find('#username'));
      const username = $username ? $username.val() : null;
      if (recaptchaService.hasPassedChallenge(username)) {
        this.login();
      } else {
        recaptchaService.challenge();
      }
    },
    loginToV3(event) {
      event.preventDefault();
      jsCookie.set('login:button', 'v3', defaultCookieConfig);
      return this.login(event);
    },
    preLoginValidate() {
      var $username = $(this.$el.find('#username'));
      var username = $username ? $username.val() : null;
      if (username == null || username.length == 0) {
        this.loginError('', 'Please enter a Email/Username');
        return false;
      }
      if (username.length > 256) {
        this.loginError(
          '',
          'Email/Username cannot be more than 256 characters'
        );
        return false;
      }
      return true;
    },
    loginSuccess(response) {
      if (response.success) {
        this.loggedIn();
        Session.clearCachedData();
        jsCookie.set('initial:login', true, defaultCookieConfig);

        Session.start(response);

        pubsub.trigger('app:trackEvent', 'Login');
      } else {
        this.loginError(null, response.friendlyMessage, response.unverified);
        this.$el.find('#login-form').show();
        this.$el.find('.logging-in').hide();
      }
      this.inProgress = false;
    },
    loginError(err, friendlyMessage, unverified) {
      $('#btn-login').button('reset');
      jsCookie.remove('login:button', defaultCookieConfig);

      if (window.SS_GLOBALS.useRecaptcha) {
        recaptchaService.reset();
      }

      this.$el.find('.logging-in').hide();
      this.$el.find('#login-form').show({
        delay: 500
      });

      // attempt to show real error from service layer, but only in this specialized 401 case
      if (err && err.status === 401 && err.responseJSON) {
        if (friendlyMessage === 'error' && err.responseJSON.friendlyMessage) {
          friendlyMessage = err.responseJSON.friendlyMessage;
        }
      }

      if (err && err.status === 500) {
        this.loginSystemErrorCount++;
        if (this.loginSystemErrorCount >= LOGIN_SYSTEM_ERROR_THRESHOLD) {
          this.$el.find('.login-page').addClass('login-system-error');
          this.$el.find('.system-status-message').show();
        }
      }

      // @todo return failure msg from service layer and show here
      if (friendlyMessage && friendlyMessage != 'error') {
        this.$el.find('.login-page').addClass('modal-login-error'); // SF: Accomodate the error message
        $('.login-error')
          .html(friendlyMessage)
          .show();
      } else {
        $('.login-error')
          .html('Login Failure.')
          .show();
      }

      if (typeof unverified === 'boolean' && unverified) {
        var email = this.$el.find('[name=username]').val();
        $('<a class="reverify pull-right">Resend verification.</a>')
          .attr('href', '#/public/verify/resend/' + email)
          .appendTo(this.$el.find('.login-error'));
      }

      pubsub.trigger('app:trackEvent', 'Failed Login', {
        Username: $('#username').val(),
        FriendlyMessage: friendlyMessage
      });

      this.inProgress = false;
    },
    loggedIn() {
      $('#username').val('');
      $('#password').val('');
    }
  });
});
