import uuid from 'uuid';

export default class SubscribableService {
  constructor() {
    this.subscribers = {};
  }

  subscribe(callback) {
    const subscriberID = uuid.v4();
    this.subscribers[subscriberID] = callback;
    return () => this.unsubscribe(subscriberID);
  }

  unsubscribe(subscriberID) {
    delete this.subscribers[subscriberID];
  }

  onChange(newValues) {
    Object.keys(this.subscribers).forEach(subscriberKey =>
      this.subscribers[subscriberKey](newValues)
    );
  }
}
