import { ApiUrl } from '../app/Config';
import SellerProviderMixins from '../models/SellerProviderMixins';

define(['backbone'], function(Backbone) {
  var prototype = _.extend(
    {
      urlRoot: ApiUrl + 'Providers',
      idAttribute: 'SellerProviderID',
      getSetting: function(name) {
        if (!this.get('SellerProviderSettings')) return null;

        var setting = _.findWhere(this.get('SellerProviderSettings'), {
          Name: name
        });
        if (setting) return setting.Value;
        else return null;
      }
    },
    SellerProviderMixins
  );

  return Backbone.Model.extend(prototype);
});
