import uuid from 'uuid';
import moment from 'moment';
import { createIntl, createIntlCache } from 'react-intl';
import jscookie from 'js-cookie';

const getLocaleFromCache = () => {
  return (
    jscookie.get('shipstation-v2-locale', { domain: 'shipstation.com' }) || 'en'
  );
};

const cache = createIntlCache();
let intl = createIntl(
  {
    locale: getLocaleFromCache(),
    // locale: 'en',
    // locale: 'en-XA',
    // locale: 'fr',
    defaultLocale: 'en'
  },
  cache
);

const subscriptions = {};
const localeData = {
  en: () => import('../locales/compiled/en.json'),
  es: () => import('../locales/compiled/es.json'),
  fr: () => import('../locales/compiled/fr.json'),
  de: () => import('../locales/compiled/de.json'),
  'fr-CA': () => import('../locales/compiled/fr.json'),
  'en-XA': () => import('../locales/compiled/en-XA.json'),
  'en-AU': () => import('../locales/compiled/en-AU.json'),
  'en-CA': () => import('../locales/compiled/en-CA.json'),
  'en-GB': () => import('../locales/compiled/en-GB.json')
};
let localeToMessages = {};

const getMomentLocale = locale => {
  if (locale === 'en') {
    return null;
  }

  if (locale === 'en-XA') {
    return 'x-pseudo';
  }

  return locale.toLowerCase();
};

const setLocale = newLocale => {
  loadMessages(newLocale).then(() => {
    const momentLocale = getMomentLocale(newLocale);
    if (!momentLocale) {
      moment.locale(newLocale);
    } else {
      import(`moment/locale/${momentLocale}.js`).then(() => {
        moment.locale(momentLocale);
      });
    }

    intl = createIntl(
      {
        locale: newLocale,
        defaultLocale: 'en',
        messages: localeToMessages[newLocale]
      },
      cache
    );
    Object.values(subscriptions).forEach(cb => cb());
  });
};
const getLocale = () => {
  return intl.locale;
};
const unsubscribe = id => delete subscriptions[id];
const subscribe = cb => {
  let id = uuid.v4();
  while (!!subscriptions[id]) {
    id = uuid.v4();
  }
  subscriptions[id] = cb;
  return () => unsubscribe(id);
};
const loadMessages = locale => {
  return new Promise((resolve, reject) => {
    if (localeToMessages[locale]) {
      resolve();
      return;
    }
    const localeLoader = localeData[locale];
    if (!localeLoader) {
      reject();
      return;
    }
    localeLoader().then(messages => {
      localeToMessages[locale] = messages.default;
      resolve();
    });
  });
};

const getTranslatedString = (definedMessage, values) => {
  return intl.formatMessage(definedMessage, {
    strong: chunks => `<strong>${chunks}</strong>`,
    b: chunks => `<b>${chunks}</b>`,
    i: chunks => `<i>${chunks}</i>`,
    pre: chunks => `<pre>${chunks}</pre>`,
    br: () => '<br />',
    sup: chunks => `<sup>${chunks}</sup>`,
    blockquote: chunks => `<blockquote>${chunks}</blockquote>`,
    ...values
  });
};

const getMessages = () => {
  return localeToMessages[intl.locale];
};

export default {
  setLocale,
  getLocale,
  subscribe,
  getTranslatedString,
  getMessages
};

// todo remove for demo purposes.
window.setLocale = setLocale;
