define(['core/BaseModel'], function(BaseModel) {
  const ScaleModel = BaseModel.extend({
    resetToDefaults: function() {
      this.set({
        retries: 10,
        readWait: 600,
        deviceTimeout: 2000,
        ssTimeout: 3000,
        weight1Byte: 4,
        weight2Byte: 5,
        weight1Factor: 1,
        weight2Factor: 1
      });
    },
    setMode2: function() {
      this.set('weight1Factor', 0.1);
      this.set('weight2Factor', 0.1);
    }
  });

  return ScaleModel;
});
