// these functions get extended. do not make them into arrow functions.
export default {
  isActive: function() {
    return this.get('Status') === 'Active';
  },

  isPending: function() {
    return this.get('Status') === 'Pending';
  },

  isInactive: function() {
    return this.get('Status') === 'Inactive';
  }
};
