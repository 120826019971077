import { Store } from 'fluxed';
import V3AccessService from 'client/services/v3-access-service';

class AppModeStore extends Store {
  state = {
    currentMode: ''
  };

  constructor() {
    super();
    this.previousMode = '';
  }

  enterMode(mode) {
    if (this.state.currentMode === mode) {
      return;
    }
    this.previousMode = this.state.currentMode;
    this.setState({ currentMode: mode });
  }

  exitMode(mode) {
    if (this.state.currentMode !== mode) {
      return;
    }

    const prevMode = this.previousMode;
    this.previousMode = this.state.currentMode;
    this.setState({ currentMode: prevMode });
  }

  isInMode(mode) {
    return this.state.currentMode === mode;
  }

  isPreviouslyInMode(mode) {
    return this.previousMode === mode;
  }

  isInScanToPrint() {
    return this.isInMode('scan-to-print');
  }

  isPreviouslyInScanToPrint() {
    return this.isPreviouslyInMode('scan-to-print');
  }

  enterScanToPrintMode() {
    this.enterMode('scan-to-print');
  }

  exitScanToPrintMode() {
    if (V3AccessService.isIframeMode()) {
      const message = {
        eventType: 'v3CloseScanToPrint'
      };
      window.parent.postMessage(JSON.stringify(message), '*');
      setTimeout(() => this.exitMode('scan-to-print'), 0);
      return;
    }
    this.exitMode('scan-to-print');
  }

  isInBarcodeScan() {
    return this.isInMode('barcode-scan');
  }

  enterBarcodeScanMode() {
    this.enterMode('barcode-scan');
  }

  exitBarcodeScanMode() {
    this.exitMode('barcode-scan');
  }
}

export default new AppModeStore();
