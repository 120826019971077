import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '070be3fa-70dd-436e-a8ae-7772e8252c17',
    defaultMessage: 'Test Connection'
  }),
  defineMessage({
    id: '17387a8d-002b-4060-b808-a29429feb6e5',
    defaultMessage: 'Connect'
  }),
  defineMessage({
    id: 'e7f5e7f4-6269-4d17-9851-ca55cf396534',
    defaultMessage: 'Cancel'
  }),
  defineMessage({
    id: '485e5957-f742-41bc-9603-f9ad8055b5e1',
    defaultMessage: 'Close'
  }),
  defineMessage({
    id: '8f855fd7-2ff2-4e2f-aa2e-f1dfb60d789a',
    defaultMessage: 'Back'
  }),
  defineMessage({
    id: 'a3a3c6a9-bc4f-4b70-b4ac-ea5bddb913b1',
    defaultMessage: 'Next'
  })
];

export default translations;
