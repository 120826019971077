import uuid from 'uuid';
import amplify from 'amplify';
import jsCookie from 'js-cookie';

import { AppID } from '../app/Config';

import AnalyticsService from 'client/services/analytics-service';
import InteractivityService from 'client/services/interactivity-service';
import Auth0Session from 'client/services/auth0-session';
import UserService from 'client/services/user-service';
import { PusherService } from 'client/services/pusher/pusher-service';
import defaultCookieConfig from 'public/defaultCookieConfig';
import moneyFormat from 'public/core/utilities/number/money-format';
import UserInterfaceService from 'client/services/user-interface-service';

define(['backbone', 'pubsub', 'Data', 'WeightAndMeasureModel'], function(
  Backbone,
  pubsub,
  Data,
  WeightAndMeasureModel
) {
  function warnAndLogout(reason) {
    jsCookie.set('ss.logout.msg', reason, {
      ...defaultCookieConfig
    });
    pubsub.trigger('user:logmeout');
  }

  const sessionHandleKey = AppID + 'sessionHandle';

  return _.extend({}, Backbone.Events, {
    sub: {
      'app:trackEvent': 'trackEvent',
      'app:message:forced_seller_logout': 'processForcedSellerLogout',
      'app:message:user_login_v2': 'processExternalLogIn',
      'app:message:client-user:session:auth0login': 'processAuth0Login',
      'app:message:client-user:session:auth0logout': 'processAuth0Logout',
      'orders:search': 'orderSearchTriggered',
      'shipments:search': 'shipmentSearchTriggered',
      'returns:classic:authorized': 'classicReturnAuthorized'
    },
    processForcedSellerLogout: function(message) {
      console.log('in processForcedSellerLogout...');
      if (!UserInterfaceService.appIsIFramedInV3()) {
        warnAndLogout(message.data.alert_notification);
      }
    },
    processExternalLogIn: function(message) {
      //User logins from another machine
      if (
        jsCookie.get('auth') === null ||
        jsCookie.get('auth') === 'undefined'
      ) {
        return; //already handled
      }

      if (UserService.isSysAdmin()) {
        return;
      }

      var sessionHandle = amplify.store(sessionHandleKey);
      if (!sessionHandle) {
        return;
      }
      const data = message.data;
      const messageUserName = data.userName ? data.userName.toLowerCase() : '';
      const appUserName = App.user.userName.toLowerCase();

      if (messageUserName === appUserName && data.handle !== sessionHandle) {
        warnAndLogout(
          'You have signed in to multiple locations.  Please login again to continue.'
        );
      }
    },
    processAuth0Login: function(message) {
      if (!Auth0Session.isAuthenticatedFast()) {
        return;
      }
      if (UserService.isSysAdmin()) {
        return;
      }
      const sessionHandle = amplify.store(sessionHandleKey);
      if (!sessionHandle) {
        amplify.store(sessionHandleKey, uuid.v4());
        pubsub.trigger('user:session:auth0login', {
          handle: sessionHandle,
          username: App.user.userName
        });
        return;
      }

      const data = message.data;
      if (!data) {
        return;
      }
      const messageUserName = data.username ? data.username.toLowerCase() : '';
      const appUserName = App.user.userName.toLowerCase();
      if (messageUserName === appUserName && data.handle !== sessionHandle) {
        warnAndLogout(
          'You have signed in to multiple locations.  Please login again to continue.'
        );
      }
    },
    processAuth0Logout: function(message) {
      if (!Auth0Session.isAuthenticatedFast()) {
        return;
      }

      if (!message || !message.data) {
        return;
      }
      const data = message.data;

      const isSelf = data.pusherSessionId === PusherService.getSessionId();
      if (isSelf) {
        return;
      }
      const loggedOutSession = data.sessionHandle;
      let forceLogout = false;

      if (loggedOutSession) {
        const currentSessionHandle = amplify.store(sessionHandleKey);

        //when we have a valid session handle we can compare it
        if (currentSessionHandle) {
          forceLogout = currentSessionHandle === loggedOutSession;
        } else {
          //however, logging out on another tab deletes the shared local-storage session handle
          //so if a handle is missing treat this like a logged out session (this is most likely)
          forceLogout = true;
        }
      }

      if (forceLogout) {
        console.debug('logout from user:session logout event');
        pubsub.trigger('user:logmeout', {
          source: message
        });
      }
    },
    initialize: function(options) {
      this.tracking = true;

      if (this.sub && !_.isEmpty(this.sub)) {
        for (var key in this.sub) {
          var method = this.sub[key];
          if (!_.isFunction(method)) this.sub[key] = this[this.sub[key]];
        }
        this.listenTo(pubsub, this.sub, this);
      }

      if (App.user && App.user.seller) {
        AnalyticsService.initialize(options.ssConfig);

        if (options.ssConfig.AppMode === 'labelapi') {
          window.addEventListener('hashchange', function() {
            pubsub.trigger('app:route', window.location.hash);
            AnalyticsService.trackPage(window.location.hash);
          });
        }

        InteractivityService.startListening();
      }
    },
    orderSearchTriggered: function(type, term, status) {
      this.trackEvent('Order Search', {
        SearchType: type,
        SearchTerm: term,
        OrderSearchStatuses: status
      });
    },
    shipmentSearchTriggered: function(term) {
      this.trackEvent('Shipment Search', {
        SearchTerm: term
      });
    },
    classicReturnAuthorized: function(
      trackingData,
      returnRequest,
      returnResponse
    ) {
      var confirmation = Data.Confirmations.findWhere({
        ConfirmationID: returnRequest.confirmation
      });
      var confirmationText = confirmation
        ? confirmation.get('Name')
        : 'Unspecified';

      var insurance = Data.ShippingInsurance.findWhere({
        ID: parseInt(returnRequest.insuranceProvider)
      });
      var insuranceText = insurance ? insurance.get('Name') : 'No insurance';

      var sizeText =
        returnRequest.length && returnRequest.width && returnRequest.height
          ? 'Custom'
          : 'Default';
      var weightModel = new WeightAndMeasureModel();
      weightModel.set({
        WeightUnitOfMeasureID: returnRequest.weightUnitOfMeasureID,
        Weight: returnRequest.weight
      });
      var weight = !isNaN(weightModel.get('WeightOunce'))
        ? weightModel.get('WeightOunce')
        : undefined;

      var trackPayload = {
        'RMA Value': returnResponse.rma,
        'RMA Type': returnResponse.isAutoRma ? 'Default' : 'Custom',
        'Return To': returnResponse.warehouse,
        'Weight In Ounces': weight,
        Carrier: returnResponse.carrier,
        Service: returnResponse.service,
        Package: returnResponse.packageType,
        Size: sizeText,
        Confirm: confirmationText,
        Insurance: insuranceText,
        'Insurance Amount': returnRequest.insuredValue
          ? moneyFormat(parseFloat(returnRequest.insuredValue), 2)
          : '',
        'Return Shipping Paid': moneyFormat(
          returnResponse.returnShippingPaid,
          2
        ),
        'Total Order Line Items': returnResponse.totalOrderLineItems,
        'Total Order Quantity': returnResponse.totalOrderItemQuantity,
        'Total Order Unit Price': returnResponse.totalOrderItemUnitPrice
          ? moneyFormat(parseFloat(returnResponse.totalOrderItemUnitPrice), 2)
          : '',
        'Email label to': returnRequest.emailTo ? returnRequest.emailTo : 'None'
      };

      if (!trackingData) {
        trackingData = {};
      }
      this.trackEvent(
        'Authorize Return (old)',
        'Authorized',
        _.extend(trackingData, trackPayload)
      );
    },
    //(type = 'User Activity', action, params)
    trackEvent: function(type, action, params) {
      const actionIsPresent = arguments.length < 3;
      const defaultActionToUserActivity = () => {
        params = action ? action : {};
        params.Action = type;
        action = 'User Activity';
      };

      if (!actionIsPresent) {
        params.Action = action;
        action = type;
      } else {
        defaultActionToUserActivity();
      }

      if (type && type.toLowerCase() === 'route') {
        action = 'Navigation';
        params.Action = 'Route';
      }

      if (this.tracking && type) {
        AnalyticsService.trackEvent(action, params);
      }
    }
  });
});
