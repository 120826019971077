import ApiHttpService from './api-http-service';

export const getProvidersWithBalances = () =>
  ApiHttpService.get('Providers?balances=true');

export const getAllProviders = () => ApiHttpService.get('providers');

export const removeProvider = (providerId, sellerProviderId) =>
  ApiHttpService.post('providers/removeregistration', {
    id: providerId,
    sellerProviderId
  });
