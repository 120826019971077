const ResourceManager = require('ResourceManager');
const Data = require('Data');

const WorkstationUpdatePubSubHandler = {
  event: 'app:message:connect:workstation:update',
  handler: msg => {
    ResourceManager.loadCache(() => {
      const workstation = Data.Workstations.get(msg.data.workstationId);
      if (workstation) {
        workstation.set('Description', msg.data.description);
      }
    });
  }
};

export default WorkstationUpdatePubSubHandler;
