const PoundsAndOuncesValidationMethod = {
  name: 'lbsandoz',
  errorMessage: 'Please specify a weight',
  validateValue: function(value, element, param) {
    const target = $(this.currentForm).find(param);
    if (target.hasClass('displayNone')) return true;
    if (this.settings.onfocusout) {
      target
        .unbind('.validate-lbsandoz')
        .bind('blur.validate-lbsandoz', function() {
          $(element).valid();
        });
    }
    const first = Number.parseFloat(value && value.trim()) || 0;
    const last = Number.parseFloat(target.val() && target.val().trim()) || 0;
    return first + last > 0;
  }
};

export default PoundsAndOuncesValidationMethod;
