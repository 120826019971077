import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: 'bcf7194a-068d-4584-990c-a20fe0398300',
    defaultMessage: 'Cancel'
  }),
  defineMessage({
    id: '2cee69dd-3fd1-40e1-8aca-48766eaf03f6',
    defaultMessage: 'Save'
  })
];

export default translations;
