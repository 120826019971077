import Amplify from 'amplify';
import Enums from 'public/core/Enums';
import { ApiUrl } from '../app/Config';
import FeatureAvailabilityService from 'client/services/feature-availability-service';
import { defineMessage } from 'react-intl';
import LocaleService from 'client/i18n/locale-service';

const jsTranslations = {
  carrier: defineMessage({
    id: 'ShippingInsurance.options.carrier',
    defaultMessage: 'Carrier'
  }),
  shipsurance: defineMessage({
    id: 'ShippingInsurance.options.shipsurance',
    defaultMessage: 'Shipsurance'
  }),
  otherExternal: defineMessage({
    id: 'ShippingInsurance.options.otherExternal',
    defaultMessage: 'Other/External'
  }),
  return: defineMessage({
    id: 'intlNonDelivery.options.return',
    defaultMessage: 'Return'
  }),
  treatAsAbandoned: defineMessage({
    id: 'intlNonDelivery.options.treatAsAbandoned',
    defaultMessage: 'Treat as Abandoned'
  }),
  documents: defineMessage({
    id: 'customsContent.options.documents',
    defaultMessage: 'Documents'
  }),
  gift: defineMessage({
    id: 'customsContent.options.gift',
    defaultMessage: 'Gift'
  }),
  merchandise: defineMessage({
    id: 'customsContent.options.merchandise',
    defaultMessage: 'Merchandise'
  }),
  returnedGoods: defineMessage({
    id: 'customsContent.options.returnedGoods',
    defaultMessage: 'Returned Goods'
  }),
  sample: defineMessage({
    id: 'customsContent.options.sample',
    defaultMessage: 'Sample'
  })
};

/*
 * This class represents the singleton data store across modules
 * */
var Backbone = require('backbone');

// it is important that the ResourceManager load is complete before we use the data below
var data = (module.exports = {
  init: function(getDataFn) {
    var me = this;
    this.EmailTemplates.comparator = 'Name';

    this.Stores.reset(getDataFn('Stores') || []);
    this.AllMarketplaces.reset(getDataFn('AllMarketplaces') || []);
    this.Marketplaces.reset(getDataFn('Marketplaces') || []);
    this.OrderTags.reset(getDataFn('Tags') || []);
    this.OrderFilters.reset(getDataFn('OrderFilters') || []);
    this.Providers.reset(getDataFn('ShippingProviders') || []);
    this.AutoProvisionedOnlyProviders =
      getDataFn('AutoProvisionedOnlyProviders') || [];
    this.Services.reset(getDataFn('Services') || []);
    this.FillProviders.reset(getDataFn('FillProviders') || []);
    this.SellerFillProviders.reset(getDataFn('SellerFillProviders') || []);
    this.FillServices.reset(getDataFn('FillServices') || []);
    this.PackageTypes.reset(getDataFn('Packages') || []);
    this.PackageClasses.reset(getDataFn('PackageClasses') || []);
    this.CarrierPackageTypes.reset(getDataFn('CarrierPackages') || []);
    this.CustomPackages.reset(getDataFn('CustomPackages') || []);
    this.ShippingPresets.reset(getDataFn('Presets') || []);
    this.States.reset(getDataFn('States') || []);
    this.Countries.reset(getDataFn('Countries') || []);
    this.Warehouses.reset(getDataFn('Warehouses') || []);
    this.InventoryWarehouses.reset(getDataFn('InventoryWarehouses') || []);
    this.Confirmations.reset(getDataFn('Confirmation') || []);
    this.Users.reset(getDataFn('Users') || []);
    this.EmailTemplates.reset(getDataFn('EmailTemplates') || []);
    this.PackingSlips.reset(getDataFn('PackingSlips') || []);
    this.TrackingPages.reset(getDataFn('TrackingPages') || []);
    this.TimeZones.reset(getDataFn('TimeZones') || []);
    this.OrderStatuses.reset(getDataFn('OrderStatuses') || []);
    this.OrderStatuses.add({
      OrderStatusID: 6,
      Name: 'Processing...',
      Hidden: true
    });
    this.AllCarriers.reset(getDataFn('AllCarriers') || []);
    this.Carriers.reset(getDataFn('Carriers') || []);
    this.DocTemplates.reset(getDataFn('Docs') || []);
    this.GCPPrinters.reset(getDataFn('GCPPrinters') || []);
    this.GCPInfo.set(getDataFn('GCPInfo'));
    this.TimeZoneInfo = getDataFn('TimeZoneInfo') || {};
    this.UtcOffsetMinutes = getDataFn('UtcOffsetMinutes') || 0;
    var excl = getDataFn('Exclusions');
    this.Exclusions.Packages = new Backbone.Collection(
      (excl && excl.PackageTypes) || []
    );
    this.Exclusions.Services = new Backbone.Collection(
      (excl && excl.Services) || []
    );
    this.OrderWorkflows.reset(getDataFn('OrderWorkflows'));
    this.Settings = getDataFn('Settings') || {};
    this.UserSettings = getDataFn('UserSettings') || {};
    this.ProductCategories.reset(getDataFn('ProductCategories') || []);
    this.ProductProfiles.reset(getDataFn('ProductProfiles') || []);
    this.ShipStationConnectVersion = getDataFn('ShipStationConnectVersion');
    this.ShippingInsurance = new Backbone.Collection([
      {
        ID: 1,
        Name: LocaleService.getTranslatedString(jsTranslations.shipsurance)
      },
      {
        ID: 2,
        Name: LocaleService.getTranslatedString(jsTranslations.carrier)
      },
      {
        ID: 3,
        Name: LocaleService.getTranslatedString(jsTranslations.otherExternal)
      }
    ]);

    if (!FeatureAvailabilityService.canUseShipsurance()) {
      this.ShippingInsurance.remove(
        this.ShippingInsurance.findWhere({
          ID: 1
        })
      );
    }

    this.DeliveryStatus = new Backbone.Collection([
      {
        ID: 1,
        Name: 'Unknown'
      },
      {
        ID: 2,
        Name: 'In transit'
      },
      {
        ID: 3,
        Name: 'Delivered'
      },
      {
        ID: 4,
        Name: 'Undeliverable'
      }
    ]);
    this.OrderExtractList.reset(getDataFn('OrderExtractList'));
    this.ShipmentExtractList.reset(getDataFn('ShipmentExtractList'));
    this.CustomsContent = new Backbone.Collection([
      {
        ID: 'Documents',
        Name: LocaleService.getTranslatedString(jsTranslations.documents)
      },
      {
        ID: 'Gift',
        Name: LocaleService.getTranslatedString(jsTranslations.gift)
      },
      {
        ID: 'Merchandise',
        Name: LocaleService.getTranslatedString(jsTranslations.merchandise)
      },
      {
        ID: 'Returned Goods',
        Name: LocaleService.getTranslatedString(jsTranslations.returnedGoods)
      },
      {
        ID: 'Sample',
        Name: LocaleService.getTranslatedString(jsTranslations.sample)
      }
    ]);
    this.InternationalNonDelivery = new Backbone.Collection([
      {
        ID: 'Return',
        Name: LocaleService.getTranslatedString(jsTranslations.return)
      },
      {
        ID: 'Abandon',
        Name: LocaleService.getTranslatedString(jsTranslations.treatAsAbandoned)
      }
    ]);

    this.ServiceClasses = new Backbone.Collection([
      {
        ServiceClassID: 1,
        Name: '3 to 5 Day'
      },
      {
        ServiceClassID: 2,
        Name: '1 Day'
      },
      {
        ServiceClassID: 3,
        Name: '1 Day'
      },
      {
        ServiceClassID: 4,
        Name: '1 Day'
      },
      {
        ServiceClassID: 5,
        Name: '2 Day'
      },
      {
        ServiceClassID: 6,
        Name: '2 Day'
      },
      {
        ServiceClassID: 7,
        Name: '3 Day'
      }
    ]);

    this.ServiceGrades = new Backbone.Collection([
      {
        ServiceGradeID: 4,
        Name: 'Standard'
      },
      {
        ServiceGradeID: 2,
        Name: 'Expedited'
      },
      {
        ServiceGradeID: 3,
        Name: 'Overnight'
      },
      {
        ServiceGradeID: 1,
        Name: 'Economy'
      }
    ]);

    this.ProductTypes = new ProductTypesCol();

    this.createOrigins();

    this.Warehouses.on('change', this.createOrigins);
    this.SellerFillProviders.on('change', this.createOrigins);

    this.FeatureMask = getDataFn('FeatureMask') || 0;

    this.Workstations.reset(getDataFn('Workstations') || []);

    this.ABTestCampaigns.reset(getDataFn('ABTestCampaigns') || []);
    this.LightSellerProviders.reset(getDataFn('LightSellerProviders') || []);
    this.LightWallets.reset(getDataFn('LightWallets') || []);
    this.LightSellerIntegrations.reset(
      getDataFn('LightSellerIntegrations') || []
    );

    this.ReturnReasons.comparator = 'Name';
    this.ReturnReasons.reset(getDataFn('ReturnReasons'));

    this.InventoryWarehouses.updateLinkedWarehouses();

    // note: this is here because both settings/providers and settings/providerlist use it, extracting here for common usage
    this.Settings.StampsEndiciaNoConnectModalContent = function(pid) {
      return {
        title: 'Unable to Add ' + (pid == 2 ? 'Stamps.com' : 'Endicia'),
        content:
          'It looks like you have already registered for a free USPS PC Postage account. Unfortunately, we can only offer one PC postage account for free. If you would like to use both Endicia and Stamps.com for an additional fee, please open a support case or contact your account manager.',
        height: '24rem',
        buttons: 'close'
      };
    };
    var me = this;
    // extracting this here for common usage between settings/providers and settings/providerlist
    this.Settings.ShouldPreventStampsAndEndiciaConnection = function(
      connected,
      pid
    ) {
      //Can't add Stamps and Endicia unless Seller Setting is present
      if (
        !me.Settings.AllowFreeEndiciaAndStamps ||
        me.Settings.AllowFreeEndiciaAndStamps != 'true'
      ) {
        var hasStamps = _.indexOf(connected, 2) > -1;
        var hasEndicia = _.indexOf(connected, 5) > -1;
        if ((pid == 2 && hasEndicia) || (pid == 5 && hasStamps)) {
          return true;
        }
        return false;
      }
    };
    this.Settings.GetProvidersToShow = function(providers, success) {
      me.stampsSASAccts = 0;
      me.stampsNonSASAccts = 0;
      me.endiciaAccts = 0;

      var sellerProviders = new ProvidersCol();

      var eachFunction = function(p) {
        if (!p.get && !p.set) {
          p.get = function(key) {
            return p[key];
          };
          p.set = function(key, value) {
            p[key] = value;
          };
        } else {
          p.set('FillMethod', 1);
        }
        var sUp = me.Providers.findWhere({
          ProviderID: p.get('ProviderID')
        });
        p.set('IsSignedUp', sUp !== undefined);

        var isHouseAccount = sellerProviders.findWhere({
          ProviderID: p.ProviderID,
          UseHouseCredentials: true
        })
          ? true
          : false;
        if (
          !isHouseAccount &&
          p.get('IsSignedUp') &&
          p.get('ProviderID') === Enums.Provider.StampsWhiteLabel
        ) {
          me.stampsSASAccts++;
          p.set('Show', true);
        } else if (
          p.get('IsSignedUp') &&
          p.get('ProviderID') === Enums.Provider.Stamps_com &&
          p.get('FillMethod') === 1
        ) {
          // only show express one if connected stamps account is SAS
          if (
            (p.get('Param4') || '').toLowerCase().indexOf('expressone') == 0
          ) {
            me.stampsSASAccts++;
          } else if (!isHouseAccount) {
            me.stampsNonSASAccts++;
          }
          p.set('Show', true);
        } else if (
          p.get('ProviderID') === Enums.Provider.Express1 &&
          p.get('FillMethod') === 1
        ) {
          // do nothing, already handled below
        } else {
          if (
            p.get('ProviderID') === Enums.Provider.Endicia &&
            p.get('FillMethod') === 1
          ) {
            me.endiciaAccts++;
          }
          p.set('Show', true);
        }
      };

      sellerProviders.fetch({
        data: { balances: true },
        success: function() {
          var eachFn = _.bind(eachFunction, sellerProviders);

          if (providers.each) {
            providers.each(eachFn);
          } else {
            _.each(providers, eachFn);
          }

          me.expressOne = _.findWhere(providers, {
            ProviderID: 1,
            FillMethod: 1
          });

          me.stampsSASConnected = me.stampsSASAccts > 0;
          if (me.expressOne) {
            me.expressOneShow =
              me.stampsSASAccts >= 0 && me.stampsNonSASAccts === 0;
            me.expressOne.Show =
              me.expressOneShow != null ? me.expressOneShow : true;
          }
          me.stampsAccts = me.stampsSASAccts + me.stampsNonSASAccts;

          var filterFunction = function(p) {
            return p.get('Show');
          };
          if (providers.filter) {
            providers = providers.filter(filterFunction);
          } else {
            providers = _.filter(providers, filterFunction);
          }

          success(providers);
        }
      });
    };
    Amplify.publish('dataready');
  },
  getServicesByCarrier: function(intl) {
    var groups = {};
    var me = this;
    this.Carriers.each(function(c) {
      var criteria = {
        CarrierID: c.id,
        Hidden: false
      };
      if (intl != undefined) {
        criteria.International = intl;
      }

      var allservices = me.Services.where(criteria);
      if (!allservices.length) return;

      var grouped = _.groupBy(allservices, function(s) {
        return s.get('CarrierID') + s.get('ApiCode');
      });

      var services = [];
      _.each(grouped, function(g) {
        var m = _.min(g, function(x) {
          return x.get('ProviderId');
        });
        services.push(m);
      });

      var group = [];
      _.each(services, function(s) {
        group.push({
          ServiceID: s.id,
          ProviderID: s.get('ProviderId'),
          ConsolidationService: s.get('ConsolidationService'),
          Name: s.get('Name') + (s.get('ProviderId') == 1 ? '(Exp. 1)' : '')
        });
      });

      //If the account has an Express 1 account
      if (
        c.id == 1 &&
        intl !== false &&
        me.Providers.findWhere({
          ProviderID: 1
        })
      ) {
        var srv = me.Services.findWhere({
          ProviderId: 2,
          ShippingServiceID: 16
        });
        if (srv) {
          group.push({
            ServiceID: srv.id,
            ProviderID: srv.get('ProviderId'),
            ConsolidationService: srv.get('ConsolidationService'),
            Name: srv.get('Name') + ' (Flat Rate)'
          });
        } else {
          srv = me.Services.findWhere({
            ProviderId: 5,
            ShippingServiceID: 24
          });
          if (srv) {
            group.push({
              ServiceID: srv.id,
              ProviderID: srv.get('ProviderId'),
              ConsolidationService: srv.get('ConsolidationService'),
              Name: srv.get('Name') + ' (Flat Rate)'
            });
          }
        }
        group = _.sortBy(group, 'Name');
      }

      if (group.length) {
        groups[c.get('Name')] = group;
      }
    });
    return groups;
  },
  getServicesByProvider: function(intl) {
    var groups = {};
    var me = this;
    this.Providers.each(function(p) {
      var criteria = {
        ProviderId: p.id,
        Hidden: false
      };
      if (intl != undefined) {
        criteria.International = intl;
      }

      var allservices = me.Services.where(criteria);
      if (!allservices.length) return;

      var group = [];
      _.each(allservices, function(s) {
        group.push({
          ServiceID: s.id,
          ProviderID: s.get('ProviderId'),
          ConsolidationService: s.get('ConsolidationService'),
          Name: s.get('Name')
        });
      });
      if (group.length) {
        groups[p.get('Name')] = group;
      }
    });
    return groups;
  },
  getServicesByGrade: function(intl) {
    var groups = {};
    var me = this;
    this.ServiceGrades.each(function(g) {
      var criteria = {
        ShippingServiceGradeID: g.get('ServiceGradeID'),
        Hidden: false
      };
      if (intl != undefined) {
        criteria.International = intl;
      }

      var allservices = me.Services.where(criteria);
      if (!allservices.length) return;

      var grouped = _.groupBy(allservices, function(s) {
        return s.get('ApiCode');
      });

      var services = [];
      _.each(grouped, function(g) {
        var m = _.min(g, function(x) {
          return x.get('ProviderId');
        });
        services.push(m);
      });

      var group = [];
      _.each(services, function(s) {
        group.push({
          ServiceID: s.id,
          ProviderID: s.get('ProviderId'),
          ConsolidationService: s.get('ConsolidationService'),
          Name: s.get('Name') + (s.get('ProviderId') == 1 ? ' (Exp. 1)' : '')
        });
      });

      if (group.length) {
        groups[g.get('Name')] = group;
      }
    });
    return groups;
  },
  getServicesByTime: function(intl) {
    var groups = {};
    var me = this;
    var names = this.ServiceClasses.groupBy(function(s) {
      return s.get('Name');
    });

    _.each(names, function(v, k) {
      //For each name
      var group = [];
      _.each(v, function(g) {
        //foreach sub-group

        var criteria = {
          ShippingServiceClassID: g.get('ServiceClassID'),
          Hidden: false
        };
        if (intl != undefined) {
          criteria.International = intl;
        }

        //Find services matching the service class
        var allservices = me.Services.where(criteria);
        if (!allservices.length) return;

        //Group them to find the preferred provider
        var grouped = _.groupBy(allservices, function(s) {
          return s.get('ApiCode');
        });

        var services = [];
        _.each(grouped, function(g) {
          var m = _.min(g, function(x) {
            return x.get('ProviderId');
          });
          services.push(m);
        });

        _.each(services, function(s) {
          group.push({
            ServiceID: s.id,
            Name: s.get('Name') + (s.get('ProviderId') == 1 ? ' (Exp. 1)' : '')
          });
        });
      });

      if (group.length) {
        groups[k] = group;
      }
    });

    return groups;
  },
  getServices: function(intl, by, providerFilter) {
    var services;
    switch (by) {
      case 'Grade':
        services = this.getServicesByGrade(intl);
        break;
      case 'Speed':
        services = this.getServicesByTime(intl);
        break;
      case 'Provider':
        services = this.getServicesByProvider(intl);
        break;
      default:
        services = this.getServicesByCarrier(intl);
    }

    if (providerFilter) {
      var filteredServices = {};
      _.each(services, function(s) {
        var svcs = _.filter(s, providerFilter);
        if (svcs.length) {
          var prov = data.Providers.where({
            ProviderID: svcs[0].ProviderID
          });
          filteredServices[prov[0].get('Name')] = svcs;
        }
      });

      return filteredServices;
    }

    return services;
  },
  saveSettings: function(settings, success, error) {
    var changedObj = App.user.getChangedProperties(settings, this.Settings);
    if (changedObj == null) {
      if (success) {
        success();
      }
      return;
    }
    var me = this;
    if (settings.LabelOutput) {
      settings.PSInline = settings.LabelOutput.indexOf('-i') > 0;
    }
    if (settings.PSInline) {
      settings.PSFormat = settings.LabelOutput.replace('-i', '');
    }

    return $.post(ApiUrl + 'account/SaveSettings', changedObj, function(data) {
      if (data.success) {
        _.each(
          data.settings,
          function(i, e) {
            me.Settings[e] = data.settings[e];
          },
          'json'
        );
        if (success) {
          success(data);
        }
      } else {
        if (error) {
          error(data.message);
        }
      }
    });
  },
  get: function(key) {
    return this[key];
  },
  appendToCollection: function(collectionName, model) {
    // The caller of this to append a new preset only knows them as Presets and nothing about the collection name it is stored in ShippingPreset, so mapping here
    if (collectionName === 'Presets') {
      collectionName = 'ShippingPresets';
    }

    var col = data[collectionName];
    if (col) {
      col.add(model);
    }
  },
  createOrigins: function() {
    this.Origins = {};
    if (this.SellerFillProviders.length && this.FillProviders.length) {
      const fillProvidersById = this.FillProviders.models.reduce(
        (fps, fp) => ({
          ...fps,
          [fp.get('FillProviderID')]: fp
        }),
        {}
      );

      var sfpList = this.SellerFillProviders.map(sfp => {
        const fp = fillProvidersById[sfp.get('FillProviderID')];
        if (!fp) {
          return;
        }
        return {
          // id: -sfp.id,
          id: -sfp.get('SellerFillProviderID'),
          fillProviderId: sfp.get('FillProviderID'),
          sellerFillProviderId: sfp.get('SellerFillProviderID'),
          name: sfp.get('Name'),
          apicode: fp.get('ApiCode')
        };
      }).filter(origin => origin && origin.apicode !== 'external');

      if (sfpList.length !== 0) this.Origins['3rd Party'] = sfpList;
    }
    if (this.Warehouses.length) {
      this.Origins['Warehouses'] = this.Warehouses.map(function(w) {
        return {
          id: w.id,
          name: w.get('Name'),
          originCountryCode: w.get('OriginCountryCode')
        };
      });
    }
  },
  hasShipProviders: function() {
    return this.Providers && this.Providers.length > 0;
  },
  hasFillProviders: function() {
    return this.FillProviders && this.FillProviders.length > 0;
  },
  /// Determines whether the seller has multiple providers for any carrier
  hasMultipleCarrierProviders: function() {
    var grouped = this.Providers.groupBy(function(p) {
      return p.get('CarrierID');
    });

    return Object.values(grouped).some(function(g) {
      return g.length > 1;
    });
  },
  getUnitOfMeasureForDimensions: function() {
    return (this.Settings['UnitOfMeasure:Dimension'] || 'inch').toLowerCase();
  },
  getUnitOfMeasureForWeight: function() {
    return (this.Settings['UnitOfMeasure:Weight'] || 'inch').toLowerCase();
  },
  isAdvancedProducts: function() {
    return (
      this.Settings.UseAdvancedProductDisplay === true ||
      this.Settings.UseAdvancedProductDisplay === 'true'
    );
  },
  getProviderPostagePurchaseSuggestion: function(
    sellerProvider,
    success,
    fail
  ) {
    if (
      !(
        sellerProvider.get('ProviderID') === Enums.Provider.StampsWhiteLabel ||
        sellerProvider.get('ProviderID') === Enums.Provider.Stamps_com
      )
    ) {
      //only supporting Stamps postage selection at this point
      console.error(
        'Only Shipstation Carrier Services and Stamps postage suggestion are supported at this time'
      );
      return 0;
    }
    if (!success)
      console.error(
        "Calling the Data.getProviderPostagePurchaseSuggestion method without a success function won't give you accurate results!"
      );
    $.get(
      ApiUrl +
        'Providers/SuggestedPurchaseAmount/?sellerProviderId=' +
        sellerProvider.get('SellerProviderID') +
        '&neededForPostage=1'
    )
      .success(function(resp) {
        sellerProvider.set('PurchasePostageSuggestion', resp);
        sellerProvider.set(
          'PurchasePostageSuggestionCreateDate',
          new moment().format('MM/DD/YYYY')
        );
        if (success) success(resp);
      })
      .fail(function(err) {
        if (fail) fail(err);
      });
  }
});

// IMPORTANT: these are required down below the definition of data
// because some of these modules have circular dependencies on data
// due to commonjs module initialization order we have to require things
// after initializing and exporting the Data object until we can unwind
// the circular depdencies
// see: https://stackoverflow.com/questions/30378226/circular-imports-with-webpack-returning-empty-object
var StoresCol = require('collections/Stores');
var OrderTagCol = require('collections/OrderTags');
var OrderFiltersCol = require('collections/OrderFilters');
var ProvidersCol = require('collections/Providers');
var FillProvidersCol = require('collections/FillProviders');
var ServicesCol = require('collections/Services');
var FillServicesCol = require('collections/FillServices');
var PackageTypesCol = require('collections/PackageTypes');
var CarrierPackageTypesCol = require('collections/CarrierPackageTypes');
var ShippingPresetsCol = require('collections/ShippingPresets');
var WarehousesCol = require('collections/Warehouses');
var ConfirmationsCol = require('collections/Confirmations');
var UsersCol = require('collections/Users');
var OrderStatusesCol = require('collections/OrderStatuses');
var DocTemplatesCol = require('collections/DocTemplates');
var CarriersCol = require('collections/Carriers');
var MarketplacesCol = require('collections/Marketplaces');
var WorkflowCol = require('collections/OrderWorkflows');
var ProductCategoryCol = require('collections/ProductCategories');
var PackingSlipsCol = require('collections/PackingSlips');
var TrackPagesCol = require('collections/TrackingPageTemplates');
var TimeZonesCol = require('collections/TimeZones');
var ProductTypesCol = require('collections/ProductTypes');
var ProductProfilesCol = require('collections/ProductProfiles');
var GCPInfoModel = require('models/GCPInfo');
var WorkstationsCol = require('collections/Workstations');
var InventoryWarehousesCol = require('collections/InventoryWarehouses');
var ABTestCampaignsCol = require('collections/ABTestCampaigns');
var LightSellerProvidersCol = require('collections/LightSellerProviders');
var LightWalletsCol = require('collections/LightWallets');
var SellerFillProvidersCol = require('collections/SellerFillProviders');

data.Stores = new StoresCol();
data.OrderTags = new OrderTagCol();
data.OrderFilters = new OrderFiltersCol();
data.Providers = new ProvidersCol();
data.AutoProvisionedOnlyProviders = [];
data.Services = new ServicesCol();
data.FillProviders = new FillProvidersCol();
data.SellerFillProviders = new SellerFillProvidersCol();
data.FillServices = new FillServicesCol();
data.PackageTypes = new PackageTypesCol();
data.CarrierPackageTypes = new CarrierPackageTypesCol();
data.ShippingPresets = new ShippingPresetsCol();
data.States = new Backbone.Collection();
data.Countries = new Backbone.Collection();
data.Warehouses = new WarehousesCol();
data.InventoryWarehouses = new InventoryWarehousesCol();
data.Confirmations = new ConfirmationsCol();
data.AllMarketplaces = new MarketplacesCol();
data.Marketplaces = new MarketplacesCol();
data.Users = new UsersCol();
data.EmailTemplates = new Backbone.Collection();
data.PackingSlips = new PackingSlipsCol();
data.TrackingPages = new TrackPagesCol();
data.TimeZones = new TimeZonesCol();
data.CustomPackages = new PackageTypesCol();
data.OrderStatuses = new OrderStatusesCol();
data.AllCarriers = new CarriersCol();
data.Carriers = new CarriersCol();
data.DocTemplates = new DocTemplatesCol();
data.GCPPrinters = new Backbone.Collection();
data.GCPInfo = new GCPInfoModel();
data.TimeZoneInfo = {};
data.ShippingInsurance = [];
data.DeliveryStatus = [];
data.OrderWorkflows = new WorkflowCol();
data.Settings = {};
data.UserSettings = {};
data.Origins = {};
data.Exclusions = {};
data.OrderExtractList = new Backbone.Collection();
data.ShipmentExtractList = new Backbone.Collection();
data.PackageClasses = new Backbone.Collection();
data.ProductCategories = new ProductCategoryCol();
data.ProductProfiles = new ProductProfilesCol();
data.CustomsContent = [];
data.InternationalNonDelivery = [];
data.FeatureMask = 0;
data.Workstations = new WorkstationsCol();
data.ABTestCampaigns = new ABTestCampaignsCol();
data.LightSellerProviders = new LightSellerProvidersCol();
data.LightSellerIntegrations = new Backbone.Collection();
data.LightWallets = new LightWalletsCol();
data.ReturnReasons = new Backbone.Collection();
