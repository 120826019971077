import { ApiUrl } from '../app/Config';

define(['models/RollbackModel'], function(RollbackModel) {
  return RollbackModel.extend({
    urlRoot: ApiUrl + 'ProductProfile',
    idAttribute: 'ProductProfileID',
    initialize: function() {
      this._super('initialize', arguments);
    },
    defaults: _.extend({}, RollbackModel.prototype.defaults),
    toJSON: function() {
      var obj = this._super('toJSON', arguments);
      if (obj.WeightUnitOfMeasureID === 2 && obj.Weight === 0) {
        obj.Weight = null;
      }

      if (obj.WeightUnitOfMeasureID === 1 && obj.Weight === 0) {
        obj.Weight = null;
      }
      return obj;
    }
  });
});
