/*
 * This class controls the instantiation, caching and DOM attachment of application modules
 */
import V3AccessService from 'client/services/v3-access-service';

const InvalidSSLazyLoadedModules = ['createaccount', 'portal'];
const InvalidSELazyLoadedModules = [
  'admin',
  'AdminCore',
  'customers',
  'dashboard',
  'inventory',
  'onboard',
  'orders',
  'products',
  'reports',
  'shipments',
  'stamps',
  'track',
  'yahoo'
];
const InvalidLazyLoadedModules =
  window.SS_GLOBALS.appMode === 'labelapi'
    ? InvalidSELazyLoadedModules
    : InvalidSSLazyLoadedModules;

const errorCommandInfo = {
  moduleName: 'error',
  section: null,
  options: { module: 'error' }
};

const lazyLoadedPages = {
  admin: cb => {
    import('views/Admin/main').then(cb);
  },
  AdminCore: cb => {
    import('views/Admin/Core').then(cb);
  },
  changepassword: cb => {
    import('views/Login/changePassword').then(cb);
  },
  createaccount: cb => {
    import('views/Login/CreateAccount').then(cb);
  },
  customers: cb => {
    import('views/Customers/main').then(cb);
  },
  dashboard: cb => {
    import('views/Dashboard/main').then(cb);
  },
  error: cb => {
    import('views/Error/main').then(cb);
  },
  forgot: cb => {
    import('views/Login/forgotPassword').then(cb);
  },
  inventory: cb => {
    import('views/Inventory/main').then(cb);
  },
  login: cb => {
    import('views/Login/main').then(cb);
  },
  marketplaces: cb => {
    import('views/Marketplaces/main').then(cb);
  },
  onboard: cb => {
    import('views/Onboard/main').then(cb);
  },
  orders: cb => {
    import('views/Orders/main').then(cb);
  },
  portal: cb => {
    import('label-api/views/Portal/main').then(cb);
  },
  products: cb => {
    import('views/Products/main').then(cb);
  },
  reports: cb => {
    import('views/Reports/main').then(cb);
  },
  settings: cb => {
    import('views/Settings/main').then(cb);
  },
  shipments: cb => {
    import('views/Shipments/main').then(cb);
  },
  stamps: cb => {
    import('views/Stamps/main').then(cb);
  },
  topnav: cb => {
    // this is different for SE and SS
    import('topnav').then(cb);
  },
  track: cb => {
    import('views/Track/main').then(cb);
  },
  unauthorized: cb => {
    import('views/Unauthorized/main').then(cb);
  },
  verify: cb => {
    import('views/Login/Verify').then(cb);
  },
  verifyemail: cb => {
    import('views/Login/ChangeEmailVerify').then(cb);
  },
  yahoo: cb => {
    import('views/Yahoo/main').then(cb);
  }
};

let mostRecentModuleCalled = '';

define(['jquery', 'core/libs/extended-marionette'], function($, Marionette) {
  return Marionette.Controller.extend({
    activeModules: {},
    workspaceRegion: undefined, // <-- the DOM element in which all modules will get attached to they will stack and only one will show at a time
    modalRegion: undefined, // <-- the DOM element in which all modals will get attached to, likewise they will stack and only one will show at a time
    initialize: function() {
      // listen for start and stop module events
      App.vent.on('Controller.StartModule', this.startModule, this);
      App.vent.on('Controller.StopModule', this.stopModule, this);
    },
    // load and show a module's view
    startModule: function(commandInfo) {
      //Would this be a good place to check authentication and redirect to login?

      if (InvalidLazyLoadedModules.includes(commandInfo.moduleName)) {
        commandInfo = errorCommandInfo;
      }

      var moduleName = commandInfo.moduleName; // name of module to start
      var options = commandInfo.options; // control + view options
      var startComplete = commandInfo.startComplete; // callback made on completion of module start

      // if we have a beforeStartModule implemented then call it
      if (typeof this.beforeStartModule === 'function') {
        this.beforeStartModule(options);
      }
      const lazyLoader = lazyLoadedPages[moduleName];
      if (!lazyLoader) {
        return;
      }
      if (moduleName !== 'topnav') {
        mostRecentModuleCalled = moduleName;
      }

      lazyLoader(lazyLoadedModule => {
        // if the route is changed rapidly (like it is in v3) only load the most recently called module.
        if (moduleName !== 'topnav' && moduleName !== mostRecentModuleCalled) {
          return;
        }
        // fire blur event on all other active modules also passing in the module name gaining focus
        _.each(this.activeModules, function(view, activeViewName) {
          // prevent calling of moduleBlur on an active module if we are navigating within it
          if (activeViewName !== moduleName) {
            view.trigger('moduleBlur', moduleName);
          }
        });

        // get the region that the module's view will attach to
        const region = this.getRegion(moduleName, options);
        // get the module's view instance
        const viewInstance = this.showModuleView(
          moduleName,
          lazyLoadedModule.default,
          region,
          options
        );

        const isLabelApi = region && region.el && region.el === '#portal';
        if (viewInstance.title && !isLabelApi) {
          if (typeof viewInstance.title === 'function') {
            document.title = viewInstance.title(options) + ' :: ShipStation';
          } else {
            document.title = viewInstance.title + ' :: ShipStation';
          }
        }

        // fire focus
        viewInstance.trigger('moduleFocus', options);

        // call the afterStart if provided by the view
        if (typeof viewInstance.afterStart === 'function') {
          viewInstance.afterStart(options);
        }

        // call the start complete callback if provided by the caller
        if (typeof startComplete === 'function') {
          startComplete(commandInfo);
        }

        // announce the start
        App.vent.trigger('AppModuleStarted', moduleName);
      });
    },
    // the region the module's view is destined for
    getRegion: function(moduleName, options) {
      var region;
      // if modal option is set to true render in the modal region
      if (options && options.modal) {
        region = App[this.modalRegion];
        // @todo not sure what happens to any prior modal attached to this region, it may close it
        $(App[this.modalRegion].el).show();
      } else {
        // otherwise render in a module specific region
        region = App[moduleName];
        if (typeof region === 'undefined') {
          // no such region yet, so create one off of the workspace DOM element
          $(App[this.workspaceRegion].el).append(
            '<div id="' + moduleName + '" class="moduleContainer"></div>'
          );
          // make a region from it
          var regionConfig = {};
          regionConfig[moduleName] = '.moduleContainer#' + moduleName;
          App.addRegions(regionConfig);
          region = App[moduleName];
        }
        $(App[this.modalRegion].el).hide();
      }

      return region;
    },
    // render the module in its region
    showModuleView: function(moduleName, ViewClass, region, options = {}) {
      var viewInstance;

      // if the module exist use it, otherwise create it and cache a reference to it for later
      if (
        typeof this.activeModules[moduleName] === 'undefined' ||
        options.force
      ) {
        //dispose old module before recreating it...
        if (options && options.force && this.activeModules[moduleName]) {
          this.activeModules[moduleName].close();
          delete this.activeModules[moduleName];
        }
        viewInstance = this.activeModules[moduleName] = new ViewClass(options);
        // attach the module's view instance to its region
        region.show(viewInstance);
      } else {
        // use view instance we already have
        viewInstance = this.activeModules[moduleName];
      }

      if (App.user.isFirstMileAffiliate) $('#topnav').addClass('firstmile');

      const isV3User = V3AccessService.isIframeMode();
      if (
        !viewInstance.hideTopbar &&
        App.user.plan != 'sdcdesktopapp' &&
        !isV3User
      ) {
        $('#topnav').show();
      }
      if (isV3User) {
        $('#content').css('top', 0);
      }

      // hide all sibling (CF: except the one we are navigating to) regions in the same container
      $(region.el)
        .parent()
        .find('.moduleContainer:not(' + region.el + ')')
        .hide();

      // show the element of the specific region for the module being started
      // options.dontShowView currently set to true for stamps.com iframes.  hide() above not sufficient as show() still renders the topnav on initial load.
      if (options && !options.dontShowView) $(region.el).show();

      this.activeModule = moduleName;
      return viewInstance;
    },
    stopModule: function(eventData) {
      var moduleName = eventData.moduleName;
      this.activeModules[moduleName].close();
      delete this.activeModules[moduleName];
      // note that we're leaving the module's region intact, subsequent starts will reuse it
    }
  });
});
