import $ from 'jquery';
const pubsub = require('pubsub');

const windowUnloadHandler = async () => {
  $(window).on('unload', () => {
    // Oh! Woe will I be if this comes back to haunt me.
    pubsub.trigger('orders:persistchanges');
  });
};

export default windowUnloadHandler;
