import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Form from 'react-jsonschema-form';
import DataDrivenLogo from 'client/components/data-driven-logo';
import 'public/less/ipaas-data-driven-modal.less';

const CustomDescriptionField = ({ id, description }) => {
  return (
    <div key={id} id={id} dangerouslySetInnerHTML={{ __html: description }} />
  );
};

const fields = {
  DescriptionField: CustomDescriptionField
};

export default class ProviderRegistrationForm extends Component {
  static propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object,
    providerId: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    hideDataDrivenLogo: PropTypes.bool
  };

  static defaultProps = {
    hideDataDrivenLogo: false
  };

  static render = el => {
    if (!el) {
      return;
    }
    ReactDOM.render(<ProviderRegistrationForm />, el);
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  submit() {
    this.form.submit();
  }

  render() {
    return (
      <div>
        {!this.props.hideDataDrivenLogo && (
          <DataDrivenLogo
            providerId={this.props.providerId}
            className={'ipaas-modal-logo'}
            logoType="logo"
          />
        )}
        <Form
          schema={this.props.schema}
          fields={fields}
          uiSchema={this.props.uiSchema || {}}
          onSubmit={this.props.onSubmit}
          showErrorList={false}
          ref={form => {
            this.form = form;
          }}
        >
          <button className="hidden" type="submit" />
        </Form>
      </div>
    );
  }
}
