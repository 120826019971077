import NoopAnalyticsService from 'client/services/analytics/noop-analytics-service';

const relayFunctionNames = Object.getOwnPropertyNames(
  NoopAnalyticsService.prototype
).filter(name => name !== 'constructor');

class AnalyticsRelayService {
  constructor(analyticServices = []) {
    this.analyticServices = analyticServices;

    const bindFunctionsToSelf = () => {
      relayFunctionNames.forEach(funcName => {
        this[funcName] = (...args) => {
          this.relayToServices(funcName, ...args);
        };
      });
    };

    bindFunctionsToSelf();
  }

  relayToServices = (funcName, ...args) => {
    this.analyticServices.forEach(service => {
      service[funcName](...args);
    });
  };
}

export default AnalyticsRelayService;
