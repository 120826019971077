const digitsAndSemicolonRegularExpression = new RegExp(/^(?:\d+[;]?)+$/i);

const DigitsAndSemicolonValidationMethod = {
  name: 'digitsandsemicolon',
  validateValue: value =>
    !value ||
    digitsAndSemicolonRegularExpression.test(value.trim().replace(/;\s/g, ';'))
};

export default DigitsAndSemicolonValidationMethod;
