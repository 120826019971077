import notify from 'public/core/utilities/notify';

const getContext = modal => {
  if (modal.jquery && modal.find) {
    return modal.find('.modal-content');
  }
  if (modal.$el) {
    return modal.$el.find('.modal-content');
  }
};

const notifyModal = (modal, title, message, stick, type, options) => {
  if (
    typeof modal === 'string' ||
    (!modal.jquery &&
      (!modal.$el ||
        modal.$el.find('.modal-content').length === 0 ||
        !modal.jquery))
  ) {
    const method = typeof console.warn === 'function' ? 'warn' : 'log';
    console[method](
      'notifyModal() called with an invalid modal reference. Did you forget to pass the modal?'
    );
  }

  const context = getContext(modal);
  const stack = context
    ? {
        context: context,
        dir1: 'down',
        dir2: 'left',
        spacing1: 8,
        spacing2: 8
      }
    : undefined;
  options = {
    ...options,
    stack
  };

  if (!options.stack) {
    delete options.stack;
  }

  return notify(title, message, stick, type, options);
};

export default notifyModal;
