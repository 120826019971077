import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '@shipstation/svgs';

const _iconSelector = '[data-icon]';
$.fn.iconify = function() {
  var iconElements = this.find(_iconSelector).addBack(_iconSelector);
  iconElements.each(function(index, iconElement) {
    if (!iconElement) {
      return;
    }
    var iconName = $(iconElement).data('icon');
    ReactDOM.render(<Icon name={iconName} version="v2" />, iconElement);
  });
  return this;
};
