import semver from 'semver';

import ShipstationConnectData from 'public/core/ShipStationConnect/shipstation-connect-data';
import { PusherService } from 'client/services/pusher/pusher-service';

const Data = require('Data');
const pubsub = require('pubsub');

const isVersionOutOfDate = version => {
  const currentVersion = Data.ShipStationConnectVersion;

  return semver.lt(version, currentVersion);
};

const ShipstationConnectUtils = {
  completeJob: job => ShipstationConnectData.jobs.remove(job),
  updateJob: (msg, callback) => {
    if (!msg || !msg.data || !msg.data.requestId) {
      return;
    }

    const job = ShipstationConnectData.jobs.findWhere({
      requestId: msg.data.requestId
    });
    if (!job) {
      return;
    }

    clearTimeout(job.responseTimeout);

    job.status = msg.data.status;

    callback(job);
  },
  updateScale: (workstationId, scaleId, isAutoing) => {
    const workstation = Data.Workstations.get(workstationId);
    if (!workstation) {
      return;
    }
    const scale = workstation.get('Scales').get(scaleId);
    if (scale) {
      scale.set('isInAutoMode', isAutoing);
    }

    workstation.trigger('change:Scales', workstation);
  },
  makeWorkstationOnline: workstation => {
    //Find out if it's running an out of date version of Connect, then let everyone know it came online
    const workstationId = workstation.get('WorkstationID');
    const workstationVersion = workstation.get('Version');

    const isWorkstationOnline = PusherService.getIsWorkstationOnline(
      workstationId
    );

    if (!isWorkstationOnline) {
      return;
    }

    if (workstationVersion) {
      workstation.set('OutOfDate', isVersionOutOfDate(workstationVersion));
    } else {
      workstation.set('OutOfDate', null);
    }

    workstation.set('Online', true);
    pubsub.trigger('shipstation-connect:workstations-changed');
  }
};

export default ShipstationConnectUtils;
