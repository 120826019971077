import _ from 'underscore';

import ShipstationConnectUtils from '../shipstation-connect-utils';

const ScaleAutoPubSubHandler = {
  event: 'app:message:client-connect:response:scale:auto',
  handler: msg => {
    const requestId = _.get(msg, 'data.requestId');
    if (!requestId) {
      return;
    }

    ShipstationConnectUtils.updateScale(
      msg.data.workstationId,
      msg.data.scaleId,
      true
    );

    ShipstationConnectUtils.updateJob(msg, job => {
      job.callback(null, msg.data);
    });
  }
};

export default ScaleAutoPubSubHandler;
