/* jshint browser: true */

import jsCookie from 'js-cookie';

class DropInRestrictionsService {
  initialize() {}

  static isResponseUnauthorizedDropInAction(response) {
    if (!response || !response.status) return false;
    return response.status == 403 && DropInRestrictionsService.isDropInActive();
  }

  static isDropInActive() {
    return !!jsCookie.get('x-imp');
  }

  static getDropinActionRestrictedMessage(response) {
    const defaultMessage =
      "You are not permitted to take this action on a seller's behalf. Contact your team lead if you need this permission.";
    const defaultMessageTitle = 'Restricted Drop-In Action';
    if (response && response.responseText && response.responseText.length) {
      const respBodyObj = JSON.parse(response.responseText);
      return {
        title: respBodyObj?.MessageTitle || defaultMessageTitle,
        message: respBodyObj?.Message || defaultMessage
      };
    }
    // malformed response
    return {
      title: defaultMessageTitle,
      message: defaultMessage
    };
  }
}

export default DropInRestrictionsService;
