import removeNotification from 'public/core/utilities/remove-notification';
import ShipstationConnectUtils from '../shipstation-connect-utils';

const ScreencapturePubSubHandler = {
  event: 'app:message:client-connect:response:screencapture',
  handler: msg => {
    ShipstationConnectUtils.updateJob(msg, job => {
      if (!job) {
        return;
      }

      removeNotification(job.notification);

      if (msg.data.status === 'complete' || msg.data.status === 'failed') {
        job.def.resolve(msg.data);

        ShipstationConnectUtils.completeJob(job);
        return;
      }
    });
  }
};

export default ScreencapturePubSubHandler;
