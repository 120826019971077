import _ from 'underscore';

function escapeStringForRegularExpression(str) {
  // disabling the next line in eslint since it's a crazy regex from the internet.
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'); // eslint-disable-line
}

export default class StringHelperService {
  static replaceAll(str, search, replacement) {
    return str.replace(
      new RegExp(escapeStringForRegularExpression(search), 'g'),
      replacement
    );
  }

  static idify(str) {
    return this.replaceAll(str.toLowerCase(), ' ', '-');
  }

  static removeAllNonNumericCharacters(str) {
    return str.replace(/[^0-9.,-]/g, '');
  }

  static removeTextAfterCharacter(str, character) {
    const index = str.indexOf(character);
    if (index === -1) {
      return str;
    }
    return str.substring(0, index);
  }

  static isBlank(str) {
    return (
      _.isUndefined(str) ||
      _.isNull(str) ||
      (str.trim && str.trim().length === 0)
    );
  }
}
