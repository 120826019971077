const removeOptions = el => {
  $(el).empty();
};

const setOptions = (el, models, append) => {
  if (!append) {
    removeOptions(el);
  }
  const select = $(el);
  const codeField = select.attr('codeFieldName') || 'Code';
  const displayField = select.attr('displayFieldName') || 'Name';
  const insertBlankOption = select.attr('insertBlankOption') || false;
  const placeholder = select.data('placeholder') || '';
  const options = [];
  if (models) {
    if (insertBlankOption) {
      options.push(`<option value="">${placeholder}</option>`);
    }
    for (let m = 0; m < models.length; m++) {
      const cdJSON = models[m].toJSON();
      const selected = cdJSON['default'] === true ? ' selected' : '';
      options.push(
        `<option ${selected} value="${_.escape(cdJSON[codeField])}">${_.escape(
          cdJSON[displayField]
        )}</option>`
      );
    }
    select.html(options.join(''));
  }
};

export default setOptions;
