import $ from 'jquery';
import _ from 'underscore';
const pubsub = require('pubsub');

const windowResizeHandler = async () => {
  const throttledResize = _.debounce(e => {
    if (e.target !== window) return;
    $('.cached-size').data('cached-size', null);
    pubsub.trigger('app:window_resize');
  }, 500);

  $(window).resize(throttledResize);
};

export default windowResizeHandler;
