import 'core/Iconify';
import AppModeStore from 'client/stores/app-mode-store';
import { mask, unmask } from 'core/libs/mask';
import V3IframeParentService from 'client/services/v3-iframe-parent-service';
import getTranslatedStringObject from 'client/i18n/get-translated-string-object';

import StampsModalTemplate from './StampsModal.html';
import ModalTemplate from './Modal.html';
import closeTemplate from './Buttons/close.html';
import confirmTemplate from './Buttons/confirm.html';
import deleteTemplate from './Buttons/delete.html';
import doitalreadyTemplate from './Buttons/doitalready.html';
import gotitTemplate from './Buttons/gotit.html';
import groovyTemplate from './Buttons/groovy.html';
import makeitsoTemplate from './Buttons/makeitso.html';
import okTemplate from './Buttons/ok.html';
import proceedTemplate from './Buttons/proceed.html';
import saveTemplate from './Buttons/save.html';
import proceedWithRemoveTemplate from './Buttons/proceed-with-remove.html';
import savecancelTemplate from './Buttons/savecancel.html';
import searchTemplate from './Buttons/search.html';
import wizardTemplate from './Buttons/wizard.html';
import wizardauthorizeTemplate from './Buttons/wizardauthorize.html';

import closeTranslations from './Buttons/close.translations';
import confirmTranslations from './Buttons/confirm.translations';
import deleteTranslations from './Buttons/delete.translations';
import doitalreadyTranslations from './Buttons/doitalready.translations';
import gotitTranslations from './Buttons/gotit.translations';
import groovyTranslations from './Buttons/groovy.translations';
import makeitsoTranslations from './Buttons/makeitso.translations';
import okTranslations from './Buttons/ok.translations';
import proceedTranslations from './Buttons/proceed.translations';
import saveTranslations from './Buttons/save.translations';
import proceedWithRemoveTranslations from './Buttons/proceed-with-remove.translations';
import savecancelTranslations from './Buttons/savecancel.translations';
import searchTranslations from './Buttons/search.translations';
import wizardTranslations from './Buttons/wizard.translations';
import wizardauthorizeTranslations from './Buttons/wizardauthorize.translations';

const buttonTypeToTemplateMap = {
  close: closeTemplate,
  confirm: confirmTemplate,
  delete: deleteTemplate,
  doitalready: doitalreadyTemplate,
  gotit: gotitTemplate,
  groovy: groovyTemplate,
  makeitso: makeitsoTemplate,
  ok: okTemplate,
  proceed: proceedTemplate,
  'proceed-with-remove': proceedWithRemoveTemplate,
  savecancel: savecancelTemplate,
  save: saveTemplate,
  search: searchTemplate,
  wizard: wizardTemplate,
  wizardauthorize: wizardauthorizeTemplate
};

const buttonTypeToTranslationMap = {
  close: closeTranslations,
  confirm: confirmTranslations,
  delete: deleteTranslations,
  doitalready: doitalreadyTranslations,
  gotit: gotitTranslations,
  groovy: groovyTranslations,
  makeitso: makeitsoTranslations,
  ok: okTranslations,
  proceed: proceedTranslations,
  'proceed-with-remove': proceedWithRemoveTranslations,
  savecancel: savecancelTranslations,
  save: saveTranslations,
  search: searchTranslations,
  wizard: wizardTranslations,
  wizardauthorize: wizardauthorizeTranslations
};

define(['BaseView', 'pubsub'], function(BaseView, pubsub) {
  var baseIndex = 18000;

  var modalTracker = {
    modals: [],
    isModalOpen: function(type) {
      return this.modals.some(m => {
        return m.modalType && m.modalType.toLowerCase() == type.toLowerCase();
      });
    },
    isTopMost: function(zindex) {
      return this.modals.some(() => {
        return zindex == baseIndex + this.modals.length * 3 + 2;
      });
    }
  };

  var modal = BaseView.extend(
    {
      initialize: function(options) {
        // Assign a globally unique ID to each Modal instance
        this.viewInstanceId = App.user.seller + '_' + Date.now();

        options = options || {};
        this.options = options;
        this.options.title = this.options.title || '';
        this.options.titleHtml =
          this.options.titleHtml || _.escape(this.options.title);
        this.options.cls = this.options.cls || '';
        this.options.bgCls = this.options.bgCls || '';
        this.options.containerCls = this.options.containerCls || '';
        this.options.containerStyles = this.options.containerStyles || '';
        if (typeof this.options.backdrop === 'undefined') {
          this.options.backdrop = 'static';
        }
        if (this.options.bodyCls === undefined)
          this.options.bodyCls = 'scrollable';

        if (options.stampsIframe) this.tpl = _.template(StampsModalTemplate);
        else this.tpl = _.template(ModalTemplate);

        if (options.header) {
          this.headerTpl = _.template(options.header);
        } else if (options.header === '') {
          this.headerTpl = null;
        }

        this.headerTplData = options.headerTplData || {};

        if (options.footer) this.footerTpl = _.template(options.footer);
        else if (options.buttons) {
          this.footerTpl = _.template(buttonTypeToTemplateMap[options.buttons]);
          this.options.footerTranslations =
            buttonTypeToTranslationMap[options.buttons];
        }

        this._super('initialize', arguments);
      },
      render: function() {
        if (this.rendered) {
          return;
        }
        this.$el.addClass('modal');

        if (this.options && this.options.fade) {
          this.$el.addClass('fade');
        }

        this.$el.html(this.tpl(this.options));

        // for css scoping across header, content and footer
        if (this.options.elId) {
          this.$el.attr('id', this.options.elId);
        }

        // SF Modal width and height should be controlled in the CSS not the JS. Add a class to the body dynamically to help control this sizing
        if (this.options.width) {
          // SF left and right need to be left alone to avoid scroll bars
          this.$el.find('.modal-dialog').css('width', this.options.width);
        }
        if (this.options.height) {
          // SF top and bottom need to be left alone to avoid scroll bars
          this.$el.find('.modal-dialog').css('height', this.options.height);
          this.$el.find('.modal-content').css('height', 'inherit'); // jms fix for some modals not respecting set height
        }

        if (this.headerTpl) {
          var hdrData = $.extend(this.headerTplData, {
            model: this.model
          });
          this.$el.find('.modal-header').html(this.headerTpl(hdrData));
        } else if (this.headerTpl === null) {
          this.$el.find('.modal-header').remove();
        }

        if (this.footerTpl) {
          this.$el.find('.modal-footer').html(
            this.footerTpl({
              model: this.model,
              translatedStrings: getTranslatedStringObject(
                this.options.footerTranslations
              )
            })
          );
        } else {
          this.$el.find('.modal-footer').hide();
          this.$el.find('.modal-body').css('bottom', '0');
        }
        this._super('render', arguments);

        if (this.options.bodyCls) {
          this.$el.find('.modal-body').addClass(this.options.bodyCls);
        }
        this.$el.iconify();
        this.rendered = true;
      },
      sizeToContent: function(max) {
        var hdr = this.$el.find('.modal-header').outerHeight();
        var ftr = this.$el.find('.modal-footer').outerHeight();
        var bodyH = this.$el.find('.modal-body').outerHeight();
        var bodyP = bodyH - this.$el.find('.modal-body').height();
        var bodyCtH = this.$el
          .find('.modal-body')
          .contents()
          .outerHeight();

        var winH = hdr + ftr + bodyCtH + bodyP + 8;
        if (max) {
          winH = Math.min(winH, max);
        }

        if (this.options.dontAnimateResize) {
          this.$el.find('.modal-dialog').css('height', winH);
        } else {
          this.$el
            .find('.modal-dialog')
            .addClass('resizing')
            .animate(
              {
                height: winH,
                duration: 100
              },
              function() {
                $(this).removeClass('resizing');
              }
            );
        }
      },
      setHeight: function(height) {
        this.$el
          .find('.modal-dialog')
          .addClass('resizing')
          .animate(
            {
              height: height,
              duration: 100
            },
            function() {
              $(this).removeClass('resizing');
            }
          );
      },
      setWidth: function(width) {
        this.$el
          .find('.modal-dialog')
          .addClass('resizing')
          .animate(
            {
              width: width,
              duration: 100
            },
            function() {
              $(this).removeClass('resizing');
            }
          );
      },
      setBackdropZIndex: function() {
        $('.modal-backdrop')
          .first()
          .css('z-index', baseIndex + modalTracker.modals.length * 3);
        if (this.$el.data('bs.modal') && this.$el.data('bs.modal').$backdrop) {
          this.$el
            .data('bs.modal')
            .$backdrop.css(
              'z-index',
              baseIndex + modalTracker.modals.length * 3 + 1
            );

          if (
            modalTracker.modals.length > 1 &&
            this.IsInScanToPrintModeAndNotOrderDetailModal()
          ) {
            this.$el.data('bs.modal').$backdrop.css('display', 'none');
          }
        }
      },
      show: function() {
        var me = this;
        this.render();

        this.$el.on('show.bs.modal', null, function(e) {
          if (e.namespace !== 'bs.modal') {
            return;
          }
          me.zindex = baseIndex + modalTracker.modals.length * 3 + 2;
          me.$el.css('z-index', me.zindex);
          me.$el
            .find('.modal-body')
            .children()
            .addClass('displayNone');
          if (me.onShowing) me.onShowing();
        });

        this.$el.on('shown.bs.modal', null, function(e) {
          if (e.namespace !== 'bs.modal') {
            return;
          }
          if (
            me.options.bgCls &&
            me.$el.data('bs.modal') &&
            me.$el.data('bs.modal').$backdrop
          ) {
            me.$el.data('bs.modal').$backdrop.addClass(me.options.bgCls);
          }

          me.zindex = baseIndex + modalTracker.modals.length * 3 + 2;
          me.$el.css('z-index', me.zindex);

          me.$el
            .find('.modal-body')
            .children()
            .removeClass('displayNone');

          me.onShow();
          if (me.options.draggable !== false) {
            me.$el.find('.modal-content').draggable({
              handle: '.modal-header',
              containment: '#content',
              cancel: '.order-num, .btn, .btn-group, .close',
              start: function() {
                var $this = $(this);
                $this.css('width', $this.width());
                $this.css('left', $this.offset().left);
              },
              drag: function() {
                var $this = $(this);
                $this.css('right', 'auto');
                $this.css('bottom', 'auto');
              }
            });
          }
        });

        this.$el.modal({
          backdrop: this.options.backdrop,
          keyboard: false,
          show: false
        });

        this.$el.css(
          'z-index',
          baseIndex + (modalTracker.modals.length || 0 + 1) * 3 + 2
        );
        this.$el.css('display', 'block').modal('show');
        this.setBackdropZIndex();

        this.$el.on(
          'hide.bs.modal',
          null,
          function(e) {
            if (
              this.shouldBeAbleToClose !== undefined &&
              !this.shouldBeAbleToClose()
            ) {
              if (this.onUnableToClose !== undefined) {
                this.onUnableToClose(e);
              }
              e.preventDefault();
              return;
            }
            if (e.namespace == 'bs.modal' && me.$el.is('.in')) {
              me.$el
                .find('.modal-body')
                .children()
                .hide();
              modalTracker.modals.pop(me);
              me.setBackdropZIndex();
            }
          }.bind(this)
        );
        this.$el.on(
          'hidden.bs.modal',
          null,
          function(e) {
            if (
              this.shouldBeAbleToClose !== undefined &&
              !this.shouldBeAbleToClose()
            ) {
              e.preventDefault();
              return;
            }
            if (e.namespace == 'bs.modal') {
              me.onClose();
              if (me.onHide) me.onHide();
              me.cleanUp();
            }
          }.bind(this)
        );

        this.$el.addClass(this.options.containerCls);
        if (this.options.containerStyles) {
          this.$el.attr('style', this.options.containerStyles);
        }

        this.modalTracker = modalTracker;
        this.listenTo(
          pubsub,
          'basicHotkey:escape',
          function() {
            if (
              !this.modalTracker.isTopMost(this.zindex) ||
              (this.shouldBeAbleToClose !== undefined &&
                !this.shouldBeAbleToClose())
            ) {
              return;
            }
            this.close();
          },
          this
        );

        if (this.IsInScanToPrintModeAndNotOrderDetailModal()) {
          this.setScanToPrintModalProperties();
        }
        if (this.IsInScanToPrintModeAndOrderPickerModal()) {
          this.setScanToPrintModalProperties();
          this.setScanToPrintOrderPickerModalProperties();
        }
        V3IframeParentService.notifyModalState(modalTracker.modals.length > 0);
      },
      mask: function(text) {
        mask(this.$el.find('.modal-content'), text);
      },
      maskBody: function(text) {
        mask(this.$el.find('.modal-body'), text);
      },
      unmask: function() {
        unmask(this.$el.find('.modal-content'));
      },
      unmaskBody: function() {
        unmask(this.$el.find('.modal-body'));
      },
      onShow: function() {
        this.trigger('show');
      },
      hide: function() {
        this.$el.modal('hide');
      },
      getTitle: function() {
        return this.$el.find('.modal-header h2, .modal-header h3').html();
      },
      setTitle: function(title, shouldAnimate) {
        shouldAnimate = typeof shouldAnimate === 'undefined' || shouldAnimate;

        if (shouldAnimate) {
          this.$el.find('.modal-header h2, .modal-header h3').hide(
            'fade',
            {
              duration: 100
            },
            function() {
              $(this)
                .html(title)
                .show('fade', {
                  duration: 100
                });
            }
          );
        } else {
          _.each(
            this.el.querySelectorAll('.modal-header h2, .modal-header h3'),
            function(el) {
              el.innerHTML = title;
            }
          );
        }
      },
      setFooterTemplate: function(template) {
        this.footerTpl = _.template(template);
        this.rendered = false;
      },
      cleanUp: function() {
        this.$el.off('bs.modal');
        // unbind view from events
        this.undelegateEvents();
        // remove and data attached to elements
        this.$el.removeData().unbind();
        //Remove view from DOM
        this.remove();
        Backbone.View.prototype.remove.call(this);
      },
      onClose: function(params) {
        // if a onClose fn was provided via options then call it
        if (typeof this.options.onClose === 'function') {
          this.options.onClose(params);
        }
        this.trigger('close');
        this.pub('modal:action:close');
      },
      close: function() {
        this.onClose();
        var data = this.$el.data('bs.modal');
        if (data && data.$backdrop) {
          data.$backdrop.removeClass('in');
          this.$el.trigger('hide.bs.modal');
          this.$el.trigger('hidden.bs.modal');
          data.$backdrop.remove();
        }
        this.remove();
        V3IframeParentService.notifyModalState(modalTracker.modals.length > 0);
      },
      onCancel: function(e) {
        if (
          this.shouldBeAbleToClose !== undefined &&
          !this.shouldBeAbleToClose()
        ) {
          return e && typeof e.preventDefault === 'function'
            ? e.preventDefault()
            : null;
        }
        this.trigger('cancel');
        this.close(e);
      },
      onOk: function(e) {
        this.close(e);
        this.trigger('ok');
        e && _.isFunction(e.preventDefault) && e.preventDefault();
      },
      beforeShow: function() {
        if (this.modalType && modalTracker.isModalOpen(this.modalType)) {
          return false;
        }
      },
      events: {
        'show.bs.modal': function(e) {
          if (this.beforeShow && this.beforeShow() === false) {
            return e.preventDefault();
          }
          modalTracker.modals.push(this);
        },
        'click .modal-footer .btn': function(e) {
          if ($(e.currentTarget).is('.btn-modal-save')) {
            this.onSave(e);
            return;
          }
          if (
            $(e.currentTarget).is('.btn-modal-cancel') ||
            $(e.currentTarget).data('dismiss') == 'modal'
          ) {
            this.onCancel(e);
            return;
          }
          if ($(e.currentTarget).is('.btn-modal-ok')) {
            this.onOk(e);
            return;
          }
          if ($(e.currentTarget).is('.btn-modal-close')) {
            this.close(e);
            return false;
          }
        },
        'click .modal-header [data-dismiss=modal]': 'onCancel'
      },
      IsInScanToPrintModeAndNotOrderDetailModal: function() {
        return (
          AppModeStore.isInScanToPrint() &&
          this.options.bgCls === 'scan-to-print-order-background'
        );
      },
      IsInScanToPrintModeAndOrderPickerModal: function() {
        return (
          AppModeStore.isInScanToPrint() &&
          this.options.bgCls === 'scan-to-print-order-picker-background'
        );
      },
      setScanToPrintModalProperties: function() {
        this.$el.css({
          right: 'auto',
          top: '100px',
          left: '50%',
          width: '700px',
          transform: 'translateX(-50%)',
          padding: '20px 0',
          'max-width': '1320px',
          'max-height': '1040px',
          'pointer-events': 'none'
        });
        this.$el.find('.modal-dialog').css({
          position: 'static',
          width: '100%',
          height: 'auto',
          'pointer-events': 'none',
          padding: 0
        });
        this.$el.find('.modal-content').css({
          'pointer-events': 'auto',
          position: 'static',
          overflow: 'hidden'
        });
      },
      setScanToPrintOrderPickerModalProperties: function() {
        this.$el.css({
          top: '120px',
          width: '600px',
          padding: '0'
        });
        this.$el.find('.modal-body').css({
          position: 'static'
        });
      }
    },
    {
      //merge event hashes of the base and derived views
      extend: function(properties, classProperties) {
        properties.events = _({}).extend(
          properties.events || {},
          this.prototype.events
        );
        return Backbone.View.extend.call(this, properties, classProperties);
      }
    }
  );

  modal.active = modalTracker;

  return modal;
});
