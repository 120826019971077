const alphanumericRegularExpression = new RegExp(/^[a-z0-9\-\s]+$/i);

const AlphanumericValidationMethod = {
  name: 'alphanumeric',
  errorMessage:
    'This value must not contain any special characters.  Only letters and numbers are allowed.',
  validateValue: value => !value || alphanumericRegularExpression.test(value)
};

export default AlphanumericValidationMethod;
