// todo. Eventually we need to subsume public/core/logger.js, but right now that's using backbone pubsub. -- Broderick TODO
import * as Sentry from '@sentry/browser';

/* eslint-disable */
const consoleError = console.error.bind(console);
const consoleWarning = console.warn.bind(console);
const consoleInfo = console.info.bind(console);
/* eslint-enable */

let environment;
if (window && window.SS_GLOBALS && window.SS_GLOBALS.Env) {
  environment = window.SS_GLOBALS.Env;
} else {
  environment = 'dev';
}
const isInDevelopmentMode = environment !== 'PROD';
const shouldLogToBrowser = isInDevelopmentMode; // change this to false for local dev logging

// Sentry SDK wraps all unhandled errors, so this is only for explicit logging as of this time
export const AlternateLogService = {
  logInfo: shouldLogToBrowser ? consoleInfo : () => {},
  logWarning: shouldLogToBrowser
    ? consoleWarning
    : message => {
        Sentry.captureMessage(message, 'warning');
      },
  logError: shouldLogToBrowser
    ? consoleError
    : message => {
        Sentry.captureMessage(message, 'error');
      }
};

export default class LogService {
  static shouldLogToConsole = true;
  static shouldLogToAlert = false;

  static logFunctions = [LogService.logToConsole, LogService.logToAlert];

  static log(message) {
    this.logFunctions.forEach(logFunction => logFunction(message));
  }

  static logToConsole(message) {
    if (
      !LogService.shouldLogToConsole ||
      typeof window === 'undefined' ||
      !window.console
    ) {
      return;
    }
    window.console.log(message);
  }

  static logToAlert(message) {
    if (
      !LogService.shouldLogToAlert ||
      typeof window === 'undefined' ||
      !window.alert
    ) {
      return;
    }
    window.alert(message);
  }
}
