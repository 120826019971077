import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '6fdd59e4-4837-4e98-bb80-9ca8337023ad',
    defaultMessage: 'Confirm'
  })
];

export default translations;
