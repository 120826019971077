define(['backbone', 'models/Carrier'], function(Backbone, CarrierModel) {
  var colors = {
    0: '#ddd',
    1: '#5E82A9',
    3: '#EAA848',
    4: '#4D148C',
    5: '#FFCC00',
    6: '#6A9A5C',
    7: '#CB011D',
    8: '#205A66',
    12: '#DA2A1D'
  };

  var labelApiColors = {
    0: '#ddd',
    1: '#5E82A9',
    3: '#644117', //ups
    4: '#472f91', //fedex
    5: '#eac841', //dhl
    6: '#6A9A5C',
    7: '#eac841', //dhl express
    8: '#205A66',
    12: '#DA2A1D'
  };

  return Backbone.Collection.extend({
    getColor: function(id) {
      return colors[id] || '#ddd';
    },
    getLabelApiColor: function(id) {
      return labelApiColors[id] || '#ddd';
    },
    getName: function(carrId) {
      if (!carrId) return '';
      var m = this.get(carrId);
      if (!m) return '???';
      return m.get('Name');
    },
    model: CarrierModel,
    getActiveCarriers: function() {
      return this.filter(function(c) {
        return c.get('Active');
      });
    }
  });
});
