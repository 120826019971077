import ShipStationPalette from './palettes/shipstation-palette';
import ShipEnginePalette from './palettes/shipengine-palette';

const palettes = [ShipStationPalette, ShipEnginePalette];

let currentPalette = ShipStationPalette;

class PaletteService {
  getPalette() {
    return currentPalette;
  }

  getPaletteByApp({ app }) {
    const appPalettes = palettes.filter(palette => palette.app === app);
    if (appPalettes.length === 0) {
      return currentPalette;
    }
    return appPalettes[0];
  }

  setCurrentPallete(newPalette) {
    currentPalette = newPalette;
  }
}

export default new PaletteService();
