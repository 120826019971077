import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '33cd01be-5836-467d-9139-397a63fd7e13',
    defaultMessage: 'Confirm'
  }),
  defineMessage({
    id: 'b0c2e4ab-7620-4db4-894c-b3d84ed30bbc',
    defaultMessage: 'Cancel'
  })
];

export default translations;
