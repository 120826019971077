import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: 'ea89724a-f3cd-40cf-95f8-0c5542fd61c1',
    defaultMessage:
      'An error occurred while attempting to connect your account.'
  })
];

export default translations;
