let shouldLog = false;

class HotkeyLogging {
  turnOnHotkeyLogging() {
    shouldLog = true;
  }

  turnOffHotkeyLogging() {
    shouldLog = false;
  }

  log() {
    if (shouldLog) {
      const args = [].slice.call(arguments);
      args.unshift('Hotkey Service: ');
      console.log.apply(null, args);
    }
  }
}

export default new HotkeyLogging();
