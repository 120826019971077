// preload dependencies that may have globals.
import _ from 'underscore';
import 'jquery';
// jquery ui must come before boostrap or bad things happen
import 'jqueryui';
import 'bootstrap';
import backbone from 'backbone';
import 'backbone.marionette';
import 'editable';
import 'jqueryvalidate.more';
import str from 'underscore.string';

import 'core/AdditionalValidationMethods';
import 'core/Iconify';
import _super from 'public/core/libs/super';

import polyfills from './pre-data-functions/polyfills';
import globalJquery from './pre-data-functions/global-jquery';
import globalMoment from './pre-data-functions/global-moment';

import monekypatchJqueryMobile from './pre-data-functions/monkeypatch-jquery-mobile';
import monkeypatchJquery from './pre-data-functions/monkeypatch-jquery';

import devExpressSetup from './pre-data-functions/dev-express-setup';
import editableSetup from './pre-data-functions/editable-setup';
import jqueryAjaxSetup from './pre-data-functions/jquery-ajax-setup';
import xssSetup from './pre-data-functions/xss-setup';
import windowResizeHandler from './pre-data-functions/window-resize-handler';
import windowFocusHandler from './pre-data-functions/window-focus-handler';
import windowUnloadHandler from './pre-data-functions/window-unload-handler';
import inputGroupSpinnerHandler from './pre-data-functions/input-group-spinner-handler';
import determineIfV3 from './pre-data-functions/determine-if-v3';
import startAuth0Session from './pre-data-functions/start-auth0-session';
import setupBackbonePubsubService from './pre-data-functions/setup-backbone-pubsub-service';
import setupModalManagerService from './pre-data-functions/setup-modal-manager-service';
import initalizeLocaleService from './pre-data-functions/initalize-locale-service';

const preDataFunctions = [
  polyfills,

  //global variables
  globalJquery,
  globalMoment,
  async () => {
    window._ = _;
  },
  async () => {
    window.Backbone = backbone;
  },

  // monkeypatching libraries
  monekypatchJqueryMobile,
  async () => {
    window._.str = str;
  },
  async () => {
    Backbone.Model.prototype.nestCollection = function(
      attributeName,
      collection
    ) {
      //setup nested references
      for (let i = 0; i < collection.length; i++) {
        this.attributes[attributeName][i] = collection.at(i).attributes;
      }

      //create empty arrays if none
      collection.bind('add', initiative => {
        if (!this.get(attributeName)) {
          this.attributes[attributeName] = [];
        }
        this.get(attributeName).push(initiative.attributes);
      });

      collection.bind('remove', initiative => {
        const updateObj = {};
        updateObj[attributeName] = _.without(
          this.get(attributeName),
          initiative.attributes
        );
        this.set(updateObj);
      });

      collection.bind('reset', () => {
        const updateObj = {};
        updateObj[attributeName] = [];
        this.set(updateObj);
      });
      return collection;
    };

    //Add Convenience method for call "super" class methods from inherited classes
    Backbone.Model.prototype._super = _super;
    Backbone.Collection.prototype._super = _super;
    Backbone.View.prototype._super = _super;
    Backbone.Router.prototype._super = _super;
  },
  monkeypatchJquery,

  // library setup
  xssSetup,
  jqueryAjaxSetup,
  devExpressSetup,
  editableSetup,

  // global event handlers
  windowResizeHandler,
  windowFocusHandler,
  windowUnloadHandler,
  inputGroupSpinnerHandler,

  // app specific things
  determineIfV3,
  startAuth0Session,
  setupBackbonePubsubService,
  setupModalManagerService,
  initalizeLocaleService
];

const executePreDataFunctions = async () => {
  for (let preDataFunction of preDataFunctions) {
    await preDataFunction();
  }
};

export default executePreDataFunctions;
