import jsCookie from 'js-cookie';

import { ApiUrl } from '../../public/app/Config';
import UrlService from 'public/core/services/UrlService';
import defaultCookieConfig from 'public/defaultCookieConfig';

let v3Confirmed = false;

export default class V3AccessService {
  static isIframeMode() {
    if (v3Confirmed) {
      return true;
    }
    return UrlService.getQueryVariableFromEncodedSegment(
      window.location.hash,
      'v3iframe'
    );
  }

  static setV3Confirmed(confirmed) {
    v3Confirmed = confirmed;
  }

  static hasV3Cookie() {
    if (
      jsCookie.get('SSHome') &&
      jsCookie
        .get('SSHome')
        .toLocaleLowerCase()
        .indexOf('ship') == 0
    ) {
      return true;
    }
    return false;
  }

  static logoutAndRedirectToV2() {
    jsCookie.set('SSHome', null, {
      expires: -1,
      path: '/',
      domain:
        window.location.hostname !== 'localhost' ? '.shipstation' : 'localhost',
      ...defaultCookieConfig
    });
    // Send a pusher call from the backend to init a parent page refresh since we're within a v2 IFRAME.
    setTimeout(() => {
      $.post(ApiUrl + 'account/logoutAndRedirect', {
        userId: App.user.userId,
        location: 'https://ss.shipstation.com/'
      });
    }, 1000);
  }
}
