import uuid from 'uuid';
import AmplifyService from 'amplify';
import AppDataService from 'client/services/app-data-service';
import BackbonePubSubService from 'client/services/backbone-pub-sub-service';
import ModalManagerService from 'client/services/modal-manager-service';
import { PusherMessages } from 'client/services/pusher/pusher-messages';
import { PusherService } from 'client/services/pusher/pusher-service';
import UnitConversionService from 'client/services/unit-conversion-service';
import UnitOfMeasureService from 'client/services/unit-of-measure-service';
import UserService from 'client/services/user-service';
import ApiHttpService from 'client/services/api/api-http-service';
import PrinterService from 'client/services/printer-service';
import notifyConnectJobUpdate from 'public/core/utilities/notify-connect-job-update';
import notify from 'public/core/utilities/notify';
import notifyConnectJob from 'public/core/utilities/notify-connect-job';

// todo remove this when the local storage is the source of truth
const Data = require('Data');

const SAVED_SCALE_LOCAL_STORAGE_STRING = 'connect:scale';
const SAVED_PRINTER_AMPLIFY_STRING = 'ss_Setting_Printer_DocType';
const PRINTER_TIMEOUT_DURATION = 10000;

const OBJECT_TYPE_TO_RECORD_CONTEXT = {
  order: 1,
  shipment: 2,
  customer: 3,
  product: 4
};

const DOCTYPE_TO_API_ENDPOINT = {
  ps: 'PackingSlips',
  eod: 'EOD',
  label: 'Labels',
  other: 'Forms',
  hotkeys: 'Hotkeys',
  barcodes: 'Barcodes'
};

export default class ShipstationConnectService {
  static jobs = {};
  static scales = {};
  static printers = {};
  static autoScaleChannelName = '';

  static initalize() {
    this.scaleReadSubscription = BackbonePubSubService.subscribeToBackboneEvent(
      'app:message:client-connect:response:scale:read',
      message => this.onScaleReadResponse(message)
    );
    this.printerResponseSubscription = BackbonePubSubService.subscribeToBackboneEvent(
      'app:message:client-connect:response:printer:job',
      message => this.onPrinterResponse(message)
    );
    this.updateScalesSubscription = BackbonePubSubService.subscribeToBackboneEvent(
      'app:message:client-connect:broadcast:scales',
      message => this.updateScales(message)
    );
    this.updatePrintersSubscription = BackbonePubSubService.subscribeToBackboneEvent(
      'app:message:client-connect:broadcast:printers',
      message => this.updatePrinters(message)
    );
  }

  static startGlobalScaleRead() {
    this.getWeightFromSavedScale(
      (weight, weightUnitOfMeasure, error) => {
        BackbonePubSubService.publishBackboneEvent(
          'shipstation-connect-service:global-read-finished',
          {
            weight,
            weightUnitOfMeasure,
            error
          }
        );
      },
      () => {
        BackbonePubSubService.publishBackboneEvent(
          'shipstation-connect-service:global-read-start',
          {}
        );
      }
    );
  }

  static createAutoScaleChannelName(workstationId, scaleId) {
    const sellerId = UserService.getSellerId();

    return `presence-${[sellerId, workstationId, scaleId].join('_')}`;
  }

  static startAutoScaleReading(readingCallback) {
    const setUpAutoScalePusherChannel = scale => {
      this.autoScaleChannelName = this.createAutoScaleChannelName(
        scale.workstationId,
        scale.id
      );

      const eventHandlers = {
        'client-connect:auto:scale:reading': message => {
          readingCallback(
            'success',
            UnitConversionService.convertPoundsAndOuncesToUnitOfMeasure(
              {
                pounds: message.weight.lbs,
                ounces: message.weight.oz
              },
              UnitOfMeasureService.OUNCES.id
            ),
            UnitOfMeasureService.OUNCES.id
          );
        },
        'client-connect:auto:scale:error': message => {
          console.log(`Error reading from scale - ${JSON.stringify(message)}`);
          readingCallback('error', undefined, undefined);
        }
      };

      PusherService.subscribeToChannel(this.autoScaleChannelName).then(() => {
        Object.entries(eventHandlers).forEach(([eventName, handler]) => {
          PusherService.bindToEvent(
            eventName,
            handler,
            this.autoScaleChannelName
          );
        });
      });
    };

    this.refreshConnect();
    const cachedScale = this.getDefaultScale();
    const onlineCachedScale = this.getSavedScale();
    if (cachedScale === undefined) {
      readingCallback('error', undefined, undefined);
      return;
    }
    setUpAutoScalePusherChannel(cachedScale);
    if (!onlineCachedScale || cachedScale.id !== onlineCachedScale.id) {
      readingCallback('error', undefined, undefined);
    }
  }

  static stopAutoScaleReading() {
    PusherService.tearDownChannel(this.autoScaleChannelName);
  }

  static refreshConnect() {
    PusherService.push(PusherMessages.ConnectRequestRefresh, {
      userId: UserService.getUserId()
    });
  }

  static isConnectEnabled() {
    return AppDataService.getSettings().UseConnect === 'true';
  }

  static getWeightFromSavedScale(
    weightCallback,
    readingStartCallback,
    skipErrorMessage
  ) {
    const savedScale = this.getSavedScale();
    if (savedScale === undefined) {
      this.launchScaleModal(weightCallback, readingStartCallback);
      return;
    }

    this.readFromScale(
      savedScale,
      weightCallback,
      readingStartCallback,
      skipErrorMessage
    );
  }

  static getSavedScale() {
    const savedScale = this.getDefaultScale();
    if (!savedScale) {
      return undefined;
    }

    const workstation = AppDataService.getShipstationConnectWorkstations().find(
      appDataWorkstation =>
        appDataWorkstation.WorkstationID === savedScale.workstationId
    );
    if (!workstation) {
      this.clearOrphanedScales(savedScale.workstationId);
      return undefined;
    }

    const workstationScales = this.scales[workstation.WorkstationID];

    if (workstationScales === undefined) {
      return undefined;
    }

    const workstationScale = workstationScales.find(
      scale => scale.id === savedScale.id
    );

    return !workstationScale ? undefined : savedScale;
  }

  static clearOrphanedScales(workstationId) {
    if (!window) {
      return;
    }
    const clearOrphanedScaleByIdentifier = scaleIdentifier => {
      const s2pScaleString = window.localStorage.getItem(scaleIdentifier);
      if (s2pScaleString) {
        const s2pScale = JSON.parse(s2pScaleString);
        if (s2pScale.workstationId === workstationId) {
          window.localStorage.setItem(scaleIdentifier, '{}');
        }
      }
    };
    const scaleIdentifiers = [
      'connect:scale',
      'connect:scales2p',
      'connect:scalenon-s2p'
    ];
    scaleIdentifiers.forEach(identifier => {
      clearOrphanedScaleByIdentifier(identifier);
    });
  }

  static clearOrphanedPrinters(workstationId) {
    const existingPrinters = PrinterService.getLocalStoragePrinters();
    const remainingPrinters = existingPrinters.filter(
      printerDescription =>
        printerDescription.printer.workstationId !== workstationId
    );
    AmplifyService.store(SAVED_PRINTER_AMPLIFY_STRING, remainingPrinters);
  }

  static launchScaleModal(action, weightCallback, readingStartCallback) {
    ModalManagerService.launchModal({
      name: 'shipstationConnectScaleModal',
      backboneModal: true,
      callback: scale => action(scale, weightCallback, readingStartCallback)
    });
  }

  static readFromScale(
    scale,
    weightCallback,
    readingStartCallback,
    skipErrorMessage
  ) {
    if (scale === undefined) {
      weightCallback(undefined, undefined, 'Invalid Scale');
      notify(
        'Invalid Scale',
        'There is a problem with your saved scale. Please select a different scale.',
        true,
        'error'
      );
      return;
    }

    const workstation = AppDataService.getShipstationConnectWorkstations().find(
      connectWorkstation =>
        connectWorkstation.WorkstationID === scale.workstationId
    );
    let workstationScales = this.scales[scale.workstationId];
    if (!workstationScales) {
      workstationScales = workstation.Scales.toJSON();
    }
    const workstationScale = workstationScales.find(
      wScale => wScale.id === scale.id
    );
    const timeoutValue = workstationScale.ssTimeout || 2000;
    const job = {
      type: 'scale:read',
      requestId: uuid.v4(),
      workstationName: workstation.Name,
      callback: weightCallback,
      showError: !skipErrorMessage
    };
    this.jobs[job.requestId] = job;

    setTimeout(() => {
      const matchingJob = this.getMatchingJobAndDeleteFromJobs(job.requestId);
      if (matchingJob === undefined) {
        return;
      }
      matchingJob.callback(undefined, undefined, 'Scale timed out');
      notifyConnectJob({
        text: 'No Response from workstation',
        type: 'error'
      });
    }, timeoutValue);

    if (readingStartCallback !== undefined) {
      readingStartCallback();
    }

    PusherService.push(PusherMessages.ConnectRequestScaleRead, {
      requestId: job.requestId,
      workstationId: scale.workstationId,
      scaleId: scale.id
    });
  }

  static onScaleReadResponse(message) {
    if (!message || !message.data || !message.data.requestId) {
      return;
    }

    const matchingJob = this.getMatchingJobAndDeleteFromJobs(
      message.data.requestId
    );
    if (matchingJob === undefined) {
      return;
    }
    this.processWeightMessage(message, matchingJob.callback);
  }

  static getMatchingJobAndDeleteFromJobs(requestId) {
    const matchingJob = this.jobs[requestId];
    if (matchingJob === undefined) {
      return;
    }
    delete this.jobs[requestId];
    return matchingJob;
  }

  static processWeightMessage(message, weightCallback) {
    const messageData = message.data;
    if (messageData.status !== 'complete') {
      weightCallback(undefined, undefined, 'Error reading from scale');
      if (message.showError) {
        notify(
          'Error Reading From Scale',
          messageData.error.message ||
            'Could not get a reading from the selected scale',
          false,
          'error'
        );
      }
      return;
    }

    weightCallback(
      UnitConversionService.convertPoundsAndOuncesToUnitOfMeasure(
        {
          pounds: messageData.weight.lbs,
          ounces: messageData.weight.oz
        },
        UnitOfMeasureService.OUNCES.id
      ),
      UnitOfMeasureService.OUNCES.id
    );
  }

  static updateScales(scaleMessage) {
    const { data } = scaleMessage;

    if (data.userId && data.userId !== UserService.getUserId()) {
      return;
    }

    const workstations = AppDataService.getShipstationConnectWorkstations();
    const workstation = workstations.find(
      connectWorkstation =>
        connectWorkstation.WorkstationID === data.workstationId
    );
    if (!workstation) {
      return;
    }

    // scale.s is shared
    const scalesICanUse = data.scales.filter(
      scale =>
        scale && (scale.s || workstation.UserID === UserService.getUserId())
    );

    // todo have the mapping done on the backend so we don't have to take this step
    this.scales[data.workstationId] = scalesICanUse.map(scale => {
      return this.mapConnectScaleToModel(scale);
    });
  }

  static updatePrinters(printersMessage) {
    const { data } = printersMessage;

    if (data.userId && data.userId !== UserService.getUserId()) {
      return;
    }

    const workstations = AppDataService.getShipstationConnectWorkstations();
    const workstation = workstations.find(
      connectWorkstation =>
        connectWorkstation.WorkstationID === data.workstationId
    );
    if (!workstation) {
      return;
    }

    const printersICanUse = data.printers.filter(
      printer =>
        printer && (printer.s || workstation.UserID === UserService.getUserId())
    );

    this.printers[data.workstationId] = printersICanUse.map(printer => {
      return {
        id: printer.i,
        name: printer.n,
        shared: printer.s === 1,
        disabled: printer.d === 1,
        ssTimeout: printer.st,
        deviceTimeout: printer.dt,
        weight1Byte: printer.w1b,
        weight2Byte: printer.w2b,
        weight1Factor: printer.w1f,
        weight2Factor: printer.w2f,
        readWait: printer.rw,
        retries: printer.r
      };
    });
  }

  static getSscDefaultPrinter(docType, workstationNeedsToBeOnline = true) {
    const defaultPrinter = PrinterService.findMatchingStoredPrinter(docType);
    if (!defaultPrinter || !defaultPrinter.isSsc) {
      return;
    }

    // TODO: redo this to not rely on the crazy stuff that ShipsStationConnect.js is doing to the collection
    const workstation = Data.Workstations.get(defaultPrinter.workstationId); // eslint-disable-line
    if (!workstation) {
      this.clearOrphanedPrinters(defaultPrinter.workstationId);
      return;
    }

    const workstationOffline = workstation && !workstation.get('Online');
    const workstationPrinterUnavailable =
      workstation &&
      workstation.Printers &&
      !workstation.Printers.toJSON().find(
        printer =>
          printer.id === defaultPrinter.id &&
          printer.online &&
          !printer.hidden &&
          !printer.disabled
      );

    if (
      workstationNeedsToBeOnline &&
      (workstationOffline || workstationPrinterUnavailable)
    ) {
      return;
    }
    return defaultPrinter;
  }

  static getDefaultScale(scalePostfix = '') {
    let scaleStore = window.localStorage.getItem(
      `${SAVED_SCALE_LOCAL_STORAGE_STRING}${scalePostfix}`
    );
    if (!scaleStore) {
      return;
    }
    try {
      scaleStore = JSON.parse(scaleStore);
      //make sure this scale workstationid is still in the local storage Workstations collection
      if (
        !AppDataService.getShipstationConnectWorkstations()
          .map(workstation => workstation.WorkstationID)
          .includes(scaleStore.workstationId)
      ) {
        return undefined;
      }
      scaleStore.name = this.getScaleNameWithoutWorkstationName(
        scaleStore.name
      );
      return scaleStore;
    } catch (e) {
      return undefined;
    }
  }

  static saveDefaultScale(scale, scalePostfix = '') {
    if (!scale || !scale.workstationId) {
      return;
    }
    window.localStorage.setItem(
      `${SAVED_SCALE_LOCAL_STORAGE_STRING}${scalePostfix}`,
      JSON.stringify(scale)
    );
  }

  static hasOnlineWorkstations() {
    return Data.Workstations.toJSON().some(workstation => workstation.Online);
  }

  static getAllAvailablePrinters() {
    const workstations = Data.Workstations.toJSON().filter(
      workstation => workstation.Online
    );
    const printers = workstations.reduce((sum, workstation) => {
      const workstationPrinters = workstation.Printers.toJSON().filter(
        printer => printer.online && !printer.hidden && !printer.disabled
      );
      workstationPrinters.forEach(
        printer => (printer.workstationId = workstation.WorkstationID)
      );
      return sum.concat(workstationPrinters);
    }, []);
    return printers;
  }

  static getAllAvailableScales() {
    const works = Data.Workstations.toJSON().filter(work => work.Online);
    const scales = works.reduce((sum, workstation) => {
      const workstationScales = workstation.Scales.toJSON().filter(
        scale => !scale.disabled
      );
      workstationScales.forEach(
        scale => (scale.workstationId = workstation.WorkstationID)
      );
      return sum.concat(workstationScales);
    }, []);
    return scales;
  }

  static mapConnectScaleToModel(connectScale) {
    return {
      id: connectScale.i,
      name: connectScale.n,
      shared: connectScale.s === 1,
      disabled: connectScale.d === 1,
      ssTimeout: connectScale.st,
      deviceTimeout: connectScale.dt,
      weight1Byte: connectScale.w1b,
      weight2Byte: connectScale.w2b,
      weight1Factor: connectScale.w1f,
      weight2Factor: connectScale.w2f,
      readWait: connectScale.rw,
      retries: connectScale.r
    };
  }

  static mapConnectPrinterToModel(connectPrinter) {
    return {
      id: connectPrinter.i,
      name: connectPrinter.n,
      shared: connectPrinter.s === 1,
      online: connectPrinter.o === 1,
      disabled: connectPrinter.d === 1,
      zplPrintSpeed: connectPrinter.zps,
      zplChunkSize: connectPrinter.zcs,
      zplInvert: connectPrinter.zi === 1,
      zplPrintSpeedRemoval: connectPrinter.zpsd === 1
    };
  }

  static getScaleNameWithoutWorkstationName(scaleName) {
    if (!scaleName) {
      return;
    }
    if (scaleName.indexOf(' / ') > -1) {
      return scaleName.slice(scaleName.indexOf(' / ') + 3);
    }
    return scaleName;
  }

  static getWorkstationById(workstationId) {
    return Data.Workstations.toJSON().find(
      workstation => workstation.WorkstationID === workstationId
    ); // eslint-disable-line
  }

  static printFromSavedPrinter(
    docType,
    printJobArguments,
    stateCallback,
    hideNotifications
  ) {
    const savedPrinter = this.getSscDefaultPrinter(docType);
    if (!savedPrinter) {
      this.launchPrinterModal(
        docType,
        printJobArguments,
        stateCallback,
        hideNotifications
      );
    }

    this.printDocument(
      savedPrinter,
      { docName: docType, ...printJobArguments },
      stateCallback,
      hideNotifications
    );
  }

  static launchPrinterModal(
    docType,
    printJobArguments,
    stateCallback,
    hideNotifications
  ) {
    ModalManagerService.launchModal({
      name: 'shipstationConnectPrinterModal',
      backboneModal: true,
      docType,
      callback: printer =>
        this.printDocument(
          printer,
          { docName: docType, ...printJobArguments },
          stateCallback,
          hideNotifications
        )
    });
  }

  static printDocument(
    printer,
    { docName = 'Print Job', objectType = 'shipment', ids, isReturn },
    stateCallback,
    hideNotifications
  ) {
    const requestId = uuid.v4();
    const job = {
      requestId,
      workstationName: this.getWorkstationById(printer.workstationId).Name,
      docName,
      printerName: printer.Name,
      stateCallback,
      hideNotifications,
      pusherArguments: {
        workstationId: printer.workstationId,
        path: ApiHttpService.prefixUrl(
          `docs/${DOCTYPE_TO_API_ENDPOINT[docName] || 'Custom'}`
        ),
        printerId: printer.id,
        asattachment: true,
        ids: ids.join(','),
        RecordContext: OBJECT_TYPE_TO_RECORD_CONTEXT[objectType],
        isReturn,
        DocTemplate: docName,
        printer: null,
        requestId,
        userId: UserService.getUserId()
      }
    };

    // todo find out all other specific properties that exist for other shipment
    if (objectType === 'shipment') {
      job.pusherArguments.ShipmentID = ids[0];
      job.pusherArguments.shipmentIds = ids.join(',');
    }

    this.jobs[job.requestId] = job;

    if (!job.hideNotifications) {
      job.notification = notifyConnectJob({ text: 'Sending print job...' });
    }

    setTimeout(() => {
      const matchingJob = this.getMatchingJobAndDeleteFromJobs(job.requestId);
      if (matchingJob === undefined) {
        return;
      }

      if (matchingJob.stateCallback) {
        matchingJob.stateCallback('error', 'No Response from workstation');
      }
      if (!matchingJob.hideNotifications) {
        matchingJob.notification.remove();
        notifyConnectJob({
          text: 'No Response from workstation',
          type: 'error'
        });
      }
    }, PRINTER_TIMEOUT_DURATION);

    PusherService.push(
      PusherMessages.ConnectRequestPrinterJob,
      job.pusherArguments
    );
  }

  static onPrinterResponse(message) {
    if (!message || !message.data || !message.data.requestId) {
      return;
    }

    const matchingJob = this.jobs[message.data.requestId];
    if (matchingJob === undefined) {
      return;
    }
    const messageData = message.data;
    if (!messageData) {
      return;
    }
    const { status } = messageData;
    if (!status) {
      return;
    }

    const errorMessage = this.getPrinterErrorMessage(
      matchingJob,
      status,
      message
    );
    this.notifyPrinterStatus(matchingJob, status, errorMessage);

    if (status === 'job:complete') {
      if (matchingJob.stateCallback) {
        matchingJob.stateCallback('success');
      }
      setTimeout(() => {
        delete this.jobs[matchingJob.requestId];
      }, 2000);
      return;
    }

    const errorStatuses = [
      'job:failed',
      'download:failed',
      'printer:missing',
      'printer:failed'
    ];
    if (errorStatuses.indexOf(status) === -1) {
      return;
    }

    if (matchingJob.notification) {
      matchingJob.notification.remove();
    }

    delete this.jobs[matchingJob.requestId];

    if (matchingJob.stateCallback) {
      matchingJob.stateCallback('error', errorMessage);
    }
  }

  static notifyPrinterStatus(job, status, errorMessage) {
    if (job.hideNotifications) {
      return;
    }
    if (status === 'job:accepted') {
      notifyConnectJobUpdate(job.notification, 'Starting print job...');
      return;
    }

    if (status === 'job:complete') {
      notifyConnectJobUpdate(job.notification, 'Sent to printer!');
      setTimeout(() => {
        job.notification.remove();
      }, 2000);
      return;
    }

    if (status === 'job:failed') {
      notify('Print Job Failed', errorMessage, true, 'error');
      return;
    }
    if (status === 'download:failed') {
      notify('Print Job Download Failed', errorMessage, true, 'error');
      return;
    }
    if (status === 'printer:missing') {
      notify('Printer Missing', errorMessage, true, 'error');
      return;
    }
    if (status === 'printer:failed') {
      notify('Print Job Failed', errorMessage, true, 'error');
    }
  }

  static getPrinterErrorMessage(job, status, message) {
    if (status === 'job:failed') {
      return `"${job.docName}" failed to start ${message} on ${job.workstationName}`;
    }
    if (status === 'download:failed') {
      return `"${job.docName}" failed to download ${message} on ${job.workstationName}`;
    }
    if (status === 'printer:missing') {
      return `"${job.docName}" could not be sent to ${job.printerName} because that printer was not found ${message} on ${job.workstationName}`;
    }
    if (status === 'printer:failed') {
      return `"${job.docName}" failed to start ${message} on ${job.workstationName}`;
    }
    return 'Could not print!';
  }
}

ShipstationConnectService.initalize();
