// common pattern for usage
/*
return getFromCacheOrSource(
    ShipEngineAppDataService.SETTING_NAME,
    () => ApiHttpService.get('URL/TO/GET'),
    data => {
      ShipEngineAppDataService.saveSettingToCacheByNamedata);
    }
  );
*/

export const getFromCacheOrSource = (
  getCachedData,
  getData,
  saveCachedData
) => {
  const cache = getCachedData();

  if (cache) {
    return Promise.resolve(cache);
  }

  return new Promise(resolve => {
    getData().then(data => {
      saveCachedData(data);
      resolve(data);
    });
  });
};
