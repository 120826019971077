import AppDataService from './app-data-service';

const KEY = {
  currentCarriers: 'SE_CurrentCarriers',
  currentMarketplaces: 'SE_CurrentMarketplaces',
  allCarriers: 'SE_AllCarriers',
  filteredCarriers: 'SE_FilteredCarriers',
  sandboxCarriers: 'SE_SandboxCarriers',
  cancelInfo: 'SE_CancelInfo',
  billingInfo: 'SE_BillingInfo',
  planInfo: 'SE_PlanInfo',
  webhooks: 'SE_Webhooks',
  apiKeys: 'SE_APIKeys',
  sandboxKeys: 'SE_SandboxKeys',
  shipsuranceSettings: 'SE_ShipsuranceSettings',
  allMarketplaces: 'SE_AllMarketplaces',
  hasSandboxAccount: 'SE_HasSandboxAccount',
  activePartners: 'SE_ActivePartnerIntegrations',
  authInfo: 'SE_AuthInfo',
  metricsSettings: 'SE_MetricsSettings'
};

class ShipEngineAppDataService {
  getSEAllMarketplaces() {
    return AppDataService.getDataFromCache(KEY.allMarketplaces);
  }

  saveSEAllMarketplacesToCache(marketplaces) {
    AppDataService.setDataToCache(KEY.allMarketplaces, marketplaces);
    AppDataService.onChange();
  }

  getSECurrentCarriers() {
    return AppDataService.getDataFromCache(KEY.currentCarriers);
  }

  saveSECurrentCarriersToCache(currentCarriers) {
    AppDataService.setDataToCache(KEY.currentCarriers, currentCarriers);
    AppDataService.setDataToCache('LightSellerProviders', currentCarriers);
    AppDataService.onChange();
  }

  clearCacheSECurrentCarriers() {
    AppDataService.setDataToCache(KEY.currentCarriers, null);
    AppDataService.setDataToCache('LightSellerProviders', null);
    AppDataService.onChange();
  }

  getSECurrentMarketplaces() {
    return AppDataService.getDataFromCache(KEY.currentMarketplaces);
  }

  saveSECurrentMarketplacesToCache(currentMarketplaces) {
    AppDataService.setDataToCache(KEY.currentMarketplaces, currentMarketplaces);
    AppDataService.onChange();
  }

  clearCacheSECurrentMarketplaces() {
    AppDataService.setDataToCache(KEY.currentMarketplaces, null);
    AppDataService.onChange();
  }

  getSEAllCarriers() {
    return AppDataService.getDataFromCache(KEY.allCarriers);
  }

  saveSEAllCarriersToCache(carriers) {
    AppDataService.setDataToCache(KEY.allCarriers, carriers);
    AppDataService.onChange();
  }

  getFilteredSECarriers() {
    return AppDataService.getDataFromCache(KEY.filteredCarriers);
  }

  saveFilteredSECarriersToCache(filteredCarriers) {
    AppDataService.setDataToCache(KEY.filteredCarriers, filteredCarriers);
    AppDataService.onChange();
  }

  getSESandboxCarriers() {
    return AppDataService.getDataFromCache(KEY.sandboxCarriers);
  }

  saveSESandboxCarriersToCache(carriers) {
    AppDataService.setDataToCache(KEY.sandboxCarriers, carriers);
    AppDataService.onChange();
  }

  clearCacheSESandboxCarriers() {
    AppDataService.setDataToCache(KEY.sandboxCarriers, null);
    AppDataService.onChange();
  }

  getSECancelInfo() {
    return AppDataService.getDataFromCache(KEY.cancelInfo);
  }

  saveSECancelInfoToCache(cancelInfo) {
    AppDataService.setDataToCache(KEY.cancelInfo, cancelInfo);
    AppDataService.onChange();
  }

  clearCacheSECancelInfo() {
    AppDataService.setDataToCache(KEY.cancelInfo, null);
    AppDataService.onChange();
  }

  getSEBillingInfo() {
    return AppDataService.getDataFromCache(KEY.billingInfo);
  }

  saveSEBillingInfoToCache(billingInfo) {
    AppDataService.setDataToCache(KEY.billingInfo, billingInfo);
    AppDataService.onChange();
  }

  clearCacheSEBillingInfo() {
    AppDataService.setDataToCache(KEY.billingInfo, null);
    AppDataService.onChange();
  }

  getSEPlanInfo() {
    return AppDataService.getDataFromCache(KEY.planInfo);
  }

  saveSEPlanInfoToCache(planInfo) {
    AppDataService.setDataToCache(KEY.planInfo, planInfo);
    AppDataService.onChange();
  }

  clearCacheSEPlanInfo() {
    AppDataService.setDataToCache(KEY.planInfo, null);
    AppDataService.onChange();
  }

  getSEWebhooks() {
    return AppDataService.getDataFromCache(KEY.webhooks);
  }

  saveSEWebhooksToCache(webhooks) {
    AppDataService.setDataToCache(KEY.webhooks, webhooks);
    AppDataService.onChange();
  }

  clearCacheSEWebhooks() {
    AppDataService.setDataToCache(KEY.webhooks, null);
    AppDataService.onChange();
  }

  getSEAPIKeys() {
    return AppDataService.getDataFromCache(KEY.apiKeys);
  }

  saveSEAPIKeysToCache(keys) {
    AppDataService.setDataToCache(KEY.apiKeys, keys);
    AppDataService.onChange();
  }

  clearCacheSEAPIKeys() {
    AppDataService.setDataToCache(KEY.apiKeys, null);
    AppDataService.onChange();
  }

  getSESandboxKeys() {
    return AppDataService.getDataFromCache(KEY.sandboxKeys);
  }

  saveSESandboxKeysToCache(keys) {
    AppDataService.setDataToCache(KEY.sandboxKeys, keys);
    AppDataService.onChange();
  }

  clearCacheSESandboxKeys() {
    AppDataService.setDataToCache(KEY.sandboxKeys, null);
    AppDataService.onChange();
  }

  getSEShipsuranceSettings() {
    return AppDataService.getDataFromCache(KEY.shipsuranceSettings);
  }

  saveSEShipsuranceSettingsToCache(settings) {
    AppDataService.setDataToCache(KEY.shipsuranceSettings, settings);
    AppDataService.onChange();
  }

  clearCacheSEShipsuranceSettings() {
    AppDataService.setDataToCache(KEY.shipsuranceSettings, null);
    AppDataService.onChange();
  }

  getHasSandboxAccount() {
    return AppDataService.getDataFromCache(KEY.hasSandboxAccount) === true;
  }

  getActivePartners() {
    return AppDataService.getDataFromCache(KEY.activePartners);
  }

  saveActivePartnersToCache(activePartners) {
    AppDataService.setDataToCache(KEY.activePartners, activePartners);
    AppDataService.onChange();
  }

  getMetricsSettings() {
    return AppDataService.getDataFromCache(KEY.metricsSettings);
  }

  saveMetricsSettingsToCache(settings) {
    AppDataService.setDataToCache(KEY.metricsSettings, settings);
    AppDataService.onChange();
  }

  clearCachedMetricsSettings() {
    AppDataService.setDataToCache(KEY.metricsSettings, null);
    AppDataService.onChange();
  }

  getAuthInfo() {
    return AppDataService.getDataFromCache(KEY.authInfo);
  }
}

export default new ShipEngineAppDataService();
