import SellerProviderMixins from '../models/SellerProviderMixins';

define(['backbone'], function(Backbone) {
  var prototype = _.extend(
    {
      idAttribute: 'SellerProviderID'
    },
    SellerProviderMixins
  );

  return Backbone.Model.extend(prototype);
});
