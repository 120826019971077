/* eslint-disable */
// this should NOT be used by anyone except UserService. If you're thinking about including this, use UserService instead!
// Note that there is a lot of bitwise stuff here. I'd like to make it a lot more human readable.
import AppDataService from './app-data-service';

export default class UserAuthService {
  static permit(r, userData) {
    if (!userData || !userData.proviso) {
      return false;
    }
    let proviso = userData.proviso;
    const sd = (2048 & proviso) == 2048;

    //If the user doesn't have enhanced security treat them as an administrator
    if (!this.hasFeature(32) && !sd && AppDataService.getFeatureMask() != 0) {
      proviso = 2;
    }
    const nd = r & proviso;
    const ad = 2 & proviso;

    return nd == r || (ad == 2 && r != 2048) || sd;
  }

  static hasFeature(r) {
    return (r & AppDataService.getFeatureMask()) == r;
  }
}
