const Enums = {
  Provider: {
    Express1: 1,
    Stamps_com: 2,
    UPS: 3,
    FedEx: 4,
    Endicia: 5,
    DHL_GlobalMail: 6,
    USPS_MailInnovations: 8,
    FedEx_InternationalMailService: 9,
    CanadaPost: 11,
    FedExCanada: 12,
    DHLExpress: 13,
    OnTrac: 14,
    Newgistics: 15,
    FirstMile: 16,
    Globegistics: 17,
    LoneStar: 18,
    Asendia: 19,
    RoyalMail: 20,
    APC: 21,
    AccessWorldwide: 22,
    AustraliaPost: 23,
    DHLExpressCanada: 24,
    IMEX: 25,
    ShipStationCarrierServices: 26,
    AmazonBuyShipping: 27,
    DHLExpressAustralia: 28,
    DHLExpressUK: 29,
    PurolatorCanada: 30,
    RRDonnelley: 31,
    FedExUK: 32,
    Sendle: 33,
    StampsWhiteLabel: 34,
    DHLExpressWallet: 38,
    Hermes: 39,
    FedexParcelcast: 40,
    Parcelforce: 41,
    DPD: 42,
    StarTrack: 43,
    ParcelforceWallet: 44,
    HermesCorp: 54,
    DpdWallet: 57,
    Canpar: 60,
    CouriersPlease: 61,
    SekoAU: 63,
    SekoCA: 64,
    SekoUK: 65,
    SekoUS: 66,
    SekoLTL: 105,
    LandmarkGlobal: 69,
    AmazonShippingUk: 80,
    AmazonShippingUs: 81,
    Aramex: 82,
    CourierPost: 85,
    NewZealandPostInternational: 86,
    NewZealandPostPace: 87,
    DpdLocal: 88,
    FastwayAU: 92,
    FastwayNZ: 93,
    IBC: 95,
    UpsWallet: 103,
    CanparWallet: 104,
    ContactSupport: -42
  },
  Carrier: {
    USPS: 1,
    UPS: 3,
    FedEx: 4,
    DHL_Express: 5,
    Other: 6,
    DHL_eCommerce: 7,
    UPS_Mail_Innovations: 8,
    FedEx_International_MailService: 10,
    Canada_Post: 11,
    OnTrac: 14,
    Newgistics: 15,
    FirstMile: 16,
    Globegistics: 17,
    LSO: 18,
    Asendia: 19,
    Royal_Mail: 20,
    APC: 21,
    Access_Worldwide: 22,
    Australia_Post: 23,
    DHL_Express_Canada: 24,
    IMEX: 25,
    Amazon_Buy_Shipping: 27,
    DHL_Express_Australia: 28,
    DHL_Express_UK: 29,
    Purolator_Canada: 30,
    RR_Donnelley: 31,
    Sendle: 33,
    Jet: 35,
    DHL_Express_from_ShipStation: 38,
    Hermes_ShipStation_Carrier_Services: 39,
    FedEx_from_Parcelcast: 40,
    Parcelforce: 41,
    DPD: 42,
    StarTrack: 43,
    Hermes: 54,
    SEKO_Omni_Channel_Logistics: 63,
    SEKO_Omni_Channel_Logistics_CA: 64,
    SEKO_Omni_Channel_Logistics_UK: 65,
    SEKO_Omni_Channel_Logistics_US: 66,
    SEKO_LTL: 105,
    Parcelforce_ShipStation_Carrier_Services: 68,
    Landmark_Global: 70,
    Amazon_Shipping: 80,
    Australia_Post_v2: 84,
    Courierpost: 85,
    New_Zealand_Post_International: 86,
    DPD_Local: 88
  },
  Marketplace: {
    ShipStation: 0,
    EBay: 1,
    Amazon: 2,
    Rakuten: 3,
    PayPal: 6,
    Volusion: 7,
    Etsy: 8,
    GoogleCheckout: 9,
    ZenCart: 10,
    Payvment: 11,
    MagentoGo: 12,
    BigCommerce: 13,
    Shopify: 14,
    AmazonUK: 15,
    OsCommerce: 16,
    XCart: 17,
    ChannelAdvisor: 18,
    OpenCart: 19,
    Memberly: 20,
    Spree: 21,
    CustomStore: 22,
    ThreeDcart: 23,
    Yahoo: 24,
    Sears: 25,
    StitchLabs: 26,
    TopShelf: 27,
    Magento: 28,
    Bizelo: 29,
    LemonStand: 30,
    Highwire: 31,
    AmazonCA: 32,
    Goodsie: 33,
    NewEgg: 34,
    UltraCart: 35,
    WooCommerce: 36,
    CommerceInterface: 37,
    AbanteCart: 38,
    Sophio: 39,
    Storenvy: 40,
    Brightpearl: 41,
    AmazonDE: 42,
    AmazonES: 43,
    AmazonFR: 44,
    AmazonIT: 45,
    EcwidV1: 46,
    Redditgifts: 47,
    Wish: 48,
    Soldsie: 49,
    BestBuy: 50,
    PrestaShop: 51,
    EBayUK: 52,
    EBayCA: 53,
    MivaMerchant: 54,
    Jane: 55,
    Vault: 56,
    Squarespace: 57,
    Bigcommerce: 58,
    AmeriCommerce: 59,
    SkuVault: 60,
    FoxyCart: 61,
    CSCart: 62,
    Bonanza: 63,
    MijoShop: 64,
    SellerActive: 65,
    MoreCommerce: 66,
    TradeGecko: 67,
    GrouponGoods: 68,
    Reverb: 69,
    Choxi: 71,
    AmazonJP: 72,
    NeweggBusiness: 73,
    WooCommerceV2: 74,
    FullscreenDirect: 75,
    Celery: 76,
    Penny: 77,
    Groopdealz: 78,
    CrateJoy: 79,
    DearSystems: 80,
    Freestyle: 81,
    SellBrite: 82,
    Hatch: 83,
    Zoey: 84,
    Linnworks: 85,
    BigCartel: 86,
    AmazonMX: 87,
    SureDone: 88,
    SecureStore: 89,
    NeweggCanada: 90,
    Spreesy: 91,
    Ecwid: 92,
    Walmart: 93,
    Mozu: 94,
    Stripe: 95,
    Tanga: 96,
    Weebly: 97,
    LabelApi: 98,
    EBayAU: 99,
    Houzz: 100,
    Tophatter: 101,
    ODBC: 102,
    SparkShipping: 103,
    ChannelSale: 104,
    CoreCommerce: 105,
    GeekSeller: 106,
    SAPAnywhere: 107,
    Cin7: 108,
    Bandcamp: 109,
    Jet: 110,
    SalesforceCommerceCloud: 111,
    RevolutionParts: 112,
    AmazonAU: 113,
    Handshake: 114,
    Acumatica: 115,
    Facebook: 116,
    ShipEngineApi: 118,
    Wix: 119,
    Overstock: 120,
    ListingMirror: 123,
    Ordertime: 128,
    Volo: 129,
    InkSoft: 130,
    MiraklBestBuyCA: 131,
    AcumaticaV2: 132,
    VisualSoft: 133,
    SalesforceCore: 135,
    NotOnTheHighStreet: 136,
    CustomStore2: 138,
    CDiscount: 139,
    EBayFR: 141,
    Oxatis: 142,
    EbayV2: 144,
    MercadoLibre: 162
  },
  BillToParty: {
    Account: 0,
    Recipient: 1,
    ThirdParty: 2,
    Collect: 3,
    OtherAccount: 4
  },
  FulfillmentProviders: {
    FBA: 1,
    FBAUK: 2,
    FBACA: 3,
    ShipWire: 6,
    Amplifier: 14,
    Demo: 15,
    ShipRelay: 16,
    ShipBob: 17,
    Evobox: 18,
    ShippingTree: 19
  },
  StoreRefreshStatus: {
    SUCCESS: 0,
    QUEUED: 1,
    IMPORTING: 2,
    ERROR: 3
  },
  AccountValues: {
    Stamps_com: 17.99
  }
};

Enums.CarriersThatSupportReturns = [
  Enums.Carrier.USPS,
  Enums.Carrier.UPS,
  Enums.Carrier.FedEx,
  Enums.Carrier.Canada_Post,
  Enums.Carrier.Purolator_Canada
];

Enums.CarriersToIgnoreForShipmentsInTransitChart = [
  Enums.Carrier.DHL_Express,
  Enums.Carrier.Other,
  Enums.Carrier.DHL_eCommerce,
  Enums.Carrier.OnTrac,
  Enums.Carrier.Newgistics,
  Enums.Carrier.Asendia
];

export default Enums;
