import { ApiUrl } from '../../../app/Config';
import HttpService from '../HttpService';

define([], function() {
  function ApiHttpService() {}

  ApiHttpService.prototype.post = function(url, data) {
    return HttpService.post(prefixUrl(url), data);
  };

  ApiHttpService.prototype.get = function(url, data) {
    return HttpService.get(prefixUrl(url), data);
  };

  function prefixUrl(url) {
    return ApiUrl + url;
  }

  return new ApiHttpService();
});
