/*
 * This class represents the singleton data store across modules
 * */
define([
  'collections/admin/AccountManagers',
  'collections/admin/BillingPlans'
], function(AccountManagersColl, BillingPlansColl) {
  // it is important that the ResourceManager load is complete before we use the data below
  var data = {
    AccountManagers: new AccountManagersColl(),
    BillingPlans: new BillingPlansColl(),
    init: function(getDataFn) {
      this.AccountManagers.reset(getDataFn('AccountManagers') || []);
      this.BillingPlans.reset(getDataFn('BillingPlans') || []);
    }
  };

  return data;
});
