import AppDataService from './app-data-service';

export default class PackageService {
  static DEFAULT_PACKAGE_ID = 3;

  static getPackagesByCarrierId(carrierId) {
    const carrierPackageTypes = AppDataService.getCarrierPackageTypes();
    if (!carrierPackageTypes) {
      return [];
    }
    return carrierPackageTypes.filter(
      packageType => packageType.CarrierID === carrierId && !packageType.Hidden
    );
  }

  static getCustomPackages() {
    const customPackages = AppDataService.getCustomPackages();
    if (!customPackages) {
      return [];
    }
    return customPackages.filter(packageType => !packageType.Hidden);
  }

  static isCustomPackage(packageTypeId) {
    if (typeof packageTypeId === 'string') {
      packageTypeId = parseInt(packageTypeId, 10);
    }
    return this.getMatchingCustomPacakge(packageTypeId) !== undefined;
  }

  static getMatchingCustomPacakge(packageTypeId) {
    if (typeof packageTypeId === 'string') {
      packageTypeId = parseInt(packageTypeId, 10);
    }
    return this.getCustomPackages().find(
      customPackage => customPackage.PackageTypeID === packageTypeId
    );
  }

  static getPackage(packageTypeId, carrierId) {
    if (this.isCustomPackage(packageTypeId)) {
      return this.getMatchingCustomPacakge(packageTypeId);
    }
    const carrierPackages = this.getPackagesByCarrierId(carrierId);
    return carrierPackages.find(
      packageType =>
        !packageType.Hidden && packageType.PackageTypeID === packageTypeId
    );
  }
}
