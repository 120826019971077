import LogService from 'client/services/log-service';
import AppDataService from 'client/services/app-data-service';
import AnalyticsRelayService from 'client/services/analytics/analytics-relay-service';
import SegmentService from 'client/services/analytics/segment';
import LogrocketService from 'client/services/analytics/logrocket';
import SentryService from 'client/services/analytics/sentry';
import IPAddressService from 'client/services/api/ip-address-service';
import UserService from 'client/services/user-service';

let initialized = false;
let relayService;

const isShipEngine = AppDataService.getIsShipEngine();

const logExecuteWhenUnInitialized = func => {
  const funcName = func ? func.name : undefined;
  LogService.logToConsole(
    `Attempted to call ${funcName} on AnalyticsService without the service being initialized`
  );
};

const logMultipleInitializations = () => {
  LogService.logToConsole(
    'Attempted to initialize AnalyticsService when it was previously initialized'
  );
};

const executeIfInitialized = (func, args) => {
  if (initialized && relayService) {
    func(...args);
  } else {
    logExecuteWhenUnInitialized(func);
  }
};

export default class AnalyticsService {
  static initialize(ssConfig) {
    if (initialized) {
      logMultipleInitializations();
      return;
    }

    const services = [];

    if (ssConfig.EnableSegment) {
      services.push(
        new SegmentService({
          key: window.SS_GLOBALS.segmentKey,
          isShipEngine
        })
      );
    }

    services.push(new LogrocketService(ssConfig.logRocketKey));

    // initialize Sentry user
    const userData = UserService.getUserData();
    const { userId } = userData;
    const sellerId = UserService.getSellerId();
    const Sentry = new SentryService();
    Sentry.identifyUser(userId, sellerId, userData);
    services.push(Sentry);

    relayService = new AnalyticsRelayService(services);

    initialized = true;
  }

  static trackPage(...args) {
    if (typeof relayService === 'undefined') {
      logExecuteWhenUnInitialized(this.trackPage);
      return;
    }
    executeIfInitialized(relayService.trackPage, args);
  }

  static trackEvent(action, params = {}, ...args) {
    params['Client IP Address'] = IPAddressService.ipAddress;
    if (!relayService) {
      const relayServiceInitializationInterval = setInterval(() => {
        if (!relayService) {
          return;
        }
        clearInterval(relayServiceInitializationInterval);
        executeIfInitialized(relayService.trackEvent, [
          action,
          params,
          ...args
        ]);
      }, 1000);
      return;
    }
    executeIfInitialized(relayService.trackEvent, [action, params, ...args]);
  }
}
