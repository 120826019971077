define(['backbone'], function(Backbone) {
  return Backbone.Collection.extend({
    getFormattedCampaigns: function() {
      return this.pluck('CampaignCode').join(',');
    },
    getFormattedVariations: function() {
      return this.map(function(x) {
        return _.str.sprintf(
          '%s:%s',
          x.get('CampaignCode'),
          x.get('VariationCode')
        );
      }).join(',');
    },
    getVariationCodeForCampaignCode: function(campaignCode) {
      var campaign = this.findWhere({
        CampaignCode: campaignCode
      });

      return campaign ? campaign.get('VariationCode') : null;
    },
    isInABTestCampaignVariation: function(
      requiredCampaignCode,
      requiredVariationCode
    ) {
      var actualVariationCode = this.getVariationCodeForCampaignCode(
        requiredCampaignCode
      );

      return requiredVariationCode === actualVariationCode;
    }
  });
});
