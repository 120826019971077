import { ApiUrl } from '../app/Config';

define(['backbone', 'models/TrackingPageTemplate'], function(
  Backbone,
  PageModel
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Tracking',
    model: PageModel,
    parse: function(resp) {
      return resp.pages;
    }
  });
});
