import jsCookie from 'js-cookie';
import Auth0Session from '../../../client/services/auth0-session';

const CookieAuthFlow = '_auth_flow';

const AuthRedirectService = {
  redirectOnLogout: () => {
    if (jsCookie.get(CookieAuthFlow)) {
      return Auth0Session.logout();
    }
    window.location.reload();
  }
};

export default AuthRedirectService;
