import ApiHttpService from './api-http-service';
import { getFromCacheOrSource } from './api-cache-service';
import ShipEngineAppDataService from '../shipengine-app-data-service';
import ShipEngineSandboxCookieService from '../cookies/shipengine-sandbox-cookie-service';

function ShipEngineKeyService() {}

ShipEngineKeyService.prototype.getKeys = function getKeys() {
  return getFromCacheOrSource(
    () => ShipEngineAppDataService.getSEAPIKeys(),
    () => ApiHttpService.get('account/GetSellerShipEngineApiKeys'),
    data => {
      ShipEngineAppDataService.saveSEAPIKeysToCache(data);
    }
  );
};

ShipEngineKeyService.prototype.disableKey = function disableKey(encryptedKey) {
  ShipEngineAppDataService.clearCacheSEAPIKeys();
  return ApiHttpService.post('account/DisableApiKey', encryptedKey);
};

ShipEngineKeyService.prototype.createKey = function createKey(description) {
  ShipEngineAppDataService.clearCacheSEAPIKeys();
  return ApiHttpService.post('account/CreateShipEngineApiKey', description);
};

ShipEngineKeyService.prototype.getSandboxKeys = function getSandboxKeys() {
  return getFromCacheOrSource(
    () => ShipEngineAppDataService.getSESandboxKeys(),
    () => ApiHttpService.get('shipengine/sandbox/api_keys'),
    data => {
      ShipEngineAppDataService.saveSESandboxKeysToCache(data);
      ShipEngineSandboxCookieService.setCookie(data);
    }
  );
};

ShipEngineKeyService.prototype.disableSandboxKey = function disableKey(
  encryptedKey
) {
  ShipEngineAppDataService.clearCacheSESandboxKeys();
  return ApiHttpService.post('account/DisableApiKey', encryptedKey).then(
    this.getSandboxKeys()
  );
};

ShipEngineKeyService.prototype.createSandboxKey = function createSandboxKey(
  description
) {
  ShipEngineAppDataService.clearCacheSESandboxKeys();
  return ApiHttpService.post(
    'shipengine/sandbox/create_api_key',
    description
  ).then(this.getSandboxKeys());
};

export default new ShipEngineKeyService();
