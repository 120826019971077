import Enums from 'public/core/Enums';
import ProviderFormService from 'client/services/provider-form-service';
import FillProviderFormService from 'client/services/fill-provider-form-service';

const StampsWalletImport = cb => {
  import('./Setup/StampsWallet').then(cb);
};

const wizards = {
  [Enums.Provider.Express1]: cb => {
    import('./Setup/Express1').then(cb);
  },
  [Enums.Provider.Stamps_com]: cb => {
    import('./Setup/Stamps').then(cb);
  },
  [Enums.Provider.UPS]: cb => {
    import('./Setup/UPS').then(cb);
  },
  [Enums.Provider.FedEx]: cb => {
    import('./Setup/FedEx').then(cb);
  },
  [Enums.Provider.Endicia]: cb => {
    import('./Setup/Endicia').then(cb);
  },
  [Enums.Provider.CanadaPost]: cb => {
    import('./Setup/CanadaPost').then(cb);
  },
  [Enums.Provider.DHLExpress]: cb => {
    import('./Setup/DHLExpress').then(cb);
  },
  [Enums.Provider.OnTrac]: cb => {
    import('./Setup/OnTrac').then(cb);
  },
  [Enums.Provider.Newgistics]: cb => {
    import('./Setup/Newgistics').then(cb);
  },
  [Enums.Provider.FirstMile]: cb => {
    import('./Setup/FirstMile').then(cb);
  },
  [Enums.Provider.Globegistics]: cb => {
    import('./Setup/Globegistics').then(cb);
  },
  [Enums.Provider.LoneStar]: cb => {
    import('./Setup/LoneStar').then(cb);
  },
  [Enums.Provider.Asendia]: cb => {
    import('./Setup/Asendia').then(cb);
  },
  [Enums.Provider.RoyalMail]: cb => {
    import('./Setup/RoyalMail').then(cb);
  },
  [Enums.Provider.APC]: cb => {
    import('./Setup/APC').then(cb);
  },
  [Enums.Provider.AccessWorldwide]: cb => {
    import('./Setup/AccessWorldwide').then(cb);
  },
  [Enums.Provider.AustraliaPost]: cb => {
    import('./Setup/AustraliaPost').then(cb);
  },
  [Enums.Provider.IMEX]: cb => {
    import('./Setup/IMEX').then(cb);
  },
  [Enums.Provider.PurolatorCanada]: cb => {
    import('./Setup/PurolatorCanada').then(cb);
  },
  [Enums.Provider.RRDonnelley]: cb => {
    import('./Setup/RRDonnelley').then(cb);
  },
  [Enums.Provider.FedExUK]: cb => {
    import('./Setup/FedExUK').then(cb);
  },
  [Enums.Provider.Sendle]: cb => {
    import('./Setup/Sendle').then(cb);
  },
  [Enums.Provider.DHLExpressWallet]: cb => {
    import('./Setup/StampsDHLExpress').then(cb);
  },
  [Enums.Provider.Hermes]: cb => {
    import('./Setup/Hermes').then(cb);
  },
  [Enums.Provider.Parcelforce]: cb => {
    import('./Setup/Parcelforce').then(cb);
  },
  [Enums.Provider.DPD]: cb => {
    import('./Setup/DPD').then(cb);
  },
  [Enums.Provider.StarTrack]: cb => {
    import('./Setup/StarTrack').then(cb);
  },
  [Enums.Provider.HermesCorp]: cb => {
    import('./Setup/HermesCorp').then(cb);
  },
  [Enums.Provider.DpdWallet]: cb => {
    import('../Wallets/Setup/BillingEngineWallet').then(cb);
  },
  [Enums.Provider.SekoAU]: cb => {
    import('./Setup/SekoAU').then(cb);
  },
  [Enums.Provider.SekoCA]: cb => {
    import('./Setup/SekoCA').then(cb);
  },
  [Enums.Provider.SekoUK]: cb => {
    import('./Setup/SekoUK').then(cb);
  },
  [Enums.Provider.SekoUS]: cb => {
    import('./Setup/SekoUS').then(cb);
  },
  [Enums.Provider.AmazonShippingUk]: cb => {
    import('./Setup/AmazonShippingUk').then(cb);
  },
  [Enums.Provider.AmazonShippingUs]: cb => {
    import('./Setup/AmazonShippingUs').then(cb);
  },
  [Enums.Provider.Aramex]: cb => {
    import('./Setup/Aramex').then(cb);
  },
  [Enums.Provider.LandmarkGlobal]: cb => {
    import('./Setup/LandmarkGlobal').then(cb);
  },
  [Enums.Provider.DpdLocal]: cb => {
    import('./Setup/DPDLocal').then(cb);
  },
  [Enums.Provider.ContactSupport]: cb => {
    import('./Setup/ContactSupport').then(cb);
  },
  StampsWallet: StampsWalletImport,
  [Enums.Provider.StampsWhiteLabel]: StampsWalletImport,
  [Enums.Provider.UpsWallet]: StampsWalletImport
};

const fillWizards = {
  [Enums.FulfillmentProviders.FBA]: cb => {
    import('./Setup/FBA').then(cb);
  },
  [Enums.FulfillmentProviders.FBAUK]: cb => {
    import('./Setup/FBAUK').then(cb);
  },
  [Enums.FulfillmentProviders.FBACA]: cb => {
    import('./Setup/FBACA').then(cb);
  },
  [Enums.FulfillmentProviders.ShipWire]: cb => {
    import('./Setup/ShipWire').then(cb);
  }
};

define([
  'Data',
  'views/Settings/Providers/Setup/ProviderRegistrationWizard',
  'views/Settings/Providers/Setup/FillProviderRegistrationWizard'
], function(Data, ProviderRegistrationWizard, FillProviderRegistrationWizard) {
  let registrationModal;

  const showModalFromConstructor = (
    constructor,
    options,
    id,
    def,
    complete,
    cancelled
  ) => {
    registrationModal = new constructor(options);
    registrationModal.show();
    registrationModal.once('complete', function(isOAuth) {
      if (complete) {
        complete(id);
      }

      def.resolve(isOAuth);
    });
    registrationModal.once('cancelled', function() {
      if (cancelled) {
        // I think this.done is always false but who knows.
        cancelled(id, this.done);
      }
      def.reject();
    });
  };

  const isInStampsWallet = (providerId, sellerProviderId, isWallet) => {
    const isStamps =
      providerId === Enums.Provider.Stamps_com ||
      providerId === Enums.Provider.StampsWhiteLabel;

    if (!isStamps) {
      return false;
    }

    if (isWallet) {
      return true;
    }

    if (sellerProviderId && Data && Data.LightSellerProviders) {
      const sellerProvider = Data.LightSellerProviders.get(sellerProviderId);
      return sellerProvider && sellerProvider.get('WalletID');
    }
  };

  const start = (
    providerId,
    complete,
    isYahoo,
    cancelled,
    returnToOnboard,
    stampsSASConnected,
    sellerProvId,
    isWallet,
    startingStepName
  ) => {
    const def = $.Deferred();
    let type = wizards[providerId];

    if (isInStampsWallet(providerId, sellerProvId, isWallet)) {
      type = wizards['StampsWallet'];
    }

    const options = {
      returnToOnboard: returnToOnboard || false,
      stampsSASConnected: stampsSASConnected || false,
      sellerProvId: sellerProvId || false,
      providerId,
      startingStepName
    };

    if (!type) {
      // If a wizard isn't explicitly defined, check if there's a data-driven one available.
      ProviderFormService.getFormMetadata(
        providerId,
        null,
        'Registration',
        function(formMetadata) {
          options.formMetadata = formMetadata;
          showModalFromConstructor(
            ProviderRegistrationWizard,
            options,
            providerId,
            def,
            complete,
            cancelled
          );
        },
        function() {
          throw new Error(`Missing Wizard for ProviderID: ${providerId}`);
        }
      );
    } else {
      if (isYahoo) {
        options.stampsIframe = true;
        options.cls = 'stamps-modal-dialog';
        options.bgCls = 'stamps-modal-backdrop';
        options.containerCls = 'stamps-modal';
      }

      type(modalConstructor =>
        showModalFromConstructor(
          modalConstructor.default,
          options,
          providerId,
          def,
          complete,
          cancelled
        )
      );
    }

    return def.promise();
  };

  const startFill = (fillProviderId, complete) => {
    const def = $.Deferred();
    const options = {
      fillProviderId
    };
    const type = fillWizards[fillProviderId];
    if (!type) {
      FillProviderFormService.getFormMetadata(
        fillProviderId,
        'Registration',
        function(formMetadata) {
          options.formMetadata = formMetadata;
          showModalFromConstructor(
            FillProviderRegistrationWizard,
            options,
            fillProviderId,
            def,
            complete
          );
        },
        function() {
          throw new Error(
            `Missing Wizard for FillProviderID: ${fillProviderId}`
          );
        }
      );
    }
    type(registrationModalType => {
      showModalFromConstructor(
        registrationModalType.default,
        {},
        fillProviderId,
        def,
        complete
      );
    });

    return def.promise();
  };

  const close = () => {
    if (!registrationModal) {
      return;
    }
    registrationModal.close();
  };

  return {
    start,
    isInStampsWallet,
    startFill,
    close
  };
});
