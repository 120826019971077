import AppDataService from 'client/services/app-data-service';
import WalletList from './wallets/wallet-list';
import NumberFormattingService from './number-formatting-service';
import DisplayInformation from './display-information/display-information';
import ProviderService from 'client/services/provider-service';
import SellerSettingService from 'client/services/seller-setting-service';
import { getEnableUpsWalletFlag } from 'client/services/shipengine/launch-darkly-flag-service';
import Enums from 'public/core/Enums';
import ShipEngineAppDataService from 'client/services/shipengine-app-data-service';

const shouldBeEnabledForShipengine = isShipEngineCarrierServices => {
  // potentially provide a providerId and map to various LD flags for targeted display,
  // unnecessary at this time though

  const shipEngineCarriers =
    ShipEngineAppDataService.getSECurrentCarriers() || [];
  const hasUpsAlready = shipEngineCarriers.some(
    provider => provider.ProviderID === Enums.Provider.UpsWallet
  );

  return (
    AppDataService.getIsShipEngine() &&
    getEnableUpsWalletFlag() &&
    !hasUpsAlready &&
    isShipEngineCarrierServices
  );
};

export default class ProviderWalletService {
  static providerServicesLinkMessage(providerId) {
    const providerServices = AppDataService.getServices().filter(
      service => service.ProviderId === providerId && !service.Hidden
    );
    return !providerServices.length
      ? 'No services selected'
      : `${providerServices.length} services`;
  }

  static getWalletShippingProviders(providersJSON) {
    const walletProviderGroups = {};
    providersJSON.forEach(provider => {
      if (provider.walletId) {
        if (!walletProviderGroups[provider.walletId]) {
          walletProviderGroups[provider.walletId] = [];
        }
        walletProviderGroups[provider.walletId].push(provider);
      }
    });
    return walletProviderGroups;
  }

  static getNonWalletShippingProviders(providersJSON) {
    const nonWalletProviders = [];
    providersJSON.forEach(provider => {
      if (!provider.walletId) {
        nonWalletProviders.push(provider);
      }
    });
    return this.filterOutShipsuranceProviders(nonWalletProviders);
  }

  static filterOutShipsuranceProviders(providers) {
    return providers.filter(
      provider =>
        provider.providerType === undefined || provider.providerType > 0
    );
  }

  static isWalletProvider(shippingServiceProvider) {
    // a wallet shippingServiceProvider may be grouped by the walletId and passed in as an array
    if (Array.isArray(shippingServiceProvider)) {
      return !!shippingServiceProvider[0].walletId;
    }
    return !!shippingServiceProvider.walletId;
  }

  static isPrimaryProviderForWallet(walletedProvider, walletedProviderMeta) {
    if (!walletedProvider || !walletedProviderMeta) {
      return false;
    }
    return walletedProvider.providerId === walletedProviderMeta.providerId;
  }

  static getProviderWalletMetaData(providerId) {
    providerId = NumberFormattingService.coerceOtherTypesToNumber(providerId);
    return WalletList.find(provider =>
      provider.supportedProviders.includes(providerId)
    );
  }

  static getDisplayInformation(providerId) {
    const metaData = this.getProviderWalletMetaData(providerId);
    const defaultDisplayInformation = new DisplayInformation({
      displayName: 'Unknown Wallet',
      name: 'unknown-wallet'
    });
    if (!metaData) {
      return defaultDisplayInformation;
    }
    const { displayInformation } = metaData;
    if (displayInformation) {
      return displayInformation;
    }
    return defaultDisplayInformation;
  }

  static isProviderStatusPending(provider) {
    if (!provider.status) {
      return false;
    }
    return provider.status === 'Pending';
  }

  // Used for Stamps White Label
  static isAutoProvisionedWalletProvider(providerId) {
    let lightSellerProviders = AppDataService.getProviderAccounts();
    let sellerProvider = lightSellerProviders.find(
      provider => provider.ProviderID === providerId
    );
    if (sellerProvider) {
      let isHouseAccount = !!sellerProvider.UseHouseCredentials;
      let isInWallet = !!sellerProvider.WalletID;
      return isHouseAccount && isInWallet;
    }
    return false;
  }

  // Used for Stamps White Label
  static sellerHasAutoProvisionedStampsWhiteLabelWalletProvider() {
    let hasAutoProvisionedStampsWhiteLabelWallet = this.isAutoProvisionedWalletProvider(
      ProviderService.StampsWhiteLabel
    );
    return hasAutoProvisionedStampsWhiteLabelWallet;
  }

  static canConnectWalletedUpsWithRegistration(
    sellerProviders,
    isShipEngineCarrierServices
  ) {
    const isSellerSettingEnabled =
      AppDataService.getIsShipStation() &&
      SellerSettingService.shouldAutoCreateUpsWhenConnectingStamps();
    const doesSellerHaveWalletedUpsThatIsntUsingHouseCreds =
      sellerProviders.filter(
        sp =>
          sp.get('ProviderID') === Enums.Provider.UpsWallet &&
          !sp.get('UseHouseCredentials')
      ).length > 0;
    return (
      (isSellerSettingEnabled ||
        shouldBeEnabledForShipengine(isShipEngineCarrierServices)) &&
      !doesSellerHaveWalletedUpsThatIsntUsingHouseCreds
    );
  }
}
