import nanoajax from 'nanoajax';
import Promise from 'core-js/library/fn/promise';

const http = {
  // exported for testing
  request: nanoajax.ajax.bind(nanoajax),
  _dispatch: options => {
    options.headers = options.headers || {};
    options.headers['git-version'] = window.gitVersion || 'unknown';
    return new Promise((resolve, reject) => {
      http.request(options, (statusCode, responseText) => {
        if (statusCode < 299) {
          let parsedResponse = responseText;
          try {
            parsedResponse = JSON.parse(responseText);
          } catch (e) {
            console.warn(
              'HttpService could not parse response as JSON, returning a string'
            ); // eslint-disable-line
          }
          return resolve({
            statusCode,
            data: parsedResponse
          });
        }
        return reject({
          statusCode,
          responseText
        });
      });
    });
  },
  get: opts => {
    const options = opts || {};
    options.method = 'GET';
    return http._dispatch(options);
  },
  delete: opts => {
    const options = opts || {};
    options.method = 'DELETE';
    return http._dispatch(options);
  },
  post: options => {
    options.method = 'POST';
    if (options.body) {
      options.headers = options.headers || {};
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(options.body);
    }
    return http._dispatch(options);
  },
  postForm: options => {
    options.method = 'POST';
    if (options.body) {
      options.headers = options.headers || {};
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded';

      const fields = Object.keys(options.body)
        .map(key => `${key}=${encodeURIComponent(options.body[key])}`)
        .join('&');
      options.body = fields;
    }
    return http._dispatch(options);
  },
  put: options => {
    options.method = 'PUT';
    if (options.body) {
      options.headers = options.headers || {};
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(options.body);
    }
    return http._dispatch(options);
  }
};

export default http;
