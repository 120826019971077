define(['backbone'], function(Backbone) {
  return Backbone.Model.extend({
    initialize: function() {
      this.on(
        'change',
        function(model, options) {
          if (options && options.parse) {
            return;
          }
          var me = this;
          var i,
            changedAttributes = this.changedAttributes() || [];
          _.each(
            this.attributes,
            function(value, key) {
              if (me.changeAffects && me.changeAffects[key]) {
                var affects = me.changeAffects[key];
                if (!_.isArray(me.changeAffects[key]))
                  affects = [me.changeAffects[key]];
                if (_.has(changedAttributes, key)) {
                  for (i in affects) {
                    this.changed[affects[i]] = this.get(affects[i]);
                    this.trigger('change:' + affects[i]);
                    return;
                  }
                }
              }
            },
            this
          );
        },
        this
      );
    },
    getSetFields: function(key, value, options) {
      if (!key) {
        return null;
      }
      var attributes = {};
      if (!_.isObject(key)) {
        attributes[key] = value;
      } else {
        attributes = key;
        options = value;
      }
      return {
        values: attributes,
        options: options
      };
    },
    set: function() {
      return Backbone.Model.prototype.set.apply(this, arguments);
    },
    get: function(attr) {
      var value = Backbone.Model.prototype.get.call(this, attr);
      return _.isFunction(value) ? value.call(this) : value;
    },
    toJSON: function() {
      var json = Backbone.Model.prototype.toJSON.apply(this, arguments);
      _.each(json, function(value, key) {
        if (typeof value == 'function') {
          delete json[key];
        }
      });
      return json;
    }
  });
});
