import jsCookie from 'js-cookie';

import NoopAnalyticsService from 'client/services/analytics/noop-analytics-service';
import UserService from 'client/services/user-service';
import IPAddressService from 'client/services/api/ip-address-service';
import AppDataService from 'client/services/app-data-service';
import LogService from 'client/services/log-service';
import SellerSettingService from 'client/services/seller-setting-service';
import Enums from 'public/core/Enums';

const isSegmentLoaded = () => !!(analytics && analytics.load);

const userRoles = {
  systemAdmin: {
    isEnabled: () => UserService.isSysAdmin(),
    value: 'system admin'
  },
  administration: {
    isEnabled: () => UserService.hasAccessToAdministration(),
    value: 'administration'
  },
  configuration: {
    isEnabled: () => UserService.hasAccessToConfiguration(),
    value: 'configuration'
  },
  customerManagement: {
    isEnabled: () => UserService.hasCustomerManagement(),
    value: 'customer management'
  },
  inventoryManagement: {
    isEnabled: () => UserService.hasInventoryManagement(),
    value: 'inventory management'
  },
  orderManagement: {
    isEnabled: () => UserService.hasOrderManagement(),
    value: 'order management'
  },
  productManagement: {
    isEnabled: () => UserService.hasProductManagement(),
    value: 'product management'
  },
  purchasing: {
    isEnabled: () => UserService.hasPurchasingRole(),
    value: 'purchasing'
  },
  reporting: {
    isEnabled: () => UserService.hasReportingAccess(),
    value: 'reporting'
  },
  shipping: {
    isEnabled: () => UserService.hasShipping(),
    value: 'shipping'
  }
};

const buildUserRolesList = () => {
  return Object.keys(userRoles)
    .map(key => userRoles[key])
    .filter(userRole => userRole.isEnabled())
    .map(userRole => userRole.value);
};

const buildRestrictions = restrictions => {
  return Object.keys(restrictions)
    .map(key => ({ key, value: restrictions[key] }))
    .filter(restriction => restriction.value)
    .map(restriction => restriction.key);
};

const buildUserIdentifyConfig = (isShipEngine, userData) => {
  return {
    groupId: userData.seller,
    sellerId: userData.seller,
    userId: userData.userId,
    email: userData.email,
    createDate: userData.userSince,
    name: userData.name,
    username: userData.userName,
    currentUIVersion: UserService.getUserSetting('CurrentUIVersion'),
    version: 'V2',
    clientIpAddress: IPAddressService.ipAddress,
    company: userData.pageTitle,
    restrictions: buildRestrictions(userData.restrictions),
    roles: buildUserRolesList()
  };
};

const sellerHasUPSAccount = () => {
  const providerAccounts = AppDataService.getProviderAccounts();
  if (!providerAccounts) {
    return false;
  }
  return AppDataService.getProviderAccounts().some(provider => {
    return [Enums.Provider.UPS, Enums.Provider.UpsWallet].includes(
      provider.ProviderID
    );
  });
};

const buildGroupTraits = userData => {
  return {
    sellerId: userData.seller,
    environment: AppDataService.getEnvironment(),
    homeCountry: userData.homeCountry,
    isNativeV3Seller: SellerSettingService.isV3Native(),
    minimumVersion: AppDataService.getMinimumSemVersion(),
    planName: userData.plan,
    earlyAccessEnabled: SellerSettingService.earlyAccessTurnedOn(),
    hasUPSAccount: sellerHasUPSAccount()
  };
};

const getDisplayInformation = () => ({
  windowInnerHeight: window.innerHeight,
  windowInnerWidth: window.innerWidth,
  screenAvailHeight: window.screen.availHeight,
  screenAvailWidth: window.screen.availWidth,
  screenHeight: window.screen.height,
  screenWidth: window.screen.width,
  clientWidth: document.documentElement.clientWidth,
  clientHeight: document.documentElement.clientHeight,
  devicePixelRatio: window.devicePixelRatio
});

const logInitializeFail = (notLoaded, noUserData, noKey) => {
  const msgs = [];
  if (!notLoaded) {
    msgs.push('segment was not loaded');
  }
  if (noUserData) {
    msgs.push('no user data was available');
  }
  if (noKey) {
    msgs.push('no key was provided');
  }
  LogService.logToConsole(
    `Tried to initialize Segment Service when ${msgs.join(' and ')}`
  );
};
const WINDOW_RESIZE = 'WINDOW_RESIZE';

export default class SegmentAnalyticsService extends NoopAnalyticsService {
  constructor(config) {
    super();
    const userData = UserService.getUserData();
    const isLoaded = isSegmentLoaded();
    const noUserData = !userData;

    if (!isLoaded || !config.key) {
      logInitializeFail(isLoaded, noUserData, !config.key);
      return new NoopAnalyticsService();
    }

    analytics.load(config.key);
    const impersonating = jsCookie.get('x-imp') !== undefined;
    if (userData && !impersonating) {
      const userIdentifyConfig = buildUserIdentifyConfig(
        config.isShipEngine,
        userData
      );

      analytics.identify(userData.userId, userIdentifyConfig);
      analytics.group(userData.seller, buildGroupTraits(userData));
    }
    window.onresize = this.trackResize.bind(this);
  }

  trackPage(name) {
    analytics.page(name, getDisplayInformation());
  }

  trackEvent(action, params) {
    const impersonating = jsCookie.get('x-imp') !== undefined;
    if (impersonating) {
      return;
    }
    analytics.track(action, params);
  }

  trackResize() {
    const impersonating = jsCookie.get('x-imp') !== undefined;
    if (analytics && !impersonating) {
      analytics.track(WINDOW_RESIZE, getDisplayInformation());
    }
  }
}
