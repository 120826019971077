import UserService from 'client/services/user-service';
import SellerSettingService from 'client/services/seller-setting-service';
import AppDataService from 'client/services/app-data-service.js';
import _ from 'underscore';
import V3AccessService from 'client/services/v3-access-service';
const LOCAL_V3_PORT = ':2999';
const LOCAL_V2_PORT = ':3000';
const V2_SUB_DOMAIN_PREPEND = 'ss';
const V3_SUB_DOMAIN_PREPEND = 'ship';
const PREFERRED_VERSION_KEY_NAME = 'CurrentUIVersion';
const V3_ITEMS_GROUPBY_KEY_NAME = 'v3.Orders:Drawer:GroupItemsBy';
const defaultLandingSpot = '/orders';
const DYNAMIC_V2_PREPEND = 'v2-';
const DYNAMIC_V3_PREPEND = 'v3-';
const URI_REGEX = /(http[s]?:\/\/)?([^\/\s]+)(\/.*)/g;
const URI_PATH_GROUP = 3;
// If paths for v2 and v3 are the same, they don't need an entry here.
const v2ToV3SubstitionPaths = [
  {
    v2Path: /#?track\/shipments\/?/g,
    v3Path: 'shipping/'
  },
  {
    v2Path: /#?track\//g,
    v3Path: 'shipping/'
  },
  {
    v2Path: /\/eod/g,
    v3Path: '/end-of-day'
  },
  {
    v2Path: /#\//g,
    v3Path: ''
  }
];

const urlIndexOf = (string, urlPart) => {
  return string.toLowerCase().indexOf(urlPart.toLowerCase());
};

const urlReplace = (string, urlPartFind, urlPartReplace) => {
  return string
    .toLowerCase()
    .replace(urlPartFind.toLowerCase(), urlPartReplace.toLowerCase());
};

class UserInterfaceService {
  isValidSSHome(stringToCompare) {
    if (!stringToCompare) {
      return false;
    }
    return (
      this.isV2SubDomain(stringToCompare) || this.isV3SubDomain(stringToCompare)
    );
  }

  isV2SubDomain(stringToCompare) {
    if (!stringToCompare) {
      return false;
    }
    return (
      stringToCompare.toLocaleLowerCase().indexOf(V2_SUB_DOMAIN_PREPEND) === 0
    );
  }

  isV3SubDomain(stringToCompare) {
    if (!stringToCompare) {
      return false;
    }
    return (
      stringToCompare.toLocaleLowerCase().indexOf(V3_SUB_DOMAIN_PREPEND) === 0
    );
  }

  isUsersPreferredVersionV3() {
    const preferredUi = UserService.getUserSetting(PREFERRED_VERSION_KEY_NAME);
    return preferredUi && preferredUi.toLocaleLowerCase() === 'v3';
  }

  isUsersPreferredVersionV2() {
    const preferredUi = UserService.getUserSetting(PREFERRED_VERSION_KEY_NAME);
    return preferredUi && preferredUi.toLocaleLowerCase() === 'v2';
  }

  ssHomeCookieIsV2(stringToCompare) {
    const subDomain = this.getSubDomain(stringToCompare);
    if (subDomain && typeof subDomain === 'string') {
      return !subDomain.includes(V3_SUB_DOMAIN_PREPEND);
      // v2 subdomain will have ss2/ss3 etc. where as v3 has ship (maybe shipengine too)
    }
    return true;
  }

  getSubDomain(stringToCompare) {
    if (
      stringToCompare &&
      stringToCompare.length &&
      stringToCompare.split('.').length > 0
    ) {
      return stringToCompare.split('.')[0];
    }
  }

  getRedirectHostForV3UserAccessingV2() {
    const siteHost = window.location.host;
    if (!siteHost || this.isV3SubDomain(this.getSubDomain(siteHost))) {
      return;
    }
    return this.getCorrectedSubDomainHostForV3User(siteHost);
  }

  getRedirectLandingSpot(v2Url) {
    var regex = new RegExp(URI_REGEX);
    var match = regex.exec(v2Url);

    if (!match || !_.isArray(match) || match.length < URI_PATH_GROUP + 1) {
      return defaultLandingSpot;
    }

    var v3Url = match[URI_PATH_GROUP];
    v2ToV3SubstitionPaths.forEach(pathObj => {
      v3Url = v3Url.replace(pathObj.v2Path, pathObj.v3Path);
    });

    return v3Url;
  }

  isDynamicEnvironment(stringToCheck) {
    const dynEnvironmentPrepend = `${DYNAMIC_V2_PREPEND}devss`;
    return stringToCheck.includes(dynEnvironmentPrepend);
  }

  getCorrectedSubDomainHostForV3User(siteHost) {
    const siteHostParts = siteHost.split('.');
    // dynamic environments support
    if (!this.isDynamicEnvironment(siteHostParts[0])) {
      siteHostParts[0] = siteHostParts[0].replace(
        V2_SUB_DOMAIN_PREPEND,
        V3_SUB_DOMAIN_PREPEND
      );
    }
    return siteHostParts.join('.');
  }

  optIntoV3() {
    UserService.setUserSetting(V3_ITEMS_GROUPBY_KEY_NAME, 'items');
    UserService.setUserSetting(PREFERRED_VERSION_KEY_NAME, 'V3');
    this.sendUserToV3();
  }

  sendUserToV3() {
    const redirectHost = this.getRedirectHostForV3UserAccessingV2();
    const landingSpot = this.getRedirectLandingSpot(window.location.href);
    let newHref = `${window.location.protocol}//${redirectHost}${landingSpot}`;
    // local dev support
    if (newHref.includes(LOCAL_V2_PORT)) {
      newHref = newHref.replace(LOCAL_V2_PORT, LOCAL_V3_PORT);
    }
    // dynamic environment support
    if (newHref.includes(DYNAMIC_V2_PREPEND)) {
      newHref = newHref.replace(DYNAMIC_V2_PREPEND, DYNAMIC_V3_PREPEND);
    }
    setTimeout(() => {
      window.location.href = newHref;
    }, 500);
  }

  shouldSendUserToV3() {
    if (!V3AccessService.isIframeMode()) {
      const isV3User =
        // Existing rules still honored for beta users
        // This checks user settings for PREFERRED_VERSION_KEY_NAME === 'v3'
        this.isUsersPreferredVersionV3() ||
        // This is to check if the seller is logging in to v2, but is v3 native
        SellerSettingService.isV3Native() ||
        // This is to check if the seller's minimum semantic version is <= 3.0.0
        !SellerSettingService.isV3OptOutAllowed();

      if (isV3User && !UserService.isSysAdmin()) {
        return true;
      }
    }

    return false;
  }

  appIsInIframe() {
    return window && window.self !== window.top;
  }

  appIsIFramedInV3() {
    return this.appIsInIframe() && !!V3AccessService.isIframeMode();
  }

  getDefaultLandingSpot() {
    return defaultLandingSpot;
  }

  _isHostnameForV3(hostname) {
    return (
      urlIndexOf(hostname, 'ship-dev') == 0 ||
      urlIndexOf(hostname, 'v3-dev') == 0 ||
      urlIndexOf(hostname, 'ship') == 0
    );
  }

  _isRedirectingToV3(homeParitionUrl) {
    return this._isHostnameForV3(homeParitionUrl);
  }

  _amIinSslocalDomain() {
    return urlIndexOf(window.location.hostname, 'sslocal.com') > 0;
  }

  _getV3RedirectEquivelantForDev() {
    // These redirects are all based on the user's current location
    const currentHostLowerParts = window.location.hostname.split('.');
    currentHostLowerParts[0] = urlReplace(
      currentHostLowerParts[0],
      'ss-dev',
      'v3-dev'
    );
    currentHostLowerParts[0] = urlReplace(
      currentHostLowerParts[0],
      'v2-dev',
      'v3-dev'
    );
    currentHostLowerParts[0] = urlReplace(
      currentHostLowerParts[0],
      'dev',
      'ship-dev'
    );
    currentHostLowerParts[0] = urlReplace(
      currentHostLowerParts[0],
      'ss',
      'ship'
    );
    return urlReplace(currentHostLowerParts.join('.'), '3000', '2999');
  }

  specialHomeParitionDevFilters(homePartition) {
    if (!homePartition) {
      return window.location.hostname;
    }
    homePartition = this._specialFilterForDisableRedirectToV3(homePartition);
    homePartition = this._specialRedirectFilterForDevEnv(homePartition);
    return homePartition;
  }

  _specialFilterForDisableRedirectToV3(homePartition) {
    return AppDataService.getDisableRedirectToV3() &&
      this._isRedirectingToV3(homePartition)
      ? window.location.hostname
      : homePartition;
  }

  _specialRedirectFilterForDevEnv(hostname) {
    // Special redirect behavior in sslocal.com
    if (this._amIinSslocalDomain()) {
      return this._isHostnameForV3(hostname)
        ? this._getV3RedirectEquivelantForDev()
        : window.location.hostname;
    }
    return hostname;
  }
}

export default new UserInterfaceService();
