const floatAndSemicolonRegularExpression = new RegExp(/^(?:\d+[.;]?)+$/i);

const FloatAndSemicolonValidationMethod = {
  name: 'floatandsemicolon',
  validateValue: value =>
    !value ||
    floatAndSemicolonRegularExpression.test(value.trim().replace(/;\s/g, ';'))
};

export default FloatAndSemicolonValidationMethod;
