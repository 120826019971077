export default {
  id: 'shipengine-default',
  app: 'labelapi',
  name: 'ShipEngine Palette',
  buttonClasses: {
    primary: 'button-primary-shipengine',
    danger: 'button-danger',
    '': 'button',
    dark: 'button-dark',
    unstyled: 'button-unstyled'
  },
  pillClasses: {
    planned: 'planned-status-theme',
    completed: 'completed-status-theme',
    onHold: 'on-hold-status-theme',
    inProgress: 'default-status-theme',
    '': 'default-status-theme'
  }
};
