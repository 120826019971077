define(['backbone'], function(Backbone) {
  return _.extend({}, Backbone.Events, {
    isMatch: function(msg, msgType, props) {
      if (msg.type != msgType) return false;
      if (props) {
        for (var p in props) {
          if (msg.data[p] != props[p]) return false;
        }
      }
      return true;
    },
    waitFor: function(msgType, props, cb) {
      var me = this;
      var fireIfMatch = function(data) {
        if (!me.isMatch(data, msgType, props)) return;

        this.off('app:message:' + msgType, fireIfMatch);
        return cb(data);
      };

      this.on('app:message:' + msgType, fireIfMatch);
    },
    wait: function(topic, cb) {
      var fire = function() {
        this.off(topic, fire);
        return cb.apply(this, arguments);
      };

      this.on(topic, fire);
    },
    trigger: function() {
      //argument[0] is the name for trigerred event
      // console.log('pub- ' + arguments[0]);

      // For logging all arguments passed to triggered event
      // console.log(`pub - ${arguments[0]} - ${JSON.stringify(arguments)}`);
      return Backbone.Events.trigger.apply(this, arguments);
    },
    on: function() {
      return Backbone.Events.on.apply(this, arguments);
    }
  });
});
