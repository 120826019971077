define(['backbone'], function(Backbone) {
  var ProductType = Backbone.Model.extend({
    idAttribute: 'ProductTypeID'
  });

  ProductType.TYPES = {
    STANDALONE: 1,
    PARENT: 2,
    VARIANT: 3
  };

  return ProductType;
});
