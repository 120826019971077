import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@shipstation/svgs';
import style from './fancy-state-icon.less';

const exclamationCircleSolidIcon = () => (
  <Icon version="v2" className={style.icon} name="exclamation-circle-solid" />
);
const exclamationTriangleSolidIcon = () => (
  <Icon version="v2" className={style.icon} name="exclamation-triangle-solid" />
);
const informationCircleSolidIcon = () => (
  <Icon version="v2" className={style.icon} name="information-circle-solid" />
);

const stateToDisplayInformationMap = {
  error: {
    stateClassName: style['error-icon'],
    SvgIcon: exclamationCircleSolidIcon,
    useTriangle: false
  },
  warning: {
    stateClassName: style['warning-icon'],
    SvgIcon: exclamationTriangleSolidIcon,
    useTriangle: true
  },
  info: {
    stateClassName: style['info-icon'],
    SvgIcon: informationCircleSolidIcon,
    useTriangle: false
  }
};

const FancyStateIcon = ({ state }) => {
  const displayInformation =
    stateToDisplayInformationMap[state] || stateToDisplayInformationMap.error;
  const { stateClassName, SvgIcon, useTriangle } = displayInformation;
  const classes = classnames(style['fancy-state-icon'], stateClassName);
  return (
    <div className={classes}>
      {useTriangle && <div className={style.triangle} />}
      <SvgIcon />
    </div>
  );
};

FancyStateIcon.propTypes = {
  state: PropTypes.oneOf(['error', 'warning', 'info']).isRequired
};

export default FancyStateIcon;
