import './style-sheet-helpers.css';
import executePreDataFunctions from './initalization/execute-pre-data-functions';

const initalize = async () => {
  await executePreDataFunctions();
  require('GlobalInit');
  require('ResourceManager');
  window.App = require('label-api/core/App');
  window.App.start();
};

initalize();
