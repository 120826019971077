import PublicDataService from './api/public-data-service';

const cachedData = {};

export default class DataCacheService {
  static getAllCarriers() {
    return this.getData('carriers');
  }

  static getAllServices() {
    return this.getData('services');
  }

  static getAllProviders() {
    return this.getData('providers');
  }

  static getAllPackageTypes() {
    return this.getData('packageTypes');
  }

  static getAllFulfillmentProviders() {
    return this.getData('fillProviders');
  }

  static getAllMarketplaces() {
    return this.getData('marketplaces');
  }

  static getData(propertyName) {
    if (cachedData[propertyName]) {
      return new Promise(resolve => {
        setTimeout(() => resolve(cachedData[propertyName]), 0);
      });
    }
    return new Promise(resolve => {
      PublicDataService.getData(propertyName).then(data => {
        cachedData[propertyName] = data;
        resolve(data);
      });
    });
  }
}
