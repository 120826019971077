import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: 'd91162a2-2e5a-4b61-94b1-61f36a8fa721',
    defaultMessage: "Groovy, let's do this!"
  }),
  defineMessage({
    id: 'f95bf547-e672-4c68-b9e3-50c735c5d20f',
    defaultMessage: 'Cancel'
  })
];

export default translations;
