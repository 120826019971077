import PushEchoService from 'client/services/push-echo-service';
import { validateUuid } from 'client/services/uuid-validation-service';
import { getEnableAsyncClientManager } from 'client/services/launch-darkly-flag-service';
import { AsyncClientManagerWrapper } from 'client/services/pusher/async-client-manager-wrapper';
import UserService from 'client/services/user-service';

/** @typedef QueuedMessage
 *  @property {string} msgType
 *  @property {any} args
 */

let internalPusherService = undefined;

/** @type {QueuedMessage[]} */
let queuedMessages = [];

/** @type {boolean} */
let isAsyncClientManagerEnabled = false;

const initialize = async ({ preferredPusherKey, pusherInstanceConfigs }) => {
  isAsyncClientManagerEnabled = getEnableAsyncClientManager();

  internalPusherService = isAsyncClientManagerEnabled
    ? AsyncClientManagerWrapper
    : PushEchoService;

  // cleanup any existing connections
  tearDown();

  const sellerId = UserService.getSellerId();

  await internalPusherService.initialize({
    sellerId,
    activeSocketKey: preferredPusherKey,
    pusherInstanceConfigs
  });

  await subscribeToChannel(getMainSellerChannelName());

  // send queued messages on main channel
  queuedMessages.forEach(message => {
    push(message.msgType, message.args);
  });
  // reset the queuedMessages
  queuedMessages = [];
};

const subscribeToChannel = async channelName =>
  await internalPusherService?.subscribeToChannel(channelName);

const bindToEvent = (eventName, eventHandler, channelName) =>
  internalPusherService?.bindToEvent(eventName, eventHandler, channelName);

const bindToGlobalEvent = (eventHandler, channelName) =>
  internalPusherService?.bindToGlobalEvent(eventHandler, channelName);

const push = (msgType, args) => internalPusherService?.push(msgType, args);

const tearDownChannel = channelName =>
  internalPusherService?.tearDownChannel(channelName);

const tearDown = () => internalPusherService?.tearDown();

const setActiveSocketKey = key => {
  if (isAsyncClientManagerEnabled) {
    internalPusherService.setActiveSocketKey(key);
  }
};

const getIsWorkstationOnline = workstationId =>
  internalPusherService?.getIsWorkstationOnline(workstationId);

const getIsChannelSubscribed = channelName =>
  internalPusherService?.getIsChannelSubscribed(channelName);

const getSessionId = () => internalPusherService?.getSessionId();

const getMainSellerChannelName = () =>
  internalPusherService?.getMainSellerChannelName();

/**
 * Queue a pusher message to be sent when the main channel
 * is ready OR send the message if the main channel is ready
 *
 * @param msgType
 * @param args
 */
const queuePush = (msgType, args) => {
  const isMainChannelActive = getIsChannelSubscribed(
    getMainSellerChannelName()
  );

  if (isMainChannelActive) {
    // send the message
    push({ msgType, args });
  } else {
    // queue the message
    queuedMessages.push({ msgType, args });
  }
};

const getIsWorkstationMemberId = memberId => {
  // workstation memberId's are guids, but user memberIds are "username + guid"
  // By checking if the memberId is a valid guid, we can determine if it is a workstation or user
  return validateUuid(memberId);
};

export const PusherService = {
  initialize,
  subscribeToChannel,
  bindToEvent,
  bindToGlobalEvent,
  push,
  queuePush,
  tearDownChannel,
  getIsWorkstationOnline,
  getIsWorkstationMemberId,
  getSessionId,
  getMainSellerChannelName,
  setActiveSocketKey
};
