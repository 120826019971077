import { ApiUrl } from '../app/Config';

define(['backbone', 'models/Warehouse'], function(Backbone, WarehouseModel) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Warehouses',
    getName: function(whId) {
      if (!whId) return '';
      var m = this.get(whId);
      if (!m) return '???';
      return m.get('Name');
    },
    model: WarehouseModel,
    parse: function(response) {
      return response.warehouses || [];
    },
    getDefaultWarehouse: function() {
      return this.findWhere({ Default: true });
    }
  });
});
