export default class V3IframeParentService {
  static postMessage(message) {
    window.parent.postMessage(JSON.stringify(message), '*');
  }

  static refresh() {
    var hash = window.location.hash;
    var message = {
      eventType: 'refresh',
      location: hash
    };
    this.postMessage(message);
  }

  static immediatelyUpdateSellerData() {
    const message = {
      eventType: 'immediatelyRefreshSellerData'
    };
    this.postMessage(message);
  }

  static notifyVersion(version) {
    const message = {
      eventType: 'notifyV2Version',
      version
    };
    this.postMessage(message);
  }

  static notifyModalState(isModalOpen) {
    const message = {
      eventType: 'notifyModalOpen',
      isModalOpen
    };

    this.postMessage(message);
  }

  static openOneBalanceModal() {
    const message = {
      eventType: 'openOneBalanceModal'
    };
    this.postMessage(message);
  }
}
