import $ from 'jquery';

const inputGroupSpinnerHandler = async () => {
  $(document).on('click', '.input-group.spinner .btn:first-of-type', function(
    e
  ) {
    e.preventDefault();

    const $input = $(this)
      .closest('.spinner')
      .find('input');
    const max = $input.attr('max');
    const step = parseFloat($input.attr('step')) || 1;
    const val = parseFloat($input.val()) || 0;
    const incremented = val + step;

    if (typeof max !== 'undefined' && incremented > max) {
      $input
        .val(max)
        .change()
        .blur();
      return;
    }

    $input
      .val(Math.round(incremented * 10) / 10)
      .change()
      .blur();
  });
  $(document).on('click', '.input-group.spinner .btn:last-of-type', function(
    e
  ) {
    e.preventDefault();

    const $input = $(this)
      .closest('.spinner')
      .find('input');
    const min = Number.parseFloat($input.attr('min'));
    const step = Number.parseFloat($input.attr('step')) || 1;
    const val = Number.parseFloat($input.val()) || 0;
    const decremented = val - step;

    if (typeof min !== 'undefined' && decremented < min) {
      $input
        .val(min)
        .change()
        .blur();
      return;
    }

    $input
      .val(Math.round(decremented * 10) / 10)
      .change()
      .blur();
  });
};

export default inputGroupSpinnerHandler;
