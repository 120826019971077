const complicatedHtmlRegex = /<(?!\/?(a)(?=>|\s?.*>))\/?.*?>/gi;

const SimpleHtmlValidationMethod = {
  name: 'simpleHtml',
  errorMessage: 'Content cannot include HTML tags, except for links (A)',
  validateValue: function(value, element) {
    return this.optional(element) || !complicatedHtmlRegex.test(value);
  }
};

export default SimpleHtmlValidationMethod;
