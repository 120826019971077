import uuid from 'uuid';
import $ from 'jquery';

import ShipStationConnectService from 'client/services/shipstation-connect-service';
import { PusherMessages } from 'client/services/pusher/pusher-messages';
import { PusherService } from 'client/services/pusher/pusher-service';
import notifyConnectJob from 'public/core/utilities/notify-connect-job';

import AutoScaleChannel from './auto-scale-channel';

import ShipstationConnectData from './shipstation-connect-data';
import ShipstationConnectUtils from './shipstation-connect-utils';

import MemberRemovedPubSubHandler from './pubsub-handlers/member-removed';
import BroadcastPrintersPubSubHandler from './pubsub-handlers/broadcast-printers';
import BroadcastScalesPubSubHandler from './pubsub-handlers/broadcast-scales';
import PrinterJobResponsePubSubHandler from './pubsub-handlers/printer-job-response';
import ScaleReadPubSubHandler from './pubsub-handlers/scale-read';
import WorkstationActivatedPubSubHandler from './pubsub-handlers/workstation-activated';
import WorkstationDeactivatedPubSubHandler from './pubsub-handlers/workstation-deactivated';
import WorkstationUpdatePubSubHandler from './pubsub-handlers/workstation-update';
import ScreencapturePubSubHandler from './pubsub-handlers/screencapture';
import RestartPubSubHandler from './pubsub-handlers/restart';
import VersionPubSubHandler from './pubsub-handlers/version';
import ScaleAutoPubSubHandler from './pubsub-handlers/scale-auto';

import notify from './notify';

const pubsub = require('pubsub');
const Data = require('Data');

const autoScaleChannels = {};

const getAutoScales = () => {
  return (window.localStorage.getItem('connect:scales:auto') || '')
    .split(';')
    .filter(function(i) {
      return i.length > 0;
    })
    .map(function(i) {
      const pair = i.split('_');
      return {
        workstationId: pair[0],
        id: pair[1]
      };
    });
};

const startAutoScale = (workstationId, scaleId) => {
  if (!workstationId || !scaleId) {
    notify(
      'Invalid Scale',
      'There is a problem with your saved scale. Please select a different scale.',
      true,
      'error'
    );
    return;
  }

  const channelName = ShipStationConnectService.createAutoScaleChannelName(
    workstationId,
    scaleId
  );
  if (channelName in autoScaleChannels) {
    return;
  }

  const autoScaleChannel = new AutoScaleChannel(
    workstationId,
    scaleId,
    channelName
  );

  autoScaleChannel.startListening();

  autoScaleChannels[channelName] = autoScaleChannel;
};

const isAutoScaleSupported = () =>
  Data.Settings['Connect:AutoScaleEnabled'] === 'true';

const stopAutoScale = (workstationId, scaleId) => {
  const channelName = ShipStationConnectService.createAutoScaleChannelName(
    workstationId,
    scaleId
  );
  const channel = autoScaleChannels[channelName];
  if (channel) {
    channel.destroy();
    delete autoScaleChannels[channelName];
  }

  ShipstationConnectUtils.updateScale(workstationId, scaleId, false);
};

const configurePrinter = (workstationId, printer) => {
  const printerConfigurationRequest = {
    requestId: uuid.v4(),
    workstationId: workstationId,
    userId: App.user.userId,
    config: {
      id: printer.get('id'),
      name: printer.get('name'),
      shared: printer.get('shared') ? 1 : 0,
      disabled: printer.get('disabled') ? 1 : 0,
      zplPrintSpeed: printer.get('zplPrintSpeed')
        ? parseInt(printer.get('zplPrintSpeed'))
        : undefined,
      zplChunkSize: printer.get('zplChunkSize')
        ? parseInt(printer.get('zplChunkSize'))
        : undefined,
      zplInvert: printer.get('zplInvert') ? 1 : 0,
      zplPrintSpeedRemoval: printer.get('zplPrintSpeedRemoval') ? 1 : 0
    }
  };
  PusherService.push(
    PusherMessages.ConnectRequestPrinterConfig,
    printerConfigurationRequest
  );
};

const configureScale = (workstationId, scale, autoScale) => {
  const configureScaleRequest = {
    requestId: uuid.v4(),
    workstationId: workstationId,
    userId: App.user.userId,
    config: {
      id: scale.get('id'),
      name: scale.get('name'),
      shared: scale.get('shared') ? 1 : 0,
      disabled: scale.get('disabled') ? 1 : 0,
      ssTimeout: scale.get('ssTimeout')
        ? parseInt(scale.get('ssTimeout'))
        : undefined,
      deviceTimeout: scale.get('deviceTimeout')
        ? parseInt(scale.get('deviceTimeout'))
        : undefined,
      weight1Byte: scale.get('weight1Byte')
        ? parseInt(scale.get('weight1Byte'))
        : undefined,
      weight2Byte: scale.get('weight2Byte')
        ? parseInt(scale.get('weight2Byte'))
        : undefined,
      weight1Factor: scale.get('weight1Factor')
        ? parseFloat(scale.get('weight1Factor'))
        : undefined,
      weight2Factor: scale.get('weight2Factor')
        ? parseFloat(scale.get('weight2Factor'))
        : undefined,
      readWait: scale.get('readWait')
        ? parseInt(scale.get('readWait'))
        : undefined,
      retries: scale.get('retries') ? parseInt(scale.get('retries')) : undefined
    }
  };
  PusherService.push(
    PusherMessages.ConnectRequestScaleConfig,
    configureScaleRequest
  );

  toggleAutoScale(workstationId, scale.id, autoScale);
};

const refresh = workstationId => {
  const refreshRequest = {
    requestId: uuid.v4(),
    workstationId: workstationId
  };
  PusherService.push(PusherMessages.ConnectRequestRefresh, refreshRequest);
};

const queueRefresh = workstationId => {
  const refreshRequest = {
    requestId: uuid.v4(),
    workstationId: workstationId
  };
  PusherService.queuePush(PusherMessages.ConnectRequestRefresh, refreshRequest);
};

const requestScreencapture = workstationId => {
  let name = App.user.name;
  const requestId = uuid.v4();

  if (name) {
    const parts = name.split(/\s/);
    name = parts && parts.length > 0 ? parts[0] : null;
  }

  const args = {
    requestId: requestId,
    workstationId: workstationId,
    name: name
  };

  let job = {
    type: 'screencapture',
    requestId: requestId,
    workstationId: workstationId,
    args: args
  };
  job = ShipstationConnectData.jobs.push(job);
  job.def = $.Deferred();

  job.notification = notifyConnectJob({
    text: 'Screencapture Requested...',
    context: $('.modal').find('.modal-content')
  });

  job.responseTimeout = setTimeout(() => {
    job.notification.remove();
    ShipstationConnectData.jobs.remove(job);

    job.notification = notifyConnectJob({
      text: 'No response from workstation',
      type: 'error',
      context: $('.modal').find('.modal-content')
    });
  }, 5000);

  PusherService.push(PusherMessages.ConnectRequestScreenCapture, args);

  return job.def.promise();
};

const requestVersion = workstationId => {
  const requestId = uuid.v4();

  const args = {
    requestId: requestId,
    workstationId: workstationId
  };

  let job = {
    type: 'version',
    requestId: requestId,
    workstationId: workstationId,
    args: args
  };
  job = ShipstationConnectData.jobs.push(job);
  job.def = $.Deferred();

  job.notification = notifyConnectJob({
    text: 'Version Requested...',
    context: $('.modal').find('.modal-content')
  });

  job.responseTimeout = setTimeout(() => {
    job.notification.remove();
    ShipstationConnectData.jobs.remove(job);

    job.notification = notifyConnectJob({
      text: 'No response from workstation',
      type: 'error',
      context: $('.modal').find('.modal-content')
    });
  }, 5000);

  PusherService.push(PusherMessages.ConnectRequestVersion, args);

  return job.def.promise();
};

const requestRestart = workstationId => {
  const requestId = uuid.v4();

  const args = {
    requestId: requestId,
    workstationId: workstationId
  };

  let job = {
    type: 'restart',
    requestId: requestId,
    workstationId: workstationId,
    args: args
  };
  job = ShipstationConnectData.jobs.push(job);
  job.def = $.Deferred();
  job.notification = notifyConnectJob({
    text: 'Restart Requested...',
    context: $('.modal').find('.modal-content')
  });

  job.responseTimeout = setTimeout(function() {
    job.notification.remove();
    ShipstationConnectData.jobs.remove(job);

    job.notification = notifyConnectJob({
      text: 'No response from workstation',
      type: 'error',
      context: $('.modal').find('.modal-content')
    });
  }, 5000);

  PusherService.push(PusherMessages.ConnectRequestRestart, args);

  return job.def.promise();
};

const toggleAutoScale = (workstationId, scaleId, autoMode) => {
  let autoScales = getAutoScales();
  const id = {
    workstationId: workstationId,
    id: scaleId
  };

  if (autoMode) {
    startAutoScale(workstationId, scaleId, _.noop);
    if (!_.findWhere(autoScales, id)) {
      console.warn('adding new autoscale');
      autoScales.push(id);
    } else {
      console.warn('autoscale already in localStorage');
    }
  } else {
    stopAutoScale(workstationId, scaleId, _.noop);
    console.warn(
      'removing autoscale from localStorage. count before: ' + autoScales.length
    );
    autoScales = _.filter(autoScales, function(i) {
      return !_.isEqual(i, id);
    });
    console.warn('count after: ' + autoScales.length);
  }

  const str = autoScales
    .map(function(i) {
      return i.workstationId + '_' + i.id;
    })
    .join(';');
  console.warn('saving autoScales to localStorage: ' + str);
  window.localStorage.setItem('connect:scales:auto', str);
};

const isScaleInAutoMode = (workstationId, scaleId) => {
  const autoScaleSetting =
    window.localStorage.getItem('connect:scales:auto') || '';
  const fullScaleId = `${workstationId}_${scaleId}`;
  return autoScaleSetting.indexOf(fullScaleId) !== -1;
};

const connectAutoScales = () => {
  if (!isAutoScaleSupported()) {
    return;
  }

  getAutoScales().forEach(s => startAutoScale(s.workstationId, s.id, _.noop));
};

const disconnectAutoScales = function() {
  Object.values(autoScaleChannels).forEach(autoScaleChannel => {
    const channelName = autoScaleChannel.channelName;
    autoScaleChannel.destroy();
    delete autoScaleChannels[channelName];
  });
};

const getDefaultAutoScale = cb => {
  getSavedScale((err, scale) => {
    if (err || !scale) {
      return cb(err, false);
    }

    return cb(null, isScaleInAutoMode(scale.workstationId, scale.id));
  });
};

const init = () => {
  const pubsubHandlers = [
    BroadcastPrintersPubSubHandler,
    BroadcastScalesPubSubHandler,
    MemberRemovedPubSubHandler,
    PrinterJobResponsePubSubHandler,
    RestartPubSubHandler,
    ScaleAutoPubSubHandler,
    ScaleReadPubSubHandler,
    ScreencapturePubSubHandler,
    VersionPubSubHandler,
    WorkstationActivatedPubSubHandler,
    WorkstationDeactivatedPubSubHandler,
    WorkstationUpdatePubSubHandler
  ];

  pubsubHandlers.forEach(({ event, handler }) => pubsub.on(event, handler));
};

const isEnabled = () => Data.Settings.UseConnect === 'true';

const startPrintJob = args => {
  const { workstationId, printerId, requestId, docName, userId } = args;
  var workstation = Data.Workstations.findWhere({
    WorkstationID: workstationId
  });
  if (!workstation) {
    return false;
  }
  const printer = _.findWhere(workstation.getActivePrinters(), {
    id: printerId
  });
  const printerName = printer ? printer.get('name') : 'Printer';

  let job = {
    type: 'print:job',
    requestId: requestId,
    args,
    docName: (docName || 'Print Job').trim(),
    workstationName: Data.Workstations.getWorkstationName(workstationId),
    printerName: printerName
  };
  job = ShipstationConnectData.jobs.push(job);
  job.notification = notifyConnectJob({
    text: 'Sending print job...'
  });

  job.responseTimeout = setTimeout(() => {
    job.notification.remove();

    job.notification = notifyConnectJob({
      text: 'No response from workstation',
      type: 'error'
    });
  }, 10000);

  if (!userId) {
    args.userId = App.user.userId;
  }

  PusherService.push(PusherMessages.ConnectRequestPrinterJob, args);
  return true;
};

const getSavedScale = function(cb) {
  const savedScale = window.localStorage.getItem('connect:scale');
  try {
    if (!savedScale) {
      return cb(new Error('No default scale saved.'));
    }

    const parsedScale = JSON.parse(savedScale);

    const workstation = Data.Workstations.get(parsedScale.workstationId);
    if (!workstation) {
      ShipStationConnectService.clearOrphanedScales(parsedScale.workstationId);
      return cb(
        new Error(
          'The workstation for your saved scale is not currently available.'
        )
      );
    }

    const scale = workstation.get('Scales').get(parsedScale.id);
    if (!scale) {
      return cb(new Error('Your saved scale is not currently available.'));
    }

    cb(null, parsedScale);
  } catch (e) {
    return cb(e);
  }
};

const setSavedScale = function(scale) {
  if (!scale) {
    return;
  }
  scale.name = ShipStationConnectService.getScaleNameWithoutWorkstationName(
    scale.name
  );
  ShipStationConnectService.saveDefaultScale(scale);
};

const getScaleReading = (workstationId, scaleId, requestId, cb) => {
  if (!workstationId || !scaleId || !requestId) {
    notify(
      'Invalid Scale',
      'There is a problem with your saved scale. Please select a different scale.',
      true,
      'error'
    );
    return;
  }

  const workstation = Data.Workstations.findWhere({
    WorkstationID: workstationId
  });
  const scale = workstation.get('Scales').get(scaleId);
  const timeoutValue = scale.get('ssTimeout') || 2000;

  let job = {
    type: 'scale:read',
    requestId: requestId,
    workstationName: Data.Workstations.getWorkstationName(workstationId)
  };
  job = ShipstationConnectData.jobs.push(job);

  job.callback = cb;
  job.responseTimeout = setTimeout(() => {
    ShipstationConnectData.jobs.remove(job);

    job.notification = notifyConnectJob({
      text: 'No response from workstation',
      type: 'error'
    });
    cb(new Error('Timeout reading from scale.'));
  }, timeoutValue);

  const args = {
    requestId,
    workstationId,
    scaleId
  };

  PusherService.push(PusherMessages.ConnectRequestScaleRead, args);
};

const ShipStationConnect = {
  downloadEnvironment: window.SS_GLOBALS.frontEndConfig.ConnectEnvironment,
  isEnabled,
  init,
  startPrintJob,
  getSavedScale,
  setSavedScale,
  getScaleReading,
  isAutoScaleSupported,
  stopAutoScale,
  configurePrinter,
  configureScale,
  refresh,
  queueRefresh,
  requestScreencapture,
  requestVersion,
  requestRestart,
  isZplEnabled: () => Data.Settings.LabelFileType === 'ZPLII',
  toggleAutoScale,
  isScaleInAutoMode,
  connectAutoScales,
  disconnectAutoScales,
  getDefaultAutoScale
};

export default ShipStationConnect;
