import AppDataService from './app-data-service';
import DataCacheService from './data-cache-service';
import ProviderService from './provider-service';
import SubscribableService from './subscribable-service';
import UnitOfMeasureService from './unit-of-measure-service';
import UserService from './user-service';
import PackageService from './package-service';
import Enums from 'public/core/Enums';
import SellerFillProviderLookup from 'public/core/services/SellerFillProviderLookup';

// todo we should be getting this information from some backend call instead of hard coding it.
const WEIGHT_LIMITS = {
  10: {
    unitOfMeasure: UnitOfMeasureService.OUNCES.id,
    value: 15.999
  },
  18: {
    unitOfMeasure: UnitOfMeasureService.OUNCES.id,
    value: 15.949
  },
  2700: {
    unitOfMeasure: UnitOfMeasureService.POUNDS.id,
    value: 1
  }
};

class ShippingServiceService extends SubscribableService {
  getServiceGroupings() {
    return [
      { name: 'Carrier', id: 'Carrier' },
      { name: 'Postage Provider', id: 'Provider' }
    ];
  }

  getServiceGroups(
    internationalOnlyServices,
    returnsOnlyServices,
    serviceFilter
  ) {
    const grouping = UserService.getUserSetting('ShippingServiceGrouping');

    if (grouping === 'Provider') {
      return this.getServicesGroupedByProvider(
        internationalOnlyServices,
        returnsOnlyServices,
        serviceFilter
      );
    }

    return this.getServicesGroupedByCarrier(
      internationalOnlyServices,
      returnsOnlyServices,
      serviceFilter
    );
  }

  getFulfillmentServiceGroups(fulfillmentOriginId) {
    return {
      Services: this.getFulfillmentServices(fulfillmentOriginId)
    };
  }

  getFulfillmentServices(fulfillmentOriginId) {
    if (fulfillmentOriginId < 0) {
      const sellerFillProvider = SellerFillProviderLookup.getSellerFillProvider(
        {
          sellerFillProviderId: -fulfillmentOriginId
        }
      );
      return AppDataService.getFulfillmentServices().filter(
        service =>
          !service.Hidden &&
          service.FillProviderID === sellerFillProvider.FillProviderID
      );
    }
    return AppDataService.getFulfillmentServices().filter(
      service =>
        !service.Hidden && service.FillProviderID === fulfillmentOriginId
    );
  }

  getFulfillmentProviderById(fillProviderId) {
    const fillProviders = AppDataService.getFulfillmentProviders().filter(
      provider => provider.FillProviderID === fillProviderId
    );
    return fillProviders && fillProviders.length ? fillProviders[0] : undefined;
  }

  getFulfillmentServiceById(fillServiceId) {
    const services = AppDataService.getFulfillmentServices().filter(
      service => !service.Hidden && service.FillServiceID === fillServiceId
    );
    return !services || !services.length ? undefined : services[0];
  }

  getServices(
    internationalOnlyServices,
    returnsOnlyServices,
    serviceFilter = () => true
  ) {
    const services = AppDataService.getServices();
    if (!services) {
      return [];
    }
    return services.filter(
      service =>
        !service.Hidden &&
        (internationalOnlyServices
          ? service.International
          : !service.International) &&
        (returnsOnlyServices ? service.IsReturnSupported : true) &&
        serviceFilter(service)
    );
  }

  getServicesGroupedByProvider(
    internationalOnlyServices,
    returnsOnlyServices,
    serviceFilter
  ) {
    return this.getServicesGroupByCollection(
      internationalOnlyServices,
      returnsOnlyServices,
      serviceFilter,
      AppDataService.getProviders(),
      'ProviderID',
      'ProviderId'
    );
  }

  getServicesGroupedByCarrier(
    internationalOnlyServices,
    returnsOnlyServices,
    serviceFilter
  ) {
    const groups = this.getServicesGroupByCollection(
      internationalOnlyServices,
      returnsOnlyServices,
      serviceFilter,
      AppDataService.getCarriers(),
      'CarrierID'
    );

    Object.keys(groups).forEach(groupName => {
      const group = groups[groupName];
      // todo. Right now, because of legal reasons we have to hide the services that overlap.
      // this mostly happens with USPS stuff.
      // currently the right order is by the providerID (1: express 1, 2: Stamps.com, 3: Endicia)
      const uniqueGroupItems = [];
      const nameHash = {};
      group.forEach(service => {
        if (nameHash[service.Name] !== undefined) {
          return;
        }
        nameHash[service.Name] = true;
        // todo. We are doing this for parity with the old sort order. But once we can show all permutations then we can remove this
        if (service.ProviderId === ProviderService.Express1) {
          service.Name = `${service.Name} (Exp. 1)`;
        }
        uniqueGroupItems.push(service);
      });

      groups[groupName] = uniqueGroupItems;
    });

    return groups;
  }

  getServicesGroupByCollection(
    internationalOnlyServices,
    returnsOnlyServices,
    serviceFilter,
    collection,
    collectionIdProperty,
    serviceIdProperty
  ) {
    if (!collection) {
      return {};
    }
    if (serviceIdProperty === undefined) {
      serviceIdProperty = collectionIdProperty;
    }
    const serviceGroups = {};
    const services = this.getServices(
      internationalOnlyServices,
      returnsOnlyServices,
      serviceFilter
    );

    collection.forEach(collectionItem => {
      const itemServices = services.filter(
        service =>
          service[serviceIdProperty] === collectionItem[collectionIdProperty]
      );
      if (itemServices.length === 0) {
        return;
      }
      serviceGroups[collectionItem.Name] = itemServices;
    });
    return serviceGroups;
  }

  getServiceFromId(shippingServiceId) {
    if (!shippingServiceId) {
      return;
    }
    if (typeof shippingServiceId === 'string') {
      shippingServiceId = parseFloat(shippingServiceId);
    }
    const connectedServices = AppDataService.getServices();
    if (connectedServices === undefined) {
      return;
    }
    return connectedServices.find(
      shippingService => shippingService.ShippingServiceID === shippingServiceId
    );
  }

  getCarrierIdFromId(shippingServiceId) {
    return this.getPropertyFromId(shippingServiceId, 'CarrierID');
  }

  getProviderIdFromId(shippingServiceId) {
    return this.getPropertyFromId(shippingServiceId, 'ProviderId');
  }

  getPropertyFromId(shippingServiceID, propertyName) {
    const shippingService = this.getServiceFromId(shippingServiceID);
    if (shippingService === undefined) {
      return;
    }
    return shippingService[propertyName];
  }

  getWeightLimitFromID(shippingServiceId) {
    const weightLimit = WEIGHT_LIMITS[shippingServiceId];
    return weightLimit === undefined
      ? {
          unitOfMeasure: UnitOfMeasureService.OUNCES.id,
          value: Number.POSITIVE_INFINITY
        }
      : weightLimit;
  }

  needsAutoFunding(shippingServiceId) {
    if (!shippingServiceId) {
      return false;
    }
    const providerId = this.getProviderIdFromId(shippingServiceId);
    return (
      providerId !== undefined &&
      ProviderService.getProviderDoesAutofunding(providerId)
    );
  }

  hasAutoFunding(shippingServiceId) {
    const providerId = this.getProviderIdFromId(shippingServiceId);
    return ProviderService.getProviderHasAutofundingTurnedOn(providerId);
  }

  getAllServices() {
    if (this.allServices && this.allServices.length !== 0) {
      return this.allServices;
    }
    if (this.gettingAllServices) {
      return [];
    }
    this.gettingAllServices = true;
    DataCacheService.getAllServices().then(
      allServices => {
        this.allServices = allServices;
        this.onChange();
        this.gettingAllServices = false;
      },
      () => (this.gettingAllProviders = false)
    );
    return [];
  }

  isMultiPackageSupported(shippingServiceId, conditionalParams = {}) {
    const service = this.getServiceFromId(shippingServiceId);
    if (!service) {
      return false;
    }

    const providerId = this.getProviderIdFromId(shippingServiceId);
    if (providerId === Enums.Provider.PurolatorCanada) {
      const { packageTypeId } = conditionalParams;
      return (
        service.IsMultiPackageSupported &&
        packageTypeId &&
        packageTypeId === PackageService.DEFAULT_PACKAGE_ID
      );
    }

    return service.IsMultiPackageSupported;
  }
}

export default new ShippingServiceService();
