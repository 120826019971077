import { pusherServiceFactory } from '@shipstation/async-client-manager';
import { defaultPrimaryPusherConfig } from 'client/services/pusher/default-configs';
import { PusherInstanceName } from 'client/services/pusher/pusher-instance-name';
import { authenticatePusher } from 'client/services/pusher/pusher-api-client';

// Unimplemented: Required by the pusher service factory but not used unless `fallbackOptions` are passed into
//  `bindToEvent`, which we are not currently doing and is out of scope for V2.
const onFallbackTimeoutApi = () => {
  throw new Error('onFallbackTimeoutApi is not implemented in V2');
};

// Unimplemented: This property is required to initialize the pusher service, but is only used when receiving a
//  "Message too large" event. Handling this is currently out of scope for V2.
const httpFallback = () => {
  throw new Error('httpFallback is not implemented in V2');
};

// Unimplemented: This property is required to initialize the pusher service, but is not used unless
//  `setShouldUseHttpTrigger(true)` is called, which is currently out of scope for V2.
const onHttpPush = () => {
  throw new Error('onHttpPush is not implemented in V2');
};

const authenticateWithServer = ({
  options,
  socketId,
  channelName,
  pusherAppKey
}) => {
  return authenticatePusher({
    type: options.auth.params.type,
    socket_id: socketId,
    channel_name: channelName,
    pusherAppKey,
    pusherInstanceName:
      pusherAppKey === defaultPrimaryPusherConfig.key
        ? PusherInstanceName.Primary
        : PusherInstanceName.Failover
  });
};

const logger = {
  logInfo: console.info,
  logWarning: console.warn,
  logError: console.error
};

let pusherChannels = [];

const pusherChannelStore = {
  addChannel: channelInfo => {
    pusherChannels.push(channelInfo);
  }
};

const internalPusherService = pusherServiceFactory({ onFallbackTimeoutApi });

let globalEventHandlersForChannel = {};

const initialize = async ({
  sellerId,
  pusherInstanceConfigs,
  activeSocketKey
}) => {
  if (internalPusherService.isInitialized()) {
    return;
  }

  await internalPusherService.initialize({
    sellerId,
    authenticateWithServer,
    pusherInstanceConfigs,
    logger,
    externalStore: pusherChannelStore,
    onHttpPush,
    activeSocketKey,
    httpFallback,
    shouldAllowResubscribe: true
  });

  internalPusherService
    .getMessageHandler()
    .subscribe(undefined, ({ event, data, channelName }) => {
      const eventHandler = globalEventHandlersForChannel[channelName];
      if (eventHandler) {
        eventHandler(event, data);
      }
    });
};

const subscribeToChannel = async channelName =>
  internalPusherService.subscribeToChannels([channelName], () => {});

const bindToEvent = (eventName, eventHandler, channelName) =>
  internalPusherService.bindToEvent(eventName, eventHandler, channelName);

const bindToGlobalEvent = (eventHandler, channelName) => {
  globalEventHandlersForChannel[channelName] = eventHandler;
};

const push = (msgType, args) => internalPusherService.push(msgType, args);

const tearDownChannel = channelName =>
  internalPusherService.tearDownChannel(channelName);

const tearDown = () => {
  pusherChannels.forEach(({ channelName }) => {
    tearDownChannel(channelName);
  });

  pusherChannels = [];
};

const setActiveSocketKey = key => internalPusherService.setActiveSocketKey(key);

const getActiveSocket = () => {
  const connectedSockets = internalPusherService.getConnectedSockets();
  return connectedSockets.find(
    socket => socket.key === internalPusherService.getActiveSocketKey()
  );
};

const getIsWorkstationOnline = workstationId => {
  const activeSocket = getActiveSocket();
  const mainSellerChannel = internalPusherService.getChannelFromSocketByChannelName(
    internalPusherService.mainSellerChannelName,
    activeSocket
  );

  return !!mainSellerChannel?.members.get(workstationId);
};

const getIsChannelSubscribed = channelName =>
  internalPusherService.getIsChannelSubscribed(channelName);

const getMainSellerChannelName = () =>
  internalPusherService.mainSellerChannelName;

const getSessionId = () => getActiveSocket()?.sessionID;

export const AsyncClientManagerWrapper = {
  initialize,
  subscribeToChannel,
  bindToEvent,
  bindToGlobalEvent,
  push,
  tearDownChannel,
  tearDown,
  setActiveSocketKey,
  getIsWorkstationOnline,
  getIsChannelSubscribed,
  getMainSellerChannelName,
  getSessionId
};
