import getVersion from './get-version';
import AnalyticsService from 'client/services/analytics-service';
import AppDataService from 'client/services/app-data-service';
import V3IframeParentService from 'client/services/v3-iframe-parent-service';
import notify from 'public/core/utilities/notify';

const CASUAL_NOTIFICATION_DURATION = 1000 * 60 * 60 * 4; // 4 hours in ms
const CASUAL_NOTIFICATION_THRESHOLD = 1000 * 60 * 60 * 24; // 1 day in ms;

const ANNOYING_NOTIFICATION_DURATION = 1000 * 60 * 60 * 4; // 1 hour in ms
const ANNOYING_NOTIFICATION_THRESHOLD = 1000 * 60 * 60 * 24 * 3; // 1 day in ms;

const VERSION_CHECK_INTERVAL = 1000 * 60 * 5; // five minutes in ms

let currentV2Version = '';

let lastNotificationSent;
let hasSentAnnoyingNotification = false;
let isDev = false;

const getInitialVersionData = () => {
  return new Promise(resolve => {
    getVersion()
      .then(response => {
        currentV2Version = response.data;
        const v2Timestamp = Number.parseInt(currentV2Version.version, 10);
        isDev = Number.isNaN(v2Timestamp);
        resolve();
      })
      .catch();
  });
};

const getVersionData = raw => {
  return new Promise(resolve => {
    getVersion()
      .then(response => {
        const v2Version = response.data;
        if (raw) {
          resolve(v2Version);
          return;
        }
        const v2HasNewVersion = v2Version.version !== currentV2Version.version;
        const v2VersionDate = new Date(Number.parseInt(v2Version.version, 10));

        resolve({
          hasNewVersion: v2HasNewVersion,
          newestVersionDate: v2VersionDate
        });
      })
      .catch();
  });
};

const showRefreshNotification = () => {
  let title = 'New Version Available!';
  let message =
    'A new version of ShipStation is available. Please refresh your browser to see updates.';
  if (AppDataService.getAppMode() === 'labelapi') {
    title = 'Dashboard Update Available!';
    message =
      'We have made a change to the ShipEngine dashboard. Please refresh your browser to see updates.';
  }
  notify(title, message, true, 'success');
  lastNotificationSent = new Date();
};

const sendVersionToV3 = () => {
  getVersionData(true)
    .then(versionData => {
      V3IframeParentService.notifyVersion({
        ...versionData,
        isDev
      });
    })
    .catch();
};

const setupV3VersionInterval = () => {
  getInitialVersionData().then(() => {
    sendVersionToV3();
    setInterval(() => {
      sendVersionToV3();
    }, VERSION_CHECK_INTERVAL);
  });
};

const initRefreshNotifications = () => {
  const isInV3Iframe = window.location.hash.indexOf('v3iframe') !== -1;
  if (isInV3Iframe) {
    setupV3VersionInterval();
    return;
  }
  if (isInV3Iframe) {
    return;
  }
  getInitialVersionData()
    .then(() => {
      if (isDev) {
        return;
      }
      setInterval(() => {
        getVersionData().then(versionData => {
          const { hasNewVersion, newestVersionDate } = versionData;
          if (!hasNewVersion) {
            return;
          }
          const now = new Date();
          if (now - newestVersionDate > ANNOYING_NOTIFICATION_THRESHOLD) {
            if (
              !lastNotificationSent ||
              now - lastNotificationSent > ANNOYING_NOTIFICATION_DURATION
            ) {
              showRefreshNotification();
              if (!hasSentAnnoyingNotification) {
                AnalyticsService.trackEvent('V2-Refresh-Notification-Sent');
              }
              hasSentAnnoyingNotification = true;
            }
            return;
          }
          if (now - newestVersionDate > CASUAL_NOTIFICATION_THRESHOLD) {
            if (
              !lastNotificationSent ||
              now - lastNotificationSent > CASUAL_NOTIFICATION_DURATION
            ) {
              showRefreshNotification();
            }
          }
        });
      }, VERSION_CHECK_INTERVAL);
    })
    .catch();
};

export default initRefreshNotifications;
