import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import DataDrivenLogo from 'client/components/data-driven-logo';
import Enums from 'core/Enums';
import USPSIcon from '../images/svgs/usps/usps-icon-color.svg';
import USPSLogo from '../images/svgs/usps/usps-color.svg';

const logosSelector = '[data-logos-please]';

//don't do an arrow function because we want jquery to set this
$.fn.logosPlease = function() {
  const logosElements = this.find(logosSelector);
  logosElements.each((index, logoElement) => {
    if (!logoElement) {
      return;
    }
    const logoDataType = $(logoElement).data('logos-please-data-type');
    const logoDataId = $(logoElement).data('logos-please-data-id');
    const logoType = $(logoElement).data('logos-please-type');
    let parsedId =
      typeof logoDataId === 'number'
        ? logoDataId
        : Number.parseInt(
            `${logoDataId}`.replace(/"/g, '').replace(/'/g, ''),
            10
          );
    if (Number.isNaN(parsedId)) {
      parsedId = -1;
    }

    // need to exclude USPS because registry has no generic USPS logo.
    if (!(logoDataType === 'carrier' && parsedId === Enums.Carrier.USPS)) {
      const props = {};
      if (logoDataType === 'carrier') {
        props.carrierId = parsedId;
      }
      if (logoDataType === 'provider') {
        props.providerId = parsedId;
      }
      if (logoDataType === 'marketplace') {
        props.marketplaceId = parsedId;
      }
      if (logoDataType === 'fulfillment') {
        props.fillProviderId = parsedId;
      }
      const iconRequested = logoType.indexOf('icon') !== -1;
      ReactDOM.render(
        <DataDrivenLogo
          {...props}
          logoType={iconRequested ? 'icon' : 'logo'}
        />,
        logoElement
      );
      return;
    }

    if (logoDataType === 'icon') {
      ReactDOM.render(<img src={USPSIcon} />, logoElement);
      return;
    }

    ReactDOM.render(<img src={USPSLogo} />, logoElement);
  });
  return this;
};
