import DisplayInformation from '../display-information/display-information';
import ProviderService from '../provider-service';

export default {
  providerId: ProviderService.Stamps_com,
  supportedProviders: [
    ProviderService.Stamps_com,
    ProviderService.DHLExpressWallet,
    ProviderService.FedexParcelcast,
    ProviderService.UpsWallet
  ],
  displayInformation: new DisplayInformation({
    displayName: 'ShipStation Carrier Services',
    name: 'shipstation',
    colorSVG: 'shipstation-color.svg',
    iconColor: 'shipstation-icon-color.svg'
  })
};
