import jsCookie from 'js-cookie';

define([
  'core/libs/extended-marionette',
  'backbone',
  'Session',
  'pubsub'
], function(Marionette, Backbone, Session, pubsub) {
  return Backbone.Router.extend({
    constructor: function(controller) {
      this.controller = controller;
      var appRoutes = Marionette.getOption(this, 'appRoutes');
      this.processAppRoutes(controller, appRoutes);
    },
    processAppRoutes: function(controller, appRoutes) {
      if (!appRoutes) {
        return;
      }
      var routeNames = _.keys(appRoutes).reverse(); // Backbone requires reverted order of routes
      _.each(
        routeNames,
        function(route) {
          this._addAppRoute(controller, route, appRoutes[route]);
        },
        this
      );
    },
    getQueryVariableFromSegment: function(segment, variable) {
      var queryIndex = segment.indexOf('?');
      if (queryIndex > -1) {
        var query = segment.substring(queryIndex + 1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(
              vars[i].substring(vars[i].indexOf('=') + 1)
            );
          }
        }
      } else {
        return null;
      }
    },
    getQueryVariable: function(variable) {
      var segment =
        Backbone.history.location.search || Backbone.history.location.hash;
      return this.getQueryVariableFromSegment(segment, variable);
    },

    before: function(params, next) {
      var caseSensitivePath = Backbone.history.location.hash;
      var path = Backbone.history.location.hash.toLowerCase();

      pubsub.trigger('app:trackEvent', 'Route', {
        Path: path,
        Title: document.title
      });

      var isAuth = typeof jsCookie.get('auth') !== 'undefined';

      // User navigated to a route that requires auth and they are not logged in
      if (!isAuth && path.toLowerCase().indexOf('#/public') != 0) {
        path = this._getOriginalPathIfCaseSensitive(path);
        Session.redirectPath = path;
        App.vent.trigger('Controller.StartModule', {
          moduleName: 'login',
          options: {
            modal: false
          }
        });
        return;
      }

      //TODO: wrobbins- false is just for debugging
      if (false && isAuth && path.toLowerCase().indexOf('#/redirect') == 0) {
        var legacyUrl = path.toLowerCase().substring('#/redirect/'.length);
        if (
          legacyUrl.indexOf('squareconnect') == 0 ||
          legacyUrl.indexOf('ecwidaccept') == 0 ||
          legacyUrl.indexOf('threedcartconnect') == 0
        ) {
          legacyUrl = caseSensitivePath.substring('#/redirect/'.length);
        }
        if (this.processLegacyUrl(legacyUrl)) {
          return;
        } else {
          this.navigate('#/', {
            trigger: true
          });
        }
        return;
      }

      var redirectPath = Session.redirectPath;
      if (isAuth && redirectPath) {
        // User is authenticated and a redirect path exists from an attempt to nav within the app when not auth'd
        Session.loggedIn();
        return;
      }

      //TODO: wrobbins- false is just for debugging
      if (
        false &&
        isAuth &&
        !App.user.plan &&
        !App.user.permit(2048) &&
        App.user.billingproviderid != 4 &&
        App.user.billingproviderid != 6
      ) {
        this.navigate('#/settings/subscriptionplans', {
          trigger: true
        });
        return;
      }

      // No problem, handle the route!!
      return next();
    },
    // Override Backbone's Router.route method to allow before and after functions, which will execute before and after change the route
    route: function(route, name, callback) {
      if (!_.isRegExp(route)) {
        var smartRoute = this._routeToRegExp(route);
      }
      if (_.isFunction(name)) {
        callback = name;
        name = '';
      }
      if (!callback) callback = this[name];

      var router = this;

      // This will translate parameters expressed in the URL such as entity ids in to arguments passed to the corresponding controller method
      Backbone.history.route(smartRoute, function(fragment) {
        var args = router._extractParameters(smartRoute, fragment);

        if (typeof route === 'string') {
          var _paramsRegex = /:([a-zA-Z0-9\-_]+)/g;
          var arr = [];
          route.replace(_paramsRegex, function(s, g1) {
            arr.push(g1);
          });
          args = [_.object(arr, args)];
        }

        var next = function() {
          callback && callback.apply(router, args);
          router.trigger.apply(router, ['route:' + name].concat(args));
          router.trigger('route', name, args);

          Backbone.history.trigger('route', router, name, args);
          router.after.apply(router, args);

          //user may have modified settings that require a hard refresh
          if (
            $('html').data('NeedsRefresh') === true &&
            window.location.href.indexOf('/#/settings') == -1
          ) {
            $('html').data('NeedsRefresh', false);
            window.location.reload();
          }
        };
        router.before.apply(router, [args, next]);
      });
      return this;
    },
    after: function() {},
    _addAppRoute: function(controller, route, methodName) {
      var method = controller[methodName];

      if (!method) {
        throw new Error(
          "Method '" + methodName + "' was not found on the controller"
        );
      }

      if (_.isArray(route)) {
        _.each(route, function(_r) {
          this.route(_r, methodName, _.bind(method, controller));
        });
      } else {
        this.route(route, methodName, _.bind(method, controller));
      }
    },
    _getController: function() {
      return this.controller;
    },
    _getOriginalPathIfCaseSensitive: function(path) {
      if (path && path.indexOf('#/settings/verify/' === 0)) {
        return Backbone.history.location.hash;
      }

      return path;
    }
  });
});
