import { ApiUrl } from '../app/Config';

define(['backbone', 'models/OrderFilter'], function(
  Backbone,
  OrderFilterModel
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Filters',
    model: OrderFilterModel,
    comparator: function(m) {
      return (m.get('Name') || '').toLowerCase();
    },
    getName: function(filterId) {
      if (!filterId) return '';
      var m = this.get(filterId);
      if (!m) return '???';
      return m.get('Name');
    },
    parse: function(resp) {
      return resp.filters;
    }
  });
});
