define(['BaseRouter'], function(BaseRouter) {
  return BaseRouter.extend({
    appRoutes: {
      'public/:module': 'ShowModule',
      'public/:module/:section': 'ShowModule',
      'public/:module/:section/:key': 'ShowModule',
      ':module': 'ShowModule',
      ':module/:section': 'ShowModule',
      ':module/:section/:itemId': 'ShowModule',
      ':module/:section/:action/:queryparams': 'ShowModule', //if the action is search then we want to allow query params to be passed along and set in the filter
      // CF: currently supporting /#/adminSellers via the patterns above but also /#/admin/sellers with the line below
      '*default': 'DefaultAction'
    }
  });
});
