import reactModalManager from 'client/services/modal-manager-service';

define(['ModalLaunchers', 'core/ReactGenericModal'], function(
  ModalLaunchers,
  ReactGenericModal
) {
  function ModalManagerService() {}

  ModalManagerService.prototype.init = function() {
    reactModalManager.listen(handleModalRequest);
  };

  ModalManagerService.prototype.launch = function(modalConfig) {
    return handleModalRequest(modalConfig);
  };

  function handleModalRequest(modalConfig) {
    var launcher = ModalLaunchers.find(function(launcher) {
      return launcher.name === modalConfig.name;
    });

    if (launcher) {
      launcher.launch(modalConfig);
      return;
    }

    return launchReactGenericModal(modalConfig);
  }

  function launchReactGenericModal(modalConfig) {
    delete modalConfig.backboneModal;
    delete modalConfig.name;

    var modal = new ReactGenericModal(modalConfig);
    modal.show();

    modal.on('cancel', function() {
      if (modalConfig.close) {
        modalConfig.close();
      }
    });
  }

  return new ModalManagerService();
});
