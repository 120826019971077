/*
// in ResourceManager
window.ldClient.on('ready', function() {
  window.LDFeatureFlags = window.ldClient.allFlags();
*/

/*
RTFM moment -- use await window.ldClient.waitUntilReady() to async wait until flags are returned
*/
// This file is similar to the factory used in the C# SDK. We can request a specific flag by
// fn import with a default set in one location.

// Deletion PR: https://shipstation.atlassian.net/browse/ENGINE-3458
export const getEnableUpsWalletFlag = () => {
  const { ldClient } = window;
  return ldClient && ldClient.variation('enable-ups-wallet', false);
};

// Deletion PR: https://shipstation.atlassian.net/browse/ENGINE-TBD
export const getEnableShipEngineCarrierServicesFlag = () => {
  const { ldClient } = window;
  return (
    ldClient &&
    ldClient.variation('enable-shipengine-carrier-services-ui', false)
  );
};
