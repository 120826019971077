import uuid from 'uuid';

export default class BackbonePubSubService {
  static subscriptions = {};
  static subscriptionsToAdd = {};
  static backbonePubSub;

  static initialize(backbonePubSub) {
    this.backbonePubSub = backbonePubSub;
    Object.keys(this.subscriptionsToAdd).forEach(subscriptionEventName => {
      if (this.subscriptions[subscriptionEventName] === undefined) {
        this.subscriptions[subscriptionEventName] = {};
      }
      const eventSubscriptions = this.subscriptionsToAdd[subscriptionEventName];
      Object.keys(eventSubscriptions).forEach(subscriptionId => {
        const callback = eventSubscriptions[subscriptionId];
        this.subscriptions[subscriptionEventName][subscriptionId] = callback;
        this.backbonePubSub.on(subscriptionEventName, callback);
      });
    });
    this.subscriptionsToAdd = {};
  }

  static publishBackboneEvent(eventName, data) {
    if (this.backbonePubSub === undefined) {
      return;
    }
    this.backbonePubSub.trigger(eventName, data);
  }

  static subscribeToBackboneEvent(eventName, callback) {
    if (this.backbonePubSub === undefined) {
      return this.addToSubscriptionCollection(
        this.subscriptionsToAdd,
        eventName,
        callback
      );
    }
    if (!callback) {
      console.warn(
        'There is a missing callback in subscribeToBackboneEvent that needs to get cleaned up'
      );
      return;
    }
    this.backbonePubSub.on(eventName, callback);
    return this.addToSubscriptionCollection(
      this.subscriptions,
      eventName,
      callback
    );
  }

  static unsubscribeToBackboneEvent(eventName, subscriptionId) {
    if (!subscriptionId) {
      console.warn(
        'There is a missing subscriptionId in the backbone-pub-sub-service that needs to get cleaned up'
      );
      return;
    }
    if (this.backbonePubSub === undefined) {
      this.removeFromSubscriptionCollection(
        this.subscriptionsToAdd,
        eventName,
        subscriptionId
      );
      return;
    }
    const callback = this.subscriptions[eventName][subscriptionId];
    this.backbonePubSub.off(eventName, callback);
    this.removeFromSubscriptionCollection(
      this.subscriptions,
      eventName,
      subscriptionId
    );
  }

  static addToSubscriptionCollection(
    subscriptionCollection,
    eventName,
    callback
  ) {
    if (subscriptionCollection[eventName] === undefined) {
      subscriptionCollection[eventName] = {};
    }
    const subscriptionId = uuid.v4();
    subscriptionCollection[eventName][subscriptionId] = callback;
    return subscriptionId;
  }

  static removeFromSubscriptionCollection(
    subscriptionCollection,
    eventName,
    subscriptionId
  ) {
    delete subscriptionCollection[eventName][subscriptionId];
  }
}
