define(['backbone', 'models/LightSellerProvider'], function(
  Backbone,
  LightSellerProvider
) {
  return Backbone.Collection.extend({
    model: LightSellerProvider,
    getPrimaryAccountByProviderId: function(providerId) {
      var provider = this.findWhere({
        ProviderID: providerId,
        IsPrimaryAccount: true
      });
      if (provider) {
        return provider;
      }

      return this.findWhere({ ProviderID: providerId });
    },
    getSellerProviderLongName: function(
      providerId,
      sellerProviderId,
      showAccountNumberIfDisabled
    ) {
      var showAccountNumberIfDisabled = showAccountNumberIfDisabled || false;
      var name = '';
      if (!sellerProviderId) return name;

      var sp = this.findWhere({
        SellerProviderID: sellerProviderId,
        ProviderID: providerId
      });

      var count = this.filter(function(p) {
        return p.get('ProviderID') === providerId;
      }).length;

      if (sp) {
        name += sp.get('ProviderName');
        if (App.user.hasFeature(262144)) {
          if (sp.get('Nickname')) name += ' - ' + sp.get('Nickname');
          else if (sp.get('AccountNumber'))
            name += ' - ' + sp.get('AccountNumber');
        } else if (sp.get('AccountNumber') && showAccountNumberIfDisabled) {
          name += ' - ' + sp.get('AccountNumber');
        }

        if (count > 1 && sp.get('IsPrimaryAccount')) {
          name += ' (Primary)';
        }
      } else {
        return '???';
      }

      return name;
    }
  });
});
