const notifyConnectJobUpdate = args => {
  if (!args || !args.notification || !args.text) {
    return console.error('Invalid call to notifyConnectJobUpdate');
  }

  const { notification, text } = args;

  notification.title_container.find('[data-role=message]').html(text);
};

export default notifyConnectJobUpdate;
