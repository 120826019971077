import $ from 'jquery';

const HttpService = {
  post: (url, data) => {
    return $.jjax({
      url: url,
      data: data,
      type: 'POST'
    });
  },
  get: (url, data) => {
    return $.get(url, data);
  }
};

export default HttpService;
