import { PusherMessages } from 'client/services/pusher/pusher-messages';
import { PusherService } from 'client/services/pusher/pusher-service';
import UserService from 'client/services/user-service';
import V3AccessService from 'client/services/v3-access-service';

export default class V3StoreAuthRedirectService {
  static chooseRedirectTarget(redirectUrl) {
    if (V3AccessService.isIframeMode()) {
      PusherService.push(PusherMessages.V3StoreAuthRedirect, {
        redirectUrl,
        userId: UserService.getUserId()
      });
      return;
    }
    window.location.href = redirectUrl;
  }
}
