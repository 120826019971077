import CountryService from './country-service';
import AppDataService from './app-data-service';

export default class FeatureAvailabilityService {
  static canUseShipsurance() {
    return CountryService.countrySupportsShipsurance();
  }

  static internationalAddressValidationEnalbed() {
    return AppDataService.getSetting('IAV.ValidationEnabled') === 'true';
  }
}
