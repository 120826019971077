import AppError from '../../core/AppError';
import operatingSystem from '../../core/utilities/detection/operating-system';
import browser from '../../core/utilities/detection/browser';

define(['jquery', 'backbone', 'BaseController', 'login'], function(
  $,
  Backbone,
  BaseController
) {
  return BaseController.extend({
    activeModules: {},
    workspaceRegion: 'workspace',
    modalRegion: 'modal',
    initialize: function() {
      // create the three primary regions, these elements must exist in index.html
      App.addRegions({
        workspace: '#workspace',
        modal: '#modal',
        topnav: '#se-nav'
      });

      // need base class init stuff
      this._super('initialize');
      const os = operatingSystem();
      //following the same naming convention that modernizr uses instead of just plopping out the detected os into a class
      if (os === 'Windows') {
        $('html').addClass('windowsos');
      } else if (os === 'MacOS') {
        $('html').addClass('macos');
      } else {
        $('html').addClass('otheros');
      }

      //we can handle for any other os right now only win and mac are of import

      const userBrowser = browser();
      if (userBrowser === 'IE' || userBrowser === 'IE11') {
        $('html').addClass('msie');
      } else if (userBrowser === 'FF') {
        $('html').addClass('ff');
      } else if (userBrowser === 'CH') {
        $('html').addClass('chrome');
      } else if (userBrowser === 'SF') {
        $('html').addClass('safari');
      } else if (userBrowser === 'SFI') {
        $('html').addClass('safari ios');
      }

      var doc = document.documentElement;
      doc.setAttribute('data-useragent', navigator.userAgent);
    },
    // this will happen before any call to startModule()
    beforeStartModule: function() {
      App.vent.trigger('CloseModals');
    },

    // CF: this is the general case function that the controller will call
    ShowModule: function(options) {
      this.startModule({
        moduleName: options.module,
        section: options.section || null,
        options: options || {}
      });
    },
    // err default
    DefaultAction: function(options) {
      if (Backbone.history.fragment) {
        AppError(
          {},
          'Path "' + Backbone.history.fragment + '" does not exist.'
        );
      }
      //Default page based on role
      this.startModule({
        moduleName: 'portal',
        section: 'apimanagement' || null,
        options: options || {}
      });
    }
  });
});
