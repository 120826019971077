import 'jquery';
import 'editable';
import EditableFormButtons from './EditableFormButtons.html';

const editableSetup = async () => {
  window.$.fn.editabletypes.date.defaults.tpl =
    '<div class="editable-date"></div>';
  window.$.fn.editableform.buttons = EditableFormButtons;
};

export default editableSetup;
