// this file is based off of https://github.com/serg472/jquery-loadmask
// HOWEVER, that repo was last updated 5 years ago and monkeypatches onto jquery.
// instead this file will provide the same interface in es6 friendly way.
import $ from 'jquery';

/**
 * Checks if a single element is masked. Returns false if mask is delayed or not displayed.
 */
const isMasked = function(jqueryElement) {
  return jqueryElement.hasClass('masked');
};

const maskElement = function(element, label, maskClass) {
  const isModal = element.is('.modal') || element.closest('.modal').length > 0;

  const size = getCachedSize(element);

  //if this element has delayed mask scheduled then remove it and display the new one
  if (element.data('_mask_timeout') !== undefined) {
    clearTimeout(element.data('_mask_timeout'));
    element.removeData('_mask_timeout');
  }

  if (size.height === 0 || size.width === 0) return;
  if (isMasked(element)) {
    return;
  }

  element.addClass('masked');

  let maskDiv = element.data('mask');
  let newMask = false;
  if (!maskDiv) {
    newMask = true;
    const maskDivContent = `<div class="loadmask ${maskClass ? maskClass : ''}">
        <div class="loading">
          <i class="icon-gear"></i>
          <div class="loading-message">${label}</div>
        </div>
      </div>`;
    maskDiv = $(maskDivContent);
    element.data('mask', maskDiv);
  }

  //auto height fix for IE
  if (navigator.userAgent.toLowerCase().indexOf('msie') > -1) {
    maskDiv.height(
      element.height() +
        Number.parseInt(element.css('padding-top')) +
        Number.parseInt(element.css('padding-bottom'))
    );
    maskDiv.width(
      element.width() +
        Number.parseInt(element.css('padding-left')) +
        Number.parseInt(element.css('padding-right'))
    );
  }

  if (isModal) {
    maskDiv.css('width', size.width);
    maskDiv.css('height', size.height);
    if (newMask) {
      $(element).append(maskDiv);
    } else {
      maskDiv.css('display', '');
    }
  } else {
    maskDiv.css('top', size.top);
    maskDiv.css('left', size.left);
    maskDiv.css('width', size.width);
    maskDiv.css('height', size.height);
    if (newMask) {
      if (element.is('bodyxxx')) {
        element.append(maskDiv);
      } else {
        element.offsetParent().append(maskDiv);
      }
    } else {
      maskDiv.css('display', '');
    }
  }
};

const unmaskElement = (element, duration) => {
  //if this element has delayed mask scheduled then remove it
  if (element.data('_mask_timeout') !== undefined) {
    clearTimeout(element.data('_mask_timeout'));
    element.removeData('_mask_timeout');
  }
  if (element.data('mask')) {
    const mask = element.data('mask');
    if (duration) {
      mask.hide('fade', {
        duration: duration
      });
    } else {
      mask.css('display', 'none');
    }
  }
  element.removeClass('masked');
};

/*
 * Displays loading mask over selected element(s). Accepts both single and multiple selectors.
 *
 * @param label Text message that will be displayed on top of the mask besides a spinner (optional).
 *                              If not provided only mask will be displayed without a label or a spinner.
 * @param delay Delay in milliseconds before element is masked (optional). If unmask() is called
 *              before the delay times out, no mask is displayed. This can be used to prevent unnecessary
 *              mask display for quick processes.
 */
export const mask = function(jqueryElement, label, delay, maskClass) {
  if (!label) {
    label = '';
  }
  $(jqueryElement).each(function() {
    if (delay === undefined || delay <= 0) {
      maskElement($(this), label, maskClass);
      return;
    }
    const element = $(this);
    element.data(
      '_mask_timeout',
      setTimeout(() => maskElement(element, label, maskClass), delay)
    );
  });
};

/**
 * Removes mask from the element(s). Accepts both single and multiple selectors.
 */
export const unmask = (jqueryElement, duration) => {
  $(jqueryElement).each(function() {
    unmaskElement($(this), duration);
  });
};

export const cacheSize = function(jqueryElement) {
  let size = null;
  $(jqueryElement).each(function() {
    // cache this thing rather than re-create it for each line below
    const $this = $(this);

    if (!$this.is(':visible')) {
      $this.data('cached-size', null);
      size = {
        top: 0,
        left: 0,
        height: 0,
        width: 0
      };
      return size;
    }
    size = $this.position();

    // make sure we include the padding, borders, and margins
    size.height = $this.outerHeight(false);
    size.width = $this.outerWidth(false);

    $this.data('cached-size', size);
    $this.addClass('cached-size');
  });
  return size;
};

export const getCachedSize = (jqueryElement, opts) => {
  const safeOpts = {
    refresh: false,
    ...opts
  };

  let size = $(jqueryElement).data('cached-size');
  if (!size || safeOpts.refresh) size = cacheSize($(jqueryElement));
  return size;
};
