class ButtonThemeService {
  getButtonThemes() {
    return [
      'primary',
      'danger',
      '',
      'link',
      'high-contrast-link',
      'dark',
      'unstyled',
      'danger-link',
      'primary-green',
      'white'
    ];
  }
}

export default new ButtonThemeService();
