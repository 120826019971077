import { ApiUrl } from '../app/Config';

define(['backbone', 'pubsub'], function(Backbone, pubsub) {
  return Backbone.Model.extend({
    initialize: function() {
      pubsub.on('app:message:gcp_info', $.proxy(this.onGcpInfoUpdated, this));
    },
    onGcpInfoUpdated: function(data) {
      this.set(data.data);
      pubsub.trigger('printersettings:gcp:info_updated', this);
    },
    isSignedIn: function() {
      return this.get('account') && this.get('account') !== 'Not signed in';
    },
    getAccountName: function() {
      return this.get('account');
    },
    hasPrinter: function(id) {
      return this.getPrinters().some(function(p) {
        p.id === id;
      });
    },
    getPrinters: function() {
      return _.sortBy(this.get('printers') || [], 'name');
    },
    getSignInUrl: function() {
      return ApiUrl + 'GCP/SignIn';
    },
    signOut: function() {
      return $.jjax({
        type: 'POST',
        url: ApiUrl + 'GCP/SignOut'
      });
    },
    refresh: function() {
      return $.jjax({
        type: 'POST',
        url: ApiUrl + 'GCP/Refresh'
      });
    }
  });
});
