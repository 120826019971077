import React from 'react';
import PropTypes from 'prop-types';
import { ProviderLogo, MarketplaceLogo } from '@shipstation/svgs';
import getDisplayInformation from './get-display-information';

const DataDrivenLogo = ({
  providerId,
  carrierId,
  marketplaceId,
  fillProviderId,
  logoType,
  ...rest
}) => {
  const displayInformation = getDisplayInformation({
    providerId,
    carrierId,
    marketplaceId,
    fillProviderId
  });
  if (marketplaceId !== undefined) {
    return (
      <MarketplaceLogo
        displayInformation={displayInformation}
        type={logoType}
        {...rest}
      />
    );
  }
  return (
    <ProviderLogo
      displayInformation={displayInformation}
      type={logoType}
      {...rest}
    />
  );
};

DataDrivenLogo.propTypes = {
  providerId: PropTypes.number,
  carrierId: PropTypes.number,
  marketplaceId: PropTypes.number,
  fillProviderId: PropTypes.number,
  logoType: PropTypes.oneOf(['icon', 'logo'])
};

export default DataDrivenLogo;
