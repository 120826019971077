define(['WeightAndMeasureModel'], function(WeightAndMeasureModel) {
  return WeightAndMeasureModel.extend({
    defaults: _.extend({}, WeightAndMeasureModel.prototype.defaults),
    isUncommited: false,
    initialize: function() {
      this.isDirty = false;
      this._super('initialize', arguments);
      this.on('change', function(model, options) {
        options = options || {};
        if (!options.clean && !options.parse) {
          model.isDirty = true;
          model.isUncommited = true;
        }
      });
      this.on('sync', function(model) {
        model.isDirty = false;
        model.commit();
        return model;
      });
    },
    commit: function() {
      this.lastAttributes = _.clone(this.attributes);
      this.lastDirty = this.isDirty;
      this.isUncommited = false;
    },
    parse: function() {
      var res = this._super('parse', arguments);
      this.commit();
      return res;
    },
    rollback: function() {
      if (this.lastAttributes) {
        this.set(this.lastAttributes, {
          silent: true
        });
        this.isDirty = this.lastDirty;
        this.isUncommited = false;
        if (this.collection) {
          this.collection.trigger('change', this);
        }
      }
    }
  });
});
