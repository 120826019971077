import Pusher from 'pusher-js';

import UserService from './user-service';
import Auth0Session from './auth0-session';
import { defaultPrimaryPusherConfig } from 'client/services/pusher/default-configs';
import { PusherInstanceName } from 'client/services/pusher/pusher-instance-name';

/**
 * @typedef PusherClient
 * @property {string} sessionID
 * @property {function} connect
 * @property {function} disconnect
 * @property {function} subscribe
 * @property {function} unsubscribe
 */

/**
 * @typedef PusherChannel
 * @property {function} bind
 * @property {function} unbind
 * @property {function} trigger
 */

const createPusherInstance = (userId, pusherInstanceConfig) => {
  const pusherInstanceName =
    pusherInstanceConfig.key === defaultPrimaryPusherConfig.key
      ? PusherInstanceName.Primary
      : PusherInstanceName.Failover;

  return new Pusher(pusherInstanceConfig.key, {
    authEndpoint: '/api/pusher/auth',
    auth: {
      params: {
        info: JSON.stringify({
          userId
        }),
        type: 'User',
        userID: userId,
        pusherInstanceName
      },
      headers: {
        Authorization: `Bearer ${Auth0Session.getTokenFast()}`
      }
    },
    cluster: pusherInstanceConfig.cluster
  });
};

/** @type {PusherClient} */
let pusherClient = undefined;

/** @type {Object.<string, PusherChannel>} */
const pusherChannels = {};

/** @type {string} */
let mainSellerChannelName = undefined;

// todo Eventually this should replace PushEcho in the backbone.
export default class PushEchoService {
  static async initialize({
    sellerId,
    activeSocketKey,
    pusherInstanceConfigs
  }) {
    mainSellerChannelName = `presence-${sellerId}`;
    if (pusherChannels[mainSellerChannelName] !== undefined) {
      return;
    }

    if (!pusherClient) {
      const userId = UserService.getUserId();

      const activePusherInstanceConfig = pusherInstanceConfigs.find(
        ({ key }) => key === activeSocketKey
      );

      pusherClient = createPusherInstance(userId, activePusherInstanceConfig);
    } else {
      pusherClient.disconnect();
      pusherClient.connect();
    }
  }

  static async subscribeToChannel(channelName) {
    const channel = pusherClient.subscribe(channelName);

    await new Promise((resolve, reject) => {
      if (!channel.subscribed) {
        channel.bind('pusher:subscription_succeeded', () => {
          channel.unbind('pusher:subscription_succeeded');
          channel.unbind('pusher:subscription_error');
          resolve(channel);
        });

        channel.bind('pusher:subscription_error', () => {
          channel.unbind('pusher:subscription_succeeded');
          channel.unbind('pusher:subscription_error');
          reject(channel);
        });
      } else {
        resolve(channel);
      }
    });

    pusherChannels[channelName] = channel;
  }

  static bindToEvent(eventName, eventHandler, channelName) {
    const channel = pusherChannels[channelName];

    channel?.bind(eventName, eventHandler);
  }

  static bindToGlobalEvent(eventHandler, channelName) {
    const channel = pusherChannels[channelName];

    channel?.bind_global(eventHandler);
  }

  static tearDownChannel(channelName) {
    const channel = pusherChannels[channelName];

    if (channel) {
      channel.unbind();
      pusherClient.unsubscribe(channelName);
      delete pusherChannels[channelName];
    }
  }

  static push(msgType, args) {
    const channel = pusherChannels[mainSellerChannelName];

    channel?.trigger(`client-${msgType}`, args);
  }

  static getIsWorkstationOnline(workstationId) {
    const sellerPusherChannel = pusherChannels[mainSellerChannelName];
    return !!sellerPusherChannel?.members.get(workstationId);
  }

  static getIsChannelSubscribed = channelName => {
    return Boolean(pusherChannels[channelName]);
  };

  static getMainSellerChannelName() {
    return mainSellerChannelName;
  }

  static getSessionId() {
    return pusherClient.sessionID;
  }

  static tearDown() {
    Object.keys(pusherChannels).forEach(channelName => {
      this.tearDownChannel(channelName);
    });
  }
}
