import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from 'uuid';

import Noop from '../../default-props/noop';

import style from './checkbox.less';

const Checkbox = class extends React.PureComponent {
  onChange = event => {
    this.props.onChange(event.target.checked, event);
  };

  render() {
    const {
      name,
      inputId,
      value,
      disabled,
      checked,
      onClick,
      label,
      largeCheckbox,
      indeterminate,
      className,
      children
    } = this.props;

    const classesForContainer = classnames(
      style.checkbox,
      className,
      { [style.checked]: checked },
      { [style.disabled]: disabled },
      { [style['large-checkbox']]: largeCheckbox },
      { [style.indeterminate]: indeterminate },
      { [style['no-text']]: !label }
    );
    const inputElementId = inputId || `checkbox-${uuid.v4()}`;
    return (
      <div className={classesForContainer}>
        <input
          type="checkbox"
          name={name || inputElementId}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={this.onChange}
          id={inputElementId}
        />
        <label htmlFor={inputElementId}>
          <span className={style['label-text']} onClick={onClick}>
            {label}
            {children}
          </span>
        </label>
      </div>
    );
  }
};

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputId: PropTypes.string,
  largeCheckbox: PropTypes.bool,
  indeterminate: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Checkbox.defaultProps = {
  name: '',
  value: '',
  disabled: false,
  label: '',
  inputId: undefined,
  largeCheckbox: false,
  indeterminate: false,
  onClick: Noop,
  className: '',
  children: null
};

export default Checkbox;
