import ApiHttpService from './api-http-service';
import { getFromCacheOrSource } from './api-cache-service';
import ShipEngineAppDataService from '../shipengine-app-data-service';

/**
 * Account Services that relate to Billing and Subscriptions
 */
export default class AccountService {
  /**
   * @param {string} path: Suffix for the account endpoint
   * @returns {string}
   */
  static buildPath(path) {
    return `account/${path}`;
  }

  static getBillingInfo() {
    return getFromCacheOrSource(
      () => ShipEngineAppDataService.getSEBillingInfo(),
      () => ApiHttpService.get(this.buildPath('billinginfo')),
      data => {
        ShipEngineAppDataService.saveSEBillingInfoToCache(data);
      }
    );
  }

  /**
   * @param {bool} includeUsage: adds the includeUsage parameter to plan info
   * @returns {*}
   */
  static getPlanInfo(includeUsage) {
    const includeUsageParameter = `includeUsage=${
      includeUsage ? 'true' : 'false'
    }`;

    return getFromCacheOrSource(
      () => ShipEngineAppDataService.getSEPlanInfo(),
      () =>
        ApiHttpService.get(this.buildPath(`planinfo?${includeUsageParameter}`)),
      data => {
        ShipEngineAppDataService.saveSEPlanInfoToCache(data);
      }
    );
  }

  static manageSubscription() {
    return ApiHttpService.get(this.buildPath('manageSubscription'));
  }

  static getInvoicePdf(invoiceNumber) {
    return ApiHttpService.get('shipengine/get_invoice_pdf/' + invoiceNumber);
  }

  static getInvoices() {
    return ApiHttpService.get('shipengine/get_invoices');
  }

  /**
   * Change the current plan of a seller.
   * @param {string} PlanCode: Plan code the seller is changing to.
   * @param {number} AddOnPlatforms: Additional platforms to add-on to seller.
   * @param {number} AddOnUsers: Additional users to add-on to seller.
   */
  static changePlan({ PlanCode, AddOnPlatforms = 0, AddOnUsers = 0 }) {
    const planPayload = {
      AddOnPlatforms,
      AddOnUsers,
      PlanCode,
      HostUrl: this.getHostUrl()
    };

    ShipEngineAppDataService.clearCacheSEPlanInfo();
    return ApiHttpService.post(this.buildPath('changeplan'), planPayload);
  }

  static getCancelInfo() {
    return getFromCacheOrSource(
      () => ShipEngineAppDataService.getSECancelInfo(),
      () => ApiHttpService.get(this.buildPath('cancel')),
      data => {
        ShipEngineAppDataService.saveSECancelInfoToCache(data);
      }
    );
  }

  /**
   * Cancels subscription
   * @param {number} CancelReason
   * @param {string} CancelReasonText
   * @param {number} FollowUpReason
   * @param {string} FollowUpReasonText
   * @param {string} Explanation
   * @param {string} HostUrl
   * @returns {*}
   */
  static cancelPlan({
    CancelReason,
    CancelReasonText,
    FollowUpReason,
    FollowUpReasonText,
    Explanation
  }) {
    const cancelPayload = {
      CancelReason,
      CancelReasonText,
      FollowUpReason,
      FollowUpReasonText,
      Explanation,
      HostUrl: this.getHostUrl()
    };

    ShipEngineAppDataService.clearCacheSEPlanInfo();
    ShipEngineAppDataService.clearCacheSECancelInfo();
    return ApiHttpService.post(this.buildPath('cancel'), cancelPayload);
  }

  static reactivate() {
    ShipEngineAppDataService.clearCacheSEPlanInfo();
    ShipEngineAppDataService.clearCacheSECancelInfo();
    return ApiHttpService.post(this.buildPath('reactivate'));
  }

  /**
   * Updates the billing information for the seller.
   * @param {string} CompanyName
   * @param {string} FirstName
   * @param {string} LastName
   * @param {string} Email
   * @param {string} Address1
   * @param {string} Address2
   * @param {string} City
   * @param {string} State
   * @param {string} PostalCode
   * @param {string} Country
   * @param {string} Phone
   * @param {string} AccountCode
   * @param {string} CC
   * @param {string} CCV
   * @param {string} CardExpMo
   * @param {string} CardExpYear
   * @param {string} CCLastFour
   * @param {number} CCExpMO
   * @param {number} CCExpYr
   * @param {string} CCType
   */
  static updateBillingInfo({
    CompanyName,
    FirstName,
    LastName,
    Email,
    Address1,
    Address2,
    City,
    State,
    PostalCode,
    Country,
    Phone,
    AccountCode,
    CC,
    CCV,
    CardExpMo,
    CardExpYear,
    CCLastFour,
    CCExpMO,
    CCExpYr,
    CCType
  }) {
    const updateBillingInfoPayload = {
      CompanyName,
      FirstName,
      LastName,
      Email,
      CCLastFour,
      CCExpMO,
      CCExpYr,
      CCType,
      Address1,
      Address2,
      City,
      State,
      PostalCode,
      Country,
      Phone,
      AccountCode,
      CC,
      CCV,
      CardExpMo,
      CardExpYear
    };

    ShipEngineAppDataService.clearCacheSEBillingInfo();
    return ApiHttpService.post(
      this.buildPath('billinginfo'),
      updateBillingInfoPayload
    );
  }

  /**
   * Contacts the shipengine sales team with a request to upgrade to the enterprise plan
   * @param {string} message
   */
  static requestEnterprisePlan(message) {
    return ApiHttpService.post(
      this.buildPath('RequestEnterprisePlan'),
      message
    );
  }

  /**
   * Returns the hostUrl if not provided, or the provided one if provided.
   * @returns {string}
   */
  static getHostUrl() {
    return window.location.host;
  }

  /**
   * Returns the identity name from the auth'd user
   */
  static whoAmI() {
    return ApiHttpService.get(this.buildPath('whoami'));
  }
}
