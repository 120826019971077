const operatingSystem = () => {
  const { appVersion } = window.navigator;
  if (appVersion.indexOf('Win') !== -1) {
    return 'Windows';
  }
  if (appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }
  if (appVersion.indexOf('X11') !== -1) {
    return 'UNIX';
  }
  if (appVersion.indexOf('Linux') !== -1) {
    return 'Linux';
  }
  return 'Unknown OS';
};

export default operatingSystem;
