import moment from 'moment';
import { ApiUrl } from '../app/Config';
import CostSummaryService from 'client/services/cost-summary-service';
import ShipEngineAppDataService from 'client/services/shipengine-app-data-service';
import AppDataService from 'client/services/app-data-service';

define(['Data'], function(Data) {
  return $.extend(
    {},
    {
      test: false,
      p: 0,
      f: 0,
      permit: function(r) {
        if (this.test) {
          this.proviso = this.p;
        }
        var proviso = this.proviso;
        var sd = (2048 & proviso) == 2048;

        //If the user doesn't have enhanced security treat them as an administrator
        const featureMask = Data.FeatureMask || AppDataService.getFeatureMask();
        if (!this.hasFeature(32) && !sd && featureMask != 0) {
          proviso = 2;
        }
        var nd = r & proviso;
        var ad = 2 & proviso;

        return nd == r || (ad == 2 && r != 2048) || sd;
      },
      hasFeature: function(r) {
        if (this.test) {
          return r & this.f;
        }
        const featureMask = Data.FeatureMask || AppDataService.getFeatureMask();
        return (r & featureMask) == r;
      },
      useESSearch: function(override) {
        if (typeof override !== 'undefined') {
          this.ESSearchOverride = override;
        } else {
          if (typeof this.ESSearchOverride !== 'undefined') {
            return this.ESSearchOverride;
          } else {
            return this.hasFeature(65536);
          }
        }
      },
      inventoryAccessLevel: function() {
        // implemented in this way because the permissions come from two different sources
        //public enum InventoryAccess { Advanced = 3, Regular = 2, Restricted = 1, None = 0 }; //the terms here are mirroring IN-71
        if (!this.hasFeature(4096)) return 0;
        //none - seller feature doesnt exist
        else {
          if (this.permit(2) || this.permit(4096)) return 3;
          //all permissions
          else if (this.restrictions.RestrictInventory) return 1;
          // restricted inventory user
          else return 2; //regular inventory user
        }
        return 1; // default
      },
      settings: function(name, value, cb) {
        //If no arguments were passed return current settings
        if (name === undefined) {
          return Data.UserSettings;
        }
        //If setting a group of values with an object
        else if (_.isObject(name)) {
          if (typeof value == 'function') {
            cb = value;
          }
          var changedObj = this.getChangedProperties(name, Data.UserSettings);
          if (changedObj == null) return;
          return $.post(
            ApiUrl + 'profile/savesettings',
            changedObj,
            'json'
          ).done(function(data) {
            Data.UserSettings = $.extend(Data.UserSettings, name);
            if (data.timezone) Data.TimeZoneInfo = data.timezone;

            if (typeof cb == 'function') cb(value);
          });
        }
        //If setting a single name/value pair
        else if (value !== undefined) {
          if (_.isObject(value)) value = JSON.stringify(value);
          if (Data.UserSettings[name] !== value) {
            //nothing changed
            Data.UserSettings[name] = value;
            return $.post(
              ApiUrl + 'profile/savesetting',
              {
                name: name,
                value: value
              },
              'json'
            ).done(function() {
              if (typeof cb == 'function') cb(value);
            });
          }
        }
        //Retrieve a single value
        else {
          return Data.UserSettings[name];
        }
      },
      getChangedProperties: function(obj, compareTo) {
        //make sure something has changed and if so only send those properties
        var somethingChanged = false;
        var changedObj = {};
        for (var prop in obj) {
          //we don't want to compare if its a function
          if (_.isFunction(obj[prop])) continue;

          var origVal = compareTo[prop];
          var newVal = obj[prop];
          if (origVal !== newVal) {
            somethingChanged = true;
            changedObj[prop] = obj[prop];
          }
        }
        if (somethingChanged) return changedObj;
        else return null;
      },
      personalizeDate: function(dt) {
        var dtFormat = this.settings('UI:DateFormat')
          ? this.settings('UI:DateFormat')
          : 'MM/DD/YYYY';
        var localized = moment(dt).format('LLL');
        var personalized = new moment(localized).format(dtFormat);
        return personalized;
      },
      personalizeDateTime: function(dt) {
        var dtFormat = this.settings('UI:DateFormat')
          ? this.settings('UI:DateFormat')
          : 'MM/DD/YYYY';
        var tFormat = this.settings('UI:TimeFormat')
          ? this.settings('UI:TimeFormat')
          : 'hh:mm az';
        var combined = dtFormat + ' ' + tFormat;
        var localized = moment(dt).format('LLL');
        var personalized = new moment(localized).format(combined);
        return personalized;
      },
      personalizeDateTimeInString: function(val) {
        //if the message has a date and/or time in it parse it out and format it with the users preference
        var reg = /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/g;
        var timeReg = /^(1[0-2]|0?[1-9]):([0-5]?[0-9]):([0-5]?[0-9])?$/g;
        var timeRegDuec = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])?$/g;
        var fmt = this.settings('UI:DateFormat')
          ? this.settings('UI:DateFormat')
          : null;
        var timeFmt = this.settings('UI:TimeFormat')
          ? this.settings('UI:TimeFormat')
          : null;
        if (val && (fmt || timeFmt)) {
          var msgParts = val.split(' ');
          var result = [];
          for (var i = 0; i < msgParts.length; i++) {
            var msgPart = msgParts[i];
            if (fmt && msgPart.match(reg) && isNaN(msgPart)) {
              // don't create a moment if it is just a 4-digit number (2015 was evluating true for the regex, so it was parsing to 12/31/2014)
              result.push(new moment(msgPart).format(fmt));
            } else if (
              timeFmt &&
              (msgPart.match(timeReg) || msgPart.match(timeRegDuec)) && //it matches
              msgParts.length >= i + 1 && //there is another item next in the collection
              (msgParts[i + 1].toLowerCase() == 'am' ||
                msgParts[i + 1].toLowerCase() == 'pm')
            ) {
              result.push(
                new moment(
                  '1/1/2000 ' + msgPart + ' ' + msgParts[i + 1]
                ).format(timeFmt)
              ); //fake the date it's not important for the time formatting
              i += 1; //we want to skip ahead because formatting will put the am/pm in if need be
            } else {
              result.push(msgPart);
            }
          }
          return result.join(' ');
        } else {
          return val;
        }
      },
      isOnboarding: function(variation) {
        // TJS_TODO: include the check for account conversion date

        if (!variation) {
          variation = 'UPDATED';
        }

        if ((Data.Settings.SetupComplete || 'false').toLowerCase() === 'true') {
          return false;
        }

        var campaignCode = Data.ABTestCampaigns.getVariationCodeForCampaignCode(
          'ONBOARD'
        );
        if (campaignCode && campaignCode === variation) {
          return true;
        }

        // Note: just for any "legacy" new accounts not yet in the A/B test
        if (!campaignCode && variation === 'ORIGINAL') {
          return true;
        }

        return false;
      },
      getOnboardingHash: function() {
        if (
          Data.ABTestCampaigns.isInABTestCampaignVariation('ONBOARD', 'UPDATED')
        ) {
          return '#/onboard';
        }

        return '#/dashboard';
      },
      hasZeroStateGrids: function(variation) {
        if (!variation) {
          variation = 'UPDATED';
        }

        var campaignCode = Data.ABTestCampaigns.getVariationCodeForCampaignCode(
          'ZeroStateGrids'
        );
        if (!campaignCode) {
          //for existing people (those that didn't just signup) always use zero state grids
          return true;
        } else if (campaignCode && campaignCode === variation) {
          return true;
        }
        return false;
      },
      hasMultipleProviderAccounts: function(providerid) {
        if (AppDataService.getIsShipEngine()) {
          const providerAccounts =
            ShipEngineAppDataService.getSECurrentCarriers() || [];
          const filtered = providerAccounts.filter(providerAccount => {
            return providerAccount.ProviderID === providerid;
          });
          return filtered.length > 1;
        }
        const filteredSS = _.filter(Data.LightSellerProviders.models, p => {
          return p.get('ProviderID') === providerid;
        });
        return filteredSS.length > 1;
      },
      isPrimarySellerProvider: function(sellerProviderID) {
        var prov = Data.LightSellerProviders.findWhere({
          SellerProviderID: sellerProviderID
        });
        return prov && prov.get('IsPrimaryAccount');
      },
      getSellerProviderNickname: function(sellerProviderID) {
        var prov = Data.LightSellerProviders.findWhere({
          SellerProviderID: sellerProviderID
        });
        return prov && prov.get('Nickname');
      },
      getBilledToColumnText: function(mdl) {
        var billedTo = '';
        var billToParty = mdl.get('BillToParty');
        var hasMAPCEnabled = App.user.hasFeature(262144);
        if (billToParty === 1) {
          billedTo += 'Recipient';
          if (mdl.get('BillToAccount')) {
            billedTo += ' - ' + mdl.get('BillToAccount');
          }
        } else if (billToParty === 2) {
          billedTo += 'Third Party';
          if (mdl.get('BillToAccount')) {
            billedTo += ' - ' + mdl.get('BillToAccount');
          }
        } else if (billToParty === 4 && mdl.get('SellerProviderID')) {
          var prov = Data.LightSellerProviders.findWhere({
            SellerProviderID: mdl.get('SellerProviderID')
          });
          if (prov) {
            billedTo += prov.get('ProviderName');
            if (hasMAPCEnabled && prov.get('Nickname')) {
              billedTo += ' - ' + prov.get('Nickname');
            } else if (hasMAPCEnabled && prov.get('AccountNumber')) {
              billedTo += ' - ' + prov.get('AccountNumber');
            }
          }
        } else if (billToParty === 0) {
          var prov = Data.LightSellerProviders.findWhere({
            ProviderID: mdl.get('ProviderID'),
            IsPrimaryAccount: true
          });
          if (prov) {
            billedTo += prov.get('ProviderName');
            if (hasMAPCEnabled && prov.get('Nickname')) {
              billedTo += ' - ' + prov.get('Nickname');
            } else if (hasMAPCEnabled && prov.get('AccountNumber')) {
              billedTo += ' - ' + prov.get('AccountNumber');
            }
            var hasOtherAccounts = App.user.hasMultipleProviderAccounts(
              mdl.get('ProviderID')
            );
            var isPrimarySellerProvider = App.user.isPrimarySellerProvider(
              mdl.get('SellerProviderID')
            );
            if (hasOtherAccounts && isPrimarySellerProvider) {
              billedTo += ' (Primary)';
            }
          }
        }

        return billedTo;
      },
      hasAutoProvisionedProvider: function(providerId) {
        return CostSummaryService.isProviderAutoProvisioned(providerId);
      }
    }
  );
});
