define(['backbone', 'models/OrderWorkflow'], function(Backbone, OrderWorkflow) {
  return Backbone.Collection.extend({
    forStatus: function(statusId) {
      var x = this.filter(function(d) {
        return d.get('OrderStatusID') == statusId;
      });
      return x;
    },
    model: OrderWorkflow
  });
});
