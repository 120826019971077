const hostNameRegularExpression = new RegExp(
  /^(((https?|ftp):\/\/)?|([a-z0-9])\.)[a-z0-9-]+(\.[a-z0-9-]+)+([\/?].*)?$/i
);

const HostNameValidationMethod = {
  name: 'hostname',
  errorMessage: 'The URL is not valid.',
  validateValue: value => !value || hostNameRegularExpression.test(value)
};

export default HostNameValidationMethod;
