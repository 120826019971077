import _ from 'underscore';

import { PusherService } from 'client/services/pusher/pusher-service';

const Data = require('Data');
const pubsub = require('pubsub');

const AUTO_SCALE_MIN_OZ = 0.1;

class AutoScaleChannel {
  constructor(workstationId, scaleId, channelName) {
    this.workstationId = workstationId;
    this.scaleId = scaleId;
    this.channelName = channelName;
  }

  onReading(reading) {
    const isError = reading.status === 'error';

    if (!isError && reading.weight) {
      if (reading.weight.lbs === 0 && reading.weight.oz < AUTO_SCALE_MIN_OZ) {
        let uom = (
          Data.Settings['UnitOfMeasure:Weight'] || 'ounce'
        ).toLowerCase();
        if (uom === 'ounce' || uom === 'pound') {
          uom = 'oz';
        } else {
          uom = 'gr';
        }
        const warningMessage = _.str.sprintf(
          'Automatic weight read is < %f %s',
          AUTO_SCALE_MIN_OZ,
          uom
        );
        pubsub.trigger(
          'connect:auto:scale:warning',
          this.workstationId,
          this.scaleId,
          new Error(warningMessage),
          reading.weight
        );
        return;
      }
      pubsub.trigger(
        'connect:auto:scale:read',
        this.workstationId,
        this.scaleId,
        reading.weight
      );
      return;
    }
    if (isError) {
      pubsub.trigger(
        'connect:auto:scale:error',
        this.workstationId,
        this.scaleId,
        reading.description
      );
      return;
    }
    console.error('Unknown reading status: ', reading);
  }

  getDebouncedOnReading() {
    return _.debounce(reading => this.onReading(reading), 1000);
  }

  startListening() {
    PusherService.subscribeToChannel(this.channelName).then(() => {
      PusherService.bindToEvent(
        'client-connect:auto:scale:reading',
        this.getDebouncedOnReading(),
        this.channelName
      );
    });
  }

  destroy() {
    PusherService.tearDownChannel(this.channelName);

    pubsub.trigger(
      'connect:auto:scale:close',
      this.workstationId,
      this.scaleId
    );
  }
}

export default AutoScaleChannel;
