import { PusherService } from 'client/services/pusher/pusher-service';
import notify from 'public/core/utilities/notify';

const GLOBAL_CHANNEL_NAME = '_global';

define(['backbone', 'pubsub'], function(Backbone, pubsub) {
  const messagesToIgnore = new Backbone.Collection();
  let _userId;
  let isGlobalChannelInitialized = false;
  let isSellerChannelInitialized = false;

  const isIgnored = msg => {
    const match = messagesToIgnore.find(messageToIgnore => {
      const messageObject = messageToIgnore.toJSON();
      if (msg.type != messageObject.type) {
        return false;
      }

      if (messageObject.props) {
        for (const p in messageObject.props) {
          if (msg.data[p] != messageObject.props[p]) {
            return false;
          }
        }
      }
      return true;
    });

    if (match) {
      messagesToIgnore.remove(match);
    }

    return !!match;
  };

  const onMessage = msg => {
    const notification = msg.data.Notification;
    //in case the message contains a message for the user
    if (
      notification &&
      notification.Message &&
      (!notification.UserID || notification.UserID == _userId)
    ) {
      const result = pubsub.trigger('app:message_notification', msg);
      if (result !== false && !msg.handled && !isIgnored(msg)) {
        let notificationType;

        if (notification.Type == 1) {
          notificationType = 'warning';
        } else if (notification.Type == 2) {
          notificationType = 'error';
        }

        notify(
          notification.Title,
          notification.Message,
          notification.Sticky,
          notificationType
        );
      }
    }
    pubsub.trigger('app:message:' + msg.type, msg);
  };

  return {
    start: async function(userId) {
      _userId = userId;

      if (!isGlobalChannelInitialized) {
        await PusherService.subscribeToChannel(GLOBAL_CHANNEL_NAME);

        PusherService.bindToGlobalEvent((type, data) => {
          onMessage({
            global: true,
            type: type,
            data: data
          });
        }, GLOBAL_CHANNEL_NAME);

        isGlobalChannelInitialized = true;
      }

      if (!isSellerChannelInitialized) {
        PusherService.bindToGlobalEvent((type, data) => {
          const globalMessagesToIgnore = ['store_status', 'batch_progress'];

          if (!App.user.isLabelApi || !globalMessagesToIgnore.includes(type)) {
            onMessage({
              global: false,
              type: type,
              data: data
            });
          }
        }, PusherService.getMainSellerChannelName());

        isSellerChannelInitialized = true;
      }
    },
    ignoreNotification: function(type, props) {
      messagesToIgnore.add({
        type: type,
        props: props
      });
    }
  };
});
