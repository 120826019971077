import template from './Hint.html';
import getHintsConfig from '../../data/app/getHintsConfig';

let HintConfig;
getHintsConfig().then(config => (HintConfig = config));

define(['backbone', 'pubsub', 'Help'], function(Backbone, pubsub, Help) {
  return Backbone.View.extend({
    tagName: 'a',
    className: 'ui-hint',
    attributes: {
      href: 'javascript:;',
      style: 'text-align:center',
      tabindex: '-1'
    },
    initialize: function(options) {
      var me = this;
      options = $.extend({}, options);
      this.xalign = options.xalign || 'left';
      this.yalign = options.yalign || 'top';
      this.width = options.width || null;
      this.container = options.container || null;
      this.key = options.key;
      this.tip = options.tip;
      this.view = options.view;
      this.icon = options.icon || 'icon-angle-left';
      this.zIndex = options.zIndex;
      this.offset = $.extend(
        {
          x: 0,
          y: 0
        },
        options.offset || {}
      );
      this.target = options.target;
      this.color = options.color;
      this.background = options.background;
      this.beforeShow = options.beforeShow;
      this.enabled = true;
      this.config = HintConfig[this.key];
      this.listenTo(pubsub, 'app:dismiss:hints', function() {
        me.$el.hide();
      });
      this.listenTo(pubsub, 'app:restore:hints', function() {
        me.$el.show();
      });
      this.tpl = _.template(template);
      this._super('initialize', [options]);
    },
    render: function() {
      this._super('render', arguments);
      this.rendered = true;
    },
    show: function(target, delay) {
      //make sure hints are enabled
      if (!this.enabled) {
        return;
      }

      if (!this.config) {
        return;
      }

      if (!target.length) {
        return;
      }

      var hidden = false;
      //if the user has dismissed all hints or if the user has dismissed this specific hint
      if (
        App.user.settings('Hints:Show') != 'true' ||
        App.user.settings('Dismiss:' + this.key) == 'true'
      ) {
        hidden = true;
      }

      //check any beforeShow handlers
      if (typeof this.beforeShow === 'function') {
        var result = this.beforeShow.call(this.view);
        if (!result) {
          hidden = true;
        }
      }

      //if this hint is a new feature hint, only show it to sellers before a certain seller ID
      if (this.config.sellersBefore) {
        if (this.config.sellersBefore <= parseInt(App.user.seller)) {
          return;
        }
      }

      //if this hint is an "Onboarding"hint, only show it to sellers after a certain seller ID
      if (this.config.sellersAfter) {
        if (parseInt(App.user.seller) <= this.config.sellersAfter) {
          return;
        }
      }

      if (delay === 'undefined') {
        delay = 100;
      }

      var me = this;
      setTimeout(function() {
        if (!me.rendered) {
          me.render();
        }
        $(target).append(me.el);

        if (me.zIndex) {
          me.$el.css('z-index', me.zIndex);
        }

        var $target = $(target);

        var pos = {
          left: 0,
          top: 0
        };
        if (me.xalign === 'left') {
          me.$el.css('left', me.offset.x);
        } else if (me.xalign === 'center') {
          me.$el.css('left', '50%');
        } else {
          pos = $(target).position();
          var width = $target.width();
          me.$el.css('left', pos.left + width + me.offset.x);
        }

        if (me.yalign === 'top') {
          pos = $(target).position();
          me.$el.css('top', me.offset.y);
        } else if (me.yalign === 'center') {
          var height = $target.height();
          me.$el.css('top', height / 2 + me.offset.y);
        } else {
          var height = $target.height();
          me.$el.css('top', height + me.offset.y);
        }

        if (hidden) {
          me.$el.css('display', 'none');
        } else {
          me.$el.css('display', 'block');
        }

        var hint = HintConfig[me.key];
        var content = me.tpl(
          _.extend(
            {
              content: '',
              articles: null,
              width: me.width
            },
            hint
          )
        );

        var placement = 'left';
        if (me.tip) {
          placement = me.tip.placement || 'left';
        }

        var template =
          '<div class="popover"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>';
        if (me.width) {
          template =
            '<div class="popover" style="width:auto; max-width: ' +
            me.width +
            'px"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>';
        }

        me.$el.popover({
          placement: placement || 'left',
          content: content,
          title: hint.title,
          template: template,
          html: true,
          trigger: 'manual',
          container: me.container || 'body'
        });
      }, delay);
    },
    remove: function() {
      if (this.tip) {
        this.$el.popover('destroy');
      }
      this._super('remove', arguments);
    },
    hide: function() {
      this.$el.css('display', 'none');
    },
    events: {
      click: function(e) {
        var popover = $(e.target).data('bs.popover');
        popover.toggle();
        e.preventDefault();
      },
      blur: function(e) {
        var popover = $(e.target).data('bs.popover');
        if (popover) {
          if (popover.$tip) {
            popover.$tip.hide();
          }
          popover.hide();
        }

        if (e.originalEvent && $(e.relatedTarget).is('.article-link')) {
          window.open(e.relatedTarget);
        } else if (e.originalEvent && $(e.relatedTarget).is('.dismiss-hints')) {
          Help.disableHints(this.key);
        }
      },
      'shown.bs.popover': function(e) {
        var me = this;
        pubsub.trigger('app:trackEvent', 'Click Help Beacon', {
          HelpBeacon: me.key
        });

        var popover = $(e.target).data('bs.popover');
        popover.$tip.addClass('hint-popover');

        popover.$tip
          .find('.btn')
          .mousedown(function(e) {
            var btn = $(e.currentTarget);
            if (btn.is('.dismiss-hint')) {
              App.user.settings('Dismiss:' + me.key, 'true');
              me.remove();
            } else if (btn.is('.dismiss-hints')) {
              Help.disableHints(me.key);
            }
            popover.$tip.remove();
          })
          .click(function(e) {
            e.preventDefault();
          });

        popover.$tip
          .find('a[data-article]')
          .mousedown(function(e) {
            var id = $(e.currentTarget).data('article');
            Help.showArticle(id, 'Beacon', me.key);
          })
          .click(function(e) {
            e.preventDefault();
          });
      }
    }
  });
});
