import _ from 'underscore';

import ShipstationConnectService from 'client/services/shipstation-connect-service';

import ShipstationConnectUtils from '../shipstation-connect-utils';

const Data = require('Data');
const ScalesCollection = require('collections/Scales');

const BroadcastScalesPubSubHandler = {
  // This message can be received either because I just joined the channel (Connect broadcasts this when I join)
  // or because the Connect instance just started
  event: 'app:message:client-connect:broadcast:scales',
  handler: msg => {
    if (!msg) {
      return;
    }
    const { data } = msg;
    //ignore messages that are not for me
    if (!data || (data.userId && data.userId != App.user.userId)) {
      return;
    }

    //Find the workstation that just broadcase
    const workstation = Data.Workstations.get(data.workstationId);
    if (!workstation) {
      return;
    }

    ShipstationConnectUtils.makeWorkstationOnline(workstation);

    //Scales collection in the form:
    //{i, n, s}
    //where
    //i=ID
    //n=name
    //s = shared

    //Filter the list of non-shared printers to only the list that are private to me
    let scales = _.filter(data.scales, s => {
      if (!s) {
        return false;
      }
      if (s.s) {
        return true;
      }
      return workstation.get('UserID') == App.user.userId;
    });

    scales = _.map(scales, ShipstationConnectService.mapConnectScaleToModel);

    workstation.set('Scales', new ScalesCollection(scales));
  }
};

export default BroadcastScalesPubSubHandler;
