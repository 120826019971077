import { ApiUrl } from '../app/Config';

define(['pubsub', 'backbone', 'models/InventoryWarehouse', 'Data'], function(
  pubsub,
  Backbone,
  InventoryWarehouse,
  Data
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'InventoryWarehouses',
    model: InventoryWarehouse,
    comparator: 'Name',

    updateLinkedWarehouses: function() {
      this.each(function(inv) {
        var linked = Data.Warehouses.filter(function(f) {
          return (
            f.get('InventoryWarehouseID') == inv.get('InventoryWarehouseID')
          );
        });

        inv.set('Warehouses', linked);
      });

      pubsub.trigger('inventory:warehouses:updated', function() {});
    }
  });
});
