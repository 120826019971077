import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@shipstation/svgs';
import style from './loading-indicator.less';

class LoadingIndicator extends React.PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf(['white', 'grey']),
    className: PropTypes.string
  };

  static defaultProps = {
    theme: 'grey',
    className: ''
  };

  render() {
    return (
      <div
        className={classnames(
          this.props.className,
          style['loading-indicator'],
          style[this.props.theme]
        )}
      >
        <div className={style['icon-wrapper']}>
          <Icon name="spinner" className={style.icon} version="v2" />
        </div>
      </div>
    );
  }
}

export default LoadingIndicator;
