import moment from 'moment';
import { ApiUrl } from '../app/Config';

define(['models/OrderFilter', 'models/RollbackModel'], function(
  Filter,
  RollbackModel
) {
  return RollbackModel.extend({
    idAttribute: 'OrderViewID',
    urlRoot: ApiUrl + '/Workflows',
    defaults: {
      SortBy: 'OrderDate',
      SortDir: 'desc',
      PageSize: 100
    },
    initialize: function() {
      if (this.get('OrderViewID') == 0) {
        //new records don't have an ID
        this.unset('OrderViewID');
      }

      if (this.attributes.OrderFilter) {
        this.Filter = new Filter(this.attributes.OrderFilter);
      } else {
        this.Filter = new Filter({
          FilterCriterias: []
        });
      }
    },
    save: function() {
      this.attributes.OrderFilter = this.Filter.toJSON();
      if (this.ColumnArray) {
        this.attributes.Columns = JSON.stringify(this.ColumnArray);
      }

      this._super('save', arguments);
    },
    set: function(key, value, options) {
      var attributes = {};
      if (!_.isObject(key)) {
        attributes[key] = value;
      } else {
        attributes = key;
        options = value;
      }
      options = options || {};

      if (attributes.BatchID != undefined) {
        attributes.SearchTerm = null;
      }

      if (attributes.Columns != undefined) {
        if (!attributes.Columns) attributes.columns = [];
        this.ColumnArray = JSON.parse(attributes.Columns);
      }

      var ret = this._super('set', [attributes, options]);
      return ret;
    },
    parse: function(resp) {
      var dtFormat = App.user.settings('UI:DateFormat')
        ? App.user.settings('UI:DateFormat')
        : 'MM/DD/YYYY';
      if (resp.ModifyDate)
        resp.ModifyDate = moment(resp.ModifyDate).format(dtFormat);
      if (resp.CreateDate)
        resp.CreateDate = moment(resp.CreateDate).format(dtFormat);
      if (resp.OrderFilter) {
        this.Filter = new Filter(resp.OrderFilter);
      }
      if (resp.Columns) {
        this.ColumnArray = JSON.parse(resp.Columns);
      }
      return resp;
    }
  });
});
