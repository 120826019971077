import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '5272586d-39a3-4c6f-8734-07e8768f79b1',
    defaultMessage: 'Delete'
  }),
  defineMessage({
    id: '01bb1976-1a98-44b7-a67b-5bac3fdc4100',
    defaultMessage: 'Cancel'
  })
];

export default translations;
