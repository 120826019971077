import _ from 'underscore';
import uuid from 'uuid';
import AnalyticsService from 'client/services/analytics-service';

function startSession(sessionId) {
  const eventData = {
    'Session ID': sessionId
  };
  AnalyticsService.trackEvent('Interactive Session Started', eventData);
}

function endSession(sessionId, sessionDuration) {
  const eventData = {
    'Session ID': sessionId,
    'Session Duration': sessionDuration
  };
  AnalyticsService.trackEvent('Interactive Session Ended', eventData);
}

class InteractiveSession {
  constructor() {
    this.sessionId = uuid.v4();
    this.startTime = null;
  }

  start() {
    this.startTime = new Date();
    startSession(this.sessionId);
  }

  stop(offset) {
    if (!this.sessionHasStarted()) {
      return;
    }
    const currTime = new Date();
    const sessionDurationSeconds = Math.round(
      (currTime - this.startTime - offset) / 1000
    );
    endSession(this.sessionId, sessionDurationSeconds);
  }

  sessionHasStarted() {
    return !!this.startTime;
  }
}

let onTimeout;
let isListening = false;
let session;
const fiveMinSessionTimeout = 5 * 1000 * 60;
const oneSecThrottleTime = 1000;

function stopInteractiveSession() {
  session.stop(fiveMinSessionTimeout);
  session = undefined;
}

function startInteractiveSession() {
  session = new InteractiveSession();
  session.start();
}

const onMouseMove = _.throttle(() => {
  if (!session) {
    startInteractiveSession();
  }
  if (onTimeout) {
    clearTimeout(onTimeout);
  }
  onTimeout = setTimeout(stopInteractiveSession, fiveMinSessionTimeout);
}, oneSecThrottleTime);

export default class InteractivityService {
  static stopListening() {
    if (!isListening) {
      return;
    }
    isListening = false;
    document.removeEventListener('mousemove', onMouseMove);
  }

  static startListening() {
    if (isListening) {
      return;
    }
    isListening = true;
    document.addEventListener('mousemove', onMouseMove);
  }
}
