import $ from 'jquery';

import notifyConnectJob from 'public/core/utilities/notify-connect-job';
import ShipstationConnectUtils from '../shipstation-connect-utils';

const statusToHandler = {
  cancelled: () => {
    notifyConnectJob({
      text: 'User cancelled restart',
      type: 'error',
      context: $('.modal').find('.modal-content')
    });
  },
  accepted: () => {
    notifyConnectJob({
      text: 'Request request received',
      type: 'success',
      context: $('.modal').find('.modal-content')
    });
  },
  restarting: () => {
    notifyConnectJob({
      text: 'Workstation is restarting...',
      type: 'success',
      context: $('.modal').find('.modal-content')
    });
  }
};

const RestartPubSubHandler = {
  event: 'app:message:client-connect:response:restart',
  handler: msg => {
    ShipstationConnectUtils.updateJob(msg, job => {
      job.notification.remove();

      const handler = statusToHandler[msg.data.status];
      if (handler) {
        handler();
      }

      job.def.resolve(msg.data);
    });
  }
};

export default RestartPubSubHandler;
