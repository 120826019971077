import PNotify from 'public/libs/pnotify/2.0/pnotify';
import 'pnotify.buttons'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.confirm'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.callbacks'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.desktop'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.nonblock'; // not directly referenced but needed to monkeypatch pnotify

const notifyConnectJob = args => {
  if (!args || !args.text) {
    return console.error('Invalid call to notifyConnectJob');
  }
  // const {type, args}

  const { text, context } = args;

  const type = args.type || 'success';

  const progressBarType = type === 'error' ? '' : 'active';
  const progressBarClass = type === 'error' ? 'danger' : 'success';

  const title = `
    <div class="progress progress-striped ${progressBarType}" style="width: 200px; height: 20px; font-size: 13px;">
        <div class="progress-bar progress-bar-${progressBarClass}" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%; height: 20px;">
            <span data-role="message">${text}</span>
        </div>
    </div>
    `;

  const opts = {
    buttons: {
      closer: true,
      closer_hover: false,
      sticker: false
    },
    closer_hover: false,
    delay: null,
    hide: false,
    history: false,
    icon: false,
    sticker: false,
    styling: 'bootstrap2',
    title,
    type,
    width: '250px',
    animation: 'none',
    addclass: 'print-job',
    stack: context
      ? {
          context,
          dir1: 'down',
          dir2: 'left',
          spacing1: 8,
          spacing2: 8
        }
      : undefined
  };

  if (!opts.stack) {
    delete opts.stack;
  }

  return new PNotify(opts);
};

export default notifyConnectJob;
