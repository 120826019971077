import ShippingServiceService from './shipping-service-service';
import * as ProviderService from './api/provider-service';

export default class ModalManagerService {
  static listeners = [];

  static listen(listenCallback) {
    ModalManagerService.listeners.push(listenCallback);
  }

  static launchModal(modalConfiguration) {
    if (modalConfiguration.backboneModal) {
      ModalManagerService.listeners.forEach(listener =>
        listener(modalConfiguration)
      );
    }
  }

  static launchAutoFundingModal(shippingServiceID) {
    const providerId = ShippingServiceService.getProviderIdFromId(
      shippingServiceID
    );
    ProviderService.getProvidersWithBalances().then(providers => {
      const matchingProvider = providers.shipping.find(
        provider =>
          provider.ProviderID === providerId && provider.IsPrimaryAccount
      );
      if (matchingProvider === undefined) {
        return;
      }
      this.launchAutoFundingModalByProvider(matchingProvider);
    });
  }

  static launchAutoFundingModalBySellerProviderId(sellerProviderId) {
    ProviderService.getProvidersWithBalances().then(providers => {
      const matchingProvider = providers.shipping.find(
        provider => provider.SellerProviderID === sellerProviderId
      );
      if (matchingProvider === undefined) {
        return;
      }
      this.launchAutoFundingModalByProvider(matchingProvider);
    });
  }

  static launchAutoFundingModalByProvider(provider) {
    this.launchModal({
      backboneModal: true,
      name: 'purchasePostalModal',
      provider,
      selectedOption: 'auto'
    });
  }

  static launchAutoFundingModalWithCallbackBySellerProviderId(
    sellerProviderId,
    callback
  ) {
    ProviderService.getProvidersWithBalances().then(providers => {
      const matchingProvider = providers.shipping.find(
        provider => provider.SellerProviderID === sellerProviderId
      );
      if (matchingProvider === undefined) {
        return;
      }
      this.launchModal({
        backboneModal: true,
        name: 'purchasePostalModal',
        provider: matchingProvider,
        selectedOption: 'auto',
        callback
      });
    });
  }

  static launchSimpleModal(options) {
    options.backboneModal = true;
    this.launchModal(options);
  }
}
