import AppDataService from './app-data-service';
import UserService from './user-service';

const USCountryCode = 'US';
const allowedInsuranceCountries = ['CA', 'US'];
const COUNTRIES_REQUIRING_STATES = ['US', 'CA', 'AU'];

export default class CountryService {
  static isInsuranceAllowed() {
    return allowedInsuranceCountries.includes(this.getHomeCountry());
  }

  static getAPOPostalCodes() {
    return ['09', '340', '962', '963', '964', '965', '966'];
  }

  static getHomeCountry() {
    const { homeCountry } = UserService.getUserData(true);
    return homeCountry || USCountryCode;
  }

  static countryIsNotUS(country) {
    return country !== USCountryCode;
  }

  static homeCountryIsNotUS() {
    return this.countryIsNotUS(this.getHomeCountry());
  }

  static getCurrencySymbol(countryCode) {
    const homeCountry = countryCode || this.getHomeCountry();
    if (homeCountry === 'GB') {
      return '£';
    }
    if (homeCountry === 'FR') {
      return '€';
    }
    return '$';
  }

  static getCurrencySymbolFromCurrencyCode(currencyCode) {
    if (currencyCode === 'GBP') {
      return '£';
    }
    if (currencyCode === 'EUR') {
      return '€';
    }
    return '$';
  }

  static countrySupportsShipsurance() {
    const countries = AppDataService.getCountries();
    const userHomeCountry = this.getHomeCountry();
    const currentCountry = countries.find(
      country => country.Code === userHomeCountry
    );

    return currentCountry !== false && currentCountry.ShipsuranceEnabled;
  }

  static isInternational(otherCountry) {
    if (!otherCountry) {
      return false;
    }
    return otherCountry !== this.getHomeCountry();
  }

  static getCountries() {
    return AppDataService.getCountries();
  }

  static isAPO(countryCode, postalCode) {
    return countryCode === USCountryCode && this.isAPOPostalCode(postalCode);
  }

  static isAPOPostalCode(postalCode) {
    const apoPostalCode = this.getAPOPostalCodes().reduce((current, next) => {
      return current || (postalCode || '').startsWith(next);
    }, false);

    return !!postalCode && apoPostalCode;
  }

  static isStateRequiredForAddresses(countryCode) {
    return COUNTRIES_REQUIRING_STATES.includes(countryCode.toUpperCase());
  }
}
