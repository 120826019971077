define(['backbone', 'models/OrderTag'], function(Backbone, OrderTagModel) {
  return Backbone.Collection.extend({
    model: OrderTagModel,
    comparator: function(tag) {
      if (!tag.get('Tag') || tag.get('Tag').length <= 0) {
        return null;
      }
      return tag.get('Tag')[0].toLowerCase();
    }
  });
});
