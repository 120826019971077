import V3AccessService from 'client/services/v3-access-service';

const determineIfV3 = async () => {
  const inIframeFromUrl =
    window.self !== window.top &&
    window.location.href.indexOf('v3iframe') !== -1;
  V3AccessService.setV3Confirmed(inIframeFromUrl);
};

export default determineIfV3;
