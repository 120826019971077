import { ApiUrl } from '../app/Config';

define(['models/RollbackModel'], function(RollbackModel) {
  return RollbackModel.extend({
    idAttribute: 'PresetID',
    urlRoot: ApiUrl + 'Presets',
    constructor: function(attr, options) {
      if (options) options.parse = true;
      RollbackModel.apply(this, [attr, options]);
    },
    defaults: _.extend(
      {
        HasPSPC: function() {
          return this.get('ProviderID') ||
            this.get('ServiceID') ||
            this.get('PackageTypeID') ||
            this.get('ConfirmationID')
            ? true
            : false;
        },
        HasWarehouse: function() {
          return this.get('WarehouseID') ? true : false;
        },
        HasWeight: function() {
          return (this.get('Weight') !== null &&
            this.get('Weight') !== undefined) ||
            this.get('WeightGr') ||
            this.get('WeightKg') ||
            this.get('WeightPounds') ||
            this.get('WeightPound') ||
            this.get('WeightOunce')
            ? true
            : false;
        },
        HasFillService: function() {
          return this.get('FillServiceID') ? true : false;
        },
        HasDimensions: function() {
          return this.get('HeightIn') ||
            this.get('WidthIn') ||
            this.get('LengthIn') ||
            this.get('HeightCm') ||
            this.get('WidthCm') ||
            this.get('LengthCm')
            ? true
            : false;
        },
        HasInsuranceProvider: function() {
          return this.get('InsuranceProviderID') ? true : false;
        },
        HasAnyBulkFields: function() {
          return (
            this.get('HasPSPC') ||
            this.get('HasWarehouse') ||
            this.get('HasWeight') ||
            this.get('HasDimensions') ||
            this.get('HasInsuranceProvider')
          );
        }
      },
      RollbackModel.prototype.defaults
    ),
    save: function() {
      this._super('save', arguments);
    },
    parse: function(resp) {
      if (resp.FillProviderID) {
        resp.WarehouseID = -resp.FillProviderID;
      }
      return resp;
    },
    toJSON: function() {
      var obj = this._super('toJSON', arguments);
      if (obj.WarehouseID < 0) {
        obj.FillProviderID = -obj.WarehouseID;
        obj.FillMethod = 2;
        delete obj.WarehouseID;
      } else if (obj.HasPSPC) {
        obj.FillMethod = 1;
      }

      //use the unit of measure of the preset
      obj.WeightUnitOfMeasureID =
        this.get('WeightUnitOfMeasureID') || obj.WeightUnitOfMeasureID;
      obj.DimensionUnitOfMeasureID =
        this.get('DimensionUnitOfMeasureID') || obj.DimensionUnitOfMeasureID;

      return obj;
    },
    set: function(key, value, options) {
      var attributes = {};

      if (!_.isObject(key)) {
        attributes[key] = value;
      } else {
        attributes = key;
        options = value;
      }
      options = options || {};

      if (attributes.HasPSPC === false) {
        // reset the fields in this section
        attributes.ProviderID = null;
        attributes.ServiceID = null;
        attributes.PackageTypeID = null;
        attributes.ConfirmationID = null;
      }
      if (attributes.HasWarehouse === false) {
        attributes.WarehouseID = null;
      }
      if (attributes.HasWeight === false) {
        attributes.Weight = null;
        attributes.WeightUnitOfMeasureID = null;
      }
      if (attributes.HasFillService === false) {
        attributes.FillServiceID = null;
      }
      if (attributes.HasDimensions === false) {
        attributes.Height = null;
        attributes.Width = null;
        attributes.Length = null;
        attributes.DimensionUnitOfMeasureID = null;
      }
      if (attributes.HasInsuranceProvider === false) {
        attributes.InsuranceProviderID = null;
        attributes.InsuredValue = null;
      }
      if (attributes.HasAnyBulkFields !== 'undefined') {
      }

      return this._super('set', [attributes, options]);
    }
  });
});
