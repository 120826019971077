import $ from 'jquery';

const DateAfterValidationMethod = {
  name: 'dateafter',
  errorMessage: 'End date must be after the start date',
  // don't do arrow function to be able to get currentForm from this
  validateValue: function(value, element, param) {
    const target = $(this.currentForm).find('[name="' + param + '"]');
    const end = target.val();
    return new Date(value) >= new Date(end);
  }
};

export default DateAfterValidationMethod;
