import $ from 'jquery';
import 'jqueryvalidate';
import 'jqueryvalidate.more';
import AlphanumericValidationMethod from './AlphanumericValidationMethod';
import DateAfterValidationMethod from './DateAfterValidationMethod';
import DigitsAndSemicolonValidationMethod from './DigitsAndSemicolonValidationMethod';
import FloatAndSemicolonValidationMethod from './FloatAndSemicolonValidationMethod';
import HostNameValidationMethod from './HostnameValidationMethod';
import NotEqualsZeroValidationMethod from './NotEqualsZeroValidationMethod';
import PoundsAndOuncesValidationMethod from './PoundsAndOuncesValidationMethod';
import PasswordValidationMethod from './PasswordValidationMethod';
import SimpleHtmlValidationMethod from './SimpleHtmlValidationMethod';
import TooltipError from './TooltipError.html';

//Deprecated version
$.validator.setDefaults({
  highlight: function(element) {
    if ($(element).is('[type="checkbox"]')) {
      $(element)
        .closest('label:not(.noerrortreatment)')
        .addClass('error');
      return;
    }
    $(element)
      .addClass('error')
      .closest('.form-group:not(.noerrortreatment)')
      .addClass('error');
  },
  unhighlight: function(element) {
    const tip = $(element).data('bs.tooltip');
    if (tip && tip.$tip) {
      tip.$tip.remove();
    }
    $(element).tooltip('hide');
    $(element)
      .removeClass('error')
      .closest('.form-group')
      .removeClass('error');
  },
  invalidHandler: function(e) {
    $(e.currentTarget).tooltip({
      trigger: 'hover',
      html: true,
      container: 'body',
      selector: '.error[title]',
      template: TooltipError
    });
  },
  errorPlacement: function(error, element) {
    $(element).attr('title', error.html());
  }
});

const validatorMethods = [
  AlphanumericValidationMethod,
  DateAfterValidationMethod,
  DigitsAndSemicolonValidationMethod,
  FloatAndSemicolonValidationMethod,
  HostNameValidationMethod,
  NotEqualsZeroValidationMethod,
  PasswordValidationMethod,
  PoundsAndOuncesValidationMethod,
  SimpleHtmlValidationMethod
];

validatorMethods.forEach(validatorMethod =>
  $.validator.addMethod(
    validatorMethod.name,
    validatorMethod.validateValue,
    validatorMethod.errorMessage
  )
);
