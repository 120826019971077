import { defineMessage } from 'react-intl';

const translations = [
  defineMessage({
    id: '1622156b-06fe-4daa-a0fb-fac7bec73211',
    defaultMessage: "Okay, Let's Proceed!"
  })
];

export default translations;
