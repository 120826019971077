import ApiHttpService from './api-http-service';

export default class ProfileService {
  static saveSetting(settingName, settingValue) {
    ApiHttpService.post('profile/saveSetting', {
      name: settingName,
      value: settingValue
    });
  }
}
