import PNotify from 'pnotify';
import 'pnotify.buttons'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.confirm'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.callbacks'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.desktop'; // not directly referenced but needed to monkeypatch pnotify
import 'pnotify.nonblock';

const notify = function(title, message, stick, type, options) {
  let delay = stick ? null : 5000;
  const stickAsNumber = Number.parseFloat(stick);

  if (!Number.isNaN(stickAsNumber)) {
    delay = stick;
  }

  if (type && type.toLowerCase() === 'warning') {
    type = 'info';
  }

  const opts = {
    buttons: {
      closer: true,
      closer_hover: false,
      sticker: false
    },
    closer_hover: false,
    delay: delay,
    hide: stick !== true,
    history: false,
    icon: false,
    sticker: false,
    styling: 'bootstrap2',
    text: message,
    title: title,
    type: type || 'success',
    width: '400px',
    animation: 'none'
  };

  return new PNotify({ ...opts, ...options });
};

export default notify;
