import $ from 'jquery';

const PASSWORD_MINIMUM_LENGTH = 7; //default required password length
const classesToMinLengthMap = {};

const PasswordValidationMethod = {
  name: 'password',
  validateValue: (value, element, params) => {
    if (params.minlength) {
      classesToMinLengthMap[element.classes] = params.minlength;
    }
    const minimumLength = params.minlength || PASSWORD_MINIMUM_LENGTH;

    let regularExpressionString = `^[\\S]{${minimumLength},}$`;
    if ($(element).hasClass('allow-whitespace')) {
      regularExpressionString = regularExpressionString.replace(
        '\\S',
        '\\S\\s'
      );
    }
    const regularExpression = new RegExp(regularExpressionString);
    if ($(element).hasClass('cust-min-len')) {
      return true; //allow stamps password validation to override
    }
    return !value || regularExpression.test(value);
  },
  errorMessage: (value, element) => {
    const minimumLength =
      classesToMinLengthMap[element.classes] || PASSWORD_MINIMUM_LENGTH;
    let msg = `Passwords must be at least ${minimumLength} characters long`;
    if (!$(element).hasClass('allow-whitespace')) {
      msg = msg.concat(' and cannot contain spaces');
    }
    return $.validator.format(msg, value);
  }
};

export default PasswordValidationMethod;
