define(['backbone', 'models/ProductType'], function(
  Backbone,
  ProductTypeModel
) {
  return Backbone.Collection.extend({
    model: ProductTypeModel,
    initialize: function() {
      var me = this;

      this.reset([
        {
          ProductTypeID: 1,
          Name: 'Standalone'
        },
        {
          ProductTypeID: 2,
          Name: 'Parent'
        },
        {
          ProductTypeID: 3,
          Name: 'Variant'
        }
      ]);

      // gotta set this *after* adding the records or the collection gets all messed up (it's used internally by reset/add)
      this.get = function(id) {
        var model = Backbone.Collection.prototype.get.call(me, id);

        return model || Backbone.Collection.prototype.get.call(this, 1);
      };
    },
    getSelectableTypes: function() {
      var me = this;

      return me.map(function(pt) {
        return {
          model: pt,
          isSelectable: pt.id !== ProductTypeModel.TYPES.VARIANT
        };
      });
    },
    getFilterableTypes: function() {
      return this.filter(function(pt) {
        return pt.id !== ProductTypeModel.TYPES.VARIANT;
      });
    }
  });
});
