import ShipstationConnectUtils from '../shipstation-connect-utils';

const ScaleReadPubSubHandler = {
  event: 'app:message:client-connect:response:scale:read',
  handler: msg => {
    let requestId;
    if (msg && msg.data) {
      requestId = msg.data.requestId;
    }
    if (!requestId) {
      return;
    }

    ShipstationConnectUtils.updateJob(msg, job => {
      job.callback(null, msg.data);
    });
  }
};

export default ScaleReadPubSubHandler;
