export default {
  id: 'shipstation-default',
  app: 'shipstation',
  name: 'ShipStation Palette',
  buttonClasses: {
    primary: 'button-primary-shipstation',
    danger: 'button-danger',
    '': 'button',
    dark: 'button-dark',
    unstyled: 'button-unstyled',
    'primary-green': 'button-primary-green-shipstation',
    white: 'button-white'
  },
  pillClasses: {
    planned: 'planned-status-theme',
    completed: 'completed-status-theme',
    onHold: 'on-hold-status-theme',
    inProgress: 'in-progress-status-theme',
    '': 'pill'
  }
};
