import { ApiUrl } from '../app/Config';

define(['models/RollbackModel'], function(RollbackModel) {
  return RollbackModel.extend({
    urlRoot: ApiUrl + 'Tracking',
    idAttribute: 'TrackPageTemplateID',
    defaults: {
      contact_email2: function() {
        return this.get('contact_email');
      }
    },
    parse: function(resp) {
      if (resp.settings) {
        // hack to get UI to work with database values of 'True' and 'False'. UI expects 1 or 0. Database auto-converts 1/0 to True/False.
        resp.settings.dont_update_packaged =
          resp.settings.dont_update_packaged.toLowerCase() === 'true' ? 1 : 0;
        return resp.settings;
      }
      return resp;
    }
  });
});
