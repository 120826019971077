const Data = require('Data');
const PrintersCollection = require('collections/Printers');
const ScalesCollection = require('collections/Scales');

const MemberRemovedPubSubHandler = {
  // This message can be received either because I just joined the channel (Connect broadcasts this when I join)
  // or because the Connect instance just started
  event: 'app:message:pusher:member_removed',
  handler: msg => {
    let member = undefined;
    if (msg && msg.data) {
      member = msg.data.info;
    }

    if (!member || member.type !== 'Workstation') {
      return;
    }

    const workstation = Data.Workstations.findWhere({
      WorkstationID: member.workstationId
    });
    if (!workstation) {
      return;
    }
    workstation.set('OutOfDate', null);
    workstation.set('Online', false);
    workstation.set('Printers', new PrintersCollection());
    workstation.set('Scales', new ScalesCollection());
  }
};

export default MemberRemovedPubSubHandler;
