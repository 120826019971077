export default class UnitOfMeasureService {
  static OUNCES = {
    id: 1,
    name: 'ounces',
    measurementSystem: 'imperial',
    displayString: 'oz'
  };
  static GRAMS = {
    id: 2,
    name: 'grams',
    measurementSystem: 'metric',
    displayString: 'g'
  };
  static INCHES = {
    id: 3,
    name: 'inches',
    measurementSystem: 'imperial',
    displayString: 'in'
  };
  static CENTIMETERS = {
    id: 4,
    name: 'centimetres',
    measurementSystem: 'metric',
    displayString: 'cm'
  };
  static POUNDS = {
    id: 5,
    name: 'pounds',
    measurementSystem: 'imperial',
    displayString: 'lbs'
  };
  static KILOGRAMS = {
    id: 6,
    name: 'kilograms',
    measurementSystem: 'metric',
    displayString: 'kg'
  };

  static unitsOfMeasure = [
    UnitOfMeasureService.OUNCES,
    UnitOfMeasureService.GRAMS,
    UnitOfMeasureService.INCHES,
    UnitOfMeasureService.CENTIMETERS,
    UnitOfMeasureService.POUNDS,
    UnitOfMeasureService.KILOGRAMS
  ];

  static getNameById(id) {
    const matchingUnitOfMeasure = this.getUnitOfMeasureById(id);
    if (matchingUnitOfMeasure === undefined) {
      return undefined;
    }
    return matchingUnitOfMeasure.name;
  }

  static getUnitOfMeasureById(id) {
    return this.unitsOfMeasure.find(unitOfMeasure => unitOfMeasure.id === id);
  }

  static getMeasurementSystemForUnitOfMeasure(unitOfMeasureId) {
    const unitOfMeasure = this.getUnitOfMeasureById(unitOfMeasureId);
    if (unitOfMeasure === undefined) {
      return undefined;
    }
    return unitOfMeasure.measurementSystem;
  }
}
