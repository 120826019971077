import AppDataService from '../../../client/services/app-data-service';

export default {
  getSellerFillProviderId: ({ sellerFillProviderId, fillProviderId } = {}) => {
    if (sellerFillProviderId) {
      return sellerFillProviderId;
    }

    const sellerFillProviders = AppDataService.getSellerFillProviders() || [];
    return sellerFillProviders.find(
      sfp => sfp.FillProviderID == fillProviderId
    );
  },
  getSellerFillProvider: ({ sellerFillProviderId, fillProviderId } = {}) => {
    const sellerFillProviders = AppDataService.getSellerFillProviders() || [];
    if (sellerFillProviderId) {
      return sellerFillProviders.find(
        sfp => sfp.SellerFillProviderID == sellerFillProviderId
      );
    }

    return sellerFillProviders.find(
      sfp => sfp.FillProviderID == fillProviderId
    );
  }
};
