define(['backbone', 'models/CustomPackage'], function(
  Backbone,
  PackageTypeModel
) {
  return Backbone.Collection.extend({
    model: PackageTypeModel,
    getName: function(packageTypeId) {
      if (!packageTypeId) return '';
      var m = this.get(packageTypeId);
      if (!m) return '???';
      return m.get('Name');
    }
  });
});
