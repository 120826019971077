define(['pubsub'], function(pubsub) {
  return {
    showArticle: function(articleId, source, beacon) {
      require(['views/TopNav/SupportArticleModal'], function(ArticleModal) {
        var w = new ArticleModal({
          articleId: articleId,
          viewSource: source,
          beacon: beacon
        });
        w.show();
      });
    },
    enableHints: function() {
      App.user.settings('Hints:Show', 'true');
      pubsub.trigger('app:restore:hints');
      pubsub.trigger('app:trackEvent', 'Enable Interface Help');
    },
    disableHints: function(sourceBeacon) {
      App.user.settings('Hints:Show', 'false');
      pubsub.trigger('app:dismiss:hints');
      pubsub.trigger('app:trackEvent', 'Disable Interface Help', {
        HelpBeacon: sourceBeacon
      });
    },
    areHintsEnabled: function() {
      return (
        App &&
        App.user &&
        App.user.settings &&
        App.user.settings('Hints:Show') === 'true'
      );
    }
  };
});
