import moment from 'moment';
import { ApiUrl } from '../app/Config';
import CriteriaCollection from 'public/models/CriteriaCollection';

define([
  'backbone',
  'models/OrderFilterCriteria',
  'models/RollbackModel'
], function(Backbone, Criteria, RollbackModel) {
  return RollbackModel.extend({
    idAttribute: 'OrderFilterID',
    urlRoot: ApiUrl + 'Filters',
    defaults: {
      Active: true,
      isDirty: false
    },
    initialize: function() {
      this._super('initialize', arguments);
      if (this.get('OrderFilterID') == 0)
        //new records don't have an ID
        this.unset('OrderFilterID');
      this.FilterCriterias = this.nestCollection(
        'FilterCriterias',
        new CriteriaCollection(this.get('FilterCriterias'))
      );
      this.set('FilterCriterias', [], {
        silent: true
      });
      this.listenTo(
        this.FilterCriterias,
        'change',
        this.onFilterCriteriaChange
      );
      this.listenTo(this.FilterCriterias, 'add', this.onFilterCriteriaChange);
      this.listenTo(
        this.FilterCriterias,
        'remove',
        this.onFilterCriteriaChange
      );
    },

    parse: function(resp) {
      this._super('parse', arguments);
      var dtFormat = App.user.settings('UI:DateFormat')
        ? App.user.settings('UI:DateFormat')
        : 'MM/DD/YYYY';
      if (resp.ModifyDate)
        resp.ModifyDate = moment(resp.ModifyDate).format(dtFormat);

      if (resp.FilterCriterias) {
        this.set('FilterCriterias', [], {
          silent: true
        });
        this.FilterCriterias = this.nestCollection(
          'FilterCriterias',
          new CriteriaCollection(resp.FilterCriterias)
        );
        this.listenTo(
          this.FilterCriterias,
          'change',
          this.onFilterCriteriaChange
        );
        this.listenTo(this.FilterCriterias, 'add', this.onFilterCriteriaChange);
        this.listenTo(
          this.FilterCriterias,
          'remove',
          this.onFilterCriteriaChange
        );
      }

      return resp;
    },
    clone: function() {
      var res = this._super('clone', arguments);

      res.FilterCriterias = this.nestCollection(
        'FilterCriterias',
        new CriteriaCollection(this.FilterCriterias.toJSON())
      );
      res.set('OrderFilterID', 0);
      res.FilterCriterias.each(function(c) {
        c.set('OrderFilterID', 0);
        c.unset('FilterCriteriaID');
      });
      return res;
    },
    toJSON: function() {
      var obj = this._super('toJSON', arguments);
      obj.FilterCriterias = this.FilterCriterias.toJSON();
      return obj;
    },
    copy: function() {
      var res = new this.constructor({
        FilterCriterias: this.FilterCriterias.toJSON()
      });
      return res;
    },
    rollback: function() {
      if (this.lastAttributes) {
        this.FilterCriterias = this.nestCollection(
          'FilterCriterias',
          new CriteriaCollection(this.lastAttributes.FilterCriterias)
        );
        this.listenTo(
          this.FilterCriterias,
          'change',
          this.onFilterCriteriaChange
        );
        this.listenTo(this.FilterCriterias, 'add', this.onFilterCriteriaChange);
        this.listenTo(
          this.FilterCriterias,
          'remove',
          this.onFilterCriteriaChange
        );
      }
      this._super('rollback', arguments);
    },
    onFilterCriteriaChange: function() {
      this.isDirty = true;
    }
  });
});
