import $ from 'jquery';
const pubsub = require('pubsub');

const windowFocusHandler = async () => {
  $(window).focus(e => {
    if (e.target !== window) return;
    pubsub.trigger('app:window_focus');
  });
};

export default windowFocusHandler;
