import { ApiUrl } from '../app/Config';
import Enums from 'public/core/Enums';

define(['backbone', 'models/SellerProvider', 'models/Provider'], function(
  Backbone,
  SellerProviderModel,
  ProviderModel
) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Providers',
    model: function(attrs, options) {
      // this collection is sometimes used for seller provider models instead of provider models,
      // so let's set the model attribute accordingly.
      if ('SellerProviderID' in attrs) {
        return new SellerProviderModel(attrs, options);
      } else {
        return new ProviderModel(attrs, options);
      }
    },
    getName: function(providerId) {
      if (!providerId) return '';
      var m = this.findWhere({
        ProviderID: providerId
      });
      if (!m) return '???';
      return m.get('Name');
    },
    getCarrier: function(providerId) {
      if (!providerId) return null;
      var prov = this.findWhere({
        ProviderID: providerId
      });
      if (prov) return prov.get('CarrierID');
      return null;
    },
    parse: function(resp) {
      return resp.shipping;
    },
    hasStampsProvider: function() {
      return (
        this.filter(function(prov) {
          var providerId = prov.get('ProviderID');

          return (
            providerId === Enums.Provider.Stamps_com ||
            providerId === Enums.Provider.StampsWhiteLabel
          );
        }).length > 0
      );
    }
  });
});
