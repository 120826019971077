import { ApiUrl } from '../app/Config';

define(['backbone', 'collections/Printers', 'collections/Scales'], function(
  Backbone,
  PrintersCollection,
  ScalesCollection
) {
  return Backbone.Model.extend({
    urlRoot: ApiUrl + 'Workstations',
    idAttribute: 'WorkstationID',
    defaults: {
      Online: false,
      Printers: new PrintersCollection(),
      Scales: new ScalesCollection()
    },
    hidePrinter: function(printerId) {
      var hidden = window.localStorage.getItem('connect:printers:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');
      var key = this.id + '-' + printerId;
      if (!_.contains(hidden, key)) {
        hidden.push(key);
      }
      window.localStorage.setItem('connect:printers:hidden', hidden);
    },
    unhidePrinter: function(printerId) {
      var hidden = window.localStorage.getItem('connect:printers:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');
      var key = this.id + '-' + printerId;
      if (_.contains(hidden, key)) {
        hidden = _.without(hidden, key);
      }
      window.localStorage.setItem('connect:printers:hidden', hidden);
    },
    getActivePrinters: function() {
      var hidden = window.localStorage.getItem('connect:printers:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');

      this.get('Printers').each(function(p) {
        var key = this.id + '-' + p.get('id');
        p.set('hidden', _.contains(hidden, key));
      }, this);

      return this.get('Printers').filter(function(p) {
        return !p.get('disabled') && p.get('online');
      }, this);
    },

    hideScale: function(scaleId) {
      var hidden = window.localStorage.getItem('connect:scales:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');
      var key = this.id + '-' + scaleId;
      if (!_.contains(hidden, key)) {
        hidden.push(key);
      }
      window.localStorage.setItem('connect:scales:hidden', hidden);
    },
    unhideScale: function(scaleId) {
      var hidden = window.localStorage.getItem('connect:scales:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');
      var key = this.id + '-' + scaleId;
      if (_.contains(hidden, key)) {
        hidden = _.without(hidden, key);
      }
      window.localStorage.setItem('connect:scales:hidden', hidden);
    },
    getActiveScales: function() {
      var hidden = window.localStorage.getItem('connect:scales:hidden') || [];
      if (!_.isArray(hidden)) hidden = hidden.split(',');

      this.get('Scales').each(function(s) {
        var key = this.id + '-' + s.get('id');
        s.set('hidden', _.contains(hidden, key));
      }, this);

      var scales = this.get('Scales').filter(function(s) {
        return !s.get('disabled');
      }, this);

      return _.sortBy(scales, function(s) {
        return (s.get('name') || '').toLowerCase();
      });
    }
  });
});
