import ApiHttpService from './api-http-service';

function prefixUrl(url) {
  return `publicData/${url}`;
}

export default class DataService {
  static getAllCarriers() {
    return ApiHttpService.get(prefixUrl('carriers'));
  }

  static getAllServices() {
    return ApiHttpService.get(prefixUrl('services'));
  }

  static getAllProviders() {
    return ApiHttpService.get(prefixUrl('providers'));
  }

  static getAllPackageTypes() {
    return ApiHttpService.get(prefixUrl('packageTypes'));
  }

  static getAllFulfillmentProviders() {
    return ApiHttpService.get(prefixUrl('fillProviders'));
  }

  static getAllMarketplaces() {
    return ApiHttpService.get(prefixUrl('marketplaces'));
  }

  static getData(dataName) {
    return ApiHttpService.get(prefixUrl(dataName));
  }
}
