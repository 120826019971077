export const ALCOHOL = 'alcohol';
export const B13A_CANADA = 'b13a-canada';
export const BILL_DUTIES_TO_SENDER = 'bill-to-sender';
export const BILL_TO = 'bill-to'; // this does NOT mean bill to third party. That's controlled by an array in helperss
export const BILL_TO_THIRD_PARTY = 'bill-to-third-party';
export const COLLECT_ON_DELIVERY = 'collect-on-delivery';
export const CONSEQUENTIAL_LOSS = 'consequential-loss';
export const DANGEROUS_GOODS = 'dangerous-goods';
export const DANGEROUS_GOODS_DHL_ECOMMERCE = 'dangerous-goods-dhl-ecommerce';
export const DANGEROUS_GOODS_PUROLATOR = 'dangerous-goods-purolator';
export const DELIVERY_MESSAGE = 'delivery-message';
export const DONT_PREPAY_POSTAGE = 'dont-prepay-postage';
export const DRY_ICE = 'dry-ice';
export const EMAIL_NOTIFICATION = 'email-notification';
export const FREIGHT_CLASS = 'freight-class';
export const HOLD_FOR_PICKUP = 'hold-for-pickup';
export const INCLUDE_RETURN_LABEL = 'include-return-label';
export const LOCAL_COLLECT = 'local-collect';
export const NON_MACHINABLE = 'non-machinable';
export const NOTIFICATION_TYPE = 'notification-type';
export const RELEASE_NO_SIG = 'shipper-release';
export const REQUIRES_ADDITIONAL_HANDLING = 'additional-handling';
export const SAFEPLACE = 'safeplace';
export const SATURDAY_DELIVERY = 'saturday-delivery';
export const SATURDAY_GUARANTEE = 'saturday-guarantee';
export const SMS_NOTIFICATION = 'sms-notification';
export const SPECIAL_HANDLING = 'special-handling';
export const THIRD_PARTY_CONSIGNEE = 'third-party-consignee';
export const CARRIER_INSURANCE = 'carrier-insurance';
