const enabled =
  process.env.NODE_ENV === 'development' ||
  (typeof window !== 'undefined' && window.PUPPETEER_TEST_RUN === true);

const generateDataTestidAttribute = testid => {
  const props = {};

  if (enabled && testid !== undefined && testid !== '') {
    props['data-testid'] = testid;
  }

  return props;
};

const sanitizeTestid = testid =>
  testid === undefined ? '' : testid.replace(/[\s-]+/g, '-').toLowerCase();

export default generateDataTestidAttribute;
export { sanitizeTestid };
