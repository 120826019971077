define(['backbone'], function(Backbone) {
  var Model = Backbone.Model.extend({
    idAttribute: 'InventoryWarehouseID',
    isLinked: function() {
      var warehouses = this.get('Warehouses');

      return warehouses && warehouses.length > 0;
    }
  });

  return Model;
});
