import { ApiUrl } from '../app/Config';

define(['backbone', 'models/Store'], function(Backbone, StoreModel) {
  return Backbone.Collection.extend({
    url: ApiUrl + 'Stores',
    model: StoreModel,
    parse: function(resp) {
      return resp.stores;
    },
    getRefreshableStores: function() {
      return this.filter(function(s) {
        return (
          s.get('Marketplace') &&
          s.get('Marketplace').CanRefresh &&
          s.get('Active')
        );
      });
    },
    hasAnyInventoryStores: function() {
      return this.any(function(s) {
        return s.get('InventoryLevels');
      });
    }
  });
});
