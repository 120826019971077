define(['backbone', 'models/Service'], function(Backbone, ServiceModel) {
  return Backbone.Collection.extend({
    getName: function(serviceId) {
      if (!serviceId) return '';
      var m = this.get(serviceId);
      if (!m) return '???';
      return m.get('Name');
    },
    model: ServiceModel
  });
});
