define(['backbone', 'models/Confirmation'], function(
  Backbone,
  ConfirmationModel
) {
  return Backbone.Collection.extend({
    getName: function(carrierID, cId) {
      if (cId == null || cId == undefined) {
        return '';
      }
      var m = this.where({
        CarrierID: carrierID,
        ConfirmationID: parseInt(cId)
      })[0];
      if (!m) {
        return '???';
      }
      return m.get('Name');
    },
    model: ConfirmationModel
  });
});
