import React from 'react';
import { render } from 'react-dom';
import { defineMessage } from 'react-intl';

import LocaleService from 'client/i18n/locale-service';
import { ApiUrl } from '../../../../../app/Config';
import ProviderRegistrationForm from 'client/components/ipaas-forms/shipping/provider-registration-form';
import V3StoreAuthRedirectService from 'client/services/v3-store-auth-redirect-service';
import 'public/less/ipaas-data-driven-modal.less';

import template from './ProviderRegistrationWizard.html';
import translations from './translations';

const jsTranslations = {
  title: defineMessage({
    id: 'views.Settings.Providers.Setup.ProviderRegistrationWizard.title',
    defaultMessage: 'Connect your {providerName} account'
  }),
  accountInformation: defineMessage({
    id:
      'views.Settings.Providers.Setup.ProviderRegistrationWizard.accountInformation',
    defaultMessage: 'Account Information'
  })
};

define(['core/Wizard'], function(Wizard) {
  return Wizard.extend({
    initialize(options) {
      this.translations = translations;
      options = options || {};
      options.width = '600px';
      options.height = '720px';
      options.title = LocaleService.getTranslatedString(jsTranslations.title, {
        providerName: options.formMetadata.providerName
      });
      this.formMetadata = options.formMetadata;
      this.providerId = options.providerId;
      this._super('initialize', [options]);
    },
    getConfig() {
      return {
        template,
        tplData: {
          providerId: this.providerId
        },
        steps: {
          Connect: {
            el: '.provider-registration-form-wizard',
            title: this.formMetadata.formSchema.jsonSchema.title
          }
        }
      };
    },
    showError(error) {
      this.$el.find('.reg-error').show();
      this.$el.find('.reg-error-message').html(error);
    },
    finish() {
      this.form.submit();
    },
    onFormSubmission(formData) {
      const me = this;
      me.mask();
      formData.providerId = me.providerId;
      $.post(
        `${ApiUrl}${
          me.formMetadata.submitUrl
            ? me.formMetadata.submitUrl
            : 'providers/register'
        }`,
        { ...formData },
        'json'
      )
        .done(data => {
          if (data.redirectUrl) {
            me.handleOAuthRedirect(data.redirectUrl);
          }

          me.close();
          me.trigger('complete');
        })
        .fail(resp => {
          me.showError(resp.responseJSON.Message);
        })
        .always(() => {
          me.unmask();
        });
      me._super('finish', arguments);
    },
    onCancel(e) {
      this.trigger('cancel');
      this._super('onCancel', e);
    },
    render() {
      this._super('render', arguments);
      this.$el.find('.wizard-crumbs').hide();
      this.$el.find('.wizard-steps').css({ top: 0, bottom: 0 });

      const jsonSchema = {
        title: LocaleService.getTranslatedString(
          jsTranslations.accountInformation
        ),
        type: 'object',
        ...this.formMetadata.formSchema.jsonSchema
      };
      const uiSchema = this.formMetadata.formSchema.uiSchema;

      this.form = render(
        <ProviderRegistrationForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          providerId={this.providerId}
          onSubmit={({ formData }) => this.onFormSubmission(formData)}
        />,
        this.$el.find('#provider-registration-form')[0]
      );
    },
    handleOAuthRedirect(redirectUrl) {
      V3StoreAuthRedirectService.chooseRedirectTarget(redirectUrl);
    }
  });
});
