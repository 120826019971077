import $ from 'jquery';

// this might be one of the reasons it's hard to upgrade jquery

//Overriding $.offset(), $.outerWidth(), & $.outerHeight() to accomodate for an issue in Firefox that causes terribly bad performance in Firefox with grids
//Very bad performance in FF when calculating offset widths for hidden elements (i.e. hidden grid columns)
//Prefer getBoundingClientRect if it is supported
export default async () => {
  var oldOffset = $.fn.offset;
  $.fn.offset = function() {
    if (arguments.length == 0 && this[0].getBoundingClientRect) {
      return this[0].getBoundingClientRect();
    } else {
      return oldOffset.apply(this, arguments);
    }
  };

  var oldOuterW = $.fn.outerWidth;
  $.fn.outerWidth = function() {
    if (
      arguments.length == 0 &&
      this.length == 1 &&
      this[0].getBoundingClientRect
    ) {
      try {
        return this[0].getBoundingClientRect().width;
      } catch (e) {
        return oldOuterW.apply(this, arguments);
      }
    } else {
      return oldOuterW.apply(this, arguments);
    }
  };

  var oldOuterH = $.fn.outerHeight;
  $.fn.outerHeight = function() {
    if (
      arguments.length == 0 &&
      this.length == 1 &&
      this[0].getBoundingClientRect
    ) {
      try {
        return this[0].getBoundingClientRect().height;
      } catch (e) {
        return oldOuterH.apply(this, arguments);
      }
    } else {
      return oldOuterH.apply(this, arguments);
    }
  };
};
