import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FancyStateIcon from '../fancy-state-icon';
import style from './error-message.less';

const ErrorMessage = ({ className, state, stateText, children }) => (
  <div
    className={classnames(style['error-message'], className, [style[state]])}
  >
    <div className={style['icon-and-label']}>
      <div className={style['state-icon']}>
        <FancyStateIcon state={state} />
      </div>
      <div className={style.label}>{stateText}</div>
    </div>
    <div className={style['children-wrapper']}>{children}</div>
  </div>
);

ErrorMessage.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(['error', 'warning']).isRequired,
  stateText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

ErrorMessage.defaultProps = {
  className: '',
  stateText: 'Error'
};

export default ErrorMessage;
