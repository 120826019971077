import { defineMessage } from 'react-intl';

import LocaleService from 'client/i18n/locale-service';

const jsTranslations = {
  general: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.general',
    defaultMessage: 'General'
  }),
  storeName: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeName',
    defaultMessage: 'Store Name'
  }),
  storeCompanyName: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeCompanyName',
    defaultMessage: 'Store Company Name'
  }),
  recipientName: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.recipientName',
    defaultMessage: 'Recipient Name'
  }),
  username: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.username',
    defaultMessage: 'Username'
  }),
  orderDate: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.orderDate',
    defaultMessage: 'Order Date'
  }),
  shipDate: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.shipDate',
    defaultMessage: 'Ship Date'
  }),
  carrierName: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.carrierName',
    defaultMessage: 'Carrier Name'
  }),
  trackingNumber: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.trackingNumber',
    defaultMessage: 'Tracking #'
  }),
  orderNumber: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.orderNumber',
    defaultMessage: 'Order #'
  }),
  scanOrderNumber: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.scanOrderNumber',
    defaultMessage: 'Scan Order #'
  }),
  sku1: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.sku1',
    defaultMessage: 'Sku #1'
  }),
  sku2: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.sku2',
    defaultMessage: 'Sku #2'
  }),
  sku3: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.sku3',
    defaultMessage: 'Sku #3'
  }),
  product1: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.product1',
    defaultMessage: 'Product #1'
  }),
  product2: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.product2',
    defaultMessage: 'Product #2'
  }),
  product3: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.product3',
    defaultMessage: 'Product #3'
  }),
  fillSku1: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.fillSku1',
    defaultMessage: 'Fill Sku #1'
  }),
  fillSku2: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.fillSku2',
    defaultMessage: 'Fill Sku #2'
  }),
  fillSku3: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.fillSku3',
    defaultMessage: 'Fill Sku #3'
  }),
  store: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.store',
    defaultMessage: 'Store'
  }),
  storePhone: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storePhone',
    defaultMessage: 'Store Phone'
  }),
  storeEmail: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeEmail',
    defaultMessage: 'Store Email'
  }),
  storeLogo: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeLogo',
    defaultMessage: 'Store Logo'
  }),
  storeWebsite: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeWebsite',
    defaultMessage: 'Store Website'
  }),
  storeFacebookLink: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeFacebookLink',
    defaultMessage: 'Store Facebook Link'
  }),
  storeTwitterLink: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeTwitterLink',
    defaultMessage: 'Store Twitter Link'
  }),
  storeInstagramLink: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.storeInstagramLink',
    defaultMessage: 'Store Instagram Link'
  }),
  recipient: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.recipient',
    defaultMessage: 'Recipient'
  }),
  recipientAddress: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.recipientAddress',
    defaultMessage: 'Recipient Address'
  }),
  recipientPhone: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.recipientPhone',
    defaultMessage: 'Recipient Phone'
  }),
  recipientEmail: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.recipientEmail',
    defaultMessage: 'Recipient Email'
  }),
  orderSlashShipment: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.orderSlashShipment',
    defaultMessage: 'Order / Shipment'
  }),
  paymentDate: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.paymentDate',
    defaultMessage: 'Payment Date'
  }),
  shippingService: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.shippingService',
    defaultMessage: 'Shipping Service'
  }),
  requestedShippingService: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.requestedShippingService',
    defaultMessage: 'Requested Shipping Service'
  }),
  itemList: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.itemList',
    defaultMessage: 'Item List'
  }),
  buyerName: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.buyerName',
    defaultMessage: 'Buyer Name'
  }),
  notesToBuyer: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.notesToBuyer',
    defaultMessage: 'Notes to Buyer'
  }),
  notesFromBuyer: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.notesFromBuyer',
    defaultMessage: 'Notes from Buyer'
  }),
  giftMessage: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.giftMessage',
    defaultMessage: 'Gift Message'
  }),
  customField1: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.customField1',
    defaultMessage: 'Custom Field #1'
  }),
  customField2: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.customField2',
    defaultMessage: 'Custom Field #2'
  }),
  customField3: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.customField3',
    defaultMessage: 'Custom Field #3'
  }),
  internalNotes: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.internalNotes',
    defaultMessage: 'Internal Notes'
  }),
  uspsZone: defineMessage({
    id: 'views.Settings.EmailTemplates.Replacements.uspsZone',
    defaultMessage: 'USPS Zone'
  })
};

const getReplacements = () => {
  const replacements = {
    subject: [
      {
        id: 1,
        title: LocaleService.getTranslatedString(jsTranslations.general),
        items: [
          {
            id: 1,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeName
            )} - [Store Name]`,
            value: '[Store Name]'
          },
          {
            id: 2,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeCompanyName
            )} - [Store Company Name]`,
            value: '[Store Company Name]'
          },
          {
            id: 3,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientName
            )} - [Recipient Name]`,
            value: '[Recipient Name]'
          },
          {
            id: 4,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientName
            )} - [Recipient Name]`,
            value: '[Buyer Name]'
          },
          {
            id: 5,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.username
            )} - [Username]`,
            value: '[Username]'
          },
          {
            id: 6,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.orderDate
            )} - [Order Date]`,
            value: '[Order Date]'
          },
          {
            id: 7,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.shipDate
            )} - [Ship Date]`,
            value: '[Ship Date]'
          },
          {
            id: 8,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.carrierName
            )} - [Carrier Name]`,
            value: '[Carrier Name]'
          },
          {
            id: 9,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.trackingNumber
            )} - [Tracking #]`,
            value: '[Tracking #]'
          },
          {
            id: 10,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.orderNumber
            )} - [Order #]`,
            value: '[Order #]'
          },
          {
            id: 11,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.trackingNumber
            )} - [Scan Order #]`,
            value: '[Scan Order #]'
          },
          {
            id: 12,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.sku1
            )} - [Sku #1]`,
            value: '[Sku #1]'
          },
          {
            id: 13,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.sku2
            )} - [Sku #2]`,
            value: '[Sku #2]'
          },
          {
            id: 14,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.sku3
            )} - [Sku #3]`,
            value: '[Sku #3]'
          },
          {
            id: 15,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.product1
            )} - [Product #1]`,
            value: '[Product #1]'
          },
          {
            id: 16,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.product2
            )} - [Product #2]`,
            value: '[Product #2]'
          },
          {
            id: 17,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.product3
            )} - [Product #3]`,
            value: '[Product #3]'
          },
          {
            id: 18,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.fillSku1
            )} - [Fill Sku #1]`,
            value: '[Fill Sku #1]'
          },
          {
            id: 19,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.fillSku2
            )} - [Fill Sku #2]`,
            value: '[Fill Sku #2]'
          },
          {
            id: 20,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.fillSku3
            )} - [Fill Sku #3]`,
            value: '[Fill Sku #3]'
          }
        ]
      }
    ],
    content: [
      {
        id: 1,
        title: LocaleService.getTranslatedString(jsTranslations.store),
        items: [
          {
            id: 1,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeName
            )} - [Store Name]`,
            value: '[Store Name]'
          },
          {
            id: 2,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storePhone
            )} - [Store Phone]`,
            value: '[Store Phone]'
          },
          {
            id: 3,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeEmail
            )} - [Store Email]`,
            value: '[Store Email]'
          },
          {
            id: 4,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeLogo
            )} - [Store Logo]`,
            value: '<img src="http://images.shipstation.com/logo/00000">',
            isHtml: true
          },
          {
            id: 5,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeWebsite
            )} - [Store Website]`,
            value: "<a href='[StoreWebsiteUrl]'>[Store Website]</a>",
            isHtml: true
          },
          {
            id: 6,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.storeWebsite
            )} - [Store Website]`,
            value: '[Store Company Name]'
          },
          {
            id: 7,
            text: `<img src="/icons/facebook.png"> ${LocaleService.getTranslatedString(
              jsTranslations.storeFacebookLink
            )} - [Store Facebook Link]`,
            value: "<a href='[StoreFacebookUrl]'>Like us on Facebook!</a>",
            isHtml: true,
            required: 'facebook'
          },
          {
            id: 8,
            text: `<img src="/icons/tweeeeeter.png"> ${LocaleService.getTranslatedString(
              jsTranslations.storeTwitterLink
            )} - [Store Twitter Link]`,
            value: "<a href='[StoreTwitterUrl]'>Follow us on Twitter!</a>",
            isHtml: true,
            required: 'twitter'
          },
          {
            id: 9,
            text: `<img src="/icons/instagram.png"> ${LocaleService.getTranslatedString(
              jsTranslations.storeInstagramLink
            )} - [Store Instagram Link]`,
            value: "<a href='[StoreInstagramUrl]'>Follow us on Instagram!</a>",
            isHtml: true,
            required: 'instagram'
          }
        ]
      },
      {
        id: 2,
        title: LocaleService.getTranslatedString(jsTranslations.recipient),
        items: [
          {
            id: 1,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientName
            )} - [Recipient Name]`,
            value: '[Recipient Name]'
          },
          {
            id: 2,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientAddress
            )} - [Recipient Address]`,
            value: '[Recipient Address]'
          },
          {
            id: 3,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientPhone
            )} - [Recipient Phone]`,
            value: '[Recipient Phone]'
          },
          {
            id: 4,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.recipientEmail
            )} - [Recipient Email]`,
            value: '[Recipient Email]'
          }
        ]
      },
      {
        id: 3,
        title: LocaleService.getTranslatedString(
          jsTranslations.orderSlashShipment
        ),
        items: [
          {
            id: 1,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.orderNumber
            )} - [Order #]`,
            value: '[Order #]'
          },
          {
            id: 2,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.scanOrderNumber
            )} - [Scan Order #]`,
            value: '[Scan Order #]'
          },
          {
            id: 3,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.shipDate
            )} - [Ship Date]`,
            value: '[Ship Date]'
          },
          {
            id: 4,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.orderDate
            )} - [Order Date]`,
            value: '[Order Date]'
          },
          {
            id: 5,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.paymentDate
            )} - [Payment Date]`,
            value: '[Payment Date]'
          },
          {
            id: 6,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.carrierName
            )} - [Carrier Name]`,
            value: '[Carrier Name]'
          },
          {
            id: 7,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.shippingService
            )} - [Shipping Service]`,
            value: '[Shipping Service]'
          },
          {
            id: 8,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.requestedShippingService
            )} - [Requested Shipping Service]`,
            value: '[Requested Shipping Service]'
          },
          {
            id: 9,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.trackingNumber
            )} - [Tracking #]`,
            value: '<a href="[TrackingUrl]">[Tracking #]</a>',
            isHtml: true
          },
          {
            id: 10,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.itemList
            )} - [Item List]`,
            value: '[Item List]'
          },
          {
            id: 11,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.buyerName
            )} - [Buyer Name]`,
            value: '[Buyer Name]'
          },
          {
            id: 12,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.username
            )} - [Username]`,
            value: '[Username]'
          },
          {
            id: 13,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.notesToBuyer
            )} - [Notes to Buyer]`,
            value: '[Notes to Buyer]'
          },
          {
            id: 14,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.notesFromBuyer
            )} - [Notes from Buyer]`,
            value: '[Notes from Buyer]'
          },
          {
            id: 15,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.giftMessage
            )} - [Gift Message]`,
            value: '[Gift Message]'
          },
          {
            id: 16,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.customField1
            )} - [Custom Field #1]`,
            value: '[Custom Field #1]'
          },
          {
            id: 17,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.customField2
            )} - [Custom Field #2]`,
            value: '[Custom Field #2]'
          },
          {
            id: 18,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.customField3
            )} - [Custom Field #3]`,
            value: '[Custom Field #3]'
          },
          {
            id: 19,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.internalNotes
            )} - [Internal Notes]`,
            value: '[Internal Notes]'
          },
          {
            id: 20,
            text: `${LocaleService.getTranslatedString(
              jsTranslations.uspsZone
            )} - [USPS Zone]`,
            value: '[USPS Zone]'
          }
        ]
      }
    ]
  };

  return replacements;
};

export default getReplacements;
