// importing backbone here just to be safe as this is the top level file.
import 'backbone';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-unresolved
import 'toast-css';
import 'public/less/style.less';
import 'public/less/main-built.less';

define([
  'jquery',
  'label-api/app/AppController',
  'label-api/app/AppRouter',
  'Session',
  'ResourceManager',
  'core/libs/extended-marionette'
], function(
  $,
  AppController,
  AppRouter,
  Session,
  // eslint-disable-next-line no-unused-vars
  ResourceManager,
  Marionette
) {
  //Only respect SSHome Cookie if its a V2 cookie

  if (jsCookie.get('SSHome')) {
    if (
      jsCookie.get('SSHome').toLocaleLowerCase() !=
        window.location.host.toLocaleLowerCase() &&
      jsCookie
        .get('SSHome')
        .toLocaleLowerCase()
        .indexOf('ss') == 0
    ) {
      window.location.href = window.location.href.replace(
        window.location.host,
        jsCookie.get('SSHome').toLocaleLowerCase()
      );
    }
  }
  // create the app
  var App = new Marionette.Application();
  App.EnableLog = false;
  App.addInitializer(function() {
    this.router = new AppRouter(new AppController());
    Session.start();
  });

  toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeButton: false
  });

  App.user = {
    permit: function() {
      return false;
    },
    hasFeature: function() {
      return false;
    }
  };

  App.flags = {};

  return App;
});
